import React, { Component } from "react";
import { Fragment } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";
import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
// import { render } from "node-sass";
import { Helmet } from "react-helmet";
export default class SME extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Unsecured Business Loan - SME</title>
          <meta
            name="description"
            content="  Unsecured business loan for SME is typically a  term loan without any kind of collateral that is offered specifically to SMEs with regular repayments at
                a fixed interest rate."
          />
          <meta
            name="tag"
            content="smecorner unsecured business loans for smes,smecorner unsecured business loans for smes jaipur rajasthan,sme corner business loan,smecorner unsecured business loans for smes mumbai maharashtra,sme business loan,business loan for sme,"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center"> Unsecured Business Loan - SME</div>
              <br />
              <div className="about-details center">
                <p className="fs-16">
                  Unsecured business loan for SME is typically a{" "}
                  <span className="highlight-blue">term loan without any kind of collateral </span>
                  that is offered specifically to SMEs with regular repayments at a fixed interest rate. Business loans
                  are given to the entity and disburse in the active current/cc account of the entity. Business having
                  positive turnover growth trend and positive profit have higher chances to get an unsecured business
                  loan.
                </p>

                <p className="fs-16 width-80">
                  This loan can be availed from multiple financial institutions at same/different time. Prepayment
                  charges to be paid in case of foreclosure between 4-6% of principal outstanding.
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="₹5 Lac - ₹10 Lac"
              loanTenure="1 - 5 years"
              processingFee="1.5% - 2.5%"
              interestRate="14% - 22%"
              decisionTime="Min 1 day"
              buttonClick=""
              buttonText="Find the Lowest Rates"
            />
          </div>

          <div className="horizontal-space"></div>
          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Loan Against Property </div>
              <div className="sub-heading_card_wrapper">When to opt for Unsecured Business Loan - SME </div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">Interest Return</div>
                  When expected return on the debt to be deployed in business is higher than interest rate to be paid.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Wide Business Range</div>
                  Suitable for wide range of business use like small capex expenditure, new product expansion.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">No collateral involved</div>
                  When funds required urgently and no collateral in hand to be offered
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Beneficial Requirement</div>
                  To maintain working capital gap.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Invoice Facility</div>
                  For inventory and machine loan
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Shorter Tenure</div>
                  In comparison to mortgage loan
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="horizontal-space" />

          <div className="center negative-margin">
          <CalculateEMI 
              businessVintage="Min 1 Day"
              financialDataRequired="Depends upon Loan Amount"
            />
          </div>

          <div className="why-loan__wrapper extra-padding center">
            <div className="horizontal-space" />

            {/* deepmala - emi calculator */}
            {/* Deepmala - challenges with busineess */}
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Unsecured Business Loan- SME</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">High Interest rate in comparison to other kinds of loan</div>
                <div className="SME_subCard_wrapper  ">No flexibility of Part payment</div>
                <div className="SME_subCard_wrapper  ">Shorter tenure increase burden of high monthly obligation</div>
              </div>
            </div>

            <OtherLoanProducts />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
