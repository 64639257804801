import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import WhiteLogo1 from "../../assets/Images/WhiteLogo1.png";
import { phone } from "react-icons-kit/icomoon/phone";
import { Icon } from "react-icons-kit";
import { API_GET_GENERATE_TOKEN } from "../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import shopwithowner from "../../assets/Images/ads/shopwithowner.png";
import simplesteps from "../../assets/Images/ads/simplesteps.png";
import testimonials from "../../assets/Images/ads/testimonials.png";
import fast from "../../assets/Images/ads/fast.png";
import spam from "../../assets/Images/ads/spam.png";
import secure from "../../assets/Images/ads/secure.png";
import collateral from "../../assets/Images/ads/collateral.png";
import interest from "../../assets/Images/ads/interest.png";
import testimony1 from "../../assets/Images/ads/testimony1.png";
import testimony2 from "../../assets/Images/ads/testimony2.png";
import Footer from "../../components/Footer/footer";
import HowCredochainWorks from "../HomePage/MainViews/howCredochainWorks";
import { checkmarkCircled } from "react-icons-kit/ionicons/checkmarkCircled";
import Select from "react-select";
import ToggleButton from "../../components/ToogleButton/ToggleButton";
import { POST } from "../../utils/WebAPI.service";
import { getUnsortedList } from "../../store/listData.actions";
import {
  API_GET_BUSINESS_TYPE_FETCH,
  API_GET_lOAN_AMOUNT_FETCH,
  API_GET_SALES_FETCH,
  API_GET_STATES_FETCH,
  API_POST_LOAN_ADVISORY_DETAILS,
} from "../../utils/APIUrls";

class AdsPage extends Component {
  constructor(props) {
    super(props);
    this.listener = null;
    this.state = {
      isLoanAvailed: false,
      mobile: "",
      lender: "",
      gstNo: "",
      loaderStatus: false,
      canContactOnWhatsapp: false,
      isEnglish: true,
      status: "top",
      dimensions: {
        width: -1,
        height: -1,
        selectedOption: null,
      },
    };
  }
  isChecked = () => {
    let { canContactOnWhatsapp } = this.state;
    this.setState({ canContactOnWhatsapp: !canContactOnWhatsapp });
  };

  HandleChange = (selectedOption, { name }) => {
    this.setState({ [name]: selectedOption.value });
  };
  inputHandleChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };
  toggleHandler = (name) => {
    this.setState({ [name]: !this.state[name] });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onTranslate = () => {
    this.setState({ isEnglish: !this.state.isEnglish });
  };

  handleSumbit = (e) => {
    e.preventDefault();
    this.setState({ loaderStatus: true });

    let {
      businessName,
      businessType,
      canContactOnWhatsapp,
      isLoanAvailed,
      mobile,
      name,
      requiredLoanAmount,
      sales,
      state,
    } = this.state;
    let data = {
      businessName,
      businessType,
      canContactOnWhatsapp,
      isLoanAvailed,
      mobile,
      name,
      requiredLoanAmount,
      sales,
      state,
    };

    POST(API_POST_LOAN_ADVISORY_DETAILS, { data })
      .then(({ data }) => {
        this.setState({ loaderStatus: false });
        if (data.status === "success") {
          this.props.history.push("thank-you");
        } else {
          window.alert(data.message);
        }
      })
      .catch((err) => {
        this.setState({ loaderStatus: false });
        throw err;
      });
  };
  componentWillMount() {
    this.id = `toggle_${Math.random().toString().replace(/0\./, "")}`;
    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 90) {
        if (this.state.status !== "anywhere") {
          this.setState({ status: "anywhere" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }
  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  componentDidMount() {
    let {
      allStates,
      allLoanAmount,
      allSales,
      BusinessType,
      actions,
    } = this.props;
    let { getUnsortedList } = actions;
    axios.get(API_GET_GENERATE_TOKEN).then(({ data }) => {
      if (data.status === "success") {
        document.cookie = `token=${data.data.tokenId}; path=/`;

        !allStates.length &&
          getUnsortedList(
            "SET_ALL_STATES",
            API_GET_STATES_FETCH,
            "Select State"
          );
        !allLoanAmount.length &&
          getUnsortedList(
            "SET_LOAN_AMOUNT",
            API_GET_lOAN_AMOUNT_FETCH,
            "Loan Amount Required"
          );
        !allSales.length &&
          getUnsortedList("SET_ALL_SALES", API_GET_SALES_FETCH, " ");
        !BusinessType.length &&
          getUnsortedList(
            "SET_BUSINESS_TYPE",
            API_GET_BUSINESS_TYPE_FETCH,
            "Business Type"
          );
      }
    });
    const script = document.createElement("script");
    script.id = "tawkId";
    script.async = true;
    script.src =
      "https://embed.tawk.to/" + "5f6a053ff0e7167d0012b782" + "/default";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);
  }

  render() {
    let { selectedOption, isEnglish } = this.state;
    let {
      allStates,
      allLoanAmount,
      allSales,
      BusinessType,
      loaderModalStatus,
    } = this.props;

    return (
      <>
        <LoaderModal status={this.state.loaderStatus} />
        <div className="ads-wrapper" onScroll={this.handleScroll} id="top">
          <div className="background-image">
            <Navbar
              expand="lg"
              id="js-header"
              className="navbar-dark brand_nav fixed-top"
              style={{
                backgroundColor: this.state.status === "top" ? null : "#001061",
                position: "fixed",
              }}
            >
              <Navbar.Brand href="/" className="brand_container">
                <img
                  src={WhiteLogo1}
                  alt="Credochain Logo"
                  style={{
                    width: this.state.status === "top" ? null : "7rem",
                  }}
                />
              </Navbar.Brand>
              <Nav.Link
                href="tel:18001205770"
                className="menu-items number number-header"
              >
                <Icon className="phone pr3" size={16} icon={phone} />
                <span>1800 120 5770</span>
              </Nav.Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto"> </Nav>
                <Nav.Link href="/" className="menu-items">
                  Home
                </Nav.Link>
                <Nav.Link href="/#loanRecommendation" className="menu-items">
                  Products
                </Nav.Link>
                <Nav.Link href="/#ourApproach" className="menu-items">
                  Approach
                </Nav.Link>
                <Nav.Link href="/" className="menu-items">
                  MSME Tools
                </Nav.Link>
                <Nav.Link href="tel:18001205770" className="menu-items number">
                  <Icon className="phone pr3" size={16} icon={phone} />
                  <span>1800 120 5770</span>
                </Nav.Link>
              </Navbar.Collapse>
            </Navbar>
            <div className="form-wrapper pt6">
              <div className="image-wrapper">
                <img className="image" src={shopwithowner} alt="retailer" />
                <div className="headline">
                  Vyapaar karna hua Aasaan!!!
                  <br />
                  <span className="b"> 5 Crore</span> tak ka Loan chutkion mein
                </div>
              </div>
              <div className="lead-form__wrapper">
                <div className="lead-form">
                  <div className="heading">
                    {isEnglish ? (
                      <div className="title pr6">
                        Join other successful <br />
                        businesses with Credochain
                      </div>
                    ) : (
                      <div className="title pr6">
                        क्रेडोचैन के साथ अन्य <br />
                        सफल व्यवसायों में शामिल हों
                      </div>
                    )}

                    <div
                      className="language pl2 pt2"
                      onClick={this.onTranslate}
                    >
                      <span className="lang pointer">
                        {isEnglish ? "हिन्दी" : "English"}
                      </span>
                    </div>
                  </div>
                  <div className="pros mt4 mb4">
                    <Icon className="check" size={25} icon={checkmarkCircled} />{" "}
                    <span className="trust">
                      {isEnglish
                        ? `Trusted by India’s leading Institutions`
                        : `भारत के प्रमुख संस्थानों द्वारा विश्वसनीय`}
                    </span>
                  </div>

                  <div className="form-content">
                    <div className="form-title">
                      {isEnglish
                        ? `Tell us a little about you`
                        : `अपने बारे में थोड़ा बताइए`}
                    </div>

                    <div className="form-fields">
                      <div className="row">
                        <input
                          type="text"
                          className="form-field"
                          placeholder={isEnglish ? `Your Name` : `आपका नाम`}
                          onChange={this.inputHandleChange}
                          name="name"
                        />
                        <input
                          type="tel"
                          className="form-field"
                          placeholder={isEnglish ? `Mobile No.` : `मोबाइल नंबर`}
                          onChange={this.inputHandleChange}
                          name="mobile"
                        />
                      </div>
                      <div className="row">
                        <input
                          type="text"
                          className="form-field"
                          placeholder={
                            isEnglish ? `Business Name` : `व्यापार का नाम`
                          }
                          onChange={this.inputHandleChange}
                          name="businessName"
                        />

                        <Select
                          name="state"
                          onChange={this.HandleChange}
                          options={allStates.map((t) => ({
                            value: t,
                            label: t,
                          }))}
                          placeholder={
                            isEnglish ? `Select State` : `राज्य चुनें`
                          }
                          className="form-select-field"
                        />
                      </div>
                      <div className="row">
                        <Select
                          name="businessType"
                          value={selectedOption}
                          onChange={this.HandleChange}
                          options={BusinessType.map((t) => ({
                            value: t,
                            label: t,
                          }))}
                          placeholder={
                            isEnglish ? `Business Type` : `व्यापार का प्रकार`
                          }
                          className="form-select-field"
                        />
                        <div className="availed-loan ml1">
                          {isEnglish ? (
                            <div className="text mt3">
                              Taken a <br /> Loan Before
                            </div>
                          ) : (
                            <div className="text mt3">
                              पेहले कबी <br />
                              लोन लिया है ?
                            </div>
                          )}
                          <div className="ml2 mt3">
                            <ToggleButton
                              toggleHandler={(name) => this.toggleHandler(name)}
                              name="isLoanAvailed"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <Select
                          name="requiredLoanAmount"
                          value={selectedOption}
                          onChange={this.HandleChange}
                          options={allLoanAmount.map((t) => ({
                            value: t,
                            label: t,
                          }))}
                          className="form-select-field"
                          placeholder={
                            isEnglish ? `Loan Amount Required` : `लोन की  राशि`
                          }
                        />
                        <Select
                          name="sales"
                          value={selectedOption}
                          onChange={this.HandleChange}
                          options={allSales.map((t) => ({
                            value: t,
                            label: t,
                          }))}
                          className={
                            isEnglish
                              ? "form-select-field"
                              : "form-select-field mr6"
                          }
                          placeholder={
                            isEnglish
                              ? `Sales in last 12 months`
                              : `पिछले 12 महीनों में बिक्री`
                          }
                        />
                      </div>

                      <div className="whatsapp-checkbox mt3 mb3">
                        <input
                          onChange={this.isChecked}
                          id={this.id}
                          type="checkbox"
                          className="mr2"
                        />
                        {isEnglish ? (
                          <label htmlFor={this.id}>
                            Let’s connect on
                            <span className="green"> Whatsapp</span>
                          </label>
                        ) : (
                          <label htmlFor={this.id}>
                            हमारे साथ
                            <span className="green"> Whatsapp </span>
                            पर जुड़ें
                          </label>
                        )}
                      </div>
                      <div
                        className="get-loan-btn tc pointer"
                        onClick={this.handleSumbit}
                      >
                        {isEnglish ? "Get Your Loan" : "अपना लोन पाएं"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why-credochain__wrapper mt6">
            <div className="tc heading">Why Credochain</div>
            <div className="benefits center flex">
              <div className="benefit flex">
                <div className="icon">
                  <img src={fast} alt="fast and secure" width={"50px"} />
                </div>
                <div className="text">Transparent and Fast</div>
              </div>
              <div className="benefit flex">
                <div className="icon">
                  <img src={interest} alt="fast and secure" width={"50px"} />
                </div>
                <div className="text">Low Interest rates</div>
              </div>
              <div className="benefit flex">
                <div className="icon">
                  <img src={collateral} alt="fast and secure" width={"50px"} />
                </div>
                <div className="text">No Collateral</div>
              </div>
              <div className="benefit flex">
                <div className="icon">
                  <img src={secure} alt="fast and secure" width={"50px"} />
                </div>
                <div className="text">Secure Online Paperless process</div>
              </div>
              <div className="benefit flex">
                <div className="icon">
                  <img src={spam} alt="fast and secure" width={"50px"} />
                </div>
                <div className="text">No Spam Calls</div>
              </div>
            </div>
          </div>

          <div className="homepage__wrapper">
            <img
              className="dn simple-steps"
              src={simplesteps}
              alt="3 simple steps"
            />
            <HowCredochainWorks
              headingText="3 simple steps"
              subHeadingText=""
              step1="Provide key information on your business and define your funding needs in under 7 minutes!"
              step2="Get approval from the best suited offers for your business within the industry best turn-around time."
              step3="Get funds in your bank account within 3 days from document submission."
            />
          </div>
          <div className="why-credochain__wrapper mt7 mb6">
            <img
              className="dn simple-steps"
              src={testimonials}
              alt="Testimonials"
            />

            <div className="tc heading mb5">
              What our customers say about us
            </div>
            <div className="testimonies w-80">
              <img className="testimony" src={testimony1} alt="testimonials" />
              <img className="testimony" src={testimony2} alt="testimonials" />
            </div>
          </div>
        </div>
        <div
          className={this.state.status === "top" ? "dn" : "get-loan tc center"}
        >
          <a href="#top">
            <div className="get-loan-btn  pointer tc white">Get Your Loan</div>
          </a>
        </div>
        <Footer phone="1800 120 5770" />
      </>
    );
  }
}
const mapStateToProps = ({ listData }) => ({
  allStates: listData.allStates,
  allLoanAmount: listData.allLoanAmount,
  allSales: listData.allSales,
  BusinessType: listData.BusinessType,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getUnsortedList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage);
