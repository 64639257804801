import * as LoanApplicationPreviewConstant from "./LoanApplicationPreview.actions.constants";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_APPLICATION_PREVIEW } from "../../../utils/APIUrls";

const getApplicationPreview = (applicationNumber) => (dispatch) => {
  return GET(API_GET_APPLICATION_PREVIEW(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: LoanApplicationPreviewConstant.APPLICATION_PREVIEW, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const updatePreviewObject = (name, value, profileIndex) => (dispatch) => {
  // console.log(name, value, profileIndex);
  return dispatch({ type: "UPDATE_PREVIEW_OBJECT", name, value, profileIndex });
};
const updatePreviewObjectAddress = (objectName, profileIndex, addressIndex , value) => (dispatch) => {
  return dispatch({ type: "UPDATE_PREVIEW_OBJECT_ADDRESS", objectName, profileIndex, addressIndex , value });
};
const deletePreviewObjectAddress = (objectName, profileIndex, addressIndex) => (dispatch) => {
  return dispatch({ type: "DELETE_PREVIEW_OBJECT_ADDRESS", objectName, profileIndex, addressIndex });
};
export { getApplicationPreview, updatePreviewObject, updatePreviewObjectAddress , deletePreviewObjectAddress };
