import React, { Component } from "react";
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import GSTDataComponent from "../../ViewsComponents/GSTData/GSTData";
import Header from "../../ViewsComponents/Header/Header";
import { getCookie } from "../../../utils/utility";
export default class GSTData extends Component {
  render() {
    let applicationNumber = getCookie("applicationNumber");
    return (
      <div className="gst-data__wrapper">
        <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "Accurate and complete business information will help us evaluate your case faster in one go!",
                "Remember to add all the addresses from where you operate your business",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9">
            <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"gst-number"} {...this.props} />
            <GSTDataComponent nextScreen={"gst-username"} applicationNumber={applicationNumber} />
          </div>
        </div>
      </div>
    );
  }
}
