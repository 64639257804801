import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { fetchLoanUse } from "../ViewsComponents/LoanPurpose/LoanPurpose.actions";
import { getTaxPayerData } from "../HomePage/homepage.actions";
import { getCookie } from "../../utils/utility";
import { setGstAddressInNewAddress } from "../ViewsComponents/GSTData/GSTData.actions";
import GSTUsername from "../BIZ3360/WithGST/GSTUsername/GSTUsername";
import GSTOTP from "../BIZ3360/WithGST/GSTOTP/GSTOTP";
import Banking from "./Banking/Banking";
import GSTData from "./GSTData/GSTData";
import KYC from "./KYC/KYC";
import GstNumber from "./GSTNumber/GstNumber";
import LoanPreference from "./LoanPreference/LoanPreference";
import LoanPurpose from "./LoanPurpose/LoanPurpose";
import AmountAndTenure from "./AmountAndTenure/AmountAndTenure";
class LoanApplication extends Component {
  render() {
    let { loaderModalStatus } = this.props;
    return (
      <div className="loan-application__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <Helmet>
          <title>User details</title>
          <meta name="description" content="Business Report for business" />
          <meta name="description" content="credit Report for business" />
          <meta name="keyword" content="how to apply for business loans,how to apply for msme loans" />
        </Helmet>
        <Switch>
          <Route path="/loan-application/loan-purpose" exact component={LoanPurpose} />
          <Route path="/loan-application/loan-preference" exact component={LoanPreference} />
          <Route path="/loan-application/loan-amount-and-tenure" exact component={AmountAndTenure} />
          <Route path="/loan-application/gst-number" exact component={GstNumber} />
          <Route path="/loan-application/gst-data" exact component={GSTData} />
          <Route path="/loan-application/gst-username" exact component={GSTUsername} />
          <Route path="/loan-application/gst-otp" exact component={GSTOTP} />
          <Route path="/loan-application/banking" exact component={Banking} />
          <Route path="/loan-application/kyc" exact component={KYC} />
        </Switch>
      </div>
    );
  }
  componentDidMount() {
    let { actions } = this.props,
      { setGstAddressInNewAddress, getTaxPayerData, fetchLoanUse } = actions;
    var gstNumber = getCookie("gstNumber");
    fetchLoanUse();
    getTaxPayerData(gstNumber).then((data) => {
      if (data.data) {  
        setGstAddressInNewAddress(data.data.gstData[0].addresses || []);
      }
    });
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchLoanUse, getTaxPayerData, setGstAddressInNewAddress }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanApplication);
