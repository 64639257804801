import React, { Component } from 'react'
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import KYCComponent from "../../../ViewsComponents/KYC/KYC";
import Header from "../../../ViewsComponents/Header/Header";
export default class KYC extends Component {
    render() {
        return (
            <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "Accurate and complete business information will help us evaluate your case faster in one go!",
                "Remember to add all the addresses from where you operate your business",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9 pr-5">
            <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"gst-data-input"} {...this.props} />
            <KYCComponent nextScreen={"report-download"}/>
          </div>
        </div>
        )
    }
}
