import { GET } from "../../utils/WebAPI.service";
import { API_GET_VERIFY_LINK } from "../../utils/APIUrls";

const validateUUID = async uuid => {
  const { data } = await GET(API_GET_VERIFY_LINK(uuid));
  return data;
};
const getUsernameAndGst = data => dispatch => {
  return dispatch({ type: "USERNAME_GST_DATA", data: data });
};
export { validateUUID , getUsernameAndGst };
