import React, { Fragment, Component } from "react";
import { ic_picture_as_pdf } from "react-icons-kit/md/ic_picture_as_pdf";
import { upload } from "react-icons-kit/fa/upload";
import { download } from "react-icons-kit/fa/download";
import unArchiveImage from "../../../../assets/Images/unArchive.svg";
import archiveImage from "../../../../assets/Images/archive.svg";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";
import Icon from "react-icons-kit";
import { Tabs, Tab } from "react-bootstrap";
import { getCookie } from "../../../../utils/utility";
import { GET, POST } from "../../../../utils/WebAPI.service";
import { loaderModal } from "../../../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DocumentList: [],
      DocumentHeadings: [],
      DocumentsArray: [],
      coApplicantTabs: [],
      businessArchivedDocumentList: [],
      profileArchivedDocumentList: [],
      ArchiveDocumentHeadings: [],
      businessDocs: [],
      profileDocs: [],
      profileArchivedDocs: [],
      businessArchivedDocs: []
    };
  }
  fetchDetails = async () => {
    let appNo = this.props.props.match.params.appNo;

    await GET(`/v1/document/allDocuments/fetch/${appNo}`).then(({ data, status }) => {
      if (data.data) {
        this.setState({
          businessDocs: data.data.businessDocuments,
          profileDocs: data.data.profileDocuments
        });
      }
    });
    await GET(`/v1/document/archive/fetch/${appNo}`).then(({ data, status }) => {
      if (data.data) {
        this.setState({
          businessArchivedDocs: data.data.businessDocuments,
          profileArchivedDocs: data.data.profileDocuments
        });
      }
    });
  };
  archiveDocs = (docId, status) => {
    let { actions } = this.props,
      { loaderModal } = actions;
    var data = {
      docId,
      status
    };
    loaderModal(true);
    POST(`/v1/document/archiveDocument`, { ...data }).then(({ data, status }) => {
      alert(data.message);
      loaderModal(false);
    });
  };
  uploadBusiness = (e, businessId, docType, docName) => {
    let applicationNumber = this.props.props.match.params.appNo;
    var files = e.target.files;
    let token = getCookie("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: token
    };
    let formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }
    let { actions } = this.props,
      { loaderModal } = actions;
    loaderModal(true);
    POST(`/v1/document/business/upload/${applicationNumber}/${businessId}/${docType}/${docName}`, formData, {
      headers
    }).then(({ data, status }) => {
      alert(data.message);
      loaderModal(false);
    });
  };
  uploadProfile = e => {
    let applicationNumber = this.props.props.match.params.appNo;
    var files = e.target.files;
    let token = getCookie("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: token
    };
    let formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }
    let { actions } = this.props,
      { loaderModal } = actions;
    loaderModal(true);
    // POST(`/uploadDocument/${applicationNumber}/null/${docType}/${entity}`, formData, {
    //   headers
    // }).then(({ data, status }) => {
    //   alert(data.message);
    //   loaderModal(false);
    // })
  };
  view = () => {
    let appNo = this.props.props.match.params.appNo;
    window.open(`/cam/${appNo}`);
  };
  render() {
    let appNo = this.props.props.match.params.appNo;
    let { DocumentHeadings, businessDocs, profileDocs, businessArchivedDocs, profileArchivedDocs } = this.state;
    return (
      <Fragment>
        <Tabs defaultActiveKey={"entityDoc"} id="adminTabs" onSelect={this.selectedTab}>
          <button onClick={() => this.fetchDetails()} className="refresh-btn">
            <span>
              <Icon icon={ic_refresh} />
            </span>
            Refresh
          </button>

          <Tab eventKey={"entityDoc"} title={"Entity Docs"}>
            <div className={"docsNameHeader"}>
              <div>{businessDocs.businessName}</div>
              <div></div>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Document Type</th>
                    <th style={{ minWidth: "300px" }}>Files</th>
                    <th>Download All</th>
                    <th>Upload Files</th>
                  </tr>
                </thead>
                <tbody>
                  {businessDocs.documentResponse
                    ? businessDocs.documentResponse
                        .map(el => el.docType)
                        .filter((item, i, ar) => ar.indexOf(item) === i)
                        .map((el, index) => {
                          return (
                            <tr key={index}>
                              <td>{el}</td>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap"
                                }}
                              >
                                {businessDocs.documentResponse
                                  ? businessDocs.documentResponse
                                      .filter(ele => ele.docType === el)
                                      .map((el, index) => {
                                        return (
                                          <div className="doc-wrapper" key={index}>
                                            <span style={{ padding: "0.1em" }}>
                                              <Icon icon={ic_picture_as_pdf} className="pdf-icon" />
                                              {el.fileName.slice(0, 10)}
                                            </span>
                                            <img
                                              src={archiveImage}
                                              alt="archive"
                                              className="archive-file"
                                              onClick={() => {
                                                this.archiveDocs(el.docId, true);
                                              }}
                                            />
                                          </div>
                                        );
                                      })
                                  : null}
                              </td>
                              <td>
                                <a
                                  download
                                  href={`/Backend/downloadMultipleDocuments/${DocumentHeadings[0]}/${appNo}/${el}/true`}
                                >
                                  <Icon icon={download} size={20} />
                                </a>
                              </td>
                              <td>
                                <label htmlFor={el}>
                                  <Icon icon={upload} style={{ cursor: "pointer" }} />
                                  <input
                                    type={"file"}
                                    id={el}
                                    style={{ display: "none" }}
                                    multiple
                                    onChange={e => this.uploadBusiness(e, businessDocs.businessId, DocumentHeadings[0])}
                                  />
                                </label>
                              </td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </table>
            </div>
          </Tab>
          {profileDocs &&
            profileDocs.map(profile => {
              return (
                <Tab eventKey={"applicantDoc"} title={`${profile.profileType} Docs`}>
                  <div className={"docsNameHeader"}>{profile.name}</div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Document Type</th>
                          <th style={{ minWidth: "300px" }}>Files</th>
                          <th>Download All</th>
                          <th>Upload Files</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.documentResponse
                          ? profile.documentResponse
                              .map(element => element.docType)
                              .filter((item, i, ar) => ar.indexOf(item) === i)
                              .map((el, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{el}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        flexWrap: "wrap"
                                      }}
                                    >
                                      {profile.documentResponse
                                        ? profile.documentResponse
                                            .filter(ele => ele.docType === el)
                                            .map((el, index) => {
                                              return (
                                                <div className="doc-wrapper" key={index}>
                                                  <span style={{ padding: "0.1em" }}>
                                                    <Icon icon={ic_picture_as_pdf} className="pdf-icon" />
                                                    {el.fileName.slice(0, 10)}
                                                  </span>
                                                  <img
                                                    src={archiveImage}
                                                    alt="archive"
                                                    className="archive-file"
                                                    onClick={() => {
                                                      this.archiveDocs(el.docId, true);
                                                    }}
                                                  />
                                                </div>
                                              );
                                            })
                                        : null}
                                    </td>
                                    <td>
                                      <a
                                        download
                                        href={`/Backend/downloadMultipleDocuments/${DocumentHeadings[0]}/${appNo}/${el}/true`}
                                      >
                                        <Icon icon={download} size={20} />
                                      </a>
                                    </td>
                                    <td>
                                      <label htmlFor={el}>
                                        <Icon icon={upload} style={{ cursor: "pointer" }} />
                                        <input
                                          type={"file"}
                                          id={el}
                                          style={{ display: "none" }}
                                          multiple
                                          onChange={e => this.uploadProfile(e, el, DocumentHeadings[0])}
                                        />
                                      </label>
                                    </td>
                                  </tr>
                                );
                              })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              );
            })}
          <Tab eventKey={"archivedDoc"} title={"Archived Docs"}>
            <Tabs
              defaultActiveKey={"archivedEntityDoc"}
              id="adminTabs"
              onSelect={this.selectedTab}
              style={{ marginTop: "-9px" }}
            >
              <Tab eventKey={"archivedEntityDoc"} title={`Archive Entity Docs`}>
                <div className={"docsNameHeader"}>
                  <div>{businessArchivedDocs.businessName}</div>
                  <div></div>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th style={{ minWidth: "300px" }}>Files</th>
                        <th>Download All</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessArchivedDocs.documentResponse
                        ? businessArchivedDocs.documentResponse
                            .map(element => element.docType)
                            .filter((item, i, ar) => ar.indexOf(item) === i)
                            .map((el, index) => {
                              return (
                                <tr key={index}>
                                  <td>{el}</td>
                                  <td
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      flexWrap: "wrap"
                                    }}
                                  >
                                    {businessArchivedDocs.documentResponse
                                      ? businessArchivedDocs.documentResponse
                                          .filter(ele => ele.docType === el)
                                          .map((el, index) => {
                                            return (
                                              <div className="doc-wrapper" key={index}>
                                                <span style={{ padding: "0.1em" }}>
                                                  <Icon icon={ic_picture_as_pdf} className="pdf-icon" />
                                                  {el.fileName.slice(0, 10)}
                                                </span>
                                                <img
                                                  src={archiveImage}
                                                  alt="archive"
                                                  className="archive-file"
                                                  onClick={() => {
                                                    this.archiveDocs(el.docId, true);
                                                  }}
                                                />
                                              </div>
                                            );
                                          })
                                      : null}
                                  </td>
                                  <td>
                                    <a
                                      download
                                      href={`/Backend/downloadMultipleDocuments/${DocumentHeadings[0]}/${appNo}/${el}/true`}
                                    >
                                      <Icon icon={download} size={20} />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                        : null}
                    </tbody>
                  </table>
                </div>
              </Tab>
              {profileArchivedDocs &&
                profileArchivedDocs.map(profile => {
                  return (
                    <Tab eventKey={"applicantDoc"} title={`${profile.profileType} Docs`}>
                      <div className={"docsNameHeader"}>{profile.name}</div>
                      <div>
                        <table>
                          <thead>
                            <tr>
                              <th>Document Type</th>
                              <th style={{ minWidth: "300px" }}>Files</th>
                              <th>Download All</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profile.documentResponse
                              ? profile.documentResponse
                                  .map(element => element.docType)
                                  .filter((item, i, ar) => ar.indexOf(item) === i)
                                  .map((el, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{el}</td>
                                        <td
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            flexWrap: "wrap"
                                          }}
                                        >
                                          {profile.documentResponse
                                            ? profile.documentResponse
                                                .filter(ele => ele.docType === el)
                                                .map((el, index) => {
                                                  return (
                                                    <div className="doc-wrapper" key={index}>
                                                      <span style={{ padding: "0.1em" }}>
                                                        <Icon icon={ic_picture_as_pdf} className="pdf-icon" />
                                                        {el.fileName.slice(0, 10)}
                                                      </span>
                                                      <img
                                                        src={archiveImage}
                                                        alt="archive"
                                                        className="archive-file"
                                                        onClick={() => {
                                                          this.archiveDocs(el.docId, true);
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                })
                                            : null}
                                        </td>
                                        <td>
                                          <a
                                            download
                                            href={`/Backend/downloadMultipleDocuments/${DocumentHeadings[0]}/${appNo}/${el}/true`}
                                          >
                                            <Icon icon={download} size={20} />
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </Tab>
                  );
                })}
            </Tabs>
          </Tab>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              // onClick={() => goTo(`/cam/${appNo}`)}
              onClick={this.view}
              className="refresh-btn mtl"
            >
              VIEW CAM
            </button>
          </div>
        </Tabs>
      </Fragment>
    );
  }
  componentDidMount = async () => {
    await this.fetchDetails();
    let DocumentHeadings = Object.keys(this.state.DocumentList);

    DocumentHeadings.map((el, index) => {
      index >= 2 && this.setState({ coApplicantTabs: [...this.state.coApplicantTabs, 1] });
      return null;
    });
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal
    },
    dispatch
  )
});
export default connect(null, mapDispatchToProps)(Document);
