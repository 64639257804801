import * as AmountAndTenureConstants from "./AmountAndTenure.actions.constants";

let initialState = {
  fetchApplicationData: [],
};

export const AmountAndTenureReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case AmountAndTenureConstants.GET_APPLICATION_DATA:
      return {
        ...state,
        fetchApplicationData: data,
      };
    default:
      return state;
  }
};
