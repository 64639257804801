import React from "react";
import { get } from "lodash";
import { rupeeCommaFormatter } from "../../utils/utility";
const cibilColumns = [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Case Role",
    accessor: "caseRole",
    width: 125
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "CIBIL Score",
    accessor: "cibilScore",
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Total Loans</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "liveLoans",
    width: 100,
    Cell: ({ row }) => {
      let { original } = row;
      if (original.loanCount && original.loanCount.length > 5) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[5].live}</span>
            <span>{original.loanCount[5].closed}</span>
          </div>
        );
      } else {
        return "NA";
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Business Loans</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "unSecuredLoans",
    Cell: ({ row }) => {
      let { original } = row;

      if (original.loanCount && original.loanCount.length > 0) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[0].live}</span>
            <span>{original.loanCount[0].closed}</span>
          </div>
        );
      }
      else{
        return "NA"
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Property Loans</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "securedLoans",
    Cell: ({ row }) => {
      let { original } = row;

      if (original.loanCount && original.loanCount.length > 1) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[1].live}</span>
            <span>{original.loanCount[1].closed}</span>
          </div>
        );
      }
      else{
        return "NA"
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Vehicle Loans</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "individualLoans",
    Cell: ({ row }) => {
      let { original } = row;
      if (original.loanCount && original.loanCount.length > 4) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[4].live}</span>
            <span>{original.loanCount[4].closed}</span>
          </div>
        );
      }
      else{
        return "NA"
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Other</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "businessLoans",
    Cell: ({ row }) => {
      let { original } = row;

      if (original.loanCount && original.loanCount.length > 2) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[2].live}</span>
            <span>{original.loanCount[2].closed}</span>
          </div>
        );
      }
      else{
        return "NA"
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="react-table-heading">Non-funded Facilities</div>
        <div className="react-table-subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "businessLoans",
    Cell: ({ row }) => {
      let { original } = row;

      if (original.loanCount && original.loanCount.length > 3) {
        return (
          <div className="react-table-subheading">
            <span className="liveLoans">{original.loanCount[3].live}</span>
            <span>{original.loanCount[3].closed}</span>
          </div>
        );
      }
      else{
        return "NA"
      }
    }
  }
];

const obligationSummaryColumns = [
  {
    Cell: ({ row }) => {
      let { original } = row;
      let index = get(original, "sno");
      return <span>{index}</span>;
    },
    Header: "Loan No.",
    accessor: "sno",
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({ row }) => {
      let { original } = row;
      let { type } = original;
      return <span title={type}>{type}</span>;
    }
  },
  {
    Header: "Loan Amount",
    accessor: "loanAmount",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { loanAmount } = original;
      return <span title={loanAmount}>{rupeeCommaFormatter(loanAmount)}</span>;
    }
  },
  {
    Header: "Overdue Amount",
    accessor: "overdueAmount",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { overdueAmount } = original;
      return <span title={overdueAmount}>{rupeeCommaFormatter(overdueAmount)}</span>;
    }
  },
  {
    Header: "Current Balance",
    accessor: "currentBalance",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { currentBalance } = original;
      return <span title={currentBalance}>{rupeeCommaFormatter(currentBalance)}</span>;
    }
  },
  {
    Header: "EMI as per Cibil",
    accessor: "emiAmount",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { emiAmount } = original;
      return <span title={emiAmount}>{rupeeCommaFormatter(emiAmount)}</span>;
    }
  },
  {
    Header: "Opening Date",
    accessor: "openingDate",
    width: 85,
    Cell: ({ row }) => {
      let { original } = row;
      let { openingDate } = original;
      return <span title={openingDate}>{openingDate}</span>;
    }
  },
  {
    Header: "Closing Date",
    accessor: "closingDate",
    width: 85,
    Cell: ({ row }) => {
      let { original } = row;
      let { closingDate } = original;
      return <span title={closingDate}>{closingDate}</span>;
    }
  },
  {
    Header: "Reporting Date",
    accessor: "reportingDate",
    width: 85,
    Cell: ({ row }) => {
      let { original } = row;
      let { reportingDate } = original;
      return <span title={reportingDate}>{reportingDate}</span>;
    }
  },
  {
    Header: "Ownership",
    accessor: "ownershipType",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { ownershipType } = original;
      return <span title={ownershipType}>{ownershipType}</span>;
    }
  },
  {
    Header: "EMI Considered",
    accessor: "ownershipType",
    width: 70,
    Cell: ({ row }) => {
      let { original } = row;
      let { obligatedEMI } = original;
      return <span title={obligatedEMI}>{rupeeCommaFormatter(obligatedEMI)}</span>;
    }
  }
];
export { cibilColumns, obligationSummaryColumns };
