import React, { Component } from "react";
import { FaMobileAlt } from "react-icons/fa";
import OtpInput from "react-otp-input";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_SIGN_UP } from "../../../utils/APIUrls";
import { goTo } from "../../../utils/utility";
import { FaPencilAlt } from "react-icons/fa";

export default class OtpScreen extends Component {
  state = {
    otp: "",
  };
  inputHandler = async (otp) => {
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(otp)) {
      this.setState({ otp });
    }
    if (otp.length === 6) {
      var dataToSend = {
        data: {
          mobile: this.props.mobileNumber,
          otp,
        },
      };

      await POST(API_POST_SIGN_UP, dataToSend)
        .then(({ data }) => {
          if (data.status === "success") {
            goTo(this.props.props.nextScreen);
            // sessionStorage.clear();
            // document.cookie = "gstNumber=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            // document.cookie = "businessPan=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          } else {
            window.alert(data.message);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };
  render() {
    let { otp } = this.state;
    return (
      <div className="otp-screen__wrapper">
        <div className="mobile__wrapper child__space-between">
          <FaMobileAlt size={24} /> <span>+91 {this.props.mobileNumber}</span>
          <FaPencilAlt
            onClick={() => this.props.changeModalHandler("SignUpUserInput")}
            className="ml-2 cursor-pointer"
          />
        </div>
        <OtpInput
          onChange={(otp) => this.inputHandler(otp)}
          numInputs={6}
          separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          value={otp}
          containerStyle={"input-otp__box"}
        />
      </div>
    );
  }
}
