import React, { Component } from "react";

export default class SignUpUserInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  editDetailsHandler = () => {
    this.props.changeModalHandler("SignUpPreFill");
  };
  inputHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  addTempUserHandler = e => {
    e.preventDefault();
    var { name, email, mobile } = this.state,
      dataToSend = {
        data: {
          email,
          firstName: name,
          mobile
        }
      };
    this.props.addTempUserHandler(dataToSend).then(data => {
      let { message, status } = data;
      if (status === "success") {
        this.props.changeModalHandler("OtpScreen", mobile);
      } else {
        alert(message);
      }
    });
  };
  render() {
    let { name, email, mobile } = this.state;
    return (
      <form onSubmit={this.addTempUserHandler}>
        <div className="signup-userInput__wrapper">
          <div>
            <label htmlFor="">
              Name <span className="optional-text__small">(optional)</span>
            </label>
            <br />
            <input type="text" className="input__generic w-100" name="name" onChange={this.inputHandler} value={name} />
          </div>
          <div>
            <label htmlFor="">
              Email ID <span className="optional-text__small">(optional)</span>
            </label>
            <br />
            <input
              type="text"
              className="input__generic w-100"
              name="email"
              onChange={this.inputHandler}
              value={email}
            />
          </div>
          <div>
            <label htmlFor="">Mob No. </label>
            <br />
            <input
              type="text"
              className="input__generic w-100"
              name="mobile"
              onChange={this.inputHandler}
              value={mobile}
              maxLength={10}
              required
            />
          </div>
          <div className="btn-box">
            <button className="button-black-transparent" onClick={() => this.props.changeModalHandler("SignUpPreFill")}>
              Undo Changes
            </button>
            <button className="button__generic">Confirm</button>
          </div>
          <div className="already-account__wrapper mt-3">
            <p>Already have an account?</p>
            <p onClick={() => this.props.changeModalHandler("SignIn")}>
              Click here to login and connect the business details to your account
            </p>
          </div>
        </div>
      </form>
    );
  }
}
