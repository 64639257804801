import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import ReportDownloadComponent from "../../../ViewsComponents/ReportDownload/ReportDownload";
import Header from "../../../ViewsComponents/Header/Header";
export default class ReportDownload extends Component {
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-md-3">
          <SidePanel advisories={["View your Biz360 Report.","You can also download a pdf copy !"]}></SidePanel>
        </div>
        <div className="col-md-9">
          <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"kyc"} {...this.props}/>
          <ReportDownloadComponent nextScreen={"/dashboard/business/biz360-report"} />
        </div>
      </div>
    );
  }
}
