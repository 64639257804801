import * as GSTDataConstant from "./GSTData.constants";
import { GET } from "../../../utils/WebAPI.service";
import {
  API_GET_BUSINESS_TYPE,
  API_GET_CONSTITUTION,
  API_GET_PRIMARY_INDUSTRY,
  API_GET_SECONDARY_INDUSTRY,
  API_GET_BUSINESS_ADDRESS_TYPE,
} from "../../../utils/APIUrls";

const getPrimaryIndustry = (businessType) => {
  return (dispatch) => {
    return GET(API_GET_PRIMARY_INDUSTRY(businessType))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: GSTDataConstant.GET_PRIMARY_INDUSTRY, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getSecondaryIndustry = (primaryIndustry, businessType) => {
  return (dispatch) => {
    return GET(API_GET_SECONDARY_INDUSTRY(businessType, primaryIndustry))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: GSTDataConstant.GET_SECONDARY_INDUSTRY, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getConstitution = () => {
  return (dispatch) => {
    return GET(API_GET_CONSTITUTION)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: GSTDataConstant.GET_CONSTITUTION, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getBusinessType = () => {
  return (dispatch) => {
    return GET(API_GET_BUSINESS_TYPE)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: GSTDataConstant.GET_BUSINESS_TYPE, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getBusinessAddressType = () => {
  return (dispatch) => {
    return GET(API_GET_BUSINESS_ADDRESS_TYPE)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: GSTDataConstant.GET_BUSINESS_ADDRESS_TYPE, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const setGstAddressInNewAddress = (data) => {
  return { type: GSTDataConstant.SET_ALL_ADDRESS_NEW_ADDRESS, data: data };
};
const deleteAddress = (id) => {
  return { type: GSTDataConstant.DELETE_ADDRESS, data: id };
};
const changeInputValue = (name, value) => {
  return { type: "CHANGE_INPUT_VALUE", name, value };
};
export {
  getSecondaryIndustry,
  getPrimaryIndustry,
  getConstitution,
  getBusinessType,
  setGstAddressInNewAddress,
  deleteAddress,
  getBusinessAddressType,
  changeInputValue,
};
