import { API_POST_FIND_PRODUCTS, API_GET_PRIMARY_COLLATERAL } from "../../utils/APIUrls";
import * as LoanAdvisoryConstant from "./loanAdvisory.actions.constants";
import { POST, GET } from "../../utils/WebAPI.service";
const findProducts = dataToSend => dispatch => {
  return POST(API_POST_FIND_PRODUCTS, dataToSend)
    .then(({ data, status }) => {
      if (data.data) {
        dispatch({ type: LoanAdvisoryConstant.POST_FIND_PRODUCTS, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getPrimaryCollateral = () => dispatch => {
  return GET(API_GET_PRIMARY_COLLATERAL)
    .then(({ data, status }) => {
      if (data.data) {
        dispatch({ type: LoanAdvisoryConstant.GET_PRIMARY_COLLATERAL, data: data.data.responseList });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

export { findProducts, getPrimaryCollateral };
