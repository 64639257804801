import * as DocumentUploadConstant from "./DocumentUpload.actions.constants";

const initialState = {
  documentListData: [],
};

export const DocumentUploadReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case DocumentUploadConstant.GET_DOCUMENT_MASTER:
      return {
        ...state,
        documentListData: data,
      };
    default:
      return state;
  }
};
