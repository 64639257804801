import { mapValues, keyBy } from "lodash";
import { rupeeCommaFormatter } from "../../../utils/utility";
import React from "react";
export const aggregateData = (data, type) => {
  let data1 = [],
    dataCredits,
    dataDebits,
    totalDebits,
    totalCredits,
    avgCredits,
    avgDebits,
    totalCreditTxnCount,
    totalDebitTxnCount,
    avgCreditTxnCount,
    avgDebitTxnCount;
  switch (type) {
    case "All":
      dataCredits = data.credits || [];
      dataDebits = data.debits || [];
      totalCredits = data.totalCredits || [];
      totalDebits = data.totalDebits || [];
      avgCredits = data.averageCredits || [];
      avgDebits = data.averageDebits || [];
      totalCreditTxnCount = data.totalCreditTxnCount || [];
      totalDebitTxnCount = data.totalDebitTxnCount || [];
      avgCreditTxnCount = data.avgCreditTxnCount || [];
      avgDebitTxnCount = data.avgDebitTxnCount || [];
      break;
    case "aggregateOperationalData":
      dataCredits = data.salesCredit || [];
      dataDebits = data.purchaseDebit || [];
      totalCredits = data.totalSalesCredit || [];
      totalDebits = data.totalPurchaseDebit || [];
      avgCredits = data.avgSalesCredit || [];
      avgDebits = data.avgPurchaseDebit || [];
      totalCreditTxnCount = data.totalSalesCreditTxnCount || [];
      totalDebitTxnCount = data.totalPurchaseDebitTxnCount || [];
      avgCreditTxnCount = data.avgSalesCreditTxnCount || [];
      avgDebitTxnCount = data.avgPurchaseDebitTxnCount || [];
      break;
    case "aggregateNonOperationalData":
      dataCredits = data.nonSalesCredit || [];
      dataDebits = data.nonPurchaseDebit || [];
      totalCredits = data.totalNonSalesCredit || [];
      totalDebits = data.totalNonPurchaseDebit || [];
      avgCredits = data.avgNonSalesCredit || [];
      avgDebits = data.avgNonPurchaseDebit || [];
      totalCreditTxnCount = data.totalNonSalesCreditTxnCount || [];
      totalDebitTxnCount = data.totalNonPurchaseDebitTxnCount || [];
      avgCreditTxnCount = data.avgNonSalesCreditTxnCount || [];
      avgDebitTxnCount = data.avgNonPurchaseDebitTxnCount || [];
      break;
    default:
      return [];
  }
  if (data && data.isLimitSanctionedPresent && data.limitUtilizationAccountwise) {
    data1 = data.limitUtilizationAccountwise
      .filter(el => {
        if (el.limitUtilizationInPercentage) {
          return el.limitUtilizationInPercentage.length !== 0;
        }
        return null;
      })
      .map((el, key) => {
        return {
          identifier: `Limit Utilization ${el.accountNumber}`,
          ...mapValues(keyBy(el.limitUtilizationInPercentage, "month"), commaFormatter =>
            commaFormatter.value && commaFormatter.value !== 0 && commaFormatter.value !== "0"
              ? commaFormatter.value.toFixed(2) + "%"
              : "-"
          ),
          total: "-",
          average:
            el.averageLimitUtilizationInPercentage &&
            el.averageLimitUtilizationInPercentage !== 0 &&
            el.averageLimitUtilizationInPercentage !== "0" &&
            el.averageLimitUtilizationInPercentage !== "Infinity"
              ? el.averageLimitUtilizationInPercentage.toFixed(2) + "%"
              : "-"
        };
      });
  }
  return [
    {
      identifier: "Total Credits",
      subIdentifier: "Transactions Count",
      ...mapValues(
        keyBy(dataCredits, "month"),
        commaFormatter => rupeeCommaFormatter(commaFormatter.value) + " - " + commaFormatter.txnCount
      ),
      total: rupeeCommaFormatter(totalCredits) + "-" + totalCreditTxnCount,
      average: rupeeCommaFormatter(avgCredits) + "-" + avgCreditTxnCount
    },
    {
      identifier: "Total Debits",
      subIdentifier: "Transactions Count",
      ...mapValues(
        keyBy(dataDebits, "month"),
        commaFormatter => rupeeCommaFormatter(commaFormatter.value) + "-" + commaFormatter.txnCount
      ),
      total: rupeeCommaFormatter(totalDebits) + "-" + totalDebitTxnCount,
      average: rupeeCommaFormatter(avgDebits) + "-" + avgDebitTxnCount
    },
    {
      identifier: "ABB",
      ...mapValues(keyBy(data.monthlyABB, "month"), commaFormatter => rupeeCommaFormatter(commaFormatter.value)),
      total: "-",
      average: rupeeCommaFormatter(data.averageABB)
    },
    ...data1.map((el, index) => {
      return el;
    })
  ];
};
export const monthsColumn = data => {
  let columns = [],
    list = [];

  if (data) {
    if (typeof data !== "undefined" && data.length > 0) {
      data.map(item => {
        if (typeof item.month !== "undefined" && item.month != null && item.month.length > 0)
          if (!list.includes(item.month)) {
            list.push(item.month);
            columns.push({
              Header: item.month,
              accessor: item.month,
              Cell: ({ cell }) => {
                let { value } = cell;
                return (
                  <div>
                    <div>{value ? value.split("-")[0] : "NA"}</div>
                    <div className="mt-1">{value && value.split("-")[1]}</div>
                  </div>
                );
              }
            });
          }
      });
    }
  }
  return columns;
};
