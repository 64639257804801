import React, { Component } from "react";

export default class ShareUsernameLink extends Component {
  render() {
    return (
      <div className="share-wrapper mt-3 ">
        <p>
          If you don’t have access to the GST username or registered mobile number, you can send a link to fill in the
          OTP to the relevant person confidentially.
        </p>
        <button className="button__share-link" onClick={this.props.nextPageHandlerChild}>
        Share Username Link
        </button>
      </div>
    );
  }
}
