import * as ACTION_CONSTANTS from "./flag.action.constant";

const initialState = {
  Score: {},
  CreditFlag: {},
  businessProfileFlagsData: {},
  businessRiskFlagsData: {},
  cashFlowRiskFlagsData: {},
  creditBehaviourFlagsData: {},
  creditCapacityFlagsData: {}
};

export const flagCCScore = (state = initialState, action) => {
  const {
    data,
    type,
    businessProfileFlagsData,
    businessRiskFlagsData,
    cashFlowRiskFlagsData,
    creditBehaviourFlagsData,
    creditCapacityFlagsData
  } = action;
  switch (type) {
    case ACTION_CONSTANTS.FLAG_CC_SCORE:
      return { ...state, Score: data };
    case ACTION_CONSTANTS.CREDIT_FLAG:
      return { ...state, CreditFlag: data };
    case ACTION_CONSTANTS.BUSINESS_PROFILE_FLAGS:
      return { ...state, businessProfileFlagsData: businessProfileFlagsData };
    case ACTION_CONSTANTS.BUSINESS_RISK_FLAGS:
      return { ...state, businessRiskFlagsData: businessRiskFlagsData };
    case ACTION_CONSTANTS.CASH_FLOW_RISK_FLAGS:
      return { ...state, cashFlowRiskFlagsData: cashFlowRiskFlagsData };
    case ACTION_CONSTANTS.CREDIT_BEHAVIOUR_FLAGS:
      return { ...state, creditBehaviourFlagsData: creditBehaviourFlagsData };
    case ACTION_CONSTANTS.CREDIT_CAPACITY_FLAGS:
      return { ...state, creditCapacityFlagsData: creditCapacityFlagsData };

    default:
      return state;
  }
};
