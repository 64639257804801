import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { download } from "react-icons-kit/ikons/download";

export default class MyDocuments extends Component {
  render() {
    return (
      <Fragment>
        <div className="my-documents__wrapper">
          <div className="headings__wrapper">
            <div className="main-heading__wrapper">
              <div className="main-heading__styling">My Documents</div>
            </div>
            <div className="note-for-user">Your documents are kept in a secured way under encryption.</div>
            <div className="sub-heading__wrapper">{/* second screen - Anika Fashion type of heading */}</div>
          </div>
          <div className="document-body__wrapper">
            <div className="entity-docs__wrapper">
              <div className="category-title__wrapper mb-2">
                <div className="category-title">Entity Docs</div>
              </div>
              <div className="main-box__wrapper mt-2 mb-2 ml-0 pl-0 col-md-10 col-sm-12">
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Anika Fashions</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Hishe Pvt Ltd.</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Kingsmen Co.</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Kingdom Quest</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Shapoor Foods</div>
                </div>
              </div>
            </div>
            <div className="applicants-docs__wrapper">
              <div className="category-title__wrapper mb-2">
                <div className="category-title">Applicants Docs</div>
              </div>
              <div className="main-box__wrapper mt-2 mb-2 col-md-10 col-sm-12">
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Anita Shapoor</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Adil Hussain</div>
                </div>
                <div className="sub-box__wrapper">
                  <Icon className="download-icon" icon={download} />
                  <div className="document-image"></div>
                  <div className="document-title">Bhiku Mhatre</div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
        </div>
      </Fragment>
    );
  }
}
