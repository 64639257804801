const DEBIT_CATEGORIES = {
    "CHEQUE GROUP": {
        label: 'Cheque Group',
        color: "rgb(48 83 255)",
    },
    "VENDOR PAYMENTS": {
        label: 'Vendor Payments',
        color: "rgb(0 16 97)",
    },
    "GST TAX PAYMENT": {
        label: 'GST Tax Payment',
        color: "rgb(0 154 142)",
    },
    "SALARY GROUP": {
        label: 'Salary Group',
        color: "rgb(0 205 190)"
    },
    "EMI REPAYMENT GROUP": {
        label: 'EMI Repayment Group',
        color: "rgb(72 255 242)"
    },
    "INTEREST TO BANK GROUP": {
        label: 'Interest To Bank Group',
        color: "rgb(125 220 213)"
    },
    "STATUTORY PAYMENT": {
        label: 'Statutory Payment',
        color: "rgb(194 255 251)"
    },
    "UTILITIES EXPENSES GROUP": {
        label: 'Utilities Expenses Group',
        color: "rgb(188 188 188)"
    },
    "RENT PAYMENT GROUP": {
        label: 'Rent Payment Group',
        color: "rgb(116 116 116)"
    },
    "OTHER MISC EXPENSES": {
        label: 'Other Misc Expenses',
        color: "rgb(29 29 29)",
    },
    "CASH WITHDRAWAL GROUP": {
        label: 'Cash Withdrawal Group',
        color: "rgb(152 169 255)",
    },
    "ATM WITHDRAWAL GROUP": {
        label: 'ATM Withdrawal Group',
        color: "rgb(152 169 255)",
    },
    "CHEQUE BOUNCE GROUP": {
        label: 'Cheque Bounce Group',
        color: "rgb(78 73 171)"
    },
    "EMI BOUNCE GROUP": {
        label: 'EMI Bounce Group',
        color: "rgb(78 73 171)"
    },
    "INWARD BOUNCE": {
        label: 'Inward Bounce',
        color: "rgb(78 73 171)"
    },
    "CC PAYMENT GROUP": {
        label: 'CC Payment Group',
        color: "rgb(235 249 252 )"
    },
    "INVESTMENT MADE GROUP": {
        label: 'Investment Made Group',
        color: "rgb(164 223 247)"
    },
    "BANK CHARGES GROUP": {
        label: 'Bank Charges Group',
        color: "rgb(14 117 159)"
    },
    "SELF GROUP": {
        label: 'Self Group',
        color: "rgb(103 201 242)"
    },
    "REFUND GROUP": {
        label: 'Refund Group',
        color: "rgb(194 255 251)"
    }
};

const CREDIT_CATEGORIES = {
    "CUSTOMER PAYMENTS": {
        label: 'Customer Payments',
        color: "rgb(0 16 97)"
    },
    "CHEQUE GROUP": {
        label: 'Cheque Group',
        color: "rgb(48 83 255)"
    },
    "CASH DEPOSIT": {
        label: 'Cash Deposit',
        color: "rgb(152 169 255)"
    },
    "LOAN AND ADVANCES": {
        label: 'Loan And Advances',
        color: "rgb(0 205 190)"
    },
    "EMI & RENT": {
        label: 'EMI & Rent',
        color: "rgb(0 154 142)"
    },
    "INTEREST FROM BANK": {
        label: 'Interest From Bank',
        color: "rgb(72 255 242)"
    },
    "INVESTMENT AND MATURED": {
        label: 'Investment And Matured',
        color: "rgb(125 220 213)"
    },
    "TAX REFUND": {
        label: 'Tax Refund',
        color: "rgb(194 255 251)"
    },
    "OUTWARD BOUNCE": {
        label: 'Outward Bounce',
        color: "rgb(78 73 171)"
    },
    "SELF GROUP": {
        label: 'Self Group',
        color: "rgb(103 201 242)"
    },
    "CONTRA": {
        label: 'Contra',
        color: "rgb(14 117 159)"
    },
    "REVERSAL": {
        label: 'Reversal',
        color: "rgb(164 223 247)"
    },
};


const GRAPHS = {
    debitCashFlowQuarter: {
        name: 'DEBIT_INFLOW_CHART',
        propsKey: 'debits',
    },
    creditCashFlowQuarter: {
        name: 'CREDIT_INFLOW_CHART',
        propsKey: 'credits',
    },
};


const Quarters = {
    firstQuarter: ['Jan', 'Feb', 'Mar'],
    secondQuarter: ['Apr', 'May', 'Jun'],
    thirdQuarter: ['Jul', 'Aug', 'Sep'],
    fourthQuarter: ['Oct', 'Nov', 'Dec'],
    wholeYear:['Jan', 'Feb', 'Mar','Apr', 'May', 'Jun','Jul', 'Aug', 'Sep','Oct', 'Nov', 'Dec']
};


const getColorCode = (type, value) => {
    let colorCode = '';
    if(type === 'credit') {
        Object.keys(CREDIT_CATEGORIES).forEach((keyName) => {
            const obj = CREDIT_CATEGORIES[keyName];
            if(obj.label.toLowerCase() === value.toLowerCase()) {
                colorCode = obj.color
            }
        })
    } else if (type === 'debit') {
        Object.keys(DEBIT_CATEGORIES).forEach((keyName) => {
            const obj = DEBIT_CATEGORIES[keyName];
            if(obj.label.toLowerCase() === value.toLowerCase()) {
                colorCode = obj.color
            }
        })

    }
    return colorCode;
};


export {
    DEBIT_CATEGORIES,
    CREDIT_CATEGORIES,
    GRAPHS,
    Quarters,
    getColorCode
}
