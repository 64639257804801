import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";

import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class BankTermLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Bank/NBFC Term Loan</title>
          <meta
            name="description"
            content="The term loan is primary source for one-time debt , disbursed fully at the inception of term loan. It is taken by
                businesses to finance the one-time investment generally in fixed
                assets or to finance working capital margin. It is also called a
                term finance as money raised through term loan is generally
                repayable in regular payments for fixed tenure"
          />
          <meta
            name="tag"
            content="term loan by nbfc,loan by nbfc,types of loans provided by nbfc,loan for nbfc,personal loan by nbfc,assessment of term loan for nbfc,term loan bank of india,term loan bank overdraft,short term loans bank account,long term bank loan advantages and disadvantages,short term bank loan advantages and disadvantages,secured term loan axis bank,property term loan andhra bank,the term bank loan,is a term loan bank debt,long term bank loan balance sheet,short term bank loan balance sheet,bank term loan definition,loan term in bank,term loan bank loan,long term loan bank,bank term loan lenders,term bank loan means"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center">Bank/NBFC Term Loan</div>
              <br />
              <div className="about-details center">
                <p className="fs-16">
                  The term loan is <span className="highlight-blue">primary source for one-time debt</span>, disbursed
                  fully at the inception of term loan. It is taken by businesses to finance the one-time investment
                  generally in fixed assets or to finance working capital margin. It is also called a term finance as
                  money raised through term loan is generally repayable in regular payments for fixed tenure
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="₹50,000+"
              decisionTime="15-25 Days"
              processingFee="0.5% - 2%"
              buttonText="Find the Best Offers"
              interestRate="Case Dependent"
              loanTenure="Case Dependent"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Bank/NBFC Term Loan</div>
              <div className="sub-heading_card_wrapper">When to opt for Bank/NBFC Term Loan</div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">One time Investment</div>
                  This loan is taken when there is specific need of one-time investment in business such as fixed assets
                  or any kind of expansion.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Wide Business Range</div>
                  Suitable for wide range of business use like big large expenditure, new product expansion, new
                  geography expansion, new office setup.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Maturity date</div>
                  Term loan has set maturity date, borrower is aware about future outflows.{" "}
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">One shot disbursement</div>
                  To meet upfront requirement
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Fast Approval Time</div>
                  In comparison to CC/OD Limit
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Flexible Tenure </div>
                  Options with lenders
                </div>
              </div>
            </div>
            <br />
            <div className="horizontal-space" />
          </div>
          <div className="center negative-margin">
          <CalculateEMI 
              businessVintage="2-3 years"
              optimumTurnover="Depends upon loan amount"
              financialDataRequired="For higher loan amount"
            />
            <div className="horizontal-space" />
          </div>
          <br />
          <br />
          <div className="why-loan__wrapper extra-padding center">
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Bank/NBFC Term Loan</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">Collateral get freezes with one lender</div>
                <div className="SME_subCard_wrapper  ">
                  No flexibility of Part payment so no benefit of buffer cash in bank
                </div>
                <div className="SME_subCard_wrapper  ">
                  Risk of loss of property for borrower in case of default in payments
                </div>
              </div>
            </div>
            <br />
            <br />
            <OtherLoanProducts />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
