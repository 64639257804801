import React, { Component } from "react";
import AboutYourBusiness from "../ViewsComponents/AboutYourBusiness/aboutYourBusiness";
import { GET, POST } from "../../utils/WebAPI.service";
import { API_GET_VERIFY_LINK, API_POST_VALIDATE_GST_OTP_LINK } from "../../utils/APIUrls";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loaderModal } from "../../store/actions";
import WhiteLogo from "../../assets/Images/whiteLogo.png";
import Countdown from "react-countdown-now";
class OTPShare extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthenticated: false, isOTPValidated: false, isWrongOtp: false };
    this.validateUUID();
  }
  validateUUID = async () => {
    let { match, actions } = this.props,
      { uuid } = match.params,
      { loaderModal } = actions;
    loaderModal(true);
    if (match.path === "/validateotp/:uuid") {
      await GET(API_GET_VERIFY_LINK(uuid)).then(({ data }) => {
        if (data.status === "success") {
          this.setState({ isAuthenticated: true, gstin: data.data.gstin, gstUsername: data.data.gstUsername });
        } else {
          window.alert(data.message);
          this.setState({ isAuthenticated: false });
        }
      });
    } else {
      this.state = { isAuthenticated: true };
    }
    loaderModal(false);
  };
  gstOtpHandler = async () => {
    let { gstOtp } = this.state;
    let { match, actions } = this.props,
      { loaderModal } = actions,
      { uuid } = match.params;
    loaderModal(true);
    await POST(API_POST_VALIDATE_GST_OTP_LINK(uuid, gstOtp), null)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ isOTPValidated: true });
        } else {
          setTimeout(() => {
            this.setState({ isWrongOtp: true, gstOtp: "" });
          }, 500);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };

  otpInputHandler = async e => {
    var name = e.target.name,
      value = e.target.value;
    await this.setState({ [name]: value, isWrongOtp: false });
    if (value.length === 6) {
      this.gstOtpHandler();
    }
  };
  render() {
    let { isAuthenticated, gstOtp, isOTPValidated, isWrongOtp, gstin, gstUsername } = this.state,
      { loaderModalStatus, usernameAndGstinData } = this.props;
    return (
      <>
        <LoaderModal status={loaderModalStatus} />
        {isAuthenticated ? (
          <div className="validate-username__wrapper">
            <div className="gradient-background">
              <img src={WhiteLogo} alt="" />
            </div>
            <div className="card-wrapper">
              <h1>Get your BIZ360 Report</h1>
              <div className="child__space-between">
                <AboutYourBusiness {...this.props}/>
                &nbsp; &nbsp;
                <AboutYourBusiness {...this.props}isGstUsername={true} />
              </div>

              {isOTPValidated ? (
                <div className="otp-success__text">Your OTP has been Submitted</div>
              ) : (
                <>
                  <div className="label-heading">Enter GST Registered Username</div>
                  <input
                    type="text"
                    value={gstOtp}
                    name="gstOtp"
                    onChange={this.otpInputHandler}
                    className={isWrongOtp ? "shake-animation__input otp" : "otp"}
                    placeholder="Enter OTP"
                    maxLength={6}
                  />
                  {isWrongOtp ? <div className="wrong-otp__text">Please Enter Right OTP</div> : null}
                  <p>
                    Didn’t get your OTP? Wait for{" "}
                    <span style={{ color: "#001061" }}>
                      <Countdown
                        date={Date.now() + 600000}
                        renderer={props => (
                          <span>
                            {props.minutes} : {props.seconds}
                          </span>
                        )}
                      />
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ data, UserNameAndGstReducer }) => ({
  loaderModalStatus: data.loaderModalStatus,
  usernameAndGstinData: UserNameAndGstReducer.usernameAndGstinData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loaderModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPShare);
