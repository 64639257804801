import React, { Component } from "react";
import BlueLogo from "../../../assets/Images/BlueLogo1x.png";
import { goTo } from "../../../utils/utility";

export default class SidePanel extends Component {
  state = {
    activeLink: "business"
  };
  navLinkHandler = link => {
    this.setState({ activeLink: link });
    goTo(`/dashboard/${link}/home`)
  };
  render() {
    let { activeLink } = this.state;
    return (
      <div className="side__panel-wrapper">
        <img src={BlueLogo} alt="" />
        <div className="nav-links">
          <div
            className={activeLink === "business" ? "nav-link active" : "nav-link"}
            onClick={() => this.navLinkHandler("business")}
          >
            My Businesses
          </div>
          <div
            className={activeLink === "loans" ? "nav-link active" : "nav-link"}
            onClick={() => this.navLinkHandler("loans")}
          >
            My Loans
          </div>
          <div
            className={activeLink === "documents" ? "nav-link active" : "nav-link"}
            onClick={() => this.navLinkHandler("documents")}
          >
            Documents
          </div>
          <div
            className={activeLink === "Loan Advisory" ? "nav-link active" : "nav-link"}
            onClick={() => this.CFVZ("Loan Advisory")}
          >
            Loan Advisory
          </div>
        </div>
      </div>
    );
  }
}
