import React, { Component } from "react";
export default class SignUpPreFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }
  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };
  editDetailsHandler = () => {
    this.props.changeModalHandler("SignUpUserInput");
  };
  addTempUserHandler = () => {
    let { props } = this.props,
      { profileData } = props,
      { name, email, mobile } = profileData[0] || [],
      dataToSend = {
        data: {
          email,
          firstName: name,
          mobile,
        },
      };
    this.props.addTempUserHandler(dataToSend).then((data) => {
      let { message, status } = data;
      if (status === "success") {
        this.props.changeModalHandler("OtpScreen", mobile);
      } else {
        alert(message);
      }
    });
  };
  render() {
    let { props } = this.props,
      { profileData } = props,
      { name, email, mobile } = profileData[0] || [];

    return (
      <div>
        <div className="modal-fields__wrapper">
          <div className="col-md-6 com-sm-12">
            <label>Name</label>
            <div>{name}</div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5 com-sm-12">
              <label>Mobile No.</label>
              <div>{mobile}</div>
            </div>
            <div className="col-md-7 com-sm-12">
              <label>Email ID</label>
              <div>{email}</div>
            </div>
          </div>
        </div>
        <div className="btn-box">
          <button className="button-black-transparent" onClick={this.editDetailsHandler}>
            Edit details
          </button>
          <button className="button__generic" onClick={this.addTempUserHandler}>
            Confirm
          </button>
        </div>
        <div className="already-account__wrapper">
          <p>Already have an account?</p>
          <p onClick={() => this.props.changeModalHandler("SignIn")}>
            Click here to login and connect the business details to your account
          </p>
        </div>
      </div>
    );
  }
}
