import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Home from "./Views/Home";
import Eligibility from "./Views/Eligibility";
import loanApplication from "./Views/LoanApplication/LoanApplication";
class MyLoans extends Component {
  render() {
    return (
      <div className="dashboard-loan__wrapper">
        <Switch>
          <Route path="/dashboard/loans/home" exact component={Home} />
          <Route path="/dashboard/loans/eligibility" exact component={Eligibility} />
          <Route path="/dashboard/loans/:page" component={loanApplication} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ LoanApplicationReducer }) => ({
  // eligibilityData: LoanApplicationReducer.eligibilityData
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MyLoans);
