import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RadioBox from "../../../components/RadioBox/RadioBox";
import Icon from "react-icons-kit";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";
import { ic_add } from "react-icons-kit/md/ic_add";
import ReactTable from "../../../components/React-Table/ReactTable";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { cog } from "react-icons-kit/icomoon/cog";
import {
  rupeeCommaFormatter,
  setHeaderFixed,
  deleteCookie
} from "../../../utils/utility";
import { get, capitalize } from "lodash";
import Highcharts from "highcharts";
import { rupee } from "react-icons-kit/fa/rupee";
import { Helmet } from "react-helmet";
import tachyons from 'tachyons';

import {
  changeInputValue,
  getDecisionSummary,
  getDecisionCode,
  getRCUDocumentType,
  getRCUAgencyMaster,
  getDeviationsReasonMaster,
  getDeviations,
  getDecision,
  getSpoc,
  getCreditCapacityDetails,
  getSummaryForAllApplicant,
  getCaseHighlights,
  getBankingDetailsForFinalDecision,
  getLimitUtilzationAnalysis
} from "./finalDecision.action";
import { getBusinessDetails } from "../Overview/overview.action";
import { getList } from "../../../store/listData.actions";
import {
  API_GET_DECISION1,
  API_ADD_DEVIATION,
  API_INITIATE_RCU_REQUEST,
  API_GET_REPAYMENT_TYPE,
  API_ADD_DECISION,
  API_ADD_MANUAL_DEVIATION,
  API_GET_FIPD_DETAILS,
  API_GET_REFERENCE_RELATION,
  API_SPOC_DETAILS,
  API_UPDATE_CMR,
  API_EDIT_MITIGATION
} from "../../../utils/APIUrls";
import { getReferences } from "./finalDecision.action";
import ReferencesCard from "./ReferencesCard";
import { loaderModal } from "../../../store/actions";
import { GET, POST } from "../../../utils/WebAPI.service";
import CredoModal from "../../../components/Modal/modal";
import EditRatioForm from "./EditRatioForm";
import Fiypd from "./fiypd";
import Document from "./Documents/document";
import Edit from "../../../assets/Images/edit.svg";
import Replay from "../../../assets/Images/replay.svg";
import CrossF from "../../../assets/Images/crossIcon.svg";
import Edit3 from "../../../assets/Images/Vector.svg";
import Edit4 from "../../../assets/Images/pencilMit.svg";
import Edit5 from "../../../assets/Images/Group.svg";
import {
  checkUserLogin,
  setUserDetails,
  setUserLoginStatus
} from "../../../store/actions";

require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);

class FinalDecision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantsData: [
        {
          name: "abc",
          role: 123,
          age: 123,
          score: "false",
          status: "false"
        }
      ],
      decisionsData: [
        {
          name: "abc",
          role: 123,
          age: 123,
          score: "false",
          status: "false"
        }
      ],
      deviationsData: [
        {
          name: "abc",
          role: 123,
          age: 123,
          score: "false",
          status: "false"
        }
      ],
      cmrList: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      switchStatus: true,
      insurance: false,
      code: "",
      addRCU: [],
      modalOpen: false,
      modalType: "",
      // declaration:false
      fiypd: {},
      decisionBtn: false,
      fpr: false,
      addList: [],
      commentId: [],
      cmr: "",
      referenceIndex: -1,
      initiateRCU: false,
      decisionSummaryInput: "",
      deleteButton: -1,
      cmrEdit: false,
      data: "",
      editableRows: {}
    };
  }

  editDecision = async list => {
    let { actions, decision } = this.props;

    let list1 = list.map((item, index) => {
      return item.comment;
    });
    let list2 = list.map((item, index) => {
      return item.commentId;
    });
    await this.setState({
      decisionBtn: true,
      addList: this.state.addList.concat(list1),
      commentId: this.state.commentId.concat(list2),
      insurance: decision.isInsurance
    });

    !decision.length &&
      (await actions.getList(
        "SET_FINAL_DECISION",
        API_GET_DECISION1,
        "Select Final Decision"
      ));
    await actions.getList(
      "SET_REPAYMENT_TYPE",
      API_GET_REPAYMENT_TYPE,
      "Select Repayment Type"
    );
  };

  editDecision1 = () => {
    this.setState({
      decisionBtn: false
    });
  };

  eidtFprBtn = () => {
    this.setState({
      fpr: true
    });
  };
  eidtFprBtn1 = () => {
    this.setState({
      fpr: false
    });
  };

  subm = async e => {
    let { finalDecision } = this.props;
    await this.setState({
      addList: this.state.addList.concat(
        get(finalDecision, "specialComment", "0")
      ),
      commentId: this.state.commentId.concat(-1)
    });
    document.querySelector('input[name="specialComment"]').value = "";
  };

  handleChange = status => {
    this.setState({ switchStatus: status });
  };

  onDrop = files => {
    files.forEach(file => {});
  };

  setInsurance = status => {
    this.setState({ insurance: status });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let {
      setUserDetails,
      setUserLoginStatus,
      checkUserLogin
    } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }
  toggleModal = (modalType, index) => {
    let { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    const modalState = !this.state.modalOpen;
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalType: modalState ? modalType : "",
      referenceIndex: index
    });

    if (modalType === "") {
      actions.getReferences(applicationNumber);
    }
  };

  finalDataReferesh = async () => {
    let { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    actions.getSummaryForAllApplicant(applicationNumber);
  };

  async componentDidMount() {
    window.addEventListener("scroll", setHeaderFixed);
    let { actions, history, match } = this.props;
    let { loaderModal } = actions;
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      loaderModal(true);
      // actions.getReferences(applicationNumber);
      actions.getDecisionSummary(applicationNumber);
      actions.getDecisionCode();
      actions.getRCUDocumentType();
      actions.getRCUAgencyMaster();
      actions.getDeviationsReasonMaster();
      actions.getDeviations(applicationNumber);
      actions.getDecision(applicationNumber);
      actions.getSpoc(applicationNumber);
      actions.getCreditCapacityDetails(applicationNumber);
      actions.getBusinessDetails(applicationNumber);
      actions.getSummaryForAllApplicant(applicationNumber);
      actions.getCaseHighlights(applicationNumber);
      actions.getBankingDetailsForFinalDecision(applicationNumber);
      actions.getLimitUtilzationAnalysis(applicationNumber);
      actions.getList(
        "SET_FINAL_DECISION",
        API_GET_DECISION1,
        "Select Final Decision"
      );
      actions.getList(
        "SET_REPAYMENT_TYPE",
        API_GET_REPAYMENT_TYPE,
        "Select Repayment Type"
      );
      actions.getList(
        "SET_REFERENCE_RELATIONSHIP_LIST",
        API_GET_REFERENCE_RELATION,
        "Select Relationship"
      );

      GET(`/v1/rcu/get/rcuDetails/${applicationNumber}`).then(
        ({ data, status }) => {
          if (data.status === "success") {
            this.setState({ rcuDetails: data.data.verificationDetails });
            this.state.rcuDetails.map((el, index) => {
              return (
                (document.querySelector(
                  `select[name='rcu-agency${index}back']`
                ).value = this.state.rcuDetails[index].agency.toUpperCase()),
                (document.querySelector(
                  `select[name='rcu-document${index}back']`
                ).value = this.state.rcuDetails[index].category.toUpperCase()),
                (document.querySelector(
                  `select[name='rcu-status${index}back']`
                ).value = this.state.rcuDetails[index].verificationStatus)
              );
            });
          }
        }
      );
      await actions.getCaseHighlights(applicationNumber);
      loaderModal(false);
    } else {
      history.push("/Credit/dashboard");
    }
    let { caseHighlights, decision, spoc } = this.props;
    var cc1 = get(caseHighlights, "ccScore", 0);
    if (Object.keys(decision).length === 0) {
      this.setState({ decisionBtn: true });
    }
    if (Object.keys(spoc).length === 0) {
      this.setState({
        fpr: true
      });
    }
    Highcharts.chart("gauge-chart", {
      chart: {
        type: "solidgauge",
        backgroundColor: "transparent",
        height: 75
      },
      title: null,
      pane: {
        center: ["50%", "85%"],
        size: "170%",
        startAngle: -90,
        endAngle: 90,
        background: {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc"
        }
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        showFirstLabel: false,
        showLastLabel: false,
        stops: [
          [0.65, "#F0CE70"],
          [0.9, "#8BD591"]
        ],
        minorTickInterval: null,
        min: 0,
        max: 900
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Speed",
          data: [cc1]
        },
        {
          type: "gauge",
          data: [cc1]
        }
      ]
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }

  selectHandler = (i, name) => {
    let {
        actions,
        desicionList,
        decisionCode,
        repaymentTypeList,
        deviationReasonMaster
      } = this.props,
      { changeInputValue } = actions;
    let decisionCodeList = decisionCode.map(item => {
      return item.reason;
    });
    let codeList = decisionCode.map(item => {
      return item.code;
    });
    this.setState({
      code: codeList[i - 1]
    });
    let value = "";
    switch (name) {
      case "loanType":
        break;
      case "DecisionSummary":
        value = decisionCodeList[i - 1];
        break;
      case "FinalDecisionCode":
        value = desicionList[i - 1];
        break;
      case "RepaymentType":
        value = repaymentTypeList[i - 1];
        break;
      case "DeviationReasonMaster":
        value = deviationReasonMaster[i - 1];
        break;
      default:
        value = "";
    }
    changeInputValue(value, name);
  };
  inputChangeHandler = i => {
    const { actions } = this.props,
      { changeInputValue } = actions;

    let value = i.target.value;
    let name = i.target.name;
    changeInputValue(value, name);
  };

  submitForm = e => {
    e.preventDefault();
    const { actions, match, finalDecision, decisionCode } = this.props,
      { loaderModal } = actions,
      code = this.state.code,
      descriptionArr = decisionCode.filter(e => e.code.includes(code)),
      description = descriptionArr[0].reason,
      comment = get(finalDecision, "EnterDecisionSummary", "");
    let applicationNumber = get(match, "params.appNo", "");

    let data = {
      applicationNumber,
      code,
      comment,
      description
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_ADD_DEVIATION, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          actions.getDecisionSummary(applicationNumber);

          document.querySelector('input[name="EnterDecisionSummary"]').value =
            "";
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  submitForm1 = e => {
    e.preventDefault();
    const { actions, match, finalDecision } = this.props,
      { loaderModal } = actions,
      comment = get(finalDecision, "comment", "0"),
      decision = get(finalDecision, "FinalDecisionCode", "0"),
      decisionId = "",
      isInsurance = this.state.insurance,
      repaymentType = get(finalDecision, "RepaymentType", "0"),
      role = "",
      sanctionedAmount = get(finalDecision, "loanAmount", "0"),
      sanctionedTenure = get(finalDecision, "tenure", "0"),
      userId = null;
    var specialConditions = [],
      things = {
        commentId: "",
        comment: ""
      };
    this.state.addList.forEach((item, index) => {
      this.state.commentId.forEach((item1, index1) => {
        if (index === index1) {
          things = {
            comment: item,
            commentId: item1
          };
        }
      });
      specialConditions.push(things);
    });
    let applicationNumber = get(match, "params.appNo", "");

    let data = {
      applicationNumber,
      comment,
      decision,
      decisionId,
      isInsurance,
      repaymentType,
      role,
      sanctionedAmount,
      sanctionedTenure,
      specialConditions,
      userId
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_ADD_DECISION, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          this.setState({ decisionBtn: false });
          actions.getDecision(applicationNumber);
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  submitForm2 = e => {
    e.preventDefault();
    const { actions, match, finalDecision } = this.props,
      { loaderModal } = actions,
      comment = get(finalDecision, "EnterDeviationReasonMater", ""),
      description = get(finalDecision, "DeviationReasonMaster", "");
    let applicationNumber = get(match, "params.appNo", "");
    let data = {
      comment,
      description
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_ADD_MANUAL_DEVIATION(applicationNumber), { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          actions.getDeviations(applicationNumber);
          document.querySelector(
            'input[name="EnterDeviationReasonMater"]'
          ).value = "";
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  spocSubmit = e => {
    e.preventDefault();
    const { actions, match, spoc } = this.props,
      { loaderModal } = actions,
      name = document.querySelector(`input[name='spocName']`).value,
      mobile = document.querySelector(`input[name='mobNo']`).value,
      designation = document.querySelector(`input[name='spocDesignation']`)
        .value,
      spocId = get(spoc, "spocId", null);
    let applicationNumber = get(match, "params.appNo", "");

    let data = {
      applicationNumber,
      designation,
      mobile,
      name,
      spocId
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_SPOC_DETAILS, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          actions.getSpoc(applicationNumber);
          this.setState({
            fpr: false
          });
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  onRowClick1 = e => {
    e.preventDefault();
    const { actions, match, summaryForAllApplicant } = this.props,
      { loaderModal } = actions;
    const companyList = summaryForAllApplicant.filter(e =>
      e.caseRole.includes("COMPANY")
    );
    let applicationNumber = get(match, "params.appNo", "");
    if (companyList && companyList !== null && companyList.length > 0) {
      var entity = companyList[0].name;
      var pan = companyList[0].pan;
    }
    let data = {
      applicationNumber,
      entity,
      pan
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_GET_FIPD_DETAILS, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          this.setState(
            {
              fiypd: data.data
            },
            () => this.toggleModal("Fiypd")
          );
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  onRowClick = (state, rowInfo, original, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        e.preventDefault();
        const { actions, match } = this.props,
          { loaderModal } = actions;
        let applicationNumber = get(match, "params.appNo", "");
        let entity = rowInfo.original.name;
        let pan = rowInfo.original.pan;

        let data = {
          applicationNumber,
          entity,
          pan
        };
        loaderModal(true);
        const headers = { "Content-Type": "application/json" };
        POST(API_GET_FIPD_DETAILS, { data }, { headers })
          .then(({ data }) => {
            loaderModal(false);
            if (data.status === "success") {
              this.setState(
                {
                  fiypd: data.data
                },
                () => this.toggleModal("Fiypd")
              );
            } else {
              alert(data.message);
            }
          })
          .catch(err => {
            console.log(err);
            loaderModal(false);
            alert("Something went wrong please try again later.");
          });

        if (handleOriginal) {
          handleOriginal();
        }
      }
    };
  };

  refreshDecision = () => {
    let { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", ""),
      { loaderModal } = actions;
    loaderModal(true);
    actions.getDecisionSummary(applicationNumber);
    loaderModal(false);
  };
  refreshRiskBased = () => {
    let { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", ""),
      { loaderModal } = actions;
    loaderModal(true);
    actions.getDeviations(applicationNumber);
    loaderModal(false);
  };

  rcuInitiate = (index, verificationId = null, apiType) => {
    let { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    var category = document.querySelector(
      `select[name='rcu-document${index}back']`
    ).value;
    var comment = document.querySelector(
      `textarea[name='rcu-comment${index}back']`
    ).value;
    var agency = document.querySelector(`select[name='rcu-agency${index}back']`)
      .value;
    var verificationStatus = document.querySelector(
      `select[name='rcu-status${index}back']`
    ).value;
    var data = {
      agency,
      applicationNumber,
      category,
      comment,
      verificationId,
      verificationStatus
    };
    let { loaderModal } = actions;

    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    POST(API_INITIATE_RCU_REQUEST, { data }, { headers })
      .then(async ({ data, status }) => {
        if (data.status === "success") {
          loaderModal(false);
          await GET(`/v1/rcu/get/rcuDetails/${applicationNumber}`);
          await this.setState({
            initiateRCU: true
          });
        } else {
          alert(data.message);
          loaderModal(false);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
      });
  };

  cmrChange = async e => {
    await this.setState({
      cmr: e.target.value
    });
    const { actions, match } = this.props,
      { loaderModal } = actions,
      score = "" + this.state.cmr;
    let applicationNumber = get(match, "params.appNo", "");
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_UPDATE_CMR(applicationNumber, score), { data: "" }, { headers })
      .then(async ({ data }) => {
        loaderModal(false);
        if (data.status !== "success") {
          alert(data.message);
          await this.setState({
            cmrEdit: false
          });
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  rcuDelete = index => {
    if (index !== -1) {
      this.setState({
        addRCU: this.state.addRCU.filter((_, i) => i !== index)
      });
    }
  };
  cmrEdit = () => {
    this.setState({
      cmrEdit: true
    });
  };

  toggleRowEditable = index => {
    const { deviations } = this.props;
    this.setState({
      editableRows: {
        ...this.state.editableRows,
        [index]: !this.state.editableRows[index]
      },
      data: deviations[index].mitigation
    });
  };

  renderEditable = cellInfo => {
    const { deviations } = this.props;
    const editable = this.state.editableRows[cellInfo.index];
    const description =
      deviations[cellInfo.row.id].tag +
      ":" +
      deviations[cellInfo.row.id].reason;

    return (
      <div>
        <textarea
          disabled={!editable}
          id={cellInfo.row.id}
          defaultValue={deviations[cellInfo.row.id].mitigation}
          style={{
            backgroundColor: editable ? "#fafafa" : "transparent",
            flex: "350 0 auto",
            width: "350px",
            maxWidth: "350px",
            border: "transparent",
            textAlign: "center"
          }}
          type="text"
          autofocus="autofocus"
          onChange={this.handleChange}
        />

        {editable ? (
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              float: "right"
            }}
            onClick={() => this.editMitigation(description, cellInfo.row.id)}
          >
            {" "}
            <img size={12} src={Edit5} alt="Edit Icon" className="editIcon" />
          </button>
        ) : (
          <button
            style={{
              border: "none",
              backgroundColor: "transparent",
              float: "right"
            }}
            onClick={() => this.toggleRowEditable(cellInfo.row.id)}
          >
            <img size={12} src={Edit4} alt="Edit Icon" className="editIcon" />
          </button>
        )}
      </div>

      // />
    );
  };

  editMitigation = (description, index) => {
    const { actions, match } = this.props,
      { loaderModal } = actions,
      comment = this.state.valueMitigation;
    let applicationNumber = get(match, "params.appNo", "");

    let data = {
      comment,
      description
    };
    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_EDIT_MITIGATION(applicationNumber), { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          // actions.getDecisionSummary(applicationNumber);
          this.setState({
            editableRows: {
              ...this.state.editableRows,
              [index]: !this.state.editableRows[index]
            }
          });
          actions.getDeviations(applicationNumber);
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };
  handleChange = evt => {
    this.setState({ valueMitigation: evt.target.value.substr(0, 100) });
  };
  render() {
    let {
      applicantsData,
      decisionsData,
      deviationsData,
      insurance,
      modalOpen,
      modalType
    } = this.state;
    const {
        finalData,
        referencesList,
        desicionList,
        finalDecision,
        decisionCode,
        decisionSummary,
        documentType,
        repaymentTypeList,
        deviationReasonMaster,
        deviations,
        decision,
        spoc,
        capacityDetails,
        businessDetails,
        summaryForAllApplicant,
        caseHighlights,
        bankingDetails,
        match,
        limitUtilizationAnalysis
      } = this.props,
      cc = get(caseHighlights, "ccScore", 0);
    var companyList = summaryForAllApplicant.filter(e =>
      e.caseRole.includes("COMPANY")
    );
    var applicantList = summaryForAllApplicant.filter(e =>
      e.caseRole.includes("APPLICANT", "CO-APPLICANT")
    );
    // decisionSummary=get(decisionSummary ||[]);
    let decisionCodeList = decisionCode.map(item => {
      return item.code + ":" + item.reason;
    });
    if (companyList && companyList.length !== 0) {
      var fiDetails1 = companyList[0].fiDetails;
    }
    const applicantsColumns = [
      {
        Header: "Name",
        accessor: "name"
      },

      {
        Header: "Age",
        accessor: "age"
      },
      {
        Header: "Case Role",
        accessor: "caseRole"
      },

      {
        Header: "CIBIL Score",
        accessor: "cibilScore",
        Cell: ({ row }) => {
          let { cibilScore } = row.original;

          return (
            <span style={{ color: cibilScore >= 600 ? "green" : "red" }}>
              {cibilScore}
            </span>
          );
        }
      },
      {
        Header: "FI Status",
        accessor: "fiDetails",
        width: 200,
        Cell: row => {
          return (
            <div>
              {row.row.fiDetails.map((item, index) => {
                return (
                  <div className={index === 0 ? "row" : "row mt-2"} key={index}>
                    <span className={"boldText"}>
                      Address{""} {index + 1}:
                    </span>
                    <span>{item.status ? capitalize(item.status) : null}</span>
                  </div>
                );
              })}
            </div>
          );
        }
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ original }) => {
          return (
            <span style={{ color: "blue" }}>
              {<Icon icon={cog} size={27} />}
            </span>
          );
        }
      }
    ];
    const decisionsColumns = [
      {
        Header: "Decision Code & Description",
        // accessor: "code"
        width: 250,
        Cell: ({ row }) => {
          let { code, description } = row.original;

          return (
            <div>
              <span>{code}: </span>
              <span>{description}</span>
            </div>
          );
        }
      },
      {
        Header: "Decision Comment",
        accessor: "comment",
        width: 400
      },
      {
        Header: "User Id & User Name",
        accessor: "createdBy"
      },
      {
        Header: "Date & Time",
        accessor: "date"
      }
    ];
    const deviationsColumns = [
      {
        Header: "RBA Code and Description",
        Cell: ({ row }) => {
          let { tag, reason } = row.original;

          return (
            <div>
              {tag !== null ? <span>{tag}: </span> : ""}
              <span>{reason}</span>
            </div>
          );
        }
      },
      {
        Header: "Mitigation",
        accessor: "mitigation",
        width: 350,
        Cell: this.renderEditable
      },
      {
        Header: "Level",
        accessor: "level1",

        Cell: ({ row }) => {
          let { level1, level2, level3 } = row.original;
          var arr = [];
          if (level1 === true) {
            arr.push("L1");
          }
          if (level2 === true) {
            arr.push("L2");
          }
          if (level3 === true) {
            arr.push("L3");
          }
          return (
            <div>
              <span style={{ color: "green" }}>{arr.join(",")}</span>
            </div>
          );
        }
      },
      {
        Header: "Date & Time",
        accessor: "createdDate"
      }
    ];

    const limitUtilisationColoumn = [
      {
        Header: "Bank Name & Account No.",
        accessor: "accountNumber"
      },
      {
        Header: "Limit Utilization(%)",
        accessor: "totalLimitUtilization",
        Cell: ({ row }) => {
          let { totalLimitUtilization } = row.original;

          return (
            <span>
              {totalLimitUtilization > 0 ? Number(totalLimitUtilization).toFixed(2) : 0}
            </span>
          );
        }
      },
      {
        Header: "Limit Sanctioned",
        accessor: "sanctionedLimit"
      }
    ];

    return (
      <Fragment>
        <div className={"final-decision-wrapper content-container"}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Final Decision</title>
          </Helmet>
          <div className={"box-wrapper secondary"}>
            <div className={"box-header"}>
              <ul>
                <li>
                  <h4>CC Case ID</h4>
                  <small className="mt-2">
                    {get(match, "params.appNo", "")}
                  </small>
                </li>
                <li>
                  <h4>{get(businessDetails, "businessName")}</h4>
                  <small className="mt-2">
                    {` ${get(businessDetails, "businessConstitutionType")},
                                 ${get(businessDetails, "businessAge")}`}
                  </small>
                </li>
                <li>
                  <h4>Type Of Buisness </h4>
                  <small className="mt-2">
                    {get(businessDetails, "businessType")}
                  </small>
                </li>
                <li>
                  <h4>Primary Industry </h4>
                  <small className="mt-2">
                    {get(businessDetails, "primaryBusinessNature")}
                  </small>
                </li>
                <li>
                  <h4>Secondary Industry </h4>
                  <small className="mt-2">
                    {get(businessDetails, "secondryBusinessNature")}
                  </small>
                </li>
              </ul>
            </div>
            <div className={"box-footer"}>
              <ul className={"list-unstyled"}>
                <li className="centered">
                  <label className="boldText">Turnover Considered</label>
                  <span>
                    <span className={"icon"}>
                      <Icon icon={rupee} size={16} />
                    </span>
                    {rupeeCommaFormatter(
                      get(capacityDetails, "salesConsideredForEligibility", 0)
                    )}
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">Margin Considered</label>
                  <span>
                    {get(capacityDetails, "marginConsideredForEligibility", 0)}%
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">Monthly Income</label>
                  <span>
                    <span className={"icon"}>
                      <Icon icon={rupee} size={16} />
                    </span>
                    {rupeeCommaFormatter(
                      get(capacityDetails, "monthlyMargin", 0)
                    )}
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">ABB</label>
                  <span>
                    <span className={"icon"}>
                      <Icon icon={rupee} size={16} />
                    </span>
                    {get(bankingDetails, "averageEffectiveBalance", 0)
                      ? rupeeCommaFormatter(
                          get(
                            bankingDetails,
                            "averageEffectiveBalance",
                            0
                          )
                        )
                      : 0}
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">Existing Obligations</label>
                  <span>
                    <span className={"icon"}>
                      <Icon icon={rupee} size={16} />
                    </span>
                    {rupeeCommaFormatter(
                      get(capacityDetails, "existingObligations", 0)
                    )}
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">Pre FOIR</label>
                  <span>
                    {get(capacityDetails, "preFoir", 0) !== null &&
                    get(capacityDetails, "preFoir", 0) >= 0
                      ? Number(get(capacityDetails, "preFoir", 0)).toFixed(2) + "%"
                      : "NA"}
                  </span>
                </li>
                <li className="centered">
                  <label className="boldText">Eligible Loan / Limit</label>
                  <span>
                    <span className={"icon"}>
                      <Icon icon={rupee} size={16} />
                    </span>
                    {rupeeCommaFormatter(
                      get(capacityDetails, "eligibleLoanAmount", 0)
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={"box-wrapper p-b-0"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>CC Score Spectrum</h4>
            </div>
            <div className={"row cc-score-box"}>
              <div className={"col-md-1 col-sm-9"}>
                <label className="tc">CC score</label>
                <div className={"col-sm-9 justify-content-left "}>
                  <span className="pull-left">
                    {get(caseHighlights, "ccScore", 0)}
                  </span>
                </div>
                <div className="vl"></div>
              </div>

              <div className={"col-md-2 guageChart"}>
                <div id={"gauge-chart"} />
              </div>
              <div className={"col-md-9 col-sm-9 chart"}>
                <ul>
                  <li className="score-box-text">CC Score</li>
                  <li className="score-box-text">
                    below 500
                    {500 > cc ? (
                      
                      <span className="score-box-text score-box-text-weight">
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20}/>
                      </span>
                    
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    500
                    {500 <= cc && 520 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    520
                    {520 <= cc && 540 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    540
                    {540 <= cc && 560 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    560
                    {560 <= cc && 580 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    580
                    {580 <= cc && 600 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    600
                    {600 <= cc && 620 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    620
                    {620 <= cc && 640 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text"> 
                    640
                    {640 <= cc && 660 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    660
                    {660 <= cc && 680 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    680
                    {680 <= cc && 700 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    700
                    {700 <= cc && 720 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    720
                    {720 <= cc && 740 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    740
                    {740 <= cc && 760 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    760
                    {760 <= cc && 780 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    780
                    {780 <= cc && 800 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    800
                    {800 <= cc && 820 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    820
                    {820 <= cc && 840 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    840
                    {840 <= cc && 860 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    860
                    {860 <= cc && 880 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    880
                    {880 <= cc && 900 > cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li className="score-box-text">
                    900
                    {900 === cc ? (
                      <span>
                        CC Score for {get(businessDetails, "businessName")}<br/>
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"row w-100"}>
            {/* <div className={'col-md-12'}> */}
            <div className={"box-wrapper"} style={{width : "inherit"}}>
              <div className={"box-header mb-3"}>
                <h4 style ={{fontSize : "18px"}}>System Decision</h4>
                <h4>
                  <span
                    style={{
                      color:
                        get(caseHighlights, "systemDecision", "") ===
                        "Auto Reject"
                          ? "#ed5a5a "
                          : "#8bd591",
                      fontSize: "14px"
                    }}
                  >
                    {get(caseHighlights, "systemDecision", "")}
                  </span>
                </h4>
              </div>

              {/* <div className={"box-footer"}>
                <ul className={"list-unstyled mt-2"}>
                  <li>
                    <label className="boldText">Limit Sanctioned</label>
                    <span>
                      <span className={"icon"}>
                        <Icon icon={rupee} size={16} />
                      </span>
                      {rupeeCommaFormatter(
                        get(bankingDetails, "limitSactioned", 0)
                      )}
                    </span>
                  </li>
                  <li>
                    <label className="boldText">Limit Utilisation</label>
                    {get(bankingDetails, "limitUtilisation", 0)
                      ?((get(bankingDetails, "limitUtilisation", 0).toFixed(2)))
                      : 0}%
                  </li>
                  <li>
                    <label className="boldText">
                      Average Effective Balance Available
                    </label>
                    <span>
                      <span className={"icon"}>
                        <Icon icon={rupee} size={16} />
                      </span>
                      {rupeeCommaFormatter(
                        get(bankingDetails, "averageEffectiveBalance", 0)
                      )}
                    </span>
                  </li>
                  <li>
                    <label className="boldText">CC Score</label>
                    <span>{get(caseHighlights, "ccScore", 0)}</span>
                  </li>
                  <li>
                    <label className="boldText">Pre Foir</label>
                    <span> {get(capacityDetails, "preFoir", 0) !== null
                      ? get(capacityDetails, "preFoir", 0).toFixed(2)
                      : ""}
                    %</span>
                  </li>
                  <li>
                    <label className="boldText">System Decision</label>
                    <span>{get(caseHighlights, "systemDecision", 0)}</span>
                  </li>
                </ul>
              </div> */}
              {/* <hr className="m-b-0 m-t-0" /> */}
              
              <div className="row">
                <ReactTable
                  data={limitUtilizationAnalysis}
                  columns={limitUtilisationColoumn}
                  NoDataComponent={() => null}
                  showPagination={false}
                  minRows={0}
                  className={!deviationsData.length ? "no-data m-b-0" : "m-b-0"}
                />
              </div>
              <div className="row">
                <div className="col-sm-12 mt-2">
                  <h4>System Decision Reasons:</h4>
                  <div className={"col-sm-8 mt-2"}>
                    <ol>
                      {get(caseHighlights, "systemRejectedReasons", []).map(
                        (item, index) => {
                          return (
                            <li key={index}>
                              <p>{item}</p>
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"box-wrapper p-b-0"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>Summary for all Applicants</h4>
            </div>
            <div className={"box-footer"}>
              <ul className={"list-unstyled"}>
                <li>
                  <label className="boldText">Name </label>
                  {companyList && companyList.length !== 0 ? (
                    <span>{companyList[0].name}</span>
                  ) : (
                    <span></span>
                  )}
                </li>
                <li>
                  <label className="boldText">Vintage</label>
                  {companyList && companyList.length !== 0 ? (
                    <span>{companyList[0].age}</span>
                  ) : (
                    <span></span>
                  )}
                </li>
                <li>
                  <label className="boldText">CMR</label>

                  {this.state.cmrEdit ? (
                    <span>
                      {" "}
                      <select
                        className={"select-box"}
                        name={get(finalData, "businessSummary.cibil", "")}
                        onChange={this.cmrChange}
                      >
                        <option value={""}>Select CMR</option>
                        <option value={"0"}>0</option>
                        <option value={"1"}>1</option>
                        <option value={"2"}>2</option>
                        <option value={"3"}>3</option>
                        <option value={"4"}>4</option>
                        <option value={"5"}>5</option>
                        <option value={"6"}>6</option>
                        <option value={"7"}>7</option>
                        <option value={"8"}>8</option>
                        <option value={"9"}>9</option>
                      </select>
                    </span>
                  ) : (
                    <span>
                      {companyList && companyList.length !== 0
                        ? companyList[0].cibilScore
                        : 0}
                      <button
                        onClick={this.cmrEdit}
                        className="btnIcon"
                        style={{
                          background: "white",
                          border: "none",
                          marginLeft: "20px",
                          float: "right"
                        }}
                      >
                        <img
                          size={12}
                          src={Edit3}
                          alt="Edit Icon"
                          className="editIcon"
                        />
                      </button>
                    </span>
                  )}
                </li>
                <li>
                  <label className="boldText">FI Status</label>
                  {}
                  {fiDetails1
                    ? fiDetails1.map((item, index) => {
                        return (
                          <p key={index}>
                            <span className="boldText">
                              Address {index + 1}:
                            </span>
                            {item.status ? capitalize(item.status) : null}
                          </p>
                        );
                      })
                    : null}
                </li>
                <li>
                  <button className="btnGreen padding" onClick={this.onRowClick1}>
                    {<Icon icon={cog} size={17} className="mr-2" />}
                    Action
                  </button>
                </li>
              </ul>
            </div>
            <div className={"row "}>
              <div className={"col-md-12 vertical-divider-right"}>
                <div className={"highlights-box strengths mb-3"}>
                  <div className={"box-content"}>
                    <ReactTable
                      className={
                        !applicantsData.length ? "no-data m-b-0" : "m-b-0"
                      }
                      getTrProps={this.onRowClick}
                      data={applicantList}
                      columns={applicantsColumns}
                      showPagination={false}
                      minRows={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"box-wrapper p-b-0"}>
            <div className={"box-header"}>
              <h4>Case Highlights</h4>
            </div>
            <div className={"row"}>
              <div className={"col-md-6 "}>
                <div className={"highlights-box-strengths"}>
                  <h4>Strengths</h4>
                  <div className={"box-content"}>
                    <ol>
                      {get(caseHighlights, "strengths", []).map(
                        (item, index) => {
                          return (
                            <li key={index}>
                              <p>{item}</p>
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>
                </div>
              </div>
              <div className={"col-md-6 vertical-divider-left"}>
                <div className={"highlights-box-flags "}>
                  <h4 style={{ align: "center" }}>Flags</h4>
                  <div className={"box-content"}>
                    <ol>
                      {get(caseHighlights, "flags", []).map((item, index) => {
                        return (
                          <li key={index}>
                            <p>{item}</p>
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"box-wrapper"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>Risk Based Approval</h4>
              <button
                type={"button"}
                onClick={this.refreshRiskBased}
                className="btnGreen padding "
              >
                <Icon icon={ic_refresh} size={18} /> Refresh
              </button>
            </div>
            <ReactTable
              data={deviations}
              columns={deviationsColumns}
              NoDataComponent={() => null}
              showPagination={false}
              minRows={0}
              className={!deviationsData.length ? "no-data m-b-0" : "m-b-0"}
            />
            <form className={"row filters-box"}>
              <div className={"col-md-4 input-field-box"}>
                <label className={"input-label"}>
                  RBA Code and Description
                </label>
                <DropdownButton
                  title={get(
                    finalDecision,
                    "DeviationReasonMaster",
                    "Select Deviation Reason"
                  )}
                  id={"login-select"}
                  className={"select-box"}
                  onSelect={i => this.selectHandler(i, "DeviationReasonMaster")}
                >
                  {deviationReasonMaster.map((item, index) => (
                    <Dropdown.Item
                      title={item}
                      key={index + 1}
                      eventKey={index + 1}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className={"col-md-6 input-field-box"}>
                <label className={"input-label"}>Mitigation</label>
                <input
                  type={"text"}
                  className={"input-field"}
                  name={"EnterDeviationReasonMater"}
                  onChange={this.inputChangeHandler}
                  placeholder={"Mitigation"}
                />
              </div>
              <div className={"col-md-2 btns-box"}>
                <button onClick={this.submitForm2} className="blueButton padding">
                  <Icon icon={ic_add} size={22} /> Submit
                </button>
              </div>
            </form>
          </div>

          <div className={"box-wrapper"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>Decision Summary</h4>
              <button
                type={"button"}
                onClick={this.refreshDecision}
                className="btnGreen padding "
              >
                <Icon icon={ic_refresh} size={18} /> Refresh
              </button>
            </div>
            <ReactTable
              data={decisionSummary}
              columns={decisionsColumns}
              resizable={false}
              NoDataComponent={() => null}
              showPagination={false}
              minRows={0}
              className={!decisionsData.length ? "no-data m-b-0" : "m-b-0"}
            />
            <form className={"row filters-box"}>
              <div className={"col-md-3 input-field-box"}>
                <label className={"input-label "}>Decision Code</label>
                <DropdownButton
                  title={get(
                    finalDecision,
                    "DecisionSummary",
                    "Select Decision Code"
                  )}
                  id={"DecisionSummaryDropdown"}
                  className={"select-box mt-1"}
                  onSelect={i => this.selectHandler(i, "DecisionSummary")}
                >
                  {decisionCodeList.map((item, index) => (
                    <Dropdown.Item
                      active={item === decisionCodeList}
                      title={item}
                      key={index + 1}
                      eventKey={index + 1}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className={"col-md-6 input-field-box"}>
                <label className={"input-label"}>Decision Comment</label>
                <input
                  type={"text"}
                  defaultValue={this.state.decisionSummaryInput}
                  className={"input-field mt-1"}
                  placeholder={"Enter"}
                  name={"EnterDecisionSummary"}
                  onChange={this.inputChangeHandler}
                />
              </div>
              <div className={"col-md-3 btns-box"}>
                <button
                  type={"submit"}
                  className="blueButton padding mt-1"
                  onClick={this.submitForm}
                >
                  <Icon icon={ic_add} size={22} /> Add
                </button>
              </div>
            </form>
          </div>

          <div className={"box-wrapper decisions-box"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>Decisions</h4>

              {this.state.decisionBtn ? (
                <button onClick={this.editDecision1} className="decisions">
                  <img
                    src={Replay}
                    alt="Cross Icon"
                    style={{ height: "22px" }}
                  />
                </button>
              ) : (
                <button
                  onClick={() =>
                    this.editDecision(get(decision, "specialConditions", []))
                  }
                  className="decisions"
                >
                  <img src={Edit} alt="Cross Icon" style={{ height: "22px" }} />
                </button>
              )}
            </div>
            {this.state.decisionBtn ? (
              <div className={"decisionContainer"}>
                <div className={"finalDecisionRow"}>
                  <div className="row">
                    <div className="col-md-4">
                      <label className={"input-label"}>
                        Select Final Decision
                      </label>
                      <div className={"input-field-box"}>
                        <DropdownButton
                          title={get(
                            finalDecision,
                            "FinalDecisionCode",
                            decision.decision || "Select Final Decision"
                          )}
                          id={"document-select"}
                          className={"select-box"}
                          onSelect={i =>
                            this.selectHandler(i, "FinalDecisionCode")
                          }
                        >
                          {desicionList.map((item, index) => (
                            <Dropdown.Item
                              active={
                                item ===
                                get(
                                  finalDecision,
                                  "FinalDecisionCode",
                                  "Select Decision Code"
                                )
                              }
                              title={item}
                              key={index + 1}
                              eventKey={index + 1}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className={"input-label"}>Role</label>
                      <div className={"input-field-box"}>
                        <DropdownButton
                          title={"Select Role"}
                          id={"document-select"}
                          className={"select-box"}
                          disabled={true}
                        >
                          <Dropdown.Item eventKey={1}>
                            Select Role
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label className={"input-label"}>User</label>

                      <div className={"input-field-box"}>
                        <DropdownButton
                          title={"Select User"}
                          id={"document-select"}
                          className={"select-box"}
                          disabled={true}
                        >
                          <Dropdown.Item eventKey={1}>
                            Select User
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 row">
                    <div
                      className="col-md-6"
                      style={{ padding: "0px 0px 0px 0px" }}
                    >
                      <label className={"input-label"}>
                        Sanctioned Loan Amount
                      </label>
                      <div className={"input-field-box"}>
                        <input
                          type={"number"}
                          className={"input-field"}
                          placeholder={"Enter Sanctioned Loan Amount"}
                          name={"loanAmount"}
                          onChange={this.inputChangeHandler}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                          defaultValue={decision.sanctionedAmount}
                        />
                      </div>
                    </div>
                    <div className="col-md-6" style={{ paddingRight: "0px" }}>
                      <label className={"input-label"}>Sanctioned Tenure</label>
                      <div className={"input-field-box"}>
                        <input
                          type={"number"}
                          className={"input-field"}
                          defaultValue={decision.sanctionedTenure}
                          placeholder={"Enter Sanctioned Tenure"}
                          name={"tenure"}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                          onChange={this.inputChangeHandler}
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-6"
                      style={{ padding: "0px 15px 0px 0px" }}
                    >
                      <label className={"input-label"}>
                        Select Repayment Type
                      </label>
                      <div className={" input-field-box"}>
                        <DropdownButton
                          title={get(
                            finalDecision,
                            "RepaymentType",
                            decision.repaymentType || "Select Repayment Type"
                          )}
                          id={"document-select"}
                          className={"select-box"}
                          onSelect={i => this.selectHandler(i, "RepaymentType")}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                        >
                          {repaymentTypeList &&
                            repaymentTypeList.map((item, index) => (
                              <Dropdown.Item
                                active={
                                  item ===
                                  get(
                                    finalDecision,
                                    "RepaymentType",
                                    "Select Repayment Type"
                                  )
                                }
                                title={item}
                                key={index + 1}
                                eventKey={index + 1}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                        </DropdownButton>
                      </div>
                    </div>

                    <div className={"col-md-6 row p-0"}>
                      <div className={"col-md-6 radios mt-3"}>
                        <RadioBox
                          radioColor={"#1d1d1d"}
                          checked={insurance}
                          name={"insurance"}
                          onChange={() => this.setInsurance(true)}
                          labelFor={"insurance"}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                        />
                        <label className={"input-label"} htmlFor={"insurance"}>
                          Insurance
                        </label>
                      </div>
                      <div className={"col-md-6 radios mt-3"}>
                        <RadioBox
                          radioColor={"#1d1d1d"}
                          checked={!insurance}
                          labelFor={"no-insurance"}
                          name={"insurance"}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                          onChange={() => this.setInsurance(false)}
                        />
                        <label
                          className={"input-label"}
                          htmlFor={"no-insurance"}
                        >
                          No Insurance
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label className={"input-label"}  style={{ width: "100%" }}>Comment</label>
                    <textarea
                      className={"textArea-input-field mt-3 input-field"}
                      defaultValue={decision.comment}
                      placeholder={"Enter Comment"}
                      name={"comment"}
                      disabled={
                        "Hold" === get(finalDecision, "FinalDecisionCode") ||
                        "Reject" === get(finalDecision, "FinalDecisionCode")
                      }
                      onChange={this.inputChangeHandler}
                      style={{ height: "150px", padding: "10px 10px" }}
                    />
                  </div>
                </div>
                <div className="row finalDecisionRow">
                  <hr />
                  <div className={"col-md-12 conditions-list"}>
                    <h3>
                      <span>*</span> Special Conditions
                    </h3>
                    <div className=" conditions-list">
                      <div className={"col-sm-8"}>
                        <ol>
                          {this.state.addList.map((item, index) => {
                            return (
                              <li key={index}>
                                <p>{item}</p>
                              </li>
                            );
                          })}
                        </ol>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-md-10 input-field-box">
                        <input
                          type={"text"}
                          className={"input-field"}
                          placeholder={"Enter"}
                          name={"specialComment"}
                          onChange={this.inputChangeHandler}
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                        />
                      </div>
                      <div className={"col-md-2 btns-box"}>
                        <button
                          disabled={
                            "Hold" ===
                              get(finalDecision, "FinalDecisionCode") ||
                            "Reject" === get(finalDecision, "FinalDecisionCode")
                          }
                          onClick={this.subm}
                          className="blueButton padding"
                        >
                          <Icon icon={ic_add} size={20} /> <span>Add</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button className="blueButton padding" onClick={this.submitForm1}>
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              <div className={"row decisionContainer"}>
                <div className="row content-box">
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>Final Decision</label>
                      <span className={"input-span"}>{decision.decision}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>Role</label>
                      <span>{decision.role}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>User</label>
                      <span>{decision.userId}</span>
                    </div>
                  </div>
                </div>
                <div className="row content-box">
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>Loan Amount</label>
                      <span>{decision.sanctionedAmount}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>Tenure</label>
                      <span>{decision.sanctionedTenure}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>Comment</label>
                      <span>{decision.comment}</span>
                    </div>
                  </div>
                </div>

                <div className="row content-box">
                  <div className="col-md-4">
                    <div className={"content"}>
                      <label className={"input-heading"}>
                        Repayment Structure
                      </label>
                      <span>{decision.repaymentType}</span>
                    </div>
                  </div>
                  <div className={"col-md-4 "}>
                    <div className={"col-lg-5 radios"}>
                      <RadioBox
                        radioColor={"#1d1d1d"}
                        checked={decision.isInsurance}
                        labelFor={"insurance"}
                        name={"insurance"}
                      />
                      <label className={"input-heading"} htmlFor={"insurance"}>
                        Insurance
                      </label>
                      {/* </div> */}
                    </div>
                    <div className={"col-lg-7 radios"}>
                      <RadioBox
                        radioColor={"#1d1d1d"}
                        checked={!decision.isInsurance}
                        labelFor={"no-insurance"}
                        name={"insurance"}
                      />
                      {/* <div className={'content'}> */}
                      <label
                        className={"input-heading"}
                        htmlFor={"no-insurance"}
                      >
                        No Insurance
                      </label>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="row content-box">
                  <hr
                    style={{
                      border: "0.5px solid",
                      width: "1020px",
                      marginLeft: "-11px",
                      borderRadius: "5px"
                    }}
                  />
                  <div className={"col-md-12 conditions-list"}>
                    <h3>
                      <span>*</span> Special Conditions
                    </h3>

                    <div className=" conditions-list">
                      <div className={"col-sm-8"}>
                        <ol>
                          {get(decision, "specialConditions", []).map(
                            (item, index) => {
                              return (
                                <li key={index}>
                                  <p>{item.comment}</p>
                                </li>
                              );
                            }
                          )}
                        </ol>
                      </div>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="box-wrapper">
            <div className="box-header">
              <h4 style ={{fontSize : "18px"}}>RCU</h4>
            </div>
            <div className={"table-box"}>
              <table className={"primary-table"}>
                <thead>
                  <tr>
                    <th>Documents</th>
                    <th>Comment</th>
                    <th>Agency</th>
                    <th>Action</th>
                    <th>Status</th>
                    <th>Update</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.rcuDetails
                    ? this.state.rcuDetails.map((el, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <select
                                className="select-box"
                                name={`rcu-document${index}back`}
                                defaultValue={"Select"}
                              >
                                {documentType.map((x, y) => (
                                  <option key={y} value={x}>
                                    {x ? capitalize(x) : null}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <textarea
                                name={`rcu-comment${index}back`}
                                className="textArea-input-field"
                                defaultValue={el.comment}
                              />
                            </td>
                            <td>
                              <select
                                className={"select-box"}
                                name={`rcu-agency${index}back`}
                              >
                                <option value={""}>SELECT AGENCY</option>
                                <option value={"CREDOCHAIN"}>Credochain</option>
                              </select>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  this.rcuInitiate(index, el.verificationId)
                                }
                              >
                                ReInitiate
                              </button>
                            </td>
                            <td>
                              <select
                                className={"select-box"}
                                name={`rcu-status${index}back`}
                              >
                                <option value={""}>Select status</option>
                                <option value={"positive"}>Positive</option>
                                <option value={"negative"}>Negative</option>
                                <option value={"referToCredit"}>
                                  Refer To Credit
                                </option>
                                <option value={"waived"}>Waived</option>
                              </select>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  this.rcuInitiate(
                                    index,
                                    el.verificationId,
                                    "Update"
                                  )
                                }
                              >
                                Update
                              </button>
                            </td>
                            <td>
                              <button disabled={true}>
                                <img
                                  src={CrossF}
                                  alt="Cross Icon"
                                  style={{ height: "22px" }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {this.state.addRCU.map((el, index) => {
                    var lengthIncrement = this.state.rcuDetails
                      ? this.state.rcuDetails.length
                      : 0;
                    return (
                      <tr key={index}>
                        <td>
                          <select
                            className="select-box"
                            name={`rcu-document${index + lengthIncrement}back`}
                            value={el.category}
                          >
                            {documentType.map((x, y) => (
                              <option key={y}>{x}</option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <textarea
                            cols={30}
                            rows={10}
                            name={`rcu-comment${index + lengthIncrement}back`}
                            style={{
                              height: "32px",
                              border: "1px solid #BCBCBC",
                              boxSizing: "border-box",
                              borderRadius: "5px",
                              paddingLeft: "8px",
                              marginTop: "3px"
                            }}
                            className="textArea-input-field"
                            defaultValue={el.comment}
                          />
                        </td>
                        <td>
                          <select
                            className={"select-box"}
                            name={`rcu-agency${index + lengthIncrement}back`}
                          >
                            <option value={""}>SELECT AGENCY</option>
                            <option value={"Credochain"}>CREDOCHAIN</option>
                          </select>
                        </td>
                        <td>
                          {this.state.initiateRCU ? (
                            <button
                              className="btn"
                              onClick={() =>
                                this.rcuInitiate(index + lengthIncrement)
                              }
                            >
                              ReInitiate
                            </button>
                          ) : (
                            <button
                              className="btn"
                              onClick={() =>
                                this.rcuInitiate(index + lengthIncrement)
                              }
                            >
                              Initiate
                            </button>
                          )}
                        </td>
                        <td>
                          <select
                            className={"select-box"}
                            name={`rcu-status${index + lengthIncrement}back`}
                          >
                            <option value={""}>Select status</option>
                            <option value={"positive"}>Positive</option>
                            <option value={"negative"}>Negative</option>
                            <option value={"referToCredit"}>
                              Refer To Credit
                            </option>
                            <option value={"waived"}>Waived</option>
                          </select>
                        </td>
                        <td>
                          <button
                            className="btn"
                            onClick={() =>
                              this.rcuInitiate(
                                index + lengthIncrement,
                                el.verificationId,
                                "Update"
                              )
                            }
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => this.rcuDelete(index)}
                            disabled={this.state.initiateRCU}
                          >
                            <img
                              src={CrossF}
                              alt="Cross Icon"
                              style={{ height: "22px" }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                className="btns-box"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  style={{ marginRight: "20px", marginBottom: "8px" }}
                  onClick={() =>
                    this.setState({ addRCU: [...this.state.addRCU, 1] })
                  }
                >
                  + Add Row
                </button>
              </div>
            </div>
          </div>

          <Document props={this.props} />
          <div className={"box-wrapper "}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>FPR/SPOC</h4>
              {!this.state.fpr ? (
                <button onClick={this.eidtFprBtn} className="buttonFPR">
                  <img src={Edit} alt="Cross Icon" style={{ height: "22px" }} />
                </button>
              ) : (
                <button onClick={this.eidtFprBtn1} className="buttonFPR">
                  <img
                    src={Replay}
                    alt="Cross Icon"
                    style={{ height: "22px" }}
                  />
                </button>
              )}
            </div>
            {/* <div className={'row'}> */}
            {this.state.fpr ? (
              <div className={"fprBox"}>
                <div className={"row fprBox"}>
                  <div className="col-md-4">
                    <div className={"input-field-box"}>
                      <label className={"input-label"}>Name</label>
                      <input
                        type={"text"}
                        className={"input-field"}
                        placeholder={"Enter"}
                        name={"spocName"}
                        onChange={this.inputChangeHandler}
                        defaultValue={spoc.name}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"input-field-box"}>
                      <label className={"input-label"}>Designation</label>
                      <input
                        type={"text"}
                        defaultValue={spoc.designation}
                        className={"input-field"}
                        placeholder={"Enter"}
                        name={"spocDesignation"}
                        onChange={this.inputChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"input-field-box"}>
                      <label className={"input-label"}>Mob No.</label>
                      <input
                        type={"number"}
                        defaultValue={spoc.mobile}
                        className={"input-field"}
                        placeholder={"Enter"}
                        name={"mobNo"}
                        onChange={this.inputChangeHandler}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    onClick={this.spocSubmit}
                    
                    className="blueButton padding"
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : (
              <div className={"fprBox"}>
                {/* <div className="row"> */}
                <div className="col-md-4">
                  <div className={"content"}>
                    <label className={"input-heading"}>Name</label>
                    <span className={"input-span"}>{spoc.name}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={"content"}>
                    <label className={"input-heading"}>Designation</label>
                    <span>{spoc.designation}</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={"content"}>
                    <label className={"input-heading"}>Mob No.</label>
                    <span>{spoc.mobile}</span>
                  </div>

                  {/* </div> */}
                </div>
              </div>
            )}
          </div>

          <div className={"box-wrapper"}>
            <div className={"box-header"}>
              <h4 style ={{fontSize : "18px"}}>References</h4>
            </div>
            <div className={"row references-box"}>
              {/* <div className={'col-md-10'}> */}
              {/* <div className={'reference'}> */}
              <ReferencesCard
                profileDetail={referencesList}
                props={this.props}
                editButton={this.toggleModal}
              />

              {/* </div> */}
              <div className="col-md-10"></div>
              {/* </div> */}
            </div>
            <div
              className="btns-box"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                className="btns-box"
                style={{
                  background: "#00CDBE",
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  border: "solid",
                  borderRadius: "25px",
                  width: "138px",
                  height: "41px",
                  marginTop: "25px"
                }}
                onClick={() => this.toggleModal("editRatio")}
              >
                Add References
              </button>
            </div>
          </div>
        </div>

        <CredoModal
          isOpen={modalOpen}
          className={"edit-ratio-form1"}
          props={this.props}
          fiypd={this.state.fiypd}
          editButton={this.toggleModal}
          sendData1={this.getData1}
          index1={this.state.referenceIndex}
          finalDataReferesh={this.finalDataReferesh}
          styles={{
            content: {
              width: modalType === "editRatio" ? "900px" : "1200px",
              height: "auto",
              borderRadius: "25px",
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff"
            }
          }}
          title={
            modalType === "editRatio"
              ? "Add a New Reference"
              : "Fiypd"
              ? ""
              : ""
          }
          closeAction={() => this.toggleModal("")}
          RenderingComponent={
            modalType === "editRatio"
              ? EditRatioForm
              : modalType === "Fiypd"
              ? Fiypd
              : ""
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ finalDecision, listData, OverViewReducer }) => ({
  finalData: finalDecision.finalData,
  referencesList: finalDecision.referencesList,
  desicionList: listData.desicionList,
  finalDecision: finalDecision.finalDecision,
  decisionSummary: finalDecision.decisionSummary,
  decisionCode: finalDecision.decisionCode,
  documentType: finalDecision.documentType,
  agencyMaster: finalDecision.agencyMaster,
  repaymentTypeList: listData.repaymentTypeList,
  deviationReasonMaster: finalDecision.deviationReasonMaster,
  deviations: finalDecision.deviations,
  referenceRelations: listData.referenceRelations,
  decision: finalDecision.decision,
  spoc: finalDecision.spoc,
  capacityDetails: finalDecision.capacityDetails,
  summaryForAllApplicant: finalDecision.summaryForAllApplicant,
  caseHighlights: finalDecision.caseHighlights,
  bankingDetails: finalDecision.bankingDetails,
  businessDetails: OverViewReducer.businessDetails,
  limitUtilizationAnalysis: finalDecision.limitUtilizationAnalysis
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getReferences,
      getList,
      loaderModal,
      changeInputValue,
      getDecisionSummary,
      getDecisionCode,
      getRCUDocumentType,
      getRCUAgencyMaster,
      getDeviationsReasonMaster,
      getDeviations,
      getDecision,
      getSpoc,
      getCreditCapacityDetails,
      getBusinessDetails,
      getSummaryForAllApplicant,
      getCaseHighlights,
      getBankingDetailsForFinalDecision,
      getLimitUtilzationAnalysis,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalDecision);
