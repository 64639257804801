import { combineReducers } from "redux";
import { listData } from "./listData.reducers";
import { homePageReducers } from "../Views/HomePage/homepage.reducer";
import { Biz360ReportReducer } from "../Views/BIZ360Report/Biz360Report.reducers";
import { loanAdvisoryReducers } from "../Views/LoanAdvisory/loanAdvisory.reducers";
import { UserNameAndGstReducer } from "../Views/UsernameAndOtp/usernameAndOtp.reducer";
import { MyBusinessReducer } from "../Views/Dashboard/MyBusiness/Business.reducers";
import { OverViewReducer } from "../Views/CreditDashboard/Overview/overview.reducer";
import { businessRisk } from "../Views/CreditDashboard/BusinessRisk/businessRisk.reducer";
import { cashFlow } from "../Views/CreditDashboard/CashFlow/cashflow.reducer";
import { creditBehaviour } from "../Views/CreditDashboard/CreditBehaviour/creditBehaviour.reducers";
import { flagCCScore } from "../Views/CreditDashboard/Flag/flag.reducer";
import { creditCapacity } from "../Views/CreditDashboard/CreditCapacity/creditCapacity.reducers";
import { finalDecision } from "../Views/CreditDashboard/FinalDecision/finalDecision.reducer";
import { dashBoardReducer } from "../Views/CreditDashboard/Dashboard/dashboard.reducer";
import { CreditHeaderReducer } from "../components/CreditHeader/header.reducer";
import { GSTDataReducer } from "../Views/ViewsComponents/GSTData/GSTData.reducers";
import { KYCReducer } from "../Views/ViewsComponents/KYC/KYC.reducers";
import { ReportDownloadReducer } from "../Views/ViewsComponents/ReportDownload/ReportDownload.reducers";
import { LoanPurposeReducer } from "../Views/ViewsComponents/LoanPurpose/LoanPurpose.reducers";
import { BankingReducer } from "../Views/ViewsComponents/Banking/Banking.reducers";
import { AmountAndTenureReducer } from "../Views/ViewsComponents/AmountAndTenure/AmountAndTenure.reducers";
import { GstNumberReducer } from "../Views/ViewsComponents/GSTNumber/GstNumber.reducers";
import { LoanApplicationPreviewReducer } from "../Views/ViewsComponents/LoanApplicationPreview/LoanApplicationPreview.reducers";
import { DocumentUploadReducer } from "../Views/ViewsComponents/DocumentUpload/DocumentUpload.reducers";

const initialState = {
  loaderModalStatus: false,
};

function data(state = initialState, action) {
  let { error } = action;
  switch (action.type) {
    case "SET_LOADER_MODAL_STATUS":
      return {
        ...state,
        loaderModalStatus: action.val,
      };

    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: error,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.val,
      };
    case "GET_PINCODES":
      return {
        ...state,
        userDetails: action.val,
      };
    default:
      return state;
  }
}
const reducers = combineReducers({
  data,
  homePageReducers,
  Biz360ReportReducer,
  loanAdvisoryReducers,
  UserNameAndGstReducer,
  MyBusinessReducer,
  OverViewReducer,
  creditBusinessRisk: businessRisk,
  cashFlow,
  creditBehaviour,
  flagCCScore,
  creditCapacity,
  finalDecision,
  listData,
  creditDashboard: dashBoardReducer,
  CreditHeaderReducer,
  GSTDataReducer,
  KYCReducer,
  ReportDownloadReducer,
  LoanPurposeReducer,
  BankingReducer,
  AmountAndTenureReducer,
  GstNumberReducer,
  LoanApplicationPreviewReducer,
  DocumentUploadReducer,
});

export default reducers;
