import React from 'react'

let loanPreferenceOptions = [
  "BUSINESS LOAN",
  "MUDRA LOAN",
  "LOAN AGAINST PROPERTY",
  "WORKING CAPITAL",
  "MERCHANT CASH ADVANCE",
  "TERM LOAN",
  "SUPPLY CHAIN ADVANCE",
  "let us choose for your business",
].map((el) => {
  return { label: el, value: el };
});



const addressJSX = (addressObject)=>(
  <div className="">

  </div>
)

export { loanPreferenceOptions ,addressJSX };
