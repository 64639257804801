import React from "react";
import Highcharts from "highcharts";
import Icon from "react-icons-kit";
import {
  integerToWordFormatter,
  integerToWordFormatterLabels,
  rupeeCommaFormatter,
  titleCase
} from "../../../utils/utility";
import { ic_check } from "react-icons-kit/md/ic_check";
import { monthsColumn } from "./cashFlow.selector";
import { ic_close } from "react-icons-kit/md/ic_close";
import { get } from "lodash";

const generateAreaChart = config => {
  return Highcharts.chart(
    config.name,
    {
      chart: {
        type: "area",
        backgroundColor: "transparent",
        height: 250
      },
      title: false,
      xAxis: { categories: config.categories },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          marker: {
            enabled: false
          }
        }
      },
      legend: false,
      tooltip: {
        formatter: function() {
          return "<p>" + this.x + "<br/>" + this.series.name + "<b>:  </b>" + integerToWordFormatter(this.y) + "</p>";
        }
      },
      series: [
        {
          name: config.series.name,
          data: config.series.data,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, "#00cdbe"],
              [1, "rgba(139, 213, 245, 0)"]
            ]
          }
        }
      ]
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text("No Data Available", 140, 120)
          .css({
            color: "#001061",
            fontSize: "16px"
          })
          .add();
      }
    }
  );
};

const generateAreaChartInPercentage = config => {
  return Highcharts.chart(
    config.name,
    {
      chart: {
        type: "area",
        backgroundColor: "transparent",
        height: 250
      },
      title: false,
      xAxis: { categories: config.categories },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return this.value + "%";
          }
        }
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          marker: {
            enabled: false
          }
        }
      },
      legend: false,
      tooltip: {
        formatter: function() {
          if (this.y) {
            return "<p>" + this.x + "<br/>" + this.series.name + "<b>:  </b>" + this.y.toFixed(2) + "%" + "</p>";
          }
        }
      },
      series: [
        {
          name: config.series.name,
          data: config.series.data,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1
            },
            stops: [
              [0, "#00cdbe"],
              [1, "rgba(139, 213, 245, 0)"]
            ]
          }
        }
      ]
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text("No Data Available", 140, 120)
          .css({
            color: "#001061",
            fontSize: "16px"
          })
          .add();
      }
    }
  );
};
const generateColumnChart = config => {
  Highcharts.chart(
    config.name,
    {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        height: 250
      },
      title: false,
      xAxis: {
        categories: config.categories
      },
      credits: false,
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      legend: get(config, "legends", true)
        ? {
            align: "center",
            verticalAlign: "top",
            layout: "horizontal"
          }
        : false,
      tooltip: {
        formatter: function() {
          return "<p>" + this.x + "<br/>" + this.series.name + "<b>:  </b>" + integerToWordFormatter(this.y) + "</p>";
        },
        outside: true
      },
      plotOptions: config.plotOptions,
      series: config.series
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text("No Data Available", 140, 120)
          .css({
            color: "#001061",
            fontSize: "16px"
          })
          .add();
      }
    }
  );
};
const generateStackColumnChart = config => {
  Highcharts.chart(
    config.name,
    {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        height: 250
      },
      title: false,
      xAxis: {
        categories: config.categories
      },
      credits: false,
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      legend: get(config, "legends", true)
        ? {
            align: "center",
            verticalAlign: "top",
            layout: "horizontal"
          }
        : false,
      tooltip: {
        shared: true,
        formatter: function() {
          var s = '<span style="font-size: 10px">' + this.x + "</span><br/>";
          for (var i = 0; i < this.points.length; i++) {
            var myPoint = this.points[i];
            if (myPoint.y !== 0) {
              s +=
                '<br/><span style="color:' +
                myPoint.series.color +
                ';font-size:20px">\u25CF</span>' +
                "<span>" +
                titleCase(myPoint.series.name) +
                "</span>" +
                ": ";
              if (myPoint.point.low && myPoint.point.high) {
                s += myPoint.point.low + " - " + myPoint.point.high;
              } else {
                s += integerToWordFormatter(myPoint.y);
              }
            }
          }
          s +=
            '<br/><div style="font-size: 15px; margin-top:10px"> Total:' +
            integerToWordFormatter(this.points[0].total) +
            "</div><br/>";
          return s;
        },
        outside: true
      },
      plotOptions: config.plotOptions,
      series: config.series
    },
    function(chart) {
      if (chart.series.length === 0) {
        chart.renderer
          .text("No Data Available", 140, 120)
          .css({
            color: "#001061",
            fontSize: "16px"
          })
          .add();
      }
    }
  );
};
const creditTransactionColumns1 = [
  {
    Header: "Highest Value",
    accessor: "seqNo"
  },
  {
    Header: "Value",
    accessor: "amount",
    Cell: ({ row }) => {
      let { original } = row;
      return original.amount ? rupeeCommaFormatter(original.amount) : "0";
    }
  },
  {
    Header: "% of Total Credit",
    accessor: "percentageOfSales",
    Cell: ({ row }) => {
      let { original } = row;
      return original.percentageOfSales ? Number(original.percentageOfSales).toFixed(2) + "%" : "0%";
    }
  },
  {
    Header: "Counterparty",
    accessor: "description",
    Cell: ({ row }) => {
      let { original } = row;
      let { description } = original;
      return <span title={description}>{description}</span>;
    }
  }
];
const creditTransactionColumns2 = [
  {
    Header: "Repeat TXN",
    accessor: "repeat_txn"
  },
  {
    Header: "Frequency",
    accessor: "frequency"
  },
  {
    Header: "Total Value",
    accessor: "total_value"
  },
  {
    Header: "Counterparty",
    accessor: "counterparty"
  }
];
const debitTransactionColumns1 = [
  {
    Header: "Highest Value",
    accessor: "seqNo"
  },
  {
    Header: "Value",
    accessor: "amount",
    Cell: ({ row }) => {
      let { original } = row;
      return original.amount ? rupeeCommaFormatter(original.amount) : "0";
    }
  },
  {
    Header: "% of Total Debit",
    accessor: "percentageOfSales",
    Cell: ({ row }) => {
      let { original } = row;

      return original.percentageOfSales ? Number(original.percentageOfSales).toFixed(2) + "%" : "0%";
    }
  },
  {
    Header: "Counterparty",
    accessor: "description",
    Cell: ({ row }) => {
      let { original } = row;
      return (
        <label style={{ display: "inline" }} title={original.description}>
          {original.description}
        </label>
      );
    }
  }
];
const debitTransactionColumns2 = [
  {
    Header: "Repeat TXN",
    accessor: "repeat_txn"
  },
  {
    Header: "Frequency",
    accessor: "frequency"
  },
  {
    Header: "Total Value",
    accessor: "total_value"
  },
  {
    Header: "Counterparty",
    accessor: "counterparty",
    Cell: ({ row }) => {
      let { original } = row;
      return (
        <label style={{ display: "inline" }} title={original.counterparty}>
          {original.counterparty.value}
        </label>
      );
    }
  }
];

const bankingColumns = details => [
  {
    Cell: ({ row }) => {
      let { original } = row;

      return (
        <div>
          <label>{original.identifier}</label>
          <div className={"subIdentifier"}>{original.subIdentifier}</div>
        </div>
      );
    },
    Header: "Identifier",
    accessor: "",
    width: 180,
    className: "sticky",
    headerClassName: "sticky"
  },
  ...monthsColumn(details),
  {
    Header: "Total",
    accessor: "total",
    width: 100,
    Cell: ({ cell }) => {
      let { value } = cell;
      if (value) {
        return (
          <div>
            <div className="bold">{value.split("-")[0]}</div>
            <div>{value.split("-")[1]}</div>
          </div>
        );
      } else {
        return "No Data Available";
      }
    }
  },
  {
    Header: "Average",
    accessor: "average",
    width: 102,
    Cell: ({ cell }) => {
      let { value } = cell;
      if (value) {
        return (
          <div>
            <div className="bold">{value.split("-")[0]}</div>
            <div>{value.split("-")[1]}</div>
          </div>
        );
      } else {
        return "No Data Available";
      }
    }
  }
];
const emiAndServicing = [
  {
    Header: "No.",
    accessor: "seqNo",
    width: 50
  },
  {
    Header: "Lender",
    accessor: "description",
    Cell: ({ cell }) => {
      let { value } = cell;
      return <span title={value}>{value}</span>;
    }
  },
  {
    Header: "EMI",
    accessor: "amount",
    Cell: ({ cell }) => {
      let { value } = cell;
      return value ? rupeeCommaFormatter(value) : "0";
    }
  },
  {
    Header: "Date of Debit",
    accessor: "valueDate"
  },
  {
    Header: "CIBIL Match",
    accessor: "",
    Cell: ({ row }) => {
      let { original } = row;
      if (original.isMatch) {
        return (
          <span className={"icons check-icon"}>
            <Icon icon={ic_check} size={18} />
          </span>
        );
      } else {
        return (
          <span className={"icons close-icon"}>
            <Icon icon={ic_close} size={18} />
          </span>
        );
      }
    }
  }
];
const taxTransactionColumns = [
  {
    Cell: ({ row }) => {
      return <label>{row.id + 1}</label>;
    },
    Header: "No.",
    accessor: "seqNo",
    width: 50
  },
  {
    Header: "Tax",
    accessor: "amount",
    Cell: ({ cell }) => {
      let { value } = cell;
      return value ? rupeeCommaFormatter(value) : "0";
    }
  },
  {
    Header: "Payment",
    accessor: "percentageOfSales"
  },
  {
    Header: "Date of Debit",
    accessor: "valueDate"
  },
  {
    Header: "GST Match",
    accessor: "",
    Cell: ({ row }) => {
      let { original } = row;
      if (original.isMatch) {
        return (
          <span className={"icons check-icon"}>
            <Icon icon={ic_check} size={18} />
          </span>
        );
      } else {
        return (
          <span className={"icons close-icon"}>
            <Icon icon={ic_close} size={18} />
          </span>
        );
      }
    }
  }
];
const customerConcentration = [
  {
    Header: "Highest Value",
    accessor: "seqNo"
  },
  {
    Header: "Value",
    accessor: "amount",
    Cell: ({ cell }) => {
      let { value } = cell;
      return rupeeCommaFormatter(value);
    }
  },
  {
    Header: "% of Sales ",
    accessor: "percentageOfSales",
    Cell: ({ cell }) => {
      let { value } = cell;
      return value && Number(value).toFixed(2) + "%";
    }
  },
  {
    Header: "Description",
    accessor: "description"
  }
];

const vendorConcentration = [
  {
    Header: "Highest Value",
    accessor: "seqNo"
  },
  {
    Header: "Value",
    accessor: "amount",
    Cell: ({ cell }) => {
      let { value } = cell;
      return rupeeCommaFormatter(value);
    }
  },
  {
    Header: "% of Sales",
    accessor: "percentageOfSales",
    Cell: ({ cell }) => {
      let { value } = cell;
      return value && Number(value).toFixed(2) + "%";
    }
  },
  {
    Header: "Description",
    accessor: "description"
  }
];
export {
  generateAreaChart,
  generateColumnChart,
  creditTransactionColumns1,
  creditTransactionColumns2,
  debitTransactionColumns1,
  debitTransactionColumns2,
  bankingColumns,
  emiAndServicing,
  taxTransactionColumns,
  generateStackColumnChart,
  customerConcentration,
  vendorConcentration,
  generateAreaChartInPercentage
};
