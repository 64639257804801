import React, { Component } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import CommonModal from "../../../components/Modal/modal";
import AddressModal from "../UserAddress/addressModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRelationship, getProfileAddressType, getGender, fetchProfileData } from "../KYC/KYC.actions";
import { FaPencilAlt } from "react-icons/fa";
import { POST, GET } from "../../../utils/WebAPI.service";
import { API_POST_UPDATE_PROFILE, API_GET_PROFILE_DELETE } from "../../../utils/APIUrls";
import { loaderModal } from "../../../store/actions";
import { getDMY, getCookie, goTo, getNewDateFormat } from "../../../utils/utility";
import { MdDeleteForever } from "react-icons/md";

class MultipleApplicantKYC extends Component {
  state = {
    date: new Date(),
    modalIsOpen: false,
    mobile: "",
    isChecked: false,
    userData: [
      {
        id: null,
        name: "",
        gender: "",
        dateOfBirth: "",
        disclaimerText: "Test",
        email: "",
        mobile: "",
        pan: "",
        relation: "",
        profileType: "Applicant",
        address: [],
      },
    ],
  };
  onChange = (date) => this.setState({ date });
  toggleModal = (type, addressData, addressId, userDataId) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalType: type, addressId, addressData, userDataId });
  };
  selectHandler = (e, name, index) => {
    this.setState({ [name]: e.value });
    const userData = this.state.userData.slice();
    userData[index][name] = e.value;
    this.setState({ userData });
  };
  inputHandler = (e, index) => {
    var name = e.target.name,
      value = name === "pan" ? e.target.value.toUpperCase() : e.target.value;
    if (name === "mobile") {
      let regMobile = /^[0-9]*$/;
      if (regMobile.test(value)) {
        const userData = this.state.userData.slice();
        userData[index][name] = value;
        this.setState({ userData });
      }
    } else if (name === "pan" && value.length === 10) {
      var regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      if (regPan.test(value)) {
        const userData = this.state.userData.slice();
        userData[index][name] = value;
        this.setState({ userData });
      } else {
        alert("Please enter correct pan");
      }
    } else {
      const userData = this.state.userData.slice();
      userData[index][name] = value;
      this.setState({ userData });
    }
  };
  dateHandler = (e, index) => {
    const userData = this.state.userData.slice();
    userData[index].dateOfBirth = getDMY(e);
    this.setState({ userData });
  };
  addProfileHandler = async (e) => {
    e.preventDefault();
    let { userData } = this.state;
    let { actions, nextScreen } = this.props,
      { loaderModal } = actions,
      applicationNumber = getCookie("applicationNumber"),
      count = 0,
      isError = true;
    await userData.map(async (el, index) => {
      console.log(count);
      loaderModal(true);
      var dataToSend = {
        data: {
          ...el,
          dateOfBirth: el.dateOfBirth,
        },
      };
      await POST(API_POST_UPDATE_PROFILE(applicationNumber), dataToSend).then(({ data }) => {
        count++;
        if (data.status === "success") {
          isError = false;
        } else {
          alert(data.message);
          isError = true;
        }
        if (count === userData.length) {
          !isError && goTo(nextScreen);
        }
      });
      loaderModal(false);
      return null;
    });
  };
  deleteAddressHandler = () => {
    let id = this.state.addressId;
    let { userDataId } = this.state;
    let userData = this.state.userData.slice();
    userData[userDataId].address.splice(id, 1);
    this.setState({ userData });
    this.toggleModal("");
  };
  addCoApplicantHandler = () => {
    this.setState({
      userData: [
        ...this.state.userData,
        {
          id: "",
          name: "",
          gender: "",
          dateOfBirth: "",
          disclaimerText: "Test",
          email: "",
          mobile: "",
          pan: "",
          relation: "",
          profileType: "Co-Applicant",
          address: [],
        },
      ],
    });
  };
  addNewAddress = (data) => {
    let { userDataId } = this.state;
    const userData = this.state.userData.slice();
    userData[userDataId].address.push(data);
    this.setState({ userData });
  };
  editExistingAddress = (data) => {
    let { userDataId, addressId } = this.state;
    let userData = this.state.userData.slice(),
      userDataWithIndex = userData[userDataId];
    userDataWithIndex = {
      ...userDataWithIndex,
      address: [...userDataWithIndex.address, (userDataWithIndex.address[addressId] = data)],
    };
  };
  removeProfile = (el, Id) => {
    let { userData } = this.state;
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    if (el.id) {
      GET(API_GET_PROFILE_DELETE(getCookie("applicationNumber"), el.id)).then(({ data }) => {
        if (data.status === "success") {
          this.setState({ userData: userData.filter((el, index) => index !== Id) });
        }
        loaderModal(false);
        alert(data.message);
      }).catch(err=>{
        throw err
      });
    } else {
      this.setState({ userData: userData.filter((el, index) => index !== Id) });
      loaderModal(false);

    }
  };
  render() {
    let { userData } = this.state,
      { relationshipData, genderData, profileAddressTypeData } = this.props,
      relationshipList = relationshipData.map((el) => {
        return { value: el, label: el };
      }),
      genderList = genderData.map((el) => {
        return { value: el, label: el };
      });
    return (
      <form onSubmit={this.addProfileHandler} className="without-aadhar__wrapper">
        {userData.map((el, index) => {
          return (
            <div className="withoutAadhar card__wrapper" key={el.id || `userDataId${index}`}>
              <div className="heading">{index === 0 ? "Primary applicant" : "Co-Applicant"}</div>
              <div className="delete-profile__icon">
                {index === 0 ? null : <MdDeleteForever size={30} onClick={() => this.removeProfile(el, index)} />}
              </div>
              <div className="row no-gutters">
                <div className="col-md-6 col-sm-6 pr-3">
                  <label htmlFor="" className="label-heading">
                    Name
                  </label>
                  <input
                    type="text"
                    className="input__generic w-100"
                    onChange={(e) => this.inputHandler(e, index)}
                    name="name"
                    value={el.name}
                    required
                  />
                </div>
                <div className="col-md-3 col-sm-6  pr-3">
                  <label htmlFor="" className="label-heading">
                    Gender
                  </label>
                  <Select
                    className="basic-single mt-2"
                    classNamePrefix="select"
                    isClearable={true}
                    options={genderList}
                    onChange={(e) => this.selectHandler(e, "gender", index)}
                    value={genderList.find((option) => option.label === el.gender)}
                  />
                  <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={el.gender} />
                </div>
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="" className="label-heading">
                    Date Of Birth
                  </label>
                  <DatePicker
                    onChange={(e) => this.dateHandler(e, index)}
                    value={getNewDateFormat(el.dateOfBirth)}
                    className="w-100"
                    required
                    format={"dd/MM/y"}
                  />
                </div>
                <div className="col-md-4 col-sm-6  pr-3">
                  <label htmlFor="" className="label-heading">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="input__generic w-100"
                    maxLength={10}
                    onChange={(e) => this.inputHandler(e, index)}
                    name="mobile"
                    value={el.mobile}
                    required
                  />
                </div>
                <div className="col-md-4 com-sm-6  pr-3">
                  <label htmlFor="" className="label-heading">
                    Email ID<span className="optional-text__small">(optional)</span>
                  </label>
                  <input
                    type="text"
                    className="input__generic w-100"
                    onChange={(e) => this.inputHandler(e, index)}
                    name="email"
                    value={el.email}
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <label htmlFor="" className="label-heading">
                    PAN ID
                  </label>
                  <input
                    type="text"
                    className="input__generic w-100"
                    onChange={(e) => this.inputHandler(e, index)}
                    name="pan"
                    value={el.pan}
                    required
                    maxLength={10}
                  />
                </div>
                <div className="col-md-4 col-sm-6  pr-3">
                  <label htmlFor="" className="label-heading">
                    Relationship with the Entity
                  </label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    options={relationshipList}
                    onChange={(e) => this.selectHandler(e, "relation", index)}
                    value={relationshipList.find((option) => option.label === el.relation)}
                  />
                  <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={el.relation} />
                </div>
              </div>

              <label htmlFor="" className="label-heading">
                Address
              </label>
              <div className="address__wrapper">
                {el.address &&
                  el.address.map((el, addIndex) => {
                    return (
                      <div className="available-wrapper ml-0" key={addIndex}>
                        <div className="child__space-between">
                          <div> Address {addIndex + 1}</div>
                          <FaPencilAlt onClick={() => this.toggleModal("editAddress", el, addIndex, index)} />
                        </div>
                        <div className="bold-text" style={{ fontSize: "12px" }}>
                          {el.addressType}({el.ownership})
                        </div>

                        <p className="mt-4">{`${el.line1} ${el.line2} ${el.district} ${el.state}-${el.pinCode}`}</p>
                      </div>
                    );
                  })}
                <div className="add-wrapper ml-0" onClick={() => this.toggleModal("addAddress", null, null, index)}>
                  +Add Address
                </div>
              </div>
            </div>
          );
        })}

        <button className="button__generic">Continue</button>
        <button className="m-5 ghost-grey__button" onClick={this.addCoApplicantHandler}>
          Add Co-Applicant
        </button>
        <CommonModal
          isOpen={this.state.modalIsOpen}
          props={this.props}
          type={this.state.modalType}
          addressData={this.state.addressData || []}
          deleteAddressHandler={this.deleteAddressHandler}
          styles={{
            content: {
              width: "30%",
              minWidth: "400px",
            },
          }}
          title={"Address"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={AddressModal}
          addNewAddress={this.addNewAddress}
          editExistingAddress={this.editExistingAddress}
          addressTypeArray={profileAddressTypeData}
        />
      </form>
    );
  }
  componentDidMount = () => {
    let { getRelationship, getProfileAddressType, getGender, fetchProfileData, loaderModal } = this.props.actions,
      constitution = sessionStorage.getItem("constitution"),
      applicationNumber = getCookie("applicationNumber");
    loaderModal(true);
    getRelationship(constitution);
    getProfileAddressType();
    getGender();
    fetchProfileData(applicationNumber).then((data) => {
      data.data && this.setState({ userData: this.props.profileData });
      loaderModal(false);
    });
  };

}
const mapStateToProps = ({ KYCReducer, homePageReducers }) => ({
  relationshipData: KYCReducer.relationshipData,
  profileAddressTypeData: KYCReducer.profileAddressTypeData,
  genderData: KYCReducer.genderData,
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
  profileData: KYCReducer.profileData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getRelationship, getProfileAddressType, loaderModal, getGender, fetchProfileData },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleApplicantKYC);
