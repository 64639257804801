import { ProgressBar } from "react-bootstrap";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Modal from "react-modal";
import ReactTable from "../../../components/React-Table/ReactTable";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getIdVerificationReport } from "./overview.action";
import { some, get } from "lodash";
import Icon from "react-icons-kit";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import { cross } from "react-icons-kit/metrize/cross";
import { applicantColumns } from "./overview.config";

class ApplicantModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantData: []
    };
  }

  componentDidMount() {
    this.fetchAndCreateMatchData();
  }

  fetchAndCreateMatchData = async () => {
    let { getIdVerificationReport } = this.props.actions;
    let { panNumber, applicationNumber } = this.props;
    await getIdVerificationReport(panNumber, applicationNumber);
    let { idVerificationReport } = this.props;
    let nameSegment = get(idVerificationReport, "nameSegment", "");
    let dobSegment = get(idVerificationReport, "dobSegment", "");
    let panSegment = get(idVerificationReport, "panSegment", "");
    // let passportSegment = get(idVerificationReport, 'passportSegment', '');
    let voterIDSegment = get(idVerificationReport, "voterIDSegment", "");
    let dlSegment = get(idVerificationReport, "dlSegment", "");
    let mobileSegment = get(idVerificationReport, "mobileSegment", "");
    let addressSegment = get(idVerificationReport, "addressSegment", "");
    let genderSegment = get(idVerificationReport, "genderSegment", "");
    let applicantData = [
      {
        identifier: "Name",
        enquiry: get(nameSegment, "enquiryValue", "NA"),
        response: get(nameSegment, "responseValue", "NA"),
        match: get(nameSegment, "score", "NA")
      },
      {
        identifier: "DOB",
        enquiry: get(dobSegment, "enquiryValue", "NA"),
        response: get(dobSegment, "responseValue", "NA"),
        match: get(dobSegment, "score", "NA")
      },
      {
        identifier: "PAN",
        enquiry: get(panSegment, "enquiryValue", "NA"),
        response: get(panSegment, "responseValue", "NA"),
        match: get(panSegment, "score", "NA")
      },
      // {identifier: 'Passport', enquiry: get(passportSegment, 'enquiryValue', 'NA'), response: get(passportSegment, 'responseValue', 'NA'), match: get(passportSegment, 'score', 'NA')},
      {
        identifier: "Voter ID",
        enquiry: get(voterIDSegment, "enquiryValue", "NA"),
        response: get(voterIDSegment, "responseValue", "NA"),
        match: get(voterIDSegment, "score", "NA")
      },
      {
        identifier: "DL",
        enquiry: get(dlSegment, "enquiryValue", "NA"),
        response: get(dlSegment, "responseValue", "NA"),
        match: get(dlSegment, "score", "NA")
      },
      {
        identifier: "Mobile",
        enquiry: get(mobileSegment, "enquiryValue", "NA"),
        response: get(mobileSegment, "responseValue", "NA"),
        match: get(mobileSegment, "score", "NA")
      },
      {
        identifier: "Address",
        enquiry: get(addressSegment, "enquiryValue", "NA"),
        response: get(addressSegment, "responseValue", "NA"),
        match: get(addressSegment, "score", "NA")
      },
      {
        identifier: "Gender",
        enquiry: get(genderSegment, "enquiryValue", "NA"),
        response: get(genderSegment, "responseValue", "NA"),
        match: get(genderSegment, "score", "NA")
      }
    ];
    this.setState({
      applicantData: applicantData
    });
  };

  render() {
    let { applicantData } = this.state;
    let { modalVisible, toggleModal, idVerificationReport, loader } = this.props;
    return (
      <div>
        <Modal
          isOpen={modalVisible}
          onRequestClose={null}
          className={"overview-applicant-modal "}
          overlayClassName={"content-modal-overlay"}
          parentSelector={() => document.querySelector(".overview-wrapper")}
        >
          <LoaderModal status={loader} />
          <span onClick={() => toggleModal(false, null)} className={"cross-icon"}>
            <Icon icon={cross} size={26} />
          </span>
          <h3>
            Match Details - {get(idVerificationReport, "name", "")}{" "}
            <small>({get(idVerificationReport, "applicantType", "")})</small>
          </h3>
          <div className={"row"}>
            <div className={"col-md-4"}>
              <ul className={"applicant-details"}>
                <li>
                  <label>Age</label>
                  <span>{get(idVerificationReport, "age", 0)}</span>
                </li>
                <li>
                  <label>Education</label>
                  <span>{get(idVerificationReport, "education", "")}</span>
                </li>
                <li>
                  <label>Residence</label>
                  <span>
                    <address className={"m-0"}>{get(idVerificationReport, "address", "")}</address>
                  </span>
                </li>
              </ul>
              <CircularProgressbar
                percentage={get(idVerificationReport, "overAllScore", 0)}
                text={get(idVerificationReport, "overAllScore", 0) + "%"}
                className={"circular-progressbar"}
                strokeWidth={10}
                styles={{
                  path: { stroke: "#00cdbe" },
                  text: {
                    fill: "#1d1d1d",
                    fontFamily: "Helvetica Bold",
                    fontSize: "20px"
                  }
                }}
              />
              <ul className={"progress-list"}>
                <li>
                  <label>Identity</label>
                  <ProgressBar
                    now={get(idVerificationReport, "finalIdentityScore", 0)}
                    label={get(idVerificationReport, "finalIdentityScore", 0) + "%"}
                  />
                </li>
                <li>
                  <label>Address</label>
                  <ProgressBar
                    now={get(idVerificationReport, "finalAddressScore", 0)}
                    label={get(idVerificationReport, "finalAddressScore", 0) + "%"}
                  />
                </li>
                <li>
                  <label>Contact</label>
                  <ProgressBar
                    now={get(idVerificationReport, "finalContactScore", 0)}
                    label={get(idVerificationReport, "finalContactScore", 0) + "%"}
                  />
                </li>
              </ul>
            </div>
            <div className={"col-md-8"}>
              <ReactTable
                className={!applicantData.length ? "no-data" : null}
                data={applicantData.length ? applicantData : []}
                columns={applicantColumns}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ OverViewReducer }) => ({
  loader: some(OverViewReducer.loader),
  idVerificationReport: OverViewReducer.idVerificationReport
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getIdVerificationReport
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantModal);
