import * as ACTION_CONSTANTS from "./businessRisk.action.constant";

const initialState = {
  gstAnalysisData: [],
  gstData:[]
};

export const businessRisk = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ACTION_CONSTANTS.FETCH_GST_ANNUAL_ANALYSIS:
      return {
        ...state,
        gstAnalysisData: data
      };
    case ACTION_CONSTANTS.GET_GST_ANALYSIS:
      return {
        ...state,
        gstAnalysisData: data.mergedGstData,
        gstData:data.gstData
      };

    default:
      return state;
  }
};
