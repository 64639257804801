const GET_BUSINESS_DETAILS = Symbol('GetBusinessDetails');
const GET_BUSINESS_DETAILS_START = Symbol('GetBusinessDetailsStart');
const GET_BUSINESS_DETAILS_FINISHED = Symbol('GetBusinessDetailsFinished');

const GET_PROFILE_DETAIL = Symbol('GetProfileDetail');
const GET_PROFILE_DETAIL_START = Symbol('GetProfileDetailStart');
const GET_PROFILE_DETAIL_FINISHED = Symbol('GetProfileDetailFinished');

const FETCH_ID_VERIFICATION_REPORT = Symbol('FetchIdVerificationReport');
const FETCH_ID_VERIFICATION_REPORT_START = Symbol('FetchIdVerificationReportStart');
const FETCH_ID_VERIFICATION_REPORT_FINISHED = Symbol('FetchIdVerificationReportFinished');

const FETCH_FORM_FILLED_BY = Symbol('FetchFormFilledBy');
const FETCH_FORM_FILLED_BY_START = Symbol('FetchFormFilledByStart');
const FETCH_FORM_FILLED_BY_FINISHED = Symbol('FetchFormFilledByFinished');

const FETCH_TURNOVER_DETAILS_START = Symbol('FetchTurnoverDetails');
const FETCH_TURNOVER_DETAILS= Symbol('FetchTurnoverDetailsStart');
const FETCH_TURNOVER_DETAILS_FINISHED = Symbol('FetchTurnoverDetailsFinished');


const GET_BUSINESS = Symbol('GetBusiness');
const GET_BUSINESS_START = Symbol('GetBusinessStart');
const GET_BUSINESS_FINISHED = Symbol('GetBusinessFinished');
export {
    GET_BUSINESS_DETAILS,
    GET_BUSINESS_DETAILS_START,
    GET_BUSINESS_DETAILS_FINISHED,
    GET_PROFILE_DETAIL,
    GET_PROFILE_DETAIL_START,
    GET_PROFILE_DETAIL_FINISHED,
    FETCH_ID_VERIFICATION_REPORT,
    FETCH_ID_VERIFICATION_REPORT_START,
    FETCH_ID_VERIFICATION_REPORT_FINISHED,
    FETCH_FORM_FILLED_BY,
    FETCH_FORM_FILLED_BY_START,
    FETCH_FORM_FILLED_BY_FINISHED,
    FETCH_TURNOVER_DETAILS_START,
    FETCH_TURNOVER_DETAILS,
    FETCH_TURNOVER_DETAILS_FINISHED,
    GET_BUSINESS,
    GET_BUSINESS_START,
    GET_BUSINESS_FINISHED
}
