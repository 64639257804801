import React, { Component } from "react";

export default class NotAvailed extends Component {
  render() {
    let { tenure, paidDate } = this.props;
    let pendingInstallments = tenure - paidDate;
    let isDataCorrect = pendingInstallments > -1 ? true : false;
    return (
      <div className="not-availed__wrapper">
        <div className="d-flex justify-content-between">
          <div className="left-section">
            <div className="black-heading">Total Financial Impact :</div>
            <div className="grey__text mt-1">Assuming EMI and Tenure are kept constant</div>
          </div>
          <div className="right-section">
            <div className="blue_value">₹0</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div>Pending installments :</div>
          </div>
          <div className="right-section">
            <div>{isDataCorrect ? pendingInstallments : 0}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div className="black-heading">Already paid installments :</div>
          </div>
          <div className="right-section">
            <div>{isDataCorrect ? paidDate : tenure}</div>
          </div>
        </div>
      </div>
    );
  }
}
