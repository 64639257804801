import * as BankingConstant from "./Banking.constants";

const initialState = {
  bankNamesData: [],
  eligibilityData: [],
  bankDetailsData:[]
};

export const BankingReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case BankingConstant.BANK_NAMES:
      return {
        ...state,
        bankNamesData: data
      };
    case BankingConstant.ADD_ELIGIBILITY_DATA:
      return {
        ...state,
        eligibilityData: data
      };
    case BankingConstant.BANK_DETAILS:
      return {
        ...state,
        bankDetailsData: data
      };
    default:
      return state;
  }
};
