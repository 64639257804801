import * as LoanAdvisoryConstant from "./loanAdvisory.actions.constants";

const initialState = {
  findProductsData: [],
  primaryCollateralData: []
};
export const loanAdvisoryReducers = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case LoanAdvisoryConstant.POST_FIND_PRODUCTS:
      return {
        ...state,
        findProductsData: data
      };
    case LoanAdvisoryConstant.GET_PRIMARY_COLLATERAL:
      return {
        ...state,
        primaryCollateralData: data
      };
    default:
      return state;
  }
};
