import React, { Component } from "react";
import { goTo } from "../../../../utils/utility";

export default class Biz360ReportDetails extends Component {
    biz360ReportHandler = ()=>{
        goTo("/dashboard/business/biz360-report")
    }
  render() {
    return (
      <div className="biz360-details__wrapper">
        <div className="row">
          <div className="col-md-8">
            <div className="card__wrapper row" onClick={this.biz360ReportHandler}>
              <div className="display-space-between w-100">
                <h1>Biz360 Report Details</h1>
                <div className="update-gst__button">Update with new GST Data</div>
              </div>
            </div>
          </div>

          <div className="col-md-4"></div>
        </div>
      </div>
    );
  }
}
