import * as ReportDownloadConstants from "./ReportDownload.actions.constants";
const initialState = {
  profileData: [],
};

export const ReportDownloadReducer = (state = initialState, actions) => {
  let { data } = actions;
  switch (actions.type) {
    case ReportDownloadConstants.GET_PROFILE:
      if (data) {
        return {
          ...state,
          profileData: data,
        };
      }
      break;
    default:
      return state;
  }
};
