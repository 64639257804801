import React, { Component } from "react";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getPincode } from "../../../store/actions";
import Owned from "../../../assets/Images/Owned.png";
import Rented from "../../../assets/Images/Rented.png";
class AddressModal extends Component {
  state = {
    addressOwnership: "owned",
    addressData: [],
    isAllDataFilled: false,
  };

  addressTypeHandler = (type) => {
    this.setState({
      addressOwnership: type,
    });
  };
  addressHandler = (e) => {
    e.preventDefault();
    let { type, addNewAddress, editExistingAddress } = this.props,
      { addressType, state, district, addressOwnership, pinCode, city, line1, line2 } = this.state,
      data = {
        addressType,
        city,
        district,
        durationOfStay: null,
        line1,
        line2,
        ownership: addressOwnership,
        pinCode,
        state,
      };
    type === "addAddress" && addNewAddress(data);
    type === "editAddress" && editExistingAddress(data);
    this.props.closeAction();
  };
  handleChange = (e, name) => {
    this.setState({ [name]: e.value });
  };

  setPinCode = (e) => {
    let value = e.target.value,
      name = e.target.name;
    this.setState({ [name]: value });
    let { getPincode } = this.props.actions;
    if (value.length === 6) {
      getPincode(value).then(({ data }) => {
        if (data) {
          this.setState({ state: data[0].state, district: data[0].district, city: data[0].city });
        } else {
          window.alert("Please enter right pin");
        }
      });
    }
  };
  inputHandler = (e) => {
    let name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };

  componentDidMount = () => {
    let { addressData } = this.props;
    this.setState({
      line1: addressData.line1,
      line2: addressData.line2,
      state: addressData.state,
      district: addressData.district,
      pinCode: addressData.pinCode,
      addressOwnership: addressData.addressOwnership || "owned",
      city: addressData.city,
      addressType: addressData.addressType,
    });
  };
  render() {
    let { addressOwnership, state, district, addressType, line1, line2, pinCode, city } = this.state,
      { type, addressTypeArray } = this.props;
    var addressTypeOptions = addressTypeArray.map((el) => {
      return { value: el, label: el };
    });

    return (
      <div className="address-modal__wrapper">
        <div className="label-heading">Address Type</div>
        <Select
          onChange={(e) => this.handleChange(e, "addressType")}
          options={addressTypeOptions}
          value={addressTypeOptions.find((option) => option.label === addressType)}
        />
        <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={addressType} />
        <div className="label-heading">Address Status</div>
        <button
          className={addressOwnership === "owned" ? "button-selected" : "button-normal"}
          onClick={() => this.addressTypeHandler("owned")}
        >
          <img src={Owned} alt="owned" className="mr-2"/>
          Owned
        </button>
        <button
          className={addressOwnership === "rented" ? "button-selected" : "button-normal"}
          onClick={() => this.addressTypeHandler("rented")}
        >
          <img src={Rented} alt="Rented" className="mr-2"/>
          Rented
        </button>
        <div className="label-heading">Line 1</div>
        <input className="input__generic w-100" onChange={this.inputHandler} name={"line1"} value={line1} required />
        <div className="label-heading">Line 2</div>
        <input className="input__generic w-100" onChange={this.inputHandler} name={"line2"} value={line2} required />
        <div className="row no-gutters">
          <div className="col-sm-6 col-12  pr-4">
            <div className="label-heading">PIN-CODE </div>
            <input
              onChange={this.setPinCode}
              className="input__generic w-100"
              maxLength={6}
              value={pinCode}
              name={"pinCode"}
              required
              type="text"
            />
          </div>
          <div className="col-sm-6 col-12">
            {district ? (
              <>
                <div className="label-heading">District </div>
                <input
                  type="text"
                  value={district}
                  className="input__generic w-100"
                  onChange={this.inputHandler}
                  name="district"
                />
              </>
            ) : null}
          </div>
          <div className="col-sm-6 col-12 pr-4">
            {city ? (
              <>
                <div className="label-heading">city </div>
                <input
                  type="text"
                  value={city}
                  className="input__generic w-100 "
                  onChange={this.inputHandler}
                  name="city"
                />
              </>
            ) : null}
          </div>
          <div className="col-sm-6 col-12">
            {state ? (
              <>
                <div className="label-heading">State </div>
                <input
                  type="text"
                  value={state}
                  className="input__generic w-100"
                  onChange={this.inputHandler}
                  name="state"
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="btns-box">
          {type === "editAddress" ? (
            <button className="button-deleted" onClick={this.props.deleteAddressHandler}>
              Delete
            </button>
          ) : null}
          <button
            className="button-selected"
            onClick={this.addressHandler}
            disabled={!(addressType && line1 && line2 && pinCode)}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getPincode }, dispatch),
});

export default connect(null, mapDispatchToProps)(AddressModal);
