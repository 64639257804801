import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import WhiteLogo1 from "../../../assets/Images/WhiteLogo1.png";
import { Link } from "react-router-dom";
import { getTaxPayerData } from "../homepage.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { loaderModal } from "../../../store/actions";
import { goTo } from "../../../utils/utility";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import axios from "axios";
import {
  API_GET_GENERATE_TOKEN,
  API_POST_ADD_TEMP_USER,
} from "../../../utils/APIUrls";
import { POST } from "../../../utils/WebAPI.service";
import CommonModal from "../../../components/Modal/modal";
import OtpModal from "./OtpModal";
import MobileInputModal from "./MobileInputModal";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavItem,
} from "reactstrap";
import KnowMore from "./KnowMore";
class BusinessHealth extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  state = {
    gstNumber: "",
    isGstWrong: false,
    mobile: "",
    isOTP: true,
    isSignIn: false,
    modalIsOpen: false,
    knowMoreIsOpen: false,
    dropdownOpen: false,
  };
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  generateBizHandler = async () => {
    let { loaderModal, getTaxPayerData } = this.props.actions;
    loaderModal(true);
    await axios.get(API_GET_GENERATE_TOKEN).then(({ data }) => {
      document.cookie = `token=${data.data.tokenId}; path=/`;
    });
    await getTaxPayerData(this.state.gstNumber).then(({ data }) => {
      if (data.gstin) {
        document.cookie = `gstNumber=${this.state.gstNumber.toUpperCase()}; path=/`;
        goTo("/Biz360/withGst/gst-data");
      } else {
        goTo("/biz360/withoutGst/gst-input");
      }
    });
    loaderModal(false);
  };
  gstInputHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value ? e.target.value.replace(/[- ]+/g, "") : "";
    this.setState({ [name]: value });
  };
  tokenGenerator = async () => {
    let tokenData = null;
    await axios.get(API_GET_GENERATE_TOKEN).then(({ data }) => {
      document.cookie = `token=${data.data.tokenId}; path=/`;
      tokenData = data;
    });
    return tokenData;
  };
  toggleModal = (type) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalType: type });
  };
  knowMoreToggleModal = () => {
    this.setState({ knowMoreIsOpen: !this.state.knowMoreIsOpen });
  };

  addTempUserHandler = async () => {
    let { mobile } = this.state,
      { loaderModal } = this.props.actions,
      dataToSend = {
        data: {
          mobile,
        },
      };
    this.setState({ isSignIn: false });
    loaderModal(true);
    await this.tokenGenerator().then(({ data }) => {
      if (data && data.tokenId) {
        POST(API_POST_ADD_TEMP_USER, dataToSend)
          .then(({ data }) => {
            if (data.status === "success") {
              this.toggleModal();
              window.scroll(0, 0);
            } else {
              alert(data.message);
            }
          })
          .catch((err) => {
            throw err;
          });
      } else {
        alert("Something Went Wrong");
      }
    });
    loaderModal(false);
  };
  mobileNumberHandler = (e) => {
    var name = e.target.name,
      value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(value)) {
      this.setState({ [name]: value });
    }
  };
  siginHandler = () => {
    this.setState({ isOTP: false, isSignIn: true }, () => {
      this.toggleModal();
    });
  };
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById("js-header");
    if (headerEl) {
      if (distanceY > shrinkOn) {
        headerEl.classList.add("smaller");
      } else {
        headerEl.classList.remove("smaller");
      }
    }
  }
  render() {
    let { loaderModalStatus } = this.props,
      { mobile, isOTP, isSignIn, modalIsOpen, knowMoreIsOpen } = this.state;
    return (
      <React.Fragment>
        {knowMoreIsOpen ? <KnowMore /> : ""}
        <LoaderModal status={loaderModalStatus} />
        <div className="background-image" id="msmeLoans">
          <div className="outer">
            {/* <Header /> */}

            <div className="details">
              <Navbar
                expand="lg"
                fixed="top"
                id="js-header"
                className="navbar-dark"
                style={{ zIndex: "10001" }}
              >
                <Navbar.Brand href="#home">
                  <img src={WhiteLogo1} width="85%" alt="Credochain Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto" style={{ paddingTop: "1%" }}>
                    <Nav.Link as={Link} to="/">
                      Home
                    </Nav.Link>

                    <Nav>
                      <a
                        className="menubar-item nav-link"
                        href="https://pemant.in/"
                        target="_blank"
                      >
                        Platform
                      </a>
                    </Nav>
                    <a className="menubar-item nav-link" href="#ourApproach">
                      Approach
                    </a>
                    <UncontrolledDropdown
                      nav
                      inNavbar
                      onMouseOver={this.onMouseEnter}
                      onMouseLeave={this.onMouseLeave}
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggle}
                    >
                      <DropdownToggle
                        nav
                        caret
                        style={{ marginBottom: "14px" }}
                      >
                        MSME Loans
                      </DropdownToggle>
                      <DropdownMenu className="MSMELoan_dropdown" right>
                        <div className="triangle" />
                        <div className="d-flex">
                          <div className="horizontal-line">
                            <DropdownItem
                              href="../../msme/tools/LAP"
                              className="MSMELoan_dropdownItems"
                            >
                              Loan Against Property (LAP)
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/SME"
                              className="MSMELoan_dropdownItems"
                            >
                              Unsecured Business Loan - SME
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/micro-enterprises"
                              className="MSMELoan_dropdownItems"
                            >
                              Unsecured Business Loan - Micro
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/Balance-Transfer"
                              className="MSMELoan_dropdownItems"
                            >
                              Balance Transfer(LAP)
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/Bank-Od-Limit"
                              className="MSMELoan_dropdownItems"
                            >
                              Bank OD Limit{" "}
                            </DropdownItem>
                          </div>
                          <div>
                            <DropdownItem
                              href="../../msme/tools/Bank-Term-Loan"
                              className="MSMELoan_dropdownItems"
                            >
                              Bank/NBFC Term Loan
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/Bank-Cc-Limit"
                              className="MSMELoan_dropdownItems"
                            >
                              Bank CC Limit{" "}
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/Bill-Invoice-Discounting"
                              className="MSMELoan_dropdownItems"
                            >
                              Bill/Invoice Discounting
                            </DropdownItem>
                            <DropdownItem
                              href="../../msme/tools/Dealer-Purchase-Finance"
                              className="MSMELoan_dropdownItems"
                            >
                              Dealer/purchase Finance
                            </DropdownItem>
                          </div>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Nav.Link as={Link} to="/impact">
                      Impact
                    </Nav.Link>

                    <Nav.Link className="signin-btn">
                      <div onClick={this.siginHandler}>Sign in</div>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="main-wrapper" id="biz360Report">
                <h1>Check your Business Health, Free!</h1>

                <p className="main-content montserrat">
                  Our Biz360 Report analyses the health of your business in
                  minutes to make sure you opt for best suited loan or working
                  capital management solution.{" "}
                  <span
                    className="text__italic"
                    onClick={this.knowMoreToggleModal}
                  >
                    Know more
                  </span>
                </p>

                <InputMask
                  className="input-field__gst mr-4"
                  placeholder="Enter your GST number"
                  mask={"** - ********** - ***"}
                  value={this.state.gstNumber.toUpperCase()}
                  onChange={this.gstInputHandler}
                  maskChar={null}
                  name={"gstNumber"}
                />
                <button
                  className="secondary-button__custom"
                  onClick={this.generateBizHandler}
                  disabled={this.state.gstNumber.length < 15}
                >
                  Generate Biz360 Report
                </button>
                {this.state.isGstWrong ? (
                  <p className="error-message__line">Please Enter right GST</p>
                ) : null}

                {/* <p>
                  <Link
                    to="/biz360/withoutGst"
                    className="text__no-gst link"
                    onClick={this.tokenGenerator}
                  >
                    Don’t have a GSTIN? Click this{" "}
                  </Link>
                </p> */}

                <p>
                  <Link
                    to="/biz360/withoutGst/gst-data-input"
                    className="text__no-gst link"
                    onClick={this.tokenGenerator}
                  >
                    Don’t have a GSTIN? Click this{" "}
                  </Link>
                </p>

                <div className="button__generic cursor-pointer mt-2">
                  <Link className="link" to="/calculator/moratorium">
                    Moratorium Calculator
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loan-offer__wrapper">
          <div className="row">
            {/* <div className="col-md-4 col-sm-12">
                <img src={LoanOfferImage} alt="" />
              </div> */}
            <div className="col-md-12 col-sm-12">
              <h2 className="loan-offer__text">
                Find Loan offers that suits your business
              </h2>
              <p className="montserrat">
                Our aim is to help every business avail the most well-suited and
                affordable loan completely tailored to their business and
                cashflow requirements.
              </p>
              <input
                type="text"
                className="input__generic mr-4"
                placeholder="Enter your mobile number to continue"
                name="mobile"
                value={mobile}
                onChange={this.mobileNumberHandler}
                maxLength={10}
              />
              <button
                className="secondary-button__custom mt-2"
                onClick={this.addTempUserHandler}
                disabled={mobile.length < 10}
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
        <CommonModal
          isOpen={modalIsOpen}
          {...this.props}
          className="signup__wrapper"
          styles={{
            content: {
              width: "20%",
              minWidth: "250px",
            },
          }}
          isSignIn={isSignIn}
          title={isSignIn ? "Sign In" : "Create your free account"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={isOTP ? OtpModal : MobileInputModal}
          mobileNumber={mobile}
          otpSuccessHandler={(mobile) => this.setState({ isOTP: true, mobile })}
        />
      </React.Fragment>
    );
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  };
}
const mapStateToProps = ({ homePageReducers, data }) => ({
  loaderModalStatus: data.loaderModalStatus,
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTaxPayerData,
      loaderModal,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(BusinessHealth);
