import React, { Component } from "react";
import Header from "../../components/header/header";

export default class ComingSoon extends Component {
  render() {
    return (
      <div className="coming-soon__wrapper">

        <div className="overlay">
          <div className="heading1">Under Construction</div>
          <div className="text">
            This Page is currently under construction.We should be back to you shortly. Thank you for your patience.
          </div>
        </div>
      </div>
    );
  }
}
