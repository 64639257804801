import React, { Component } from "react";
import { connect } from "react-redux";
import { goTo } from "../../../utils/utility";
class LoanPurpose extends Component {
  state = {
    loanPurpose: "",
  };
  loanUseHandler = (loanPurpose) => {
    this.setState({ loanPurpose });
    sessionStorage.setItem("loanPurpose", loanPurpose);
    setTimeout(() => {
      goTo(this.props.nextScreen);
    }, 100);
  };
  render() {
    let { loanUseData } = this.props,
      { loanPurpose } = this.state;
    return (
      <div className="loan-purpose__wrapper">
        <div className="bold-text">For what purpose you wish to take the loan?</div>
        <div>Please select at least one to continue</div>
        <div className="loan-box__wrapper">
          {loanUseData.responseList &&
            loanUseData.responseList.slice(0, 11).map((el, index) => {
              return (
                <div
                  className={loanPurpose === el ? "loan-purpose-value__box active-card" : "loan-purpose-value__box"}
                  onClick={() => this.loanUseHandler(el)}
                >
                  <img src={require(`../../../assets/Images/LoanApplication/Image${index}.png`)} alt={"logo"} />
                  <div>{el}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    let loanPurpose = sessionStorage.getItem("loanPurpose");
    this.setState({ loanPurpose });
  }
}

const mapStateToProps = ({ LoanPurposeReducer }) => ({
  loanUseData: LoanPurposeReducer.LoanUseData,
});

export default connect(mapStateToProps, null)(LoanPurpose);
