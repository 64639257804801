import React, { Component } from "react";
import { connect } from "react-redux";
class AboutYourBusiness extends Component {
  render() {
    let gstTaxPayerData = this.props.gstTaxPayerData || [];
    return (
      <div className="business-wrapper">
        <h1><span>Hello! {gstTaxPayerData.businessName}</span> - {gstTaxPayerData.tradeName}</h1>
        <div className="gst__wrapper mt-2">
          <div className="gst-name">GST Username</div>
          <div className="gst-number">{gstTaxPayerData.gstin}</div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data, homePageReducers, biz360Reducers }) => ({
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
});

export default connect(mapStateToProps, null)(AboutYourBusiness);
