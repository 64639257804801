import { GET } from "../../utils/WebAPI.service";
import {
  API_GET_GST_TREND_DETAILS,
  API_GET_CONCENTRATION_RISK_DETAILS,
  API_GET_TURNOVER_AND_PURCHASES_DETAILS,
  API_GET_CREDIT_HISTORY_DETAILS,
  API_GET_ALL_APPLICANT_SUMMARY,
  API_GET_CURRENT_OBLIGATION,
  API_GET_ENTITY_DETAILS,
  API_GET_FIND_PRODUCTS_FOR_BIZ_REPORT,
  API_GET_BUSINESS_COMMENTS_FOR_REPORT
} from "../../utils/APIUrls";
import * as Biz360ReportConstants from "./Biz360Report.actions.constants";
const getGstTrendDetails = businessPan => {
  return dispatch => {
    return GET(API_GET_GST_TREND_DETAILS(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_GST_TREND_DETAILS, data: data.data });
      }
      return data;
    });
  };
};
const getConcentrationRiskDetails = businessPan => {
  return dispatch => {
    return GET(API_GET_CONCENTRATION_RISK_DETAILS(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_CONCENTRATION_RISK_DETAILS, data: data.data });
      }
      return data;
    });
  };
};
const getTurnoverAndPurchaseDetails = businessPan => {
  return dispatch => {
    return GET(API_GET_TURNOVER_AND_PURCHASES_DETAILS(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_TURNOVER_AND_PURCHASES_DETAILS, data: data.data });
      }
      return data;
    });
  };
};
const getCreditHistoryDetails = businessPan => {
  return dispatch => {
    return GET(API_GET_CREDIT_HISTORY_DETAILS(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_CREDIT_HISTORY_DETAILS, data: data.data });
      }
      return data;
    });
  };
};
const getAllApplicantSummary = businessPan => {
  return dispatch => {
    return GET(API_GET_ALL_APPLICANT_SUMMARY(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_ALL_APPLICANT_SUMMARY, data: data.data });
      }
      return data;
    });
  };
};
const getCurrentObligations = businessPan => {
  return dispatch => {
    return GET(API_GET_CURRENT_OBLIGATION(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_CURRENT_OBLIGATION, data: data.data });
      }
      return data;
    });
  };
};
const getEntityDetails = businessPan => {
  return dispatch => {
    return GET(API_GET_ENTITY_DETAILS(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_ENTITY_DETAILS, data: data.data });
      }
      return data;
    });
  };
};
const getFindProductsForBizReport = businessPan => {
  return dispatch => {
    return GET(API_GET_FIND_PRODUCTS_FOR_BIZ_REPORT(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_FIND_PRODUCTS_FOR_BIZ_REPORT, data: data.data });
      }
      return data;
    });
  };
};
const getBusinessCommentsForReport = businessPan => {
  return dispatch => {
    return GET(API_GET_BUSINESS_COMMENTS_FOR_REPORT(businessPan)).then(({ data }) => {
      if (data.data) {
        dispatch({ type: Biz360ReportConstants.GET_BUSINESS_COMMENTS_FOR_REPORT, data: data.data });
      }
      return data;
    });
  };
};

export {
  getGstTrendDetails,
  getConcentrationRiskDetails,
  getTurnoverAndPurchaseDetails,
  getCreditHistoryDetails,
  getAllApplicantSummary,
  getCurrentObligations,
  getFindProductsForBizReport,
  getEntityDetails,
  getBusinessCommentsForReport
};
