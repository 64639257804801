import React, { Component } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";



class CommonModal extends Component {
  render() {
    const { isOpen, closeAction, title, RenderingComponent, styles, className } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeAction}
        style={ styles }
        className={`overview-applicant-modal mobile-friendly ${className} `}
        overlayClassName={"content-modal-overlay"}
      >
        <span onClick={closeAction} className={"cross-icon"}>
          <MdClose size={24}/>
        </span>
        {title ? <h3>{title}</h3> : null}
        {RenderingComponent ? <RenderingComponent {...this.props} /> : null}
      </Modal>
    );
  }
}

CommonModal.defaultProps = {
  closeAction: () => {},
  RenderingComponent: null,
  styles: {},
  className: ""
};

export default CommonModal;
