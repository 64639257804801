import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import GstDataInputComponent from "../../../ViewsComponents/GSTDataInput/GstDataInput";
import Header from "../../../ViewsComponents/Header/Header";
export default class GSTDataInput extends Component {
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-md-3">
          <SidePanel
            advisories={[
              "Provide authentic information of the Applicant in order to successfully generate your report.",
            ]}
          ></SidePanel>
        </div>
        <div className="col-md-9">
          <Header navBar_className={"navbar-light"} backButtonText={"Home"} backLink={"/"} {...this.props}/>
          <GstDataInputComponent nextScreen={"kyc"} />
        </div>
      </div>
    );
  }
}
