import get from "lodash/get";
import set from "lodash/set";

const _getValue = (state, name) => {
    return get(state, `finalDecision.${name}`);
};

const _updateFormValues = (state, value = '', name) => {
    const _preValue = _getValue(state, name);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state)),
            _changedValue = get(set(_changedState, `finalDecision.${name}`, value), 'finalDecision');
        return {...state, finalDecision: _changedValue};
    }
    return state;
};

const _getAddressFormValue = (state, name, formIndex, index) => {
    return get(state, `businessDetails[${formIndex}].addressDetails[${index}].${name}`);
};


const _updateAddressFormValues = (state, value, name, formIndex, index) => {
    const _preValue = _getAddressFormValue(state, name, formIndex, index);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state)),
            _changedValue = get(set(_changedState, `businessDetails[${formIndex}].addressDetails[${index}].${name}`, value), 'businessDetails');
        return {..._changedState, businessDetails: [..._changedValue]};
    }
    return state;
};

export {
    _updateFormValues,
    _updateAddressFormValues
}
