import React, { Component } from 'react';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { info } from 'react-icons-kit/icomoon/info'
import Icon from 'react-icons-kit';

export class CredoToolTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.attachRef = target => this.setState({ target });
    }

    render() {
        // const { target } = this.state;
        const { popover, containerClassName, placement } = this.props;
        return (
          <div className={containerClassName} ref={this.attachRef}>
              <OverlayTrigger trigger="click" placement={placement}
                              overlay={popover} rootClose>
                  <span><Icon icon={info} size={20}/></span>
              </OverlayTrigger>
          </div>
        )
    }
}

CredoToolTip.defaultProps = {
    id: +new Date(),
    popover: <Popover id={"JHSJ"} title="Popover right">
    </Popover>,
    placement: 'right',
    containerClassName: ''
};
