import React, {PureComponent} from 'react';
import Icon from "react-icons-kit";
import {ic_radio_button_checked} from 'react-icons-kit/md/ic_radio_button_checked';
import {ic_radio_button_unchecked} from 'react-icons-kit/md/ic_radio_button_unchecked';

class RadioBox extends PureComponent {

    render() {
        let {radioColor, checked, inputClass, required, name, onChange, labelFor , defaultChecked} = this.props;
        return (
            <span className={'radio-box'}>
                <input ref={ref => this.radioRef = ref} className={inputClass} type={'radio'} name={name} defaultChecked={defaultChecked}
                       required={required} checked={checked} onChange={onChange} id={labelFor}/>
                <span className={'radios'}>
                    <span onClick={() => this.radioRef.click()} style={{color: radioColor}} className={'radio-checked'}>
                        <Icon icon={ic_radio_button_checked} size={20}/>
                        </span>
                    <span onClick={() => this.radioRef.click()} style={{color: radioColor}}
                          className={'radio-unchecked'}>
                        <Icon icon={ic_radio_button_unchecked} size={20}/>
                        </span>
                </span>
            </span>
        );
    }
}

RadioBox.defaultProps = {
    inputClass: '',
    required: false,
    radioColor: '',
    checked: false,
    name: '',
    onChange: () => {
        return null
    },
    labelFor: ''
};

export default RadioBox;
