import React, { Component } from "react";
import { rupeeCommaFormatter } from "../../../utils/utility";

export default class WithSameEMI extends Component {
  render() {
    let {
      pendingEmiInstallments,
      totalFinancialImpact,
      incrementInTenure,
      newTenure,
      oldTenure,
      oldEmiAmount
    } = this.props.calculatedData;
    return (
      <div className="with-same-emi__wrapper">
        <div className="f-12">
          New total tenure and Net Financial Impact is estimated basis existing loan details and moratorium period
          selected
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>Current EMI:</div>
          </div>
          <div className="right-section">
            <div>₹{rupeeCommaFormatter(oldEmiAmount)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div className="black-heading">Increase in total tenure :</div>
            <div className="grey__text">Assuming EMI is kept constant</div>
          </div>
          <div className="right-section">
            <div className="blue_value ">
              {incrementInTenure}
              <span className="f-14"> months</span>{" "}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>Old tenure :</div>
          </div>
          <div className="right-section">
            <div>{oldTenure}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>New tenure :</div>
          </div>
          <div className="right-section">
            <div>{newTenure}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div className="black-heading">Total Financial Impact :</div>
          </div>
          <div className="right-section">
            <div className="blue_value">₹{rupeeCommaFormatter(totalFinancialImpact)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="left-section">
            <div className="grey__text">Pending Installments :</div>
          </div>

          <div className="right-section">
            <div>{pendingEmiInstallments}</div>
          </div>
        </div>
      </div>
    );
  }
}
