import React, { Component } from 'react'
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import GstNumberComponent from "../../ViewsComponents/GSTNumber/GstNumber";
import Header from "../../ViewsComponents/Header/Header";
export default class GstNumber extends Component {
    render() {
        return (
            <div className="row no-gutters">
            <div className="col-md-3">
              <SidePanel
                advisories={[
                  "Accurate and complete business information will help us evaluate your case faster in one go!",
                  "Your GSTIN details will be help us fetch GST returns electronically - No paper and no delays!",
                  "Your GSTIN detail may also help you to avail higher loan eligibility",
                ]}
              ></SidePanel>
            </div>
            <div className="col-md-9">
              <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"loan-amount-and-tenure"} {...this.props}/>
              <GstNumberComponent nextScreen={"gst-data"}/>
            </div>
          </div>
        )
    }
}
