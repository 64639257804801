import React, { Component } from "react";
import Icon from "react-icons-kit";
import { angleDown } from "react-icons-kit/fa/angleDown";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { Tabs, Tab } from "react-bootstrap";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { rupeeCommaFormatter , setHeaderFixed , deleteCookie } from "../../../utils/utility";
import { get } from "lodash";
import { changeValue } from "../CashFlow/cashflow.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getCreditFlag,
  businessProfileFlags,
  businessRiskFlags,
  cashFlowRisk,
  creditBehaviourFlags
} from "./flag.action";
import Highcharts from "highcharts";
import { rupee } from "react-icons-kit/fa/rupee";
import { Helmet } from "react-helmet";
import { checkUserLogin, setUserDetails, setUserLoginStatus , loaderModal } from "../../../store/actions";
require("highcharts/highcharts-more")(Highcharts);

class Flag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flags: {
        businessProfile: true,
        businessRisk: true,
        cashFlowRisk: true,
        creditBehaviour: true,
        creditCapacity: true,
        pastDelinquencies: [],
        pastDelinquenciesList: []
      }
    };
  }

  slideToggle = (e, item) => {
    let { flags } = this.state;
    let status = flags[item];
    flags[item] = !status;
    this.setState({ flags });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { setUserDetails, setUserLoginStatus, checkUserLogin } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }
  leftDistCount = () => {
    const { businessProfileFlagsData } = this.props,
      Incorporation = get(businessProfileFlagsData, "dateOfIncorporation"),
      registrationDate = get(businessProfileFlagsData, "dateOfGSTRegistration"),
      lastGSTReturnDate = get(businessProfileFlagsData, "lastGSTReturn", null);
    var incorporationInDays = this.parseDate(Incorporation),
      registrationInDays = this.parseDate(registrationDate),
      lastGstReturnInDays = this.parseDate(this.gstReturnDate(lastGSTReturnDate)),
      gstRegistrationAndIncorporationDays = this.datediff(registrationInDays, incorporationInDays),
      gstReturnAndIncorporationDays = this.datediff(lastGstReturnInDays, incorporationInDays);
    var value = (gstRegistrationAndIncorporationDays / gstReturnAndIncorporationDays) * 100;
    return value;
  };
  parseDate = str => {
    if (str) {
      var mdy = str.split("-");
      return new Date(mdy[2], mdy[1] - 1, mdy[0]);
    }
  };
  gstReturnDate = date => {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      month,
      year;
    if (date) {
      var monthYearArray = date.split("-");
      month = months.indexOf(monthYearArray[0]) + 1;
      year = monthYearArray[1];
    } else {
      month = new Date().getMonth();
      year = new Date().getFullYear();
    }
    var value = "01-" + ("0" + month).slice(-2) + "-" + year;
    return value;
  };
  datediff = (first, second) => {
    return Math.abs(Math.round((second - first) / (1000 * 60 * 60 * 24)));
  };
  componentDidMount = async () => {
    window.addEventListener("scroll", setHeaderFixed);
    let { actions, history, match } = this.props,
      {
        businessProfileFlags,
        businessRiskFlags,
        cashFlowRisk,
        creditBehaviourFlags,
        loaderModal,
        getCreditFlag
      } = actions;
    loaderModal(true);
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      businessProfileFlags(applicationNumber);
      businessRiskFlags(applicationNumber);
      cashFlowRisk(applicationNumber);
      creditBehaviourFlags(applicationNumber);
      await getCreditFlag(applicationNumber);
    } else {
      history.push("/Credit/dashboard");
    }
    loaderModal(false);
    this.leftDistCount();
  };
  componentDidUpdate(prevProps) {
    let { CreditFlag } = this.props;
    Highcharts.chart("waterfall-chart", {
      chart: {
        type: "waterfall",
        backgroundColor: "transparent",
        height: 200
      },
      title: false,
      xAxis: {
        type: "category"
      },
      yAxis: {
        title: false,
        labels: false
      },
      plotOptions: {
        waterfall: {
          dataLabels: {
            style: {
              textOutline: "none"
            }
          }
        }
      },
      legend: {
        enabled: false
      },
      credits: false,
      series: [
        {
          upColor: Highcharts.getOptions().colors[2],
          color: Highcharts.getOptions().colors[3],
          data: [
            {
              name: "CIBIL<br/> Obligations",
              y: get(CreditFlag, "cibilObligations", 0)
            },
            {
              name: "Bank<br/> Obligations",
              y: get(CreditFlag, "bankObligations", 0)
            },
            {
              name: "Available<br/> Credit Capacity",
              y: get(CreditFlag, "availableCreditCapacity", 0)
            },
            {
              name: "Monthly<br/> Net Income",
              y: get(CreditFlag, "monthlyNetIncome", 0),
              isIntermediateSum: true,
              color: Highcharts.getOptions().colors[1]
            }
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y / 1000, 0, ",") + "k";
            },
            style: {
              fontWeight: "bold"
            }
          },
          pointPadding: 0
        }
      ]
    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }

  render() {
    let { flags } = this.state;
    const {
      businessDet,
      Score,
      CreditFlag,
      businessProfileFlagsData,
      businessRiskFlagsData,
      cashFlowRiskFlagsData,
      creditBehaviourFlagsData
    } = this.props,
      businessDetails = get(businessDet, `businessDetails[0]`) || [],
      businessName = get(businessDetails, "businessName"),
      cc = get(Score, "ccScore");
    const pastDelinquencies = get(CreditFlag, "pastDelinquencies" || []);
    var pastDelinquenciesList = [];
    if (typeof pastDelinquencies !== "undefined" && pastDelinquencies.length > 1) {
      pastDelinquenciesList = pastDelinquencies.slice(1, pastDelinquencies.length);
    }
    return (
      <div className={"flag-wrapper content-container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Flag</title>
        </Helmet>
        <section
          className={
            flags.businessProfile
              ? "section-box business-profile-flag-box open"
              : "section-box business-profile-flag-box closed"
          }
        >
          <div className={"section-header"}>
            <h4 onClick={e => this.slideToggle(e, "businessProfile")}>
              Business Profile Flags
              <span className={"icon"}>
                <Icon icon={angleDown} size={22} />
              </span>
            </h4>
          </div>
          <SlideDown closed={!flags.businessProfile}>
            <div className={"box-wrapper"}>
              <div className={"box-header"}>
                <h4>Overall Risk Assessment</h4>
              </div>
              <div className={"row cc-score-box"}>
                <div className={"col-md-2 col-sm-3"}>
                  <div className="row">
                    <label>Estimated CC Score </label>
                  </div>
                  <div className="row">
                    <span>{get(businessProfileFlagsData, "ccScore")}</span>
                  </div>
                </div>
                <div className={"col-md-10 col-sm-9 vertical-divider-left"}>
                  <ul>
                    <li>CC Score</li>
                    <li>
                      below 500
                      {500 >= cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>

                    <li>
                      500
                      {500 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}{" "}
                    </li>
                    <li>
                      520
                      {520 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      540
                      {540 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      560
                      {560 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      580
                      {580 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      600
                      {600 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      620
                      {620 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      640
                      {640 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      660
                      {660 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      680
                      {680 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      700
                      {700 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      720
                      {720 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      740
                      {740 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      760
                      {760 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      780
                      {780 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      800
                      {800 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      820
                      {820 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      840
                      {840 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      860
                      {860 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      880
                      {880 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                    <li>
                      900
                      {900 === cc ? (
                        <span>
                          CC Score for {businessName}
                          <Icon icon={caretDown} size={20} />
                        </span>
                      ) : (
                          ""
                        )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {businessProfileFlagsData.isNegativePincode ?
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Business Profile</h4>
                  <span className={"flag-box"}>
                    <label className="redLabel">
                      {get(businessProfileFlagsData, "isNegativePincode", false) ? " Negative Industry" : null}
                    </label>
                  </span>
                </div>
                <div className={"row box-content"}>
                  <div className={"col-md-4"}>
                    <div className={"content-row"}>
                      <label>Business Type</label>
                      <span>{get(businessProfileFlagsData, "businessType")}</span>
                    </div>
                    <div className={"content-row"}>
                      <label>Primary Industry</label>
                      <span>{get(businessProfileFlagsData, "primaryIndustry")}</span>
                    </div>
                    <div className={"content-row"}>
                      <label>Secondary Industry</label>
                      <span>{get(businessProfileFlagsData, "secondaryIndustry")}</span>
                    </div>
                  </div>
                  <div className={"col-md-5 vertical-divider-left"}></div>
                  <div className={"col-md-3 text-right"}>
                    <span className={"flag-box"}>
                      <label className="redLabel">Anti-dumping Alert</label>
                    </span>
                  </div>
                </div>
              </div> : null}
            {businessProfileFlagsData.isDateOfIncorporationLessThan3Years ?
              <div className={"row"}>
                <div className={"box-wrapper w-100"}>
                  <div className={"box-header"}>
                    <h4>Business Vintage and Timeline</h4>
                  </div>
                  <div className="timeline-box">
                    <div className="timelines timeline-1">
                      <span>1</span>
                      <span>Incorporation</span>
                      <label>{get(businessProfileFlagsData, "dateOfIncorporation")}</label>
                    </div>
                    <div
                      className="timelines timeline-2"
                      style={{
                        left:
                          (this.leftDistCount() < 10 ? 10 : this.leftDistCount() > 80 ? 80 : this.leftDistCount()) + "%"
                      }}
                    >
                      <span>2</span>
                      <span>GST Registration</span>
                      <label>{get(businessProfileFlagsData, "dateOfGSTRegistration")}</label>
                    </div>
                    <div className="timelines timeline-3">
                      <span>3</span>
                      <span>Last GST Return</span>
                      <label>{get(businessProfileFlagsData, "lastGSTReturn", "No GST Filled")}</label>
                    </div>
                  </div>
                </div>
              </div> : null}
          </SlideDown>
        </section>
        <section
          className={
            flags.businessRisk ? "section-box business-risk-flag-box open" : "section-box business-risk-flag-box closed"
          }
        >
          <div className={"section-header"}>
            <h4 onClick={e => this.slideToggle(e, "businessRisk")}>
              Business Risk Flags{" "}
              <span className={"icon"}>
                <Icon icon={angleDown} size={22} />
              </span>
            </h4>
          </div>
          <SlideDown closed={!flags.businessRisk}>
            <div className={"row"}>
              <div className={"col-md-4"}>
                {businessRiskFlagsData.isHighestPurchaseBySingleCustomer ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Business Concentration</h4>
                      <span className={"flag-box"}>
                        {/* <label className="redLabel">HCR</label> */}
                      </span>
                    </div>
                    <div className={"box-content p-0 m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <label className="boldText">Highest Purchase by a Single Customer</label>
                          <span>
                            {get(businessRiskFlagsData, "highestPurchaseBySingleCustomer", 0)
                              ? Number(get(businessRiskFlagsData, "highestPurchaseBySingleCustomer", 0)).toFixed(2)
                              : "0"}{" "}
                            %
                        </span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
                {businessRiskFlagsData.isHighestToBySingleVendor ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Business Concentration</h4>
                      <span className={"flag-box"}>
                        {/* <label className="redLabel">HCR</label> */}
                      </span>
                    </div>
                    <div className={"box-content p-0 m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <label className="boldText">Highest Turnover From a Single Vendor</label>
                          <span>
                            <Icon icon={rupee} size={18} />{" "}
                            {get(businessRiskFlagsData, "highestToBySingleVendor", 0)
                              ? Number(get(businessRiskFlagsData, "highestToBySingleVendor", 0)).toFixed(2)
                              : "0"}
                            %
                        </span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
                {businessRiskFlagsData && businessRiskFlagsData.exportSalesMoreThan50 ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Export Sales more than 40%</h4>
                    </div>
                    <div className={"box-content p-0 m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>
                            {businessRiskFlagsData.isExportSalesMoreThan50OfTo ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}{" "}
                            <label>
                              {" "}
                              ,{" "}
                              {businessRiskFlagsData && businessRiskFlagsData.exportSalesMoreThan50
                                ? Number(businessRiskFlagsData.exportSalesMoreThan50).toFixed(2)
                                : "0"}
                              %
                          </label>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
                {businessRiskFlagsData && businessRiskFlagsData.domesticSalesMoreThan50 ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Domestic Sales more than 40%</h4>
                    </div>
                    <div className={"box-content p-0 m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>
                            {businessRiskFlagsData.isDomesticSalesMoreThan50OfTo ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}{" "}
                            <label>
                              {" "}
                              ,{" "}
                              {businessRiskFlagsData && businessRiskFlagsData.domesticSalesMoreThan50
                                ? Number(businessRiskFlagsData.domesticSalesMoreThan50).toFixed(2)
                                : "0"}
                              %
                          </label>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
              </div>
              <div className={"col-md-8"}>{businessRiskFlagsData.deviationInRange ?
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4>Sales / TO</h4>
                  </div>
                  <div className={"bubble-chart-box"}>
                    <div className={"list-item"}>
                      <span
                        style={{
                          width: "70px",
                          padding: "20px 15px",
                          backgroundColor: "#001061"
                        }}
                        className={"bubble"}
                      >
                        GST Sales
                      </span>
                      <label>
                        <Icon icon={rupee} size={15} /> {rupeeCommaFormatter(get(businessRiskFlagsData, "gstSales", 0))}
                      </label>
                    </div>
                    <div className={"list-item"}>
                      <span style={{ width: "62px", backgroundColor: "#001061" }} className={"bubble"}>
                        Banking Sales
                      </span>
                      <label>
                        <Icon icon={rupee} size={15} />{" "}
                        {rupeeCommaFormatter(get(businessRiskFlagsData, "bankingSales", 0))}
                      </label>
                    </div>
                    <div className={"list-item"}>
                      <span style={{ width: "45px", backgroundColor: "#ed5a5a" }} className={"bubble"}>
                        + 15%
                      </span>
                      <span className="form-box">
                        {" "}
                        {get(businessRiskFlagsData, "deviationInRange", true) ? (
                          <label className="redLabel">Yes</label>
                        ) : (
                            <label className="greenLabel">No</label>
                          )}
                      </span>
                    </div>
                    <div className={"list-item"}>
                      <span className={"bubble-transparent"}>
                        {get(businessRiskFlagsData, "deviationGstOverBanking", 0)
                          ? Number(get(businessRiskFlagsData, "deviationGstOverBanking", 0)).toFixed(2)
                          : "0"}
                        %
                      </span>
                      <span>Highest Deviation</span>
                      <label></label>
                    </div>
                  </div>
                  <div className={"row"}>
                    {businessRiskFlagsData.recentDipInGST || businessRiskFlagsData.recentDipInBanking ?
                      <div className={"col-md-6"}>
                        <div className={"box-wrapper secondary m-0"}>

                          <div className={"box-header"}>
                            <h4>Sales / TO Growth or Dip RECENT</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            {businessRiskFlagsData.recentDipInGST ?
                              <div className={"content-row"}>
                                <label>Recent Dip in GST TO</label>
                                <span className={"flag-box"}>
                                  {businessRiskFlagsData.recentDipInGST ? (
                                    <label className="redLabel">Yes</label>
                                  ) : (
                                      <label className="greenLabel">No</label>
                                    )}
                                </span>
                              </div> : null}
                            {businessRiskFlagsData.recentDipInBanking ?
                              <div className={"content-row"}>
                                <label>Recent Dip in Banking TO</label>
                                <span className={"flag-box"}>
                                  {businessRiskFlagsData.recentDipInBanking ? (
                                    <label className="redLabel">Yes</label>
                                  ) : (
                                      <label className="greenLabel">No</label>
                                    )}
                                </span>
                              </div> : null}
                          </div>
                        </div>
                      </div> : null}
                    {businessRiskFlagsData.baselineDipInGST || businessRiskFlagsData.baselineDipInBanking ?
                      <div className={"col-md-6"}>
                        <div className={"box-wrapper secondary m-0"}>
                          <div className={"box-header"}>
                            <h4>Sales / TO Growth or Dip BASELINE</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            {businessRiskFlagsData.baselineDipInGST ?
                              <div className={"content-row"}>
                                <label>Baseline Dip in GST TO</label>
                                <span className={"flag-box"}>
                                  {businessRiskFlagsData.baselineDipInGST ? (
                                    <label className="redLabel">Yes</label>
                                  ) : (
                                      <label className="greenLabel">No</label>
                                    )}
                                </span>
                              </div> : null}
                            {businessRiskFlagsData.baselineDipInBanking ?
                              <div className={"content-row"}>
                                <label>Baseline Dip in Banking TO</label>
                                <span className={"flag-box"}>
                                  {businessRiskFlagsData.baselineDipInBanking ? (
                                    <label className="redLabel">Yes</label>
                                  ) : (
                                      <label className="greenLabel">No</label>
                                    )}
                                </span>
                              </div> : null}
                          </div>
                        </div>
                      </div> : null}
                  </div>
                </div> : null}
                {/* <div className={"box-wrapper"}> // Left for future Implementation
                  <div className={"box-header"}>
                    <h4>Sales Monthly Cyclicality</h4>
                  </div>
                  <div className={"box-content p-0"}>
                    <div className={"content-row"}>
                      <label>Not Available</label>
                      <span className={"flag-box"}>
                        <label>Yes</label>
                      </span>
                    </div>
                    <div className={"content-row"}>
                      <label>
                        Monthly Sales Cyclicality Observed - Monthly Credit/sale
                        is less than 10% of yearly
                      </label>
                      <span className={"flag-box"}>
                        <label>Yes</label>
                      </span>
                    </div>
                    <div className={"content-row"}>
                      <label>Quarterly Sales less than 15% of annual</label>
                      <span>NO</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </SlideDown>
        </section>
        <section className={flags.cashFlowRisk ? "section-box open" : "section-box closed"}>
          <div className={"section-header"}>
            <h4 onClick={e => this.slideToggle(e, "cashFlowRisk")}>
              Cash Flow Risk Flags
              <span className={"icon"}>
                <Icon icon={angleDown} size={22} />
              </span>
            </h4>
          </div>
          <SlideDown closed={!flags.cashFlowRisk}>
            <div className={"row w-100"}>
              <div className={"col-md-9"}>
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4>Cash Flow Flags</h4>
                  </div>
                  <div className={"row"}>
                    {cashFlowRiskFlagsData.inwardChequeBounceRatio ?
                      <div className={"col-md-4"}>
                        <div className={"box-wrapper secondary"}>
                          <div className={"box-header"}>
                            <h4>Inward Cheque Bounce Ratio</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            <ul className={"list-unstyled"}>
                              <li>
                                <span>
                                  {get(cashFlowRiskFlagsData, "inwardChequeBounceRatio", 0)
                                    ? Number(get(cashFlowRiskFlagsData, "inwardChequeBounceRatio", 0)).toFixed(2)
                                    : "0"}
                                  %
                              </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> : null}
                    {cashFlowRiskFlagsData.cashDepositToSales > 0 ?
                      <div className={"col-md-4"}>
                        <div className={"box-wrapper secondary"}>
                          <div className={"box-header"}>
                            <h4>Cash Deposit to Sales Ratio</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            <ul className={"list-unstyled"}>
                              <li>
                                <span>
                                  {get(cashFlowRiskFlagsData, "cashDepositToSales", 0)
                                    ? Number(get(cashFlowRiskFlagsData, "cashDepositToSales", 0)).toFixed(2)
                                    : "0"}
                                  %
                              </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> : null}
                    <div className={"col-md-4"}>
                      <div className={"box-wrapper secondary"}>
                        <div className={"box-header"}>
                          <h4>High Value Counter-party - Credit</h4>
                        </div>
                        <div className={"box-content m-0"}>
                          <ul className={"list-unstyled"}>
                            <li>
                              {cashFlowRiskFlagsData.customerConcentration
                                ? cashFlowRiskFlagsData.customerConcentration.map((el, index) => {
                                  return (
                                    <div key={index}>
                                      <label>
                                        {el.seqNo} : {el.description}
                                      </label>
                                      <br />
                                    </div>
                                  );
                                })
                                : null}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"row"}>
                    {cashFlowRiskFlagsData.outwardChequeBounceRatio > 0 ?
                      <div className={"col-md-4"}>
                        <div className={"box-wrapper secondary"}>
                          <div className={"box-header"}>
                            <h4>Outward Cheque Bounce Ratio</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            <ul className={"list-unstyled"}>
                              <li>
                                <span>
                                  {cashFlowRiskFlagsData.outwardChequeBounceRatio
                                    ? Number(cashFlowRiskFlagsData.outwardChequeBounceRatio).toFixed(2)
                                    : "0"}
                                  %
                              </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> : null}
                    {cashFlowRiskFlagsData.cashWithdrawlToSales > 0 ?
                      <div className={"col-md-4"}>
                        <div className={"box-wrapper secondary"}>
                          <div className={"box-header"}>
                            <h4>Cash Withdrawal to Sales Ratio</h4>
                          </div>
                          <div className={"box-content m-0"}>
                            <ul className={"list-unstyled"}>
                              <li>
                                <span>
                                  {get(cashFlowRiskFlagsData, "cashWithdrawlToSales", 0)
                                    ? Number(get(cashFlowRiskFlagsData, "cashWithdrawlToSales", 0)).toFixed(2)
                                    : "0"}
                                  %
                              </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> : null}
                    <div className={"col-md-4"}>
                      <div className={"box-wrapper secondary"}>
                        <div className={"box-header"}>
                          <h4>High Value Counter-party - Debit</h4>
                        </div>
                        <div className={"box-content m-0"}>
                          <ul className={"list-unstyled"}>
                            <li>
                              {cashFlowRiskFlagsData && cashFlowRiskFlagsData.vendorConcentration
                                ? cashFlowRiskFlagsData.vendorConcentration.map(el => {
                                  return (
                                    <>
                                      <label>
                                        {el.seqNo} : {el.description}
                                      </label>
                                      <br />
                                    </>
                                  );
                                })
                                : null}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-md-3"}>
                {cashFlowRiskFlagsData.averageLimitUtilization > 0 ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Average Limit Utilisation -12 Months</h4>
                    </div>
                    <div className={"box-content m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>
                            {get(cashFlowRiskFlagsData, "averageLimitUtilization", 0)
                              ? Number(get(cashFlowRiskFlagsData, "averageLimitUtilization", 0)).toFixed(2)
                              : "0"}
                            %
                        </span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
                {cashFlowRiskFlagsData.limitCrossedGreaterThan90 > 0 ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Limit Crossed > 90%</h4>
                    </div>
                    <div className={"box-content m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>{get(cashFlowRiskFlagsData, "limitCrossedGreaterThan90", 0)} Times</span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
                {cashFlowRiskFlagsData.limitDippedGreaterThan40 > 0 ?
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Limit Dipped > 40%</h4>
                    </div>
                    <div className={"box-content m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>{get(cashFlowRiskFlagsData, "limitDippedGreaterThan40", 0)} Times</span>
                        </li>
                      </ul>
                    </div>
                  </div> : null}
              </div>
            </div>
          </SlideDown>
        </section>
        <section
          className={
            flags.creditBehaviour
              ? "section-box credit-behaviour-flag-box open"
              : "section-box credit-behaviour-flag-box closed"
          }
        >
          <div className={"section-header"}>
            <h4 onClick={e => this.slideToggle(e, "creditBehaviour")}>
              Tax Compliance Flags
              <span className={"icon"}>
                <Icon icon={angleDown} size={22} />
              </span>
            </h4>
          </div>
          <SlideDown closed={!flags.creditBehaviour}>
            <div className={"box-wrapper"}>
              <div className={"box-header"}>
                <h4>Tax Compliance</h4>
              </div>
              <div className={"row"}>
                <div className={"col-md-4"}>
                  <div className={"box-content"}>
                    {creditBehaviourFlagsData.gstReturnPending ?
                      <div className={"content-row"}>
                        <label>GSTN Return pending for more than 60 days</label>
                        <span className={"flag-box"}>
                          {get(creditBehaviourFlagsData, "gstReturnPending", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                    {creditBehaviourFlagsData.taxLiabilityPending ?
                      <div className={"content-row"}>
                        <label>Pending Tax Liability for more than 60 days</label>
                        <span className={"flag-box"}>
                          {get(creditBehaviourFlagsData, "taxLiabilityPending", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                  </div>
                </div>
                {/* <div className={"col-md-8"}>
                  <div className={"top5-list-box"}>
                    <label>Top 5 Purchase Codes</label>
                    <ol>
                      {creditBehaviourFlagsData &&
                        creditBehaviourFlagsData.top5PurchaseCode &&
                        creditBehaviourFlagsData.top5PurchaseCode.map((el, index) => {
                          return (
                            <li key={index}>
                              {el.hsn_sc} - {el.ccMapping}
                            </li>
                          );
                        })}
                    </ol>
                  </div>
                </div> */}
              </div>

              <div className="row">
                {creditBehaviourFlagsData.taxLiabilityToSales > 90 ?
                  <div className={"col-md-4"}>
                    <div className={"box-wrapper secondary"}>
                      <div className={"box-header"}>
                        <h4>Tax Liability to Sales Ratio</h4>
                      </div>
                      <div className={"box-content m-0"}>
                        <ul className={"list-unstyled"}>
                          <li>
                            <span>
                              {get(creditBehaviourFlagsData, "taxLiabilityToSales", 0)
                                ? Number(get(creditBehaviourFlagsData, "taxLiabilityToSales", 0)).toFixed(2)
                                : "0"}
                              %
                          </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> : null}
                {creditBehaviourFlagsData.itcTotaxLibility > 90 ?
                  <div className={"col-md-4"}>
                    <div className={"box-wrapper secondary"}>
                      <div className={"box-header"}>
                        <h4>ITC to Tax Liability Ratio</h4>
                      </div>
                      <div className={"box-content m-0"}>
                        <ul className={"list-unstyled"}>
                          <li>
                            <span>
                              {get(creditBehaviourFlagsData, "itcTotaxLibility", 0)
                                ? Number(get(creditBehaviourFlagsData, "itcTotaxLibility", 0)).toFixed(2)
                                : "0"}
                              %
                          </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> : null}
                {/* <div className={"col-md-4"}>
                  <div className={"box-wrapper secondary"}>
                    <div className={"box-header"}>
                      <h4>Mismatch Observed </h4>
                    </div>
                    <div className={"box-content m-0"}>
                      <ul className={"list-unstyled"}>
                        <li>
                          <span>NA</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </SlideDown>
        </section>
        <section
          className={
            flags.creditCapacity
              ? "section-box credit-capacity-flag-box open"
              : "section-box credit-capacity-flag-box closed"
          }
        >
          <div className={"section-header"}>
            <h4 onClick={e => this.slideToggle(e, "creditCapacity")}>
              Credit Capacity Flags{" "}
              <span className={"icon"}>
                <Icon icon={angleDown} size={22} />
              </span>
            </h4>
          </div>
          <SlideDown closed={!flags.creditCapacity}>
            <div className={"box-wrapper p-b-0"}>
              <div className={"box-header"}>
                <h4>Credit Capacity and Leverage</h4>
              </div>
              <div className={"row"}>
                <div className={"col-md-4"}>
                  <div id={"waterfall-chart"} />
                </div>
                <div className={"col-md-4 vertical-divider-left"}>
                  <div className={"box-content"}>
                    {CreditFlag.recentIncreaseInBorrowings ?
                      <div className={"content-row"}>
                        <label>Recent Increase in Borrowings</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "recentIncreaseInBorrowings", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                  </div>
                </div>
                <div className={"col-md-4"}>
                  <div className={"box-content"}>
                    {CreditFlag.preFOIRGreaterThan40Per ? <div className={"content-row"}>
                      <label>Pre-Loan FOIR > 40%</label>
                      <span className={"flag-box"}>
                        {get(CreditFlag, "preFOIRGreaterThan40Per", false) ? (
                          <label className="redLabel">Yes</label>
                        ) : (
                            <label className="greenLabel">No</label>
                          )}
                      </span>
                    </div> : null}
                    {CreditFlag.postFOIRGreaterThan60Per ?
                      <div className={"content-row"}>
                        <label>Post-Loan FOIR > 60%</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "postFOIRGreaterThan60Per", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                    {CreditFlag.medainABBToObligationRatioLessThan200Per ?
                      <div className={"content-row"}>
                        <label>Median ABB to Obligation Ratio &lt; 200%</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "medainABBToObligationRatioLessThan200Per", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                          {/*  */}
                        </span>
                      </div> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={"box-wrapper"}>
              <div className={"box-header"}>
                <h4>Repayment Track and Past Delinqunecies</h4>
              </div>
              <div className={"row"}>
                <div className={"col-md-8"}>
                  <Tabs defaultActiveKey="applicant" id="applicants-tabs">
                    <Tab eventKey="applicant" title={get(CreditFlag, "pastDelinquencies[0].name", 0)}>
                      <div className={"dpd-box"}>
                        <div className={"row"}>
                          <div className={"col-md-3"}>
                            <label>All Loans with DPD</label>
                            <span>{get(CreditFlag, "pastDelinquencies[0].allLoansDetails.loansWithDPD", 0)}</span>
                          </div>
                          <div className={"col-md-9"}>
                            <ul className={"dpd-list"}>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].allLoansDetails.dpdCountBetween0And30", 0)}
                              </li>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].allLoansDetails.dpdCountBetween30And60", 0)}
                              </li>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].allLoansDetails.dpdCountBetween60And90", 0)}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"row"}>
                          <div className={"col-md-3"}>
                            <label>Business Loans with DPD</label>
                            <span>{get(CreditFlag, "pastDelinquencies[0].businessLoanDetails.loansWithDPD", 0)}</span>
                          </div>
                          <div className={"col-md-9"}>
                            <ul className={"dpd-list"}>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].businessLoanDetails.dpdCountBetween0And30", 0)}
                              </li>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].businessLoanDetails.dpdCountBetween30And60", 0)}
                              </li>
                              <li>
                                {get(CreditFlag, "pastDelinquencies[0].businessLoanDetails.dpdCountBetween60And90", 0)}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"row"}>
                          <div className={"col-md-6 content-row"}>
                            <label>Max. DPD in All Loans</label>
                            <span>{get(CreditFlag, "pastDelinquencies[0].allLoansDetails.maxDPDInAll", 0)}</span>
                          </div>
                          <div className={"col-md-6 content-row"}>
                            <label>Max. DPD in Business Loans</label>
                            <span>{get(CreditFlag, "pastDelinquencies[0].businessLoanDetails.maxDPDInAll", 0)}</span>
                          </div>
                          <div className={"col-12"}>
                            <label>Number of Credit Enquiries In Last 30 Days</label>
                            <span>{get(CreditFlag, "pastDelinquencies[0].creditEnquiriesInLast30Days", 0)}</span>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    {typeof pastDelinquencies !== "undefined" && pastDelinquencies.length > 1
                      ? pastDelinquenciesList.map((item, index) => {
                        return (
                          <Tab
                            key={index}
                            eventKey={"CoApplicant " + (index + 1)}
                            title={"Co-Applicant" + (index + 1)}
                          >
                            <div className={"dpd-box"}>
                              <div className={"row"}>
                                <div className={"col-md-3"}>
                                  <label>All Loans with DPD</label>
                                  <span>{item.allLoansDetails && item.allLoansDetails.length !== 0 && item.allLoansDetails.loansWithDPD ? item.allLoansDetails.loansWithDPD : 0}</span>
                                </div>
                                <div className={"col-md-9"}>
                                  <ul className={"dpd-list"}>
                                    <li>{item.allLoansDetails && item.allLoansDetails.length !== 0 && item.allLoansDetails.dpdCountBetween0And30 ? item.allLoansDetails.dpdCountBetween0And30 : 0}</li>
                                    <li> {item.allLoansDetails && item.allLoansDetails.length !== 0 && item.allLoansDetails.dpdCountBetween30And60 ? item.allLoansDetails.dpdCountBetween30And60 : 0}</li>
                                    <li> {item.allLoansDetails && item.allLoansDetails.length !== 0 && item.allLoansDetails.dpdCountBetween60And90 ? item.allLoansDetails.dpdCountBetween60And90 : 0}</li>
                                  </ul>
                                </div> 
                              </div>
                              <div className={"row"}>
                                {item.businessLoanDetails ?
                                  <div className={"col-md-3"}>
                                    <label>Business Loans with DPD</label>
                                    <span>{item.businessLoanDetails.loansWithDPD}</span>
                                  </div> : null}
                                <div className={"row"}>
                                  <div className={"col-md-3"}>
                                    <label>Business Loans with DPD</label>
                                    <span>{item.businessLoanDetails && item.businessLoanDetails !== 0 && item.businessLoanDetails.loansWithDPD ? item.businessLoanDetails.loansWithDPD : 0}</span>
                                  </div>
                                  <div className={"col-md-9"}>
                                    <ul className={"dpd-list"}>
                                      <li>{item.businessLoanDetails && item.businessLoanDetails !== 0 && item.businessLoanDetails.dpdCountBetween0And30 ? item.businessLoanDetails.dpdCountBetween0And30 : 0}</li>
                                      <li>{item.businessLoanDetails && item.businessLoanDetails !== 0 && item.businessLoanDetails.dpdCountBetween30And60 ? item.businessLoanDetails.dpdCountBetween30And60 : 0}</li>
                                      <li>{item.businessLoanDetails && item.businessLoanDetails !== 0 && item.businessLoanDetails.dpdCountBetween60And90 ? item.businessLoanDetails.dpdCountBetween60And90 : 0}</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className={"row"}>
                                  <div className={"col-md-6 content-row"}>
                                    <label>Max. DPD in All Loans</label>
                                    <span>{item.allLoansDetails && item.allLoansDetails.length !== 0 && item.allLoansDetails.maxDPDInAll ? item.allLoansDetails.maxDPDInAll : 0}</span>
                                  </div>
                                  <div className={"col-md-6 content-row"}>
                                    <label>Max. DPD in Business Loans</label>
                                    <span>{item.businessLoanDetails && item.businessLoanDetails !== 0 && item.businessLoanDetails.maxDPDInAll ? item.businessLoanDetails.maxDPDInAll : 0}</span>
                                  </div>
                                  <div className={"col-12"}>
                                    <label>Number of Credit Enquiries In Last 30 Days</label>
                                    <span>{item.creditEnquiriesInLast30Days ? item.creditEnquiriesInLast30Days : 0}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        );
                      })
                      : ""}
                  </Tabs>
                </div>
                <div className={"col-md-4"}>
                  <div className={"box-content"}>
                    <div className={"content-row"}>
                      <label>CIBIL Score</label>
                      <span className={"flag-box"}>
                        {get(CreditFlag, "cibilScore", false) ? (
                          <label className="redLabel">{get(CreditFlag, "cibilScore", false)}</label>
                        ) : (
                            0
                          )}
                      </span>
                    </div>
                    {CreditFlag.writtenOffORSettledLoans ?
                      <div className={"content-row"}>
                        <label>Written Off / Settled Loan</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "writtenOffORSettledLoans", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                    {CreditFlag.wilfulDefaulter ?
                      <div className={"content-row"}>
                        <label>Wilful Defaulter</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "wilfulDefaulter", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                    {CreditFlag.loanRestructured ?
                      <div className={"content-row"}>
                        <label>Loan Restructured</label>
                        <span className={"flag-box"}>
                          {get(CreditFlag, "loanRestructured", false) ? (
                            <label className="redLabel">Yes</label>
                          ) : (
                              <label className="greenLabel">No</label>
                            )}
                        </span>
                      </div> : null}
                  </div>
                </div>
              </div>
            </div>
            {CreditFlag.profileFlagDetails && (CreditFlag.profileFlagDetails.isApplicantLessThan25 || CreditFlag.profileFlagDetails.isApplicantGreaterThan60 ||
              CreditFlag.profileFlagDetails.houseOwnerShip) ?
              <div className={"row"}>
                <div className={"col-md-4"}>
                  <div className={"box-wrapper"}>
                    <div className={"box-header"}>
                      <h4>Applicant Profile</h4>
                    </div>
                    <div className={"box-content"}>
                      {CreditFlag.profileFlagDetails && CreditFlag.profileFlagDetails.isApplicantLessThan25 ?
                        <div className={"content-row"}>
                          <label>Main Applicant’s Age &lt; 25</label>
                          <span className={"flag-box"}>
                            {get(CreditFlag, "profileFlagDetails.isApplicantLessThan25", false) ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}
                          </span>
                        </div> : null}
                      {CreditFlag.profileFlagDetails && CreditFlag.profileFlagDetails.isApplicantGreaterThan60 ?
                        <div className={"content-row"}>
                          <label>Main Applicant’s Age > 60</label>
                          <span className={"flag-box"}>
                            {get(CreditFlag, "profileFlagDetails.isApplicantGreaterThan60", false) ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}
                          </span>
                        </div> : null}
                      {CreditFlag.profileFlagDetails && CreditFlag.profileFlagDetails.houseOwnerShip ?
                        <div className={"content-row"}>
                          <label>Rented House</label>
                          <span className={"flag-box"}>
                            {get(CreditFlag, "profileFlagDetails.houseOwnerShip", false) ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}
                          </span>
                        </div> : null}
                      {CreditFlag.profileFlagDetails && CreditFlag.profileFlagDetails.officeOwnerShip ?
                        <div className={"content-row"}>
                          <label>Rented Office</label>
                          <span className={"flag-box"}>
                            {get(CreditFlag, "profileFlagDetails.officeOwnerShip", false) ? (
                              <label className="redLabel">Yes</label>
                            ) : (
                                <label className="greenLabel">No</label>
                              )}
                          </span>
                        </div> : null}
                    </div>
                  </div>
                </div>
              </div> : null}
          </SlideDown>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ creditBusinessRisk, OverViewReducer, flagCCScore }) => ({
  gstinAnalysis: creditBusinessRisk.gstinAnalysis,
  businessDet: OverViewReducer.businessDet,
  Score: flagCCScore.Score,
  CreditFlag: flagCCScore.CreditFlag,
  businessProfileFlagsData: flagCCScore.businessProfileFlagsData || [],
  businessRiskFlagsData: flagCCScore.businessRiskFlagsData || [],
  cashFlowRiskFlagsData: flagCCScore.cashFlowRiskFlagsData || [],
  creditBehaviourFlagsData: flagCCScore.creditBehaviourFlagsData || [],
  creditCapacityFlagsData: flagCCScore.creditCapacityFlagsData || []
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      changeValue,
      getCreditFlag,
      businessProfileFlags,
      businessRiskFlags,
      cashFlowRisk,
      creditBehaviourFlags,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Flag);
