import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Views/myBusinesses";
import CreditReport from "./Views/CreditReport";
import Biz360Report from "../../BIZ360Report/Biz360Report";
import Biz360ReportDetails from "./Views/Biz360ReportDetails";
import Biz360Flow from "./Views/Biz360Flow/Biz360Flow";
class MyBusiness extends Component {
  render() {
    return (
      <Switch>
        <Route path="/dashboard/business/home" exact component={Home} />
        <Route path="/dashboard/business/credit-details" exact component={CreditReport} />
        <Route path="/dashboard/business/credit-report" exact component={Biz360Report} />
        <Route path="/dashboard/business/biz360-details" exact component={Biz360ReportDetails} />
        <Route path="/dashboard/business/biz360-report" exact component={Biz360Report} />
        <Route path="/dashboard/business/add-business-gst-data/:page" component={Biz360Flow} />
      </Switch>
    );
  }
}
export default (MyBusiness);
