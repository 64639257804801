import React, { Component } from "react";
import Modal from "react-modal";
import { HashLoader } from "react-spinners";
import PropTypes from "prop-types";

class LoaderModal extends Component {
  componentWillMount() {
    Modal.setAppElement("body");
  }
  render() {
    return (
      <Modal
        isOpen={this.props.status}
        contentLabel="Loader Modal"
        overlayClassName={""}
        className={"loader-modloader-modal-overlayal-content"}
      >
        <HashLoader sizeUnit={"px"} color={"#00CDBE"} size={100} margin={"1px"} loading={true} />
      </Modal>
    );
  }
}

LoaderModal.propTypes = {
  status: PropTypes.bool.isRequired
};

export default LoaderModal;
