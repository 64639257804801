import * as HomePageConstant from "./homepage.actions.constants";
import set from "lodash/set";
import get from "lodash/get";
const initialState = {
  gstTaxPayerData: [],
  loanAdvisoryData: [],
  loanPurposeData: []
};
export const homePageReducers = (state = initialState, action) => {
  const { data, name, value } = action;
  switch (action.type) {
    case HomePageConstant.GET_TAX_PAYER_DATA:
      if (data) {
        return { ...state, gstTaxPayerData: data };
      }
      break;
    case "CHANGE_GST_DATA":
      return { ...state, gstTaxPayerData: { ...state.gstTaxPayerData, [name]: value } };
    case "LOAN_RECOMMENDATION_DATA":
      return { ...state, loanAdvisoryData: data };
    case HomePageConstant.GET_LOAN_USE:
      return { ...state, loanPurposeData: data };
    case "EDIT_GST_USERNAME":
      return {
        ...state,
        gstTaxPayerData: { ...state.gstTaxPayerData, businessName: value }
      };
    default:
      return state;
  }
};
