import * as CreditCapacityConstant from "./creditCapacity.actions.constant";
import * as CreditCapacitySelectors from "./creditCapacity.selectors";

const initialState = {
  form: {
    emi: {
      loanAmount: 1000000,
      tenure: 18,
      interestRate: 10,
      emi: 0
    },
    sales: "",
    salesRemark: "",
    margin: "",
    marginRemark: "",
    preFoir: ""
  },
  formModal: {},
  activeAccount: 0,
  emiDetails: null,
  obligationsData: {},
  mainObligationsData: [],
  filteredObligationsData: [],
  marginData: {},
  turnoverDetails: {}
};

export const creditCapacity = (state = initialState, action) => {
  const { type, name, val, data, num, index, list } = action;
  switch (type) {
    case CreditCapacityConstant.SET_CREDIT_CAPACITY_DETAILS:
      return CreditCapacitySelectors._setCreditCapacityDetails(state, data);

    case CreditCapacityConstant.CHANGE_FORM_INPUT_VALUE:
      return CreditCapacitySelectors._updateFormValues(state, name, val);

    case CreditCapacityConstant.CHANGE_FORM_MODAL_INPUT_VALUE:
      return CreditCapacitySelectors._updateModalFormValues(state, name, val);
    case CreditCapacityConstant.RESET_FORM_MODAL:
      return { ...state, formModal: {} };

    case CreditCapacityConstant.SET_EMI_DETAILS:
      return { ...state, emiDetails: data };
    case CreditCapacityConstant.SET_ACTIVE_ACCOUNT:
      return { ...state, activeAccount: num };
    case CreditCapacityConstant.SELECT_ACCOUNT_EMI:
      return CreditCapacitySelectors._selectAccountEMI(state, index);
    case CreditCapacityConstant.GET_CURRENT_OBLIGATIONS:
      return { ...state, mainObligationsData: data };
    case CreditCapacityConstant.SET_OBLIGATIONS_DATA:
      return { ...state, obligationsData: data };
    case CreditCapacityConstant.SET_FILTERED_OBLIGATIONS:
      return CreditCapacitySelectors._setFilteredObligations(state, list);
    case CreditCapacityConstant.SELECT_OBLIGATIONS_DATA:
      return CreditCapacitySelectors._selectObligationData(state, index, name);
    case CreditCapacityConstant.GET_MARGIN_DATA:
      return { ...state, marginData: data };
    case CreditCapacityConstant.SET_TURNOVER_DETAILS:
      return { ...state, turnoverDetails: data };
    case CreditCapacityConstant.GET_LOAN_TENURE_INTEREST:
      const { loanAmount, tenure, intrestRate } = data;
      return {
        ...state,
        form: {
          ...state.form,
          emi: {
            loanAmount: loanAmount,
            tenure: tenure,
            interestRate: intrestRate,
            emi: 0
          }
        }
      };
    default:
      return state;
  }
};
