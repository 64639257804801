import * as DocumentUploadConstant from "./DocumentUpload.actions.constants";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_DOCUMENT_MASTER } from "../../../utils/APIUrls";

const getDocumentList = (applicationNumber) => (dispatch) => {
  return GET(API_GET_DOCUMENT_MASTER(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: DocumentUploadConstant.GET_DOCUMENT_MASTER, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};


export{
    getDocumentList
}