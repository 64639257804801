import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/scss/main.css"
import { Provider } from 'react-redux';
import createStore from './store/store';

serviceWorker.unregister();
const store = createStore({});
const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root'),
    );
};

render(App);    