import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import { loaderModal } from "../../../store/actions";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import GSTData from "./GSTData/GSTData";
import GSTUsername from "./GSTUsername/GSTUsername";
import GSTOTP from "./GSTOTP/GSTOTP";
import KYC from "./KYC/KYC";
import ReportDownload from "./ReportDownload/ReportDownload";
import { getCookie } from "../../../utils/utility";
import { getTaxPayerData } from "../../HomePage/homepage.actions";
import { setGstAddressInNewAddress } from "../../ViewsComponents/GSTData/GSTData.actions";
class BIZ360 extends Component {
  render() {
    let { loaderModalStatus } = this.props;
    return (
      <div className="biz360__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <Helmet>
          <title>User details</title>
          <meta name="description" content="Business Report for business" />
          <meta name="description" content="credit Report for business" />
          <meta name="keyword" content="how to apply for business loans,how to apply for msme loans" />
        </Helmet>
        <Switch>
          <Route path="/biz360/withGst/gst-data" exact component={GSTData} />
          <Route path="/biz360/withGst/gst-username" exact component={GSTUsername} />
          <Route path="/biz360/withGst/gst-otp" exact component={GSTOTP} />
          <Route path="/biz360/withGst/kyc" exact component={KYC} />
          <Route path="/biz360/withGst/report-download" exact component={ReportDownload} />
        </Switch>
      </div>
    );
  }
  componentDidMount() {
    let { actions } = this.props,
      { setGstAddressInNewAddress, getTaxPayerData } = actions;
    var gstNumber = getCookie("gstNumber");
    getTaxPayerData(gstNumber).then((data) => {
      if (data.data) {
        setGstAddressInNewAddress(data.data.gstData[0].addresses || []);
      }
    });
  }
}

const mapStateToProps = ({ homePageReducers, data }) => ({
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
  loaderModalStatus: data.loaderModalStatus,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getTaxPayerData,
      setGstAddressInNewAddress,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BIZ360);
