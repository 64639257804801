import React, { Component } from "react";
import SidePanel from "./SidePanel";
import { Route, Switch } from "react-router-dom";
import MyLoans from "../../Dashboard/MyLoans/MyLoans";
import MyBusiness from "../MyBusiness/MyBusiness";
import MyDocuments from "../MyDocuments/MyDocuments";
import { loanAdvisory } from "../LoanAdvisory/loanAdvisory";

class MainDashboard extends Component {
  render() {
    return (
      <div className="dashboard__wrapper">
        <div className="row no-gutters">
          <div className="col-md-2">
            <div className="side__panel">
              <SidePanel />
            </div>
          </div>
          <div className="col-md-10">
            <div className="main__panel">
              <Switch>
                <Route path="/dashboard/business/:page" component={MyBusiness} />
                <Route path="/dashboard/loans/:page" exact component={MyLoans} />
                <Route path="/dashboard/documents/:page" exact component={MyDocuments} />
                <Route path="/dashboard/loan-advisory/:page" exact component={loanAdvisory} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainDashboard;
