import React, { Component, Fragment } from "react";
import Biz360Engine from "../../../assets/Images/HomePage/biz360-engine.png";
import Step1 from "../../../assets/Images/HomePage/step1-icon.png";
import Step2 from "../../../assets/Images/HomePage/step2-icon.png";
import Step3 from "../../../assets/Images/HomePage/step3-icon.png";
import { cross } from "react-icons-kit/metrize/cross";
import Icon from "react-icons-kit";
export default class KnowMore extends Component {
  state = {
    knowMoreModelIsOpen: false,
  };
  onCloseHandler = () => {
    this.setState({ knowMoreModelIsOpen: !this.state.knowMoreModelIsOpen });
  };
  render() {
    let { knowMoreModelIsOpen } = this.state;
    return (
      <div>
        <div className={knowMoreModelIsOpen ? "grey-bg no-display " : "grey-bg  "} onClick={this.onCloseHandler}>
          <div className="know-more__wrapper">
            <Icon
              icon={cross}
              size={26}
              className="cross-btn"
              onClick={this.onCloseHandler}
            />

            <h2> Biz360</h2>
            <img
              className="biz360-engine"
              src={Biz360Engine}
              alt="biz360 engine"
            />
            <div className="text__generic">
              Biz360 is an automated loan recommendation engine that analyses
              your business & cash flow data and determines your loan
              eligibility and product fitment.
            </div>
            <div className="text__generic">
              In just 3 easy steps you can generate Biz360 report based on your
              business data:
            </div>
            <div className="biz360steps">
              <div className="steps">
                <img className="icon" src={Step1} />
                <div className="text">
                  <div className="head">STEP1</div>
                  <div className="label">Create your Online profile</div>
                </div>
              </div>
              <div className="steps">
                <img className="icon" src={Step2} />
                <div className="text">
                  <div className="head">STEP2</div>
                  <div className="label">
                    Share your business information online
                  </div>
                </div>
              </div>
              <div className="steps">
                <img className="icon" src={Step3} />
                <div className="text">
                  <div className="head">STEP3</div>
                  <div className="label">
                    Generate and Review your Biz360 Report
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      
    );
  }
}
