import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Logo from "../../assets/Images/BlueLogo1x.png";
import Highcharts from "highcharts";
import { map } from "lodash";
import {
  getGstTrendDetails,
  getConcentrationRiskDetails,
  getTurnoverAndPurchaseDetails,
  getCreditHistoryDetails,
  getAllApplicantSummary,
  getCurrentObligations,
  getEntityDetails,
  getFindProductsForBizReport,
  getBusinessCommentsForReport,
} from "./Biz360Report.actions";
import {
  integerToWordFormatter,
  integerToWordFormatterLabels,
  titleCase,
  getCookie,
  rupeeCommaFormatter,
} from "../../utils/utility";
import { cibilColumns, obligationSummaryColumns } from "./Biz360Report.config";
import ReactTable from "../../components/React-Table/ReactTable";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import { loaderModal } from "../../store/actions";
import { FiPrinter } from "react-icons/fi";
import { Helmet } from "react-helmet";
class Biz360Report extends Component {
  constructor(props) {
    super(props);
    this.checkReportType();
  }
  checkReportType = () => {
    let { match } = this.props;
    if (match.path === "/dashboard/business/biz360-report") {
      this.state = { isBizReport: true, no0fBizProducts: 4 };
    } else {
      this.state = {};
    }
  };
  bestLoanHandler = () => {
    this.props.nextPageHandlerParent ? this.props.nextPageHandlerParent() : alert("End of flow");
  };
  render() {
    let {
        gstTrendAnalysisData,
        concentrationRiskDetailsData,
        turnoverAndPurchaseDetailsData,
        creditHistoryDetailsData,
        allApplicantSummaryData,
        currentObligationsData,
        loaderModalStatus,
        entityDetailsData,
        productsForBizReportData,
        businessCommentsForReportData,
      } = this.props,
      { no0fBizProducts, isBizReport } = this.state;
    return (
      <>
        <div className="d-flex justify-content-end mr-3">
          <Helmet>
            <title>Biz360 Report</title>
            <meta name="description" content="Business Report for business" />
            <meta name="description" content="credit Report for business" />
            <meta name="keyword" content="how to apply for business loans,how to apply for msme loans" />
          </Helmet>
          <button className="button__generic" onClick={this.bestLoanHandler}>
            View best loans on the basis of your report
          </button>
        </div>
        <div className="biz360-report__wrapper">
          <LoaderModal status={loaderModalStatus} />
          <div className="header__blue">
            <div className="container">
              <div className="left__section">
                <div className="header-heading">{entityDetailsData.businessName}</div>
                <div>Applicant</div>
                <div className="header-heading__small">
                  {allApplicantSummaryData[0] && allApplicantSummaryData[0].name}
                </div>
              </div>
              <div className="right__section">
                <div className="image-logo__wrapper">
                  <img src={Logo} alt="" />
                  <span className="header-heading ml-4">Biz</span>
                  <h3>360</h3>
                </div>
                {/* <div className="header-heading__small">Report generated on 21 Feb, 2019 (4:30 PM)</div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="btn-box__right">
              <button onClick={() => window.print()} className="print-page__button">
                {isBizReport ? "Print BIZ360 Report for free" : "Print CIBIL Report for free"} <FiPrinter />
              </button>
            </div>

            {isBizReport ? (
              <>
                <div className="box__wrapper-green">
                  <div className="heading">Your Overall Business Analysis</div>
                  <ul className="container">
                    <li>
                      Total imputed turnover ₹{" "}
                      {rupeeCommaFormatter(gstTrendAnalysisData.imputedTotalTurnoverInLast12Months)} & Average Monthly
                      turnover ₹ {rupeeCommaFormatter(gstTrendAnalysisData.averageMonthlyTurnover)}
                    </li>
                    <li> Your turnover growth is {gstTrendAnalysisData.turnoverGrowthValue}</li>
                    <li>
                      Your sales to purchase ratio is{" "}
                      {turnoverAndPurchaseDetailsData.purchaseToTurnover &&
                        turnoverAndPurchaseDetailsData.purchaseToTurnover.toFixed(2)}%
                    </li>
                    <li>
                      Your average monthly turnover is {turnoverAndPurchaseDetailsData.turnoverAndPurchaseComparison}{" "}
                      average monthly purchase
                    </li>
                    <li>Your Business vintage is {entityDetailsData.businessVintage} years</li>
                    {businessCommentsForReportData.map((el, index) => {
                      return <li key={`businessComId${index}`}>{el}</li>;
                    })}
                  </ul>
                </div>
                <div className="box__wrapper-green">
                  <div className="heading">Business Turnover Trend Analysis</div>
                  <div className="sub-heading">Statistics as per GST</div>
                  <div className="row no-gutters">
                    <div className="col-md-4 col-6">
                      <label className="label-heading">Turnover in last 13 months (in ₹)</label>
                      <div className="label-value">{rupeeCommaFormatter(gstTrendAnalysisData.totalTurnOver)}</div>
                    </div>
                    <div className="col-md-4 col-6">
                      <label className="label-heading">Imputed Turnover in last 12 months (in ₹)</label>
                      <div className="label-value">
                        {rupeeCommaFormatter(gstTrendAnalysisData.imputedTotalTurnoverInLast12Months)}
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <label className="label-heading">Avg. Monthly turnover (in ₹)</label>
                      <div className="label-value">
                        {rupeeCommaFormatter(gstTrendAnalysisData.averageMonthlyTurnover)}
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <label className="label-heading">
                        Turnover till date from April {new Date().getFullYear()} (in ₹)
                      </label>
                      <div className="label-value">{rupeeCommaFormatter(gstTrendAnalysisData.turnoverTillDate)}</div>
                    </div>
                    <div className="col-md-4 col-6">
                      <label className="label-heading">Turnover growth in the past 12 months (in %)</label>
                      <div className="label-value">
                        {gstTrendAnalysisData.turnoverGrowth && gstTrendAnalysisData.turnoverGrowth.toFixed(2)}%
                      </div>
                    </div>
                  </div>
                  <div className="sub-heading">Overall Growth In Past 12 Month</div>
                  <div id="business-turnover-trend" className="col-12"></div>
                  <hr />

                  {gstTrendAnalysisData.comments &&
                    gstTrendAnalysisData.comments.map((el, index) => {
                      return (
                        <div className={"mt-1"} key={`gstTrendId${index}`}>
                          <span className={"bullet-green"}>&#9679;</span>
                          {el}
                        </div>
                      );
                    })}
                  <div className="note__wrapper">
                    <div className="left-section">Note:</div>
                    <div className="right-section">
                      If you have any other GSTIN but details not provided by you at the time of filing details, then
                      provide that also because that will give you more clear picture of your business.
                    </div>
                  </div>
                </div>
                <div className="box__wrapper-green">
                  <div className="heading">Concentration of business</div>
                  <div className="sub-heading">Your top 3 business contributors</div>
                  <div className="concentration__wrapper-box row no-gutters">
                    {concentrationRiskDetailsData.contributionByTop10Customers &&
                    concentrationRiskDetailsData.contributionByTop10Customers.length !== 0 ? (
                      <div className="col-md-4 col-md-6 col-12">
                        <div className="concentration-heading">Customers</div>
                        <div className="concentration-table__wrapper">
                          {concentrationRiskDetailsData.contributionByTop10Customers &&
                            concentrationRiskDetailsData.contributionByTop10Customers.slice(0,3).map((el, index) => {
                              return (
                                <div className="row no-gutters" key={`top10CustomerId${index}`}>
                                  <div className="col-sm-9">{el.category}</div>
                                  <div className="col-sm-3">{el.value && el.value.toFixed(2)}%</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                    {concentrationRiskDetailsData.contributionByTop10Vendors &&
                    concentrationRiskDetailsData.contributionByTop10Vendors.length !== 0 ? (
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="concentration-heading">Vendors</div>
                        <div className="concentration-table__wrapper">
                          {concentrationRiskDetailsData.contributionByTop10Vendors &&
                            concentrationRiskDetailsData.contributionByTop10Vendors.slice(0,3).map((el, index) => {
                              return (
                                <div className="row no-gutters" key={`top10VendorsId${index}`}>
                                  <div className="col-sm-9">{titleCase(el.category)}</div>
                                  <div className="col-sm-3">{el.value && el.value.toFixed(2)}%</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                    {concentrationRiskDetailsData.contributionByTop10Products &&
                    concentrationRiskDetailsData.contributionByTop10Products.length !== 0 ? (
                      <div className="col-md-4 col-sm-6 col-12">
                        <div className="concentration-heading">Products</div>
                        <div className="concentration-table__wrapper">
                          {concentrationRiskDetailsData.contributionByTop10Products &&
                            concentrationRiskDetailsData.contributionByTop10Products.slice(0,3).map((el, index) => {
                              return (
                                <div className="row no-gutters" key={`top10ProductsId${index}`}>
                                  <div className="col-sm-9">{titleCase(el.ccMapping)}</div>
                                  <div className="col-sm-3">{el.txval && el.txval.toFixed(2)}%</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <hr />
                  {concentrationRiskDetailsData.comments &&
                    concentrationRiskDetailsData.comments.map((el, index) => {
                      return (
                        <div className={"mt-1"} key={`concId${index}`}>
                          <span className={"bullet-green"}>&#9679;</span>
                          {el}
                        </div>
                      );
                    })}
                </div>
                <div className="box__wrapper-green">
                  <div className="heading">Sales & Purchase Comparative Analysis</div>
                  <div className="sub-heading">Statistics as per GST</div>

                  <div className="row no-gutters">
                    <div className="col-4 col-md-4">
                      <label className="label-heading">Total Turnover to Purchase Ratio (in %)</label>
                      <div className="label-value">
                        {turnoverAndPurchaseDetailsData.purchaseToTurnover &&
                          turnoverAndPurchaseDetailsData.purchaseToTurnover.toFixed(2)} %
                      </div>
                    </div>
                    <div className="col-4 col-md-4">
                      <label className="label-heading">Average Monthly Turnover (in ₹)</label>
                      <div className="label-value">
                        {rupeeCommaFormatter(turnoverAndPurchaseDetailsData.averageMonthlyTurnover)}
                      </div>
                    </div>
                    <div className="col-4 col-md-4">
                      <label className="label-heading">Average Monthly Purchase (in ₹)</label>
                      <div className="label-value">
                        {rupeeCommaFormatter(turnoverAndPurchaseDetailsData.averageMonthlyPurchase)}
                      </div>
                    </div>
                  </div>
                  <div className="sub-heading">GST Turnover & Purchases ( Last 12 Months)</div>
                  <div id="gstMonthlyWise"></div>
                  <hr />
                  {turnoverAndPurchaseDetailsData.comments &&
                    turnoverAndPurchaseDetailsData.comments.map((el, index) => {
                      return (
                        <div className={"mt-1"} key={`turnId${index}`}>
                          <span className={"bullet-green"}>&#9679;</span>
                          {el}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}

            <div className="box__wrapper-green">
              <div className="heading">Entity Details</div>
              <div className="sub-heading">Basic Details</div>
              <div className="row">
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Name of Entity</label>
                  <div className="label-value">{entityDetailsData.businessName}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Vintage of Entity</label>
                  <div className="label-value">{entityDetailsData.businessVintage}</div>
                </div>

                <div className="col-sm-4 col-4">
                  <label className="label-heading">Email ID</label>
                  <div className="label-value">NA</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Mob No.</label>
                  <div className="label-value">NA</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">PAN of Entity</label>
                  <div className="label-value">{entityDetailsData.businessPan}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">CIN (if exists)</label>
                  <div className="label-value">{entityDetailsData.cin ? entityDetailsData.cin : "-"}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Entity Constitution</label>
                  <div className="label-value">{entityDetailsData.constitution}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Primary Industry</label>
                  <div className="label-value">{entityDetailsData.primaryIndustry}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Secondary Industry</label>
                  <div className="label-value">{entityDetailsData.secondaryIndutry}</div>
                </div>
                <div className="col-sm-4 col-4">
                  <label className="label-heading">Business Type</label>
                  <div className="label-value">{entityDetailsData.businessType}</div>
                </div>
                {entityDetailsData.gstDetails &&
                  entityDetailsData.gstDetails.map((el, index) => {
                    return (
                      <div className="col-sm-4 col-4" key={`gstDetailsId${index}`}>
                        <label className="label-heading">GST No. {index + 1}</label>
                        <div className="label-value">{el}</div>
                      </div>
                    );
                  })}
                {entityDetailsData.addresses &&
                  entityDetailsData.addresses.map((el, index) => {
                    return (
                      <div className="col-sm-4 col-4" key={`gstAddId${index}`}>
                        <label className="label-heading">
                          {titleCase(el.addressType)}({titleCase(el.ownership)})
                        </label>
                        <div className="label-value">{`${el.line1} , ${el.line2} , ${el.district} ,  ${el.state} - ${el.pinCode}`}</div>
                      </div>
                    );
                  })}
              </div>
              <div className="sub-heading">Recommended Products for you</div>
              <div className="card-box__wrapper">
                {productsForBizReportData.slice(0, no0fBizProducts).map((el,index) => {
                  return (
                    <div className="card__wrapper" key={`productsId${index}`}>
                      <div className="card__wrapper-heading">{el.productName}</div>
                      <div className="grey__text">Why this</div>
                      {el.positives &&
                        el.positives.map((el, index) => {
                          return (
                            <div className={"mt-1"} key={`productsPositivesId${index}`}>
                              <span className={"bullet-green"}>&#9679;</span>
                              {el}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
                <div className="btns-box mb-4">
                  {no0fBizProducts === 4 ? (
                    <button
                      className="button__generic"
                      onClick={() => this.setState({ no0fBizProducts: productsForBizReportData.length })}
                    >
                      View All
                    </button>
                  ) : null}
                </div>
                <hr />
                {entityDetailsData.comments &&
                  entityDetailsData.comments.map((el, index) => {
                    return (
                      <div className={"mt-1"} key={`entityId${index}`}>
                        <span className={"bullet-green"}>&#9679;</span>
                        {el}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="box__wrapper-green">
              <div className="heading">Credit History overview</div>
              <div className="sub-heading">CIBIL Summary for All Applicants</div>
              <ReactTable columns={cibilColumns} data={allApplicantSummaryData} />
              <div className="sub-heading">Loan Repayment Summary</div>
              <div className="container">
                {creditHistoryDetailsData.businessLoanHistory ? (
                  <>
                    <div className="sub-heading">Business Loan</div>
                    <div className="row no-gutters">
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Business Loans</label>
                        <div className="label-value">{creditHistoryDetailsData.businessLoanHistory.totalLoans}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">> 30 DPD Observed</label>
                        <div className="label-value">{creditHistoryDetailsData.businessLoanHistory.dpdObserved}</div>
                      </div>
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Highest Loan</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.businessLoanHistory.highestLoan)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Current Outstanding</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.businessLoanHistory.currentOustanding)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">
                          Total Loan Taken <br />
                          <span className="optional-text__small">(in last 6 months)</span>
                        </label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.businessLoanHistory.loanAmountInLast6Months)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading"> First Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.businessLoanHistory.firstLoanDate}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Most recent Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.businessLoanHistory.lastLoanDate}</div>
                      </div>
                    </div>
                  </>
                ) : null}
                {creditHistoryDetailsData.propertyLoanHistory && (
                  <>
                    <div className="sub-heading">Property Loan</div>
                    <div className="row no-gutters">
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Property Loan</label>
                        <div className="label-value">{creditHistoryDetailsData.propertyLoanHistory.totalLoans}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">> 30 DPD Observed</label>
                        <div className="label-value">{creditHistoryDetailsData.propertyLoanHistory.dpdObserved}</div>
                      </div>
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Highest Loan</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.propertyLoanHistory.highestLoan)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Current Outstanding</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.propertyLoanHistory.currentOustanding)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">
                          Total Loan Taken <br />
                          <span className="optional-text__small">(in last 6 months)</span>
                        </label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.propertyLoanHistory.loanAmountInLast6Months)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading"> First Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.propertyLoanHistory.firstLoanDate}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Most recent Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.propertyLoanHistory.lastLoanDate}</div>
                      </div>
                    </div>
                  </>
                )}
                {creditHistoryDetailsData.vehicleLoanHistory && (
                  <>
                    <div className="sub-heading">Vehicle Loan</div>
                    <div className="row no-gutters">
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Vehicle Loan</label>
                        <div className="label-value">{creditHistoryDetailsData.vehicleLoanHistory.totalLoans}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">> 30 DPD Observed</label>
                        <div className="label-value">{creditHistoryDetailsData.vehicleLoanHistory.dpdObserved}</div>
                      </div>
                      <div className="col-md-1 col-3">
                        <label className="label-heading">Highest Loan</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.vehicleLoanHistory.highestLoan)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Current Outstanding</label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.vehicleLoanHistory.currentOustanding)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">
                          Total Loan Taken
                          <br />
                          <span className="optional-text__small">(in last 6 months)</span>
                        </label>
                        <div className="label-value">
                          {rupeeCommaFormatter(creditHistoryDetailsData.vehicleLoanHistory.loanAmountInLast6Months)}
                        </div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading"> First Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.vehicleLoanHistory.firstLoanDate}</div>
                      </div>
                      <div className="col-md-2 col-3">
                        <label className="label-heading">Most recent Loan Taken On</label>
                        <div className="label-value">{creditHistoryDetailsData.vehicleLoanHistory.lastLoanDate}</div>
                      </div>
                    </div>
                  </>
                )}
                <hr />

                {creditHistoryDetailsData.comments &&
                  creditHistoryDetailsData.comments.map((el, index) => {
                    return (
                      <div className={"mt-1"} key={`creditHistoryId${index}`}>
                        <span className={"bullet-green"}>&#9679;</span>
                        {el}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="box__wrapper-green">
              <div className="heading">Loan Repayment & Obligation Summary</div>
              <ReactTable data={currentObligationsData.loanDetails || []} columns={obligationSummaryColumns} />
              <hr />

              {currentObligationsData.comments &&
                currentObligationsData.comments.map((el, index) => {
                  return (
                    <div className={"mt-1"} key={`currentObligationId${index}`}>
                      <span className={"bullet-green"}>&#9679;</span>
                      {el}
                    </div>
                  );
                })}
            </div>

            <div className="powered-by__wrapper">
              <p>Powered by</p>
              <img src={Logo} alt="logo" />
              <div className="logo__header">Credochain</div>
            </div>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    var businessPan;
    if (this.props.match.path === "/dashboard/business/credit-report" ) {
      businessPan = sessionStorage.getItem("businessPan");
    } else {
      var gstNumber = getCookie("gstNumber");
      businessPan = gstNumber && gstNumber.slice(2, 12);
    }
    let {
      getGstTrendDetails,
      getConcentrationRiskDetails,
      getTurnoverAndPurchaseDetails,
      getCreditHistoryDetails,
      getAllApplicantSummary,
      getCurrentObligations,
      getEntityDetails,
      getFindProductsForBizReport,
      loaderModal,
      getBusinessCommentsForReport,
    } = this.props.actions;
    loaderModal(true);
    if (this.state.isBizReport) {
      getGstTrendDetails(businessPan);
      getConcentrationRiskDetails(businessPan);
      getTurnoverAndPurchaseDetails(businessPan);
    }
    getCreditHistoryDetails(businessPan);
    getAllApplicantSummary(businessPan);
    getEntityDetails(businessPan);
    getBusinessCommentsForReport(businessPan);
    getFindProductsForBizReport(businessPan);
    await getCurrentObligations(businessPan);
    loaderModal(false);
  };
  componentDidUpdate = () => {
    let { isBizReport } = this.state;
    if (isBizReport) {
      this.highChartRenderer();
    }
  };
  highChartRenderer = () => {
    let { gstTrendAnalysisData, turnoverAndPurchaseDetailsData } = this.props;
    Highcharts.chart("business-turnover-trend", {
      chart: {
        type: "line",
      },
      title: false,
      yAxis: [
        {
          labels: {
            formatter: function () {
              return integerToWordFormatterLabels(this.value);
            },
          },
        },
      ],
      xAxis: {
        categories: map(gstTrendAnalysisData && gstTrendAnalysisData.monthWiseTurnover, (v) =>
          v.month.replace(" ", "<br/>")
        ),
        crosshair: true,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            // crop: false,
            // overflow: "none",
            style: {
              fontSize: "1em",
            },
            formatter: function () {
              return integerToWordFormatter(this.y);
            },
          },
        },
      },

      series: [
        {
          name: "GST",
          data: map(gstTrendAnalysisData && gstTrendAnalysisData.monthWiseTurnover, "value"),
          color: "#56d9fe",
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
    Highcharts.chart("gstMonthlyWise", {
      chart: {
        type: "column",
      },
      title: false,
      xAxis: {
        categories: map(
          turnoverAndPurchaseDetailsData && turnoverAndPurchaseDetailsData.monthwiseGstPurchaseTurnover,
          (v) => v.month.replace(" ", "<br/>")
        ),
        crosshair: true,
      },
      yAxis: [
        {
          labels: {
            formatter: function () {
              return integerToWordFormatterLabels(this.value);
            },
          },
          title: {
            text: "Value",
          },
        },
        {
          gridLineWidth: 0,
          title: {
            text: "Ratio",
          },
          labels: {
            format: "{value} %",
          },
          opposite: true,
        },
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1em",
            },
            formatter: function () {
              return integerToWordFormatter(this.y);
            },
          },
          enableMouseTracking: false,
        },
        spline: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1.5em",
            },
            formatter: function () {
              return this.y.toFixed(2) + "%";
            },
          },
          enableMouseTracking: false,
        },
      },
      series: [
        {
          type: "spline",
          name: "Ratio",
          yAxis: 1,
          data: map(
            turnoverAndPurchaseDetailsData && turnoverAndPurchaseDetailsData.ratioTurnoverOverPurchases,
            "value"
          ),
          tooltip: {
            valueSuffix: " %",
          },
        },
        {
          name: "Turnover",
          data: map(
            turnoverAndPurchaseDetailsData && turnoverAndPurchaseDetailsData.monthwiseGstPurchaseTurnover,
            "gst"
          ),
          color: "#56d9fe",
          type: "column",
        },
        {
          name: "Purchase",
          data: map(
            turnoverAndPurchaseDetailsData && turnoverAndPurchaseDetailsData.monthwiseGstPurchaseTurnover,
            "purchase"
          ),
          color: "#3670cc",
          type: "column",
        },
      ],
    });
  };
}

const mapStateToProps = ({ Biz360ReportReducer, data }) => ({
  gstTrendAnalysisData: Biz360ReportReducer.gstTrendAnalysisData,
  concentrationRiskDetailsData: Biz360ReportReducer.concentrationRiskDetailsData,
  turnoverAndPurchaseDetailsData: Biz360ReportReducer.turnoverAndPurchaseDetailsData,
  creditHistoryDetailsData: Biz360ReportReducer.creditHistoryDetailsData,
  allApplicantSummaryData: Biz360ReportReducer.allApplicantSummaryData,
  currentObligationsData: Biz360ReportReducer.currentObligationsData,
  entityDetailsData: Biz360ReportReducer.entityDetailsData,
  productsForBizReportData: Biz360ReportReducer.productsForBizReportData,
  businessCommentsForReportData: Biz360ReportReducer.businessCommentsForReportData,
  loaderModalStatus: data.loaderModalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getGstTrendDetails,
      getConcentrationRiskDetails,
      getTurnoverAndPurchaseDetails,
      getCreditHistoryDetails,
      getAllApplicantSummary,
      getCurrentObligations,
      loaderModal,
      getEntityDetails,
      getFindProductsForBizReport,
      getBusinessCommentsForReport,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Biz360Report);
