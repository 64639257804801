import React, { Component } from "react";
import { goTo } from "../../../utils/utility";

export default class LoanPreference extends Component {
  state = {
    loanType: "BUSINESS LOAN",
  };

  loanPreferenceHandler = (loanType) => {
    this.setState({ loanType });
    sessionStorage.setItem("loanType", loanType);
    setTimeout(() => {
      goTo(this.props.nextScreen);
    }, 100);
  };
  render() {
    let { loanType } = this.state;
    return (
      <div className="loan-preference__wrapper">
        <div className="bold-text">Select top preferences for your loan type.</div>
        <div>Select your preferred loan type</div>
        <div className="loan-box__wrapper">
          <div
            className={
              loanType === "BUSINESS LOAN" ? "loan-purpose-value__box active-card" : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("BUSINESS LOAN")}
          >
            BUSINESS LOAN
          </div>
          <div
            className={
              loanType === "MUDRA LOAN" ? "loan-purpose-value__box active-card" : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("MUDRA LOAN")}
          >
            MUDRA LOAN
          </div>
          <div
            className={
              loanType === "LOAN AGAINST PROPERTY"
                ? "loan-purpose-value__box active-card"
                : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("LOAN AGAINST PROPERTY")}
          >
            LOAN AGAINST PROPERTY
          </div>
          <div
            className={
              loanType === "WORKING CAPITAL" ? "loan-purpose-value__box active-card" : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("WORKING CAPITAL")}
          >
            WORKING CAPITAL
          </div>
          <div
            className={
              loanType === "MERCHANT CASH ADVANCE"
                ? "loan-purpose-value__box active-card"
                : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("MERCHANT CASH ADVANCE")}
          >
            MERCHANT CASH ADVANCE
          </div>
          <div
            className={
              loanType === "TERM LOAN" ? "loan-purpose-value__box active-card" : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("TERM LOAN")}
          >
            TERM LOAN
          </div>
          <div
            className={
              loanType === "SUPPLY CHAIN ADVANCE"
                ? "loan-purpose-value__box active-card"
                : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("SUPPLY CHAIN ADVANCE")}
          >
            SUPPLY CHAIN ADVANCE
          </div>
          <div
            className={
              loanType === "let us choose for your business"
                ? "loan-purpose-value__box active-card"
                : "loan-purpose-value__box"
            }
            onClick={() => this.loanPreferenceHandler("let us choose for your business")}
          >
            let us choose for your business
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    let loanType = sessionStorage.getItem("loanType");
    this.setState({ loanType });
  }
}
