import React, { Component } from 'react'
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import LoanPreferenceComponent from "../../ViewsComponents/LoanPreference/LoanPreference";
import Header from "../../ViewsComponents/Header/Header";
export default class LoanPreference extends Component {
    render() {
        return (
            <div className="row no-gutters">
            <div className="col-md-3">
              <SidePanel
                advisories={[
                  "All loan products available under one platform for you",
                  "Want us to decide the most suitable product for your business, press SKIP",
                ]}
              ></SidePanel>
            </div>
            <div className="col-md-9">
              <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"loan-purpose"} {...this.props}/>
              <LoanPreferenceComponent nextScreen={"loan-amount-and-tenure"}/>
            </div>
          </div>
        )
    }
}
