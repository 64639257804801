import React, { Component } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { goTo, getCookie } from "../../../utils/utility";
import { getTaxPayerData } from "../../HomePage/homepage.actions";
import { loaderModal } from "../../../store/actions";
import { bindActionCreators } from "redux";
import { fetchBusinessDetails, getGSTData } from "./GstNumber.actions";
import RadioBox from "../../../components/RadioBox/RadioBox";
class GstNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstNumber: "",
    };
  }
  gstInputHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value ? e.target.value.replace(/[- ]+/g, "") : "";
    this.setState({ [name]: value });
  };
  generateBizHandler = async () => {
    let { loaderModal, getTaxPayerData } = this.props.actions;
    loaderModal(true);

    await getTaxPayerData(this.state.gstNumber).then(({ data }) => {
      if (data.gstin) {
        document.cookie = `gstNumber=${this.state.gstNumber.toUpperCase()}; path=/`;
        sessionStorage.setItem("gstNumber", this.state.gstNumber.toUpperCase());
        goTo(this.props.nextScreen);
      } else {
        alert("Sorry, We are unable process your data");
      }
    });
    loaderModal(false);
  };
  setGstNumber = (gstData) => {
    this.setState({ gstNumber: gstData?.gstList[0]?.gstin }, () => {
      this.generateBizHandler();
    });
  };
  render() {
    let { gstNumber } = this.state,
      { businessDetailsData, gstData } = this.props,
      existingEntity = [];
    if (gstData.length !== 0 && businessDetailsData.length !== 0) {
      existingEntity = [...gstData, businessDetailsData];
    } else if (gstData.length !== 0) {
      existingEntity = [...gstData];
    } else {
      existingEntity = [businessDetailsData];
    }
    return (
      <div>
        <div className="bold-text">Enter GST Number</div>
        <InputMask
          className="input__generic mr-4"
          placeholder="Enter your GST number"
          mask={"** - ********** - ***"}
          value={gstNumber.toUpperCase()}
          onChange={this.gstInputHandler}
          maskChar={null}
          name={"gstNumber"}
        />
        <button className="button__generic" onClick={this.generateBizHandler} disabled={gstNumber.length < 15}>
          Submit
        </button>
        <div className="existing-gst__wrapper mt-3">
          <div className="optional-text__small">OR</div>
          <div className="blue__text my-3">Select from already existing entity</div>
          {existingEntity?.map((el, index) => (
            <>
              {/* <RadioBox
                radioColor={"#1d1d1d"}
                // checked={aggregateTotalData === "All"}
                name={"gstNumber"}
                defaultChecked={index===0}
                onChange={() => this.setGstNumber("gstNumber", el)}
                labelFor={"gstNumber"}
              /> */}

              <label className="entity-heading" htmlFor={`gstNumber`} onClick={() => this.setGstNumber(el)}>
                {index + 1}. {el.entityName}
              </label>
              <div className="gst-number__wrapper">
                {el?.gstList?.map((gstListData, index) => (
                  <div>
                    <div className="gst-number__count">GST #{index + 1}</div>
                    <div className="gst-number">{gstListData.gstin}</div>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      </div>
    );
  }
  componentDidMount() {
    let { fetchBusinessDetails, getGSTData } = this.props.actions;
    let applicationNumber = getCookie("applicationNumber");
    fetchBusinessDetails(applicationNumber);
    getGSTData();
  }
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getTaxPayerData,
      loaderModal,
      fetchBusinessDetails,
      getGSTData,
    },
    dispatch
  ),
});

const mapStateToProps = ({ GstNumberReducer }) => ({
  businessDetailsData: GstNumberReducer.businessDetailsData,
  gstData: GstNumberReducer.gstData,
});

export default connect(mapStateToProps, mapDispatchToProps)(GstNumber);
