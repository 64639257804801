import React, { Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import Select from "react-select";
import { POST } from "../../utils/WebAPI.service";

import { API_POST_CONTACT_US } from "../../utils/APIUrls";

const SupportTopicList = [
  { value: "How to apply for loan", label: "How to apply for loan" },
  { value: "GST Query", label: "GST Query" },
  { value: "Application Status", label: "Application Status" },
  { value: "Best Loan Recommendation", label: "Best Loan Recommendation" },
  { value: "Documents Query", label: "Documents Query" },
  { value: "EMI Calculation", label: "EMI Calculation" },
  { value: "Get our Services", label: "Get our Services" },
  { value: "Others", label: "Others" },

  
];
export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      topic: "",
      message: "",
    };
  }
  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }
  onTopicChange = (event) => {
    this.setState({ topic: event.value });
    this.setState({ event });
  };
  handleSubmit = async (event) => {
    event.preventDefault();

    let { firstName, lastName, phone, email, message, topic } = this.state,
      dataToSend = {
        data: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          topic: topic,
          message: message,
        },
      };
    await POST(API_POST_CONTACT_US, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          window.alert("We'll call you back in next 48 hours");
          this.props.history.push("/");
        } else {
          window.alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    const { event, firstName, lastName, phone, email, message } = this.state;
    return (
      <div>
        <Header />
        <div className="contact-us__wrapper">
          <div className="row">
            <div className="col-md-5 contact-us__inner-wrapper">
              <div className="contact-us__info">
                <div className="contact-label">Contact Us</div>
                <div className="contact-sub-label">Please drop-in your inquiry and we will respond within 2 working days</div>
              </div>
            </div>
            <form className="col-md-7 contact-us__inner-wrapper">
              <div className="card_wrapper">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="heading">First Name</div>
                    <input
                      className="basic-single"
                      type="text"
                      name="firstName"
                      value={firstName}
                      required
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="heading">Last Name</div>
                    <input
                      className="basic-single"
                      type="text"
                      name="lastName"
                      value={lastName}
                      required
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="heading">Email</div>
                    <input
                      className="basic-single"
                      value={email}
                      placeholder="Enter you email ID"
                      type="email"
                      name="email"
                      required
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="heading">Phone</div>
                    <input
                      className="basic-single"
                      value={phone}
                      placeholder="Enter you phone number"
                      type="text"
                      name="phone"
                      required
                      onChange={this.onChange}
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="heading">Topic</div>
                  <Select
                    classNamePrefix="Select Your Topic"
                    value={event}
                    options={SupportTopicList}
                    onChange={this.onTopicChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <div className="heading">Message</div>
                  <textarea
                    className="basic-single"
                    placeholder="Start writing your message (200)"
                    type="textarea"
                    name="message"
                    rows="8"
                    cols="50"
                    value={message}
                    onChange={this.onChange}
                    required
                    maxLength={200}
                  />
                </div>
                <div className="col-md-4">
                  <div className="button tc set-cursor-pointer" type="submit" onClick={this.handleSubmit}>
                    Submit
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
