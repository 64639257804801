import * as AmountAndTenureConstants from "./AmountAndTenure.actions.constants";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_APPLICATION_DATA } from "../../../utils/APIUrls";

const fetchApplication= (applicationNumber) => (dispatch) => {
  return GET(API_GET_APPLICATION_DATA(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: AmountAndTenureConstants.GET_APPLICATION_DATA, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export { fetchApplication };
