import React, { Component } from "react";
import { FaMobileAlt } from "react-icons/fa";
import OtpInput from "react-otp-input";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_B2COTP_VALIDATE } from "../../../utils/APIUrls";
import { goTo } from "../../../utils/utility";
import { FaPencilAlt } from "react-icons/fa";
export default class OtpModal extends Component {
  state = {
    otp: ""
  };
  inputHandler = async otp => {
    let { mobileNumber, isSignIn } = this.props;
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(otp)) {
      this.setState({ otp });
    }
    if (otp.length === 6) {
      var dataToSend = {
        data: {
          mobile: mobileNumber,
          otp
        }
      };
      await POST(API_POST_B2COTP_VALIDATE, dataToSend)
        .then(({ data }) => {
          if (data.status === "success") {
            isSignIn ? goTo("/dashboard/business/home") : goTo("/loan-application/loan-purpose");
          }
        })
        .catch(err => {
          throw err;
        });
    }
  };
  render() {
    let { otp } = this.state;
    return (
      <div className="otp-screen__wrapper">
        <div className="mobile__wrapper child__space-between">
          <FaMobileAlt size={24} /> <span>+91 {this.props.mobileNumber}</span>
          <FaPencilAlt onClick={() => this.props.closeAction()} />
        </div>
        <OtpInput
          onChange={otp => this.inputHandler(otp)}
          numInputs={6}
          separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          value={otp}
          containerStyle={"input-otp__box"}
        />
      </div>
    );
  }
}
