import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import GSTUsernameComponent from "../../../ViewsComponents/GSTUsername/GSTusername";
import Header from "../../../ViewsComponents/Header/Header";
export default class GSTUsername extends Component {
  render() {
    return (
      <div className="gst-data__wrapper">
        <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "Enter correct GST Username to get the OTP",
                "OTP will not be generated if API access is not enabled in GSTN Portal. Watch the below video to learn how can you enable API access.",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9">
            <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"gst-data"} {...this.props}/>
            <GSTUsernameComponent nextScreen={"gst-otp"} notSuccessNextScreen={"kyc"} />
          </div>
        </div>
      </div>
    );
  }
}
