import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";

import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class BankOdLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Bank Overdraft Limit (OD Limit)</title>
          <meta
            name="description"
            content="Loan Against property is a secured term loan Eligibility of any LAP does not exceed 70% of Market value of
                the property which is offered as collateral. LAP is offered on
                the properties having registered sale deed in the name of person
                who is also a party to the loan."
          />
          <meta
            name="tag"
            content="bank overdraft limit in india,bank overdraft limit increase letter,bank overdraft limit format,bank overdraft limit means,bank overdraft limit chase,define bank overdraft limit,what does bank overdraft limit mean,first bank overdraft limit,frost bank overdraft limit,guaranty bank overdraft limit,glacier bank overdraft limit,bank reduce overdraft limit,starling bank overdraft limit"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center">Bank Overdraft Limit (OD Limit)</div>
              <br />
              <div className="about-details center">
                <p className="fs-16 ">
                  Loan Against property is a <span className="highlight-blue">secured term loan </span>
                  by keeping commercial or residential property as collateral. It is termed as secured as in case of
                  default by borrower, bank can recover the debt by selling off the property.
                </p>
                <p className="fs-16 width-80">
                  Eligibility of any LAP does not exceed 70% of Market value of the property which is offered as
                  collateral. LAP is offered on the properties having registered sale deed in the name of person who is
                  also a party to the loan.
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="₹10 Lac - ₹5 Cr"
              interestRate="9% - 12%"
              loanTenure="10-20 Years"
              decisionTime="7-10 Days"
              processingFee="0.5% - 1.5%"
              buttonClick=""
              buttonText="Find the Best Offers"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Bank Overdraft Limit </div>
              <div className="sub-heading_card_wrapper">When to opt for Bank Overdraft Limit </div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">One time Investment</div>
                  This loan is preferred when there is specific need of one-time investment in business. Bulk amount of
                  fund can be obtained through one source.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Wide Business Range</div>
                  Suitable for wide range of business use like big large expenditure, new product expansion, new
                  geography expansion, new office setup.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Available Collateral</div>
                  When collateral is free and no plan to sell in near future. LAP cannot be availed on LAL DORA property
                  and on power of attorney.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Low Interest Rate</div>
                  In comparison to unsecured Loan
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Fast Approval Time</div>
                  In comparison to CC/OD Limit{" "}
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Flexible Tenure </div>
                  Options with lenders
                </div>
              </div>
            </div>
            <br />
            <div className="horizontal-space" />
          </div>
          <div className=" negative-margin">
          <CalculateEMI 
              businessVintage="3 years +"
              optimumTurnover="5 Crore +"
              financialDataRequired="Depends upon Loan Amount"
            />
            <div className="horizontal-space" />
          </div>
          <br />
          <br />
          <div className="why-loan__wrapper extra-padding center">
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Bank Overdraft Limit</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">Collateral get freezes with one lender</div>
                <div className="SME_subCard_wrapper  ">
                  No flexibility of Part payment so no benefit of buffer cash in bank
                </div>
                <div className="SME_subCard_wrapper  ">
                  Risk of loss of property for borrower in case of default in payments
                </div>
              </div>
            </div>

            <OtherLoanProducts />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
