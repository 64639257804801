import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import WhiteLogo1 from "../../assets/Images/WhiteLogo1.png";
import { Link } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }
  render() {
    return (
      <div className="header__container">
        <Navbar expand="lg" fixed="top" id="js-header" className="navbar-dark">
          <Navbar.Brand href="/">
            <img src={WhiteLogo1} width={"80%"} alt="Credochain Logo" />
            <div className="Credochain"></div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link href="/"> Our Solutions</Nav.Link>
              <UncontrolledDropdown className="menu-items"
                nav
                inNavbar
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
              >
                <DropdownToggle nav caret>
                  MSME Loans
                  <div className={window.location.pathname.includes("msme/tools") ? "active msme-loans" : "no-display"} />
                </DropdownToggle>
                <DropdownMenu className="MSMELoan_dropdown" right>
                  <div className="triangle" />
                  <div className="d-flex">
                  <div className="horizontal-line">
                   <div className="menu__wrapper">
                      <DropdownItem href="../../msme/tools/LAP" className="MSMELoan_dropdownItems">
                        Loan Against Property (LAP)
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/SME" className="MSMELoan_dropdownItems">
                        Unsecured Business Loan - SME
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/micro-enterprises" className="MSMELoan_dropdownItems">
                        Unsecured Business Loan - Micro
                      </DropdownItem>

                      <DropdownItem href="../../msme/tools/Balance-Transfer" className="MSMELoan_dropdownItems">
                        Balance Transfer(LAP)
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bank-Od-Limit" className="MSMELoan_dropdownItems">
                        Bank Overdraft Limit (OD Limit){" "}
                      </DropdownItem>
                    </div>
                   </div>
                    <div>
                      <DropdownItem href="../../msme/tools/Bank-Cc-Limit" className="MSMELoan_dropdownItems">
                        Bank CC Limit{" "}
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bank-Term-Loan" className="MSMELoan_dropdownItems">
                        Bank/NBFC Term Loan
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bill-Invoice-Discounting" className="MSMELoan_dropdownItems">
                        Bill/Invoice Discounting
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Dealer-Purchase-Finance" className="MSMELoan_dropdownItems">
                        Dealer/Purchase Finance
                      </DropdownItem>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <Nav.Link href="/"> MSME Loans</Nav.Link> */}
              <Nav.Link href="https://medium.com/credochain" target="_blank">
                Blog
              </Nav.Link>
              
              <Nav.Link href="/contact-us" className={"menu-items contact-us"}>
                Contact Us
              <div className={window.location.pathname.includes("/contact-us") ? "active contact-us" : "no-display"} />
              </Nav.Link>
              <Nav.Link href="/"> Sign in</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      
      </div>
    );
  }
}
