import React, { Component } from "react";
import { getCookie } from "../../../utils/utility";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_GST_OTP_LINK } from "../../../utils/APIUrls";
import { connect } from "react-redux";

class UserInput extends Component {
  state = {
    mobileNumber: ""
  };
  sendUserName = async () => {
    let gstin = getCookie("gstNumber"),
      { mobileNumber, email } = this.state,
       gstUsername  = sessionStorage.getItem("gstUsername"),
      dataToSend = {
        data: {
          email,
          gstin,
          isDataFetched: true,
          mobileNumber,
          gstUsername
        }
      },
      { loaderModal } = this.props.actions;
      console.log(this.props)
    loaderModal(true);
    await POST(API_POST_GST_OTP_LINK, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.props.nextPageHandlerChild();
        } else {
          window.alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  inputHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (name === "mobileNumber") {
      if (regMobile.test(value)) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };
  render() {
    let { mobileNumber, email } = this.state;
    return (
      <div className="share-otp__wrapper mt-1">
        <h2>Share OTP link</h2>
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xs-12 p-0">
            <label htmlFor="mobile">Mobile No.</label>
            <br />
            <input
              type="text"
              className="input__generic mt-0"
              name="mobileNumber"
              onChange={this.inputHandler}
              value={mobileNumber}
              maxLength={10}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xs-12 p-0">
            <label htmlFor="email">
              Email ID <span className="optional-text__small">(optional)</span>
            </label>
            <br />
            <input
              type="text"
              className="input__generic mt-0"
              name="email"
              onChange={this.inputHandler}
              value={email}
            />
          </div>
        </div>
        <button
          className="button__generic small-padding mt-0"
          onClick={this.sendUserName}
          disabled={!(mobileNumber || email)}
        >
          Send
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ GSTDataReducer }) => ({
  withoutGSTObject: GSTDataReducer.withoutGSTObject
});

export default connect(mapStateToProps, null)(UserInput);
