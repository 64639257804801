import Highcharts from "highcharts";
import { Helmet } from "react-helmet";
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { some, get } from "lodash";
import {
  setHeaderFixed,
  integerToWordFormatterLabels,
  integerToWordFormatter,
  deleteCookie
} from "../../../utils/utility";
import { getBusinessDetails, getProfileDetail, getFormFilledBy, getTurnOverDetails } from "./overview.action";
import ApplicantCard from "./ApplicantCard";
import ApplicationModal from "./ApplicantModal";
import { setUserDetails, setUserLoginStatus, loaderModal, checkUserLogin } from "../../../store/actions";
import ContentLoader from "react-content-loader";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      applicantPan: null
    };
    this.integerToWordFormatter = this.integerToWordFormatter.bind(this);
  }

  toggleModal = (status, pan) => {
    this.setState({
      modalVisible: status,
      applicantPan: pan
    });
  };

  fetchDetails = async () => {
    let { actions, history, match } = this.props;
    let { getProfileDetail, getBusinessDetails, getFormFilledBy, loaderModal } = actions;
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      loaderModal(true);
      getFormFilledBy(applicationNumber);
      getBusinessDetails(applicationNumber);
      this.turnoverChart(applicationNumber);
      await getProfileDetail(applicationNumber);
      loaderModal(false);
    } else {
      history.push("/Credit/dashboard");
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { setUserDetails, setUserLoginStatus, checkUserLogin } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", setHeaderFixed);
    this.fetchDetails();
  }
  integerToWordFormatter = value => {
    let intValue = value.toString;
    if (intValue.length >= 3) {
      return intValue.slice(0, 1) + "K";
    } else if (intValue.length >= 5) {
      return (intValue / 10000).slice(0, 4) + "Lakh";
    } else {
      return (intValue / 1000000).slice(0, 4) + "Crore";
    }
  };
  turnoverChart = async appNo => {
    await this.props.actions.getTurnOverDetails(appNo);
    const { turnoverDetails } = this.props;
    Highcharts.chart("turnover-chart", {
      chart: {
        type: "bar",
        height: 140,
        backgroundColor: "transparent"
      },
      title: false,
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["Bank Statement", "GST"]
      },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      tooltip: {
        formatter: function() {
          return "<p>" + this.x + "<br/>" + integerToWordFormatter(this.y) + "</p>";
        }
      },
      legend: false,
      series: [
        {
          data: [
            turnoverDetails && turnoverDetails.totalBankingTurnover,
            turnoverDetails && turnoverDetails.imputedGSTTurnover
          ],
          color: "#00cdbe"
        }
      ]
    });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }

  render() {
    let { modalVisible, applicantPan } = this.state;
    const { businessDetails, profileDetail, formFilledBy, match } = this.props;
    return (
      <div className={"overview-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Overview</title>
        </Helmet>
        {modalVisible ? (
          <ApplicationModal
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
            panNumber={applicantPan}
            applicationNumber={match.params.appNo}
          />
        ) : null}
        <div className={"row"}>
          <div className={"col-md-8"}>
            <div className={"content-wrapper"}>
              <h4>Entity</h4>
              <div className={"row"}>
                {/* <ContentLoader width={200} height={10} margin={"30px auto"} renderContent={businessDetails !== null}> */}
                {businessDetails ? (
                  <Fragment>
                    <div className={"col-md-9"}>
                      <h2>{businessDetails.businessName}</h2>
                      <span>
                        {businessDetails.constitution} {businessDetails.businessAge}
                      </span>
                      <div className={"row content-box"}>
                        <div className={"col-md-4 content"}>
                          <label>Primary Industry</label>
                          <span>{businessDetails.primaryIndustry}</span>
                        </div>
                        <div className={"col-md-4 content"}>
                          <label>Secondary Industry</label>
                          <span>{businessDetails.secondaryIndustry}</span>
                        </div>

                        <div className={"col-md-4 content"}>
                          <label>PAN Card</label>
                          <span>{businessDetails.businessPan}</span>
                        </div>
                      </div>
                      {businessDetails.gstData &&
                        businessDetails.gstData.map((el, index) => {
                          var address = el.addresses[0] || [];
                          return (
                            <div className="row">
                              <div className={"col-md-4 content"}>
                                <label>GSTIN</label>
                                <span>{el.gstin}</span>
                              </div>
                              <div className={"col-md-8 content"}>
                                <label>Office</label>
                                <span>
                                  <address
                                    className={"m-0"}
                                  >{`${address.line1} ${address.line2}, ${address.district}, ${address.state} , Pincode- ${address.pinCode}`}</address>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Fragment>
                ) : (
                  <span />
                )}
                 {/* <ContentLoader/> */}
              </div>
            </div>
          </div>
          <div className={"col-md-4"}>
            <div className={"content-wrapper"}>
              <h4>Turnover Details</h4>
              <div className={"chart-box"} id={"turnover-chart"} />
            </div>
            <div className={"content-wrapper"}>
              <h4>Form Filled By</h4>
              <h3>
                {get(formFilledBy, "firstName", "")} {get(formFilledBy, "middleName", "")}{" "}
                {get(formFilledBy, "lastName", "")}{" "}
              </h3>
              <div className={"row content-box"}>
                <div className={"col-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Address</label>
                    </div>
                    <span className={"col-md-8"}>
                      <address className={"m-0"}>{get(formFilledBy, "address", "")}</address>
                    </span>
                  </div>
                </div>
                <div className={"col-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Phone</label>
                    </div>
                    <div className={"col-md-8"}>
                      <span>{get(formFilledBy, "mobile", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Email</label>
                    </div>
                    <div className={"col-md-8"}>
                      <span>{get(formFilledBy, "email", "")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <ApplicantCard profileDetail={profileDetail} toggleModal={this.toggleModal} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ OverViewReducer }) => ({
  loader: some(OverViewReducer.loader),
  businessDetails: OverViewReducer.businessDetails,
  profileDetail: OverViewReducer.profileDetail,
  formFilledBy: OverViewReducer.formFilledBy,
  turnoverDetails: OverViewReducer.turnoverDetails
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getBusinessDetails,
      getProfileDetail,
      getFormFilledBy,
      loaderModal,
      getTurnOverDetails,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
