const initialState = {
  desicionList: [],
  allStates: [],
  allSales: [],
  allLoanAmount: [],
  BusinessType: [],
};

export const listData = (state = initialState, action) => {
  const { list, type } = action;
  switch (type) {
    case "SET_FINAL_DECISION":
      return {
        ...state,
        desicionList: list,
      };
    case "SET_ALL_STATES":
      return {
        ...state,
        allStates: list,
      };
    case "SET_ALL_SALES":
      return {
        ...state,
        allSales: list,
      };
    case "SET_LOAN_AMOUNT":
      return {
        ...state,
        allLoanAmount: list,
      };
    case "SET_BUSINESS_TYPE":
      return {
        ...state,
        BusinessType: list,
      };

    default:
      return state;
  }
};
