import React, { Component } from "react";
import CommonModal from "../../../components/Modal/modal";
import SignUpPreFill from "./SignUpPreFill";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getProfile } from "./ReportDownload.actions";
import SignUpUserInput from "./SignUpUserInput";
import SignIn from "./SignIn";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_ADD_TEMP_USER } from "../../../utils/APIUrls";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import { loaderModal } from "../../../store/actions";
import OtpScreen from "./OtpScreen";

class ReportDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      RenderingComponent: SignUpPreFill
    };
  }
  userOnBoardHandler = () => {
    this.toggleModal("");
  };
  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    this.changeModalHandler("SignUpPreFill");
  };
  componentDidMount = () => {
    let { getProfile } = this.props.actions;
    getProfile();
  };
  changeModalHandler = (type, mobileNumber) => {
    switch (type) {
      case "SignUpPreFill":
        this.setState({ RenderingComponent: SignUpPreFill, screenType: "signUp" });
        break;
      case "SignUpUserInput":
        this.setState({ RenderingComponent: SignUpUserInput, screenType: "signUp" });
        break;
      case "SignIn":
        this.setState({ RenderingComponent: SignIn, screenType: "signIn" });
        break;
      case "OtpScreen":
        this.setState({ RenderingComponent: OtpScreen });
        break;
      default:
        return null;
    }
    this.setState({ mobileNumber });
  };
  addTempUserHandler = async dataToSend => {
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    const { data } = await POST(API_POST_ADD_TEMP_USER, dataToSend);
    loaderModal(false);
    return data;
  };
  render() {
    let { loaderModalStatus } = this.props,
      { reportType, modalIsOpen, screenType, mobileNumber, RenderingComponent } = this.state;
    return (
      <div className="report-download__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <h1>Your {reportType} Report is Ready!</h1>
        <p className="mt-2">Click below to view your {reportType} Report</p>
        <button className="button__generic" onClick={this.userOnBoardHandler}>
          View Report
        </button>
        <CommonModal
          isOpen={modalIsOpen}
          props={this.props}
          className="signup__wrapper"
          styles={{
            content: {
              width: "30%"
            }
          }}
          title={screenType === "signUp" ? "Sign Up" : "Sign In"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={RenderingComponent}
          changeModalHandler={(type, mobileNumber) => this.changeModalHandler(type, mobileNumber)}
          addTempUserHandler={this.addTempUserHandler}
          mobileNumber={mobileNumber}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ ReportDownloadReducer, data }) => ({
  profileData: ReportDownloadReducer.profileData,
  loaderModalStatus: data.loaderModalStatus
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getProfile, loaderModal }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportDownload);
