const GET_ALL_APPLICATIONS_START = Symbol('SalesDashBoardGetAllApplicationsStart');
const GET_ALL_APPLICATIONS = Symbol('SalesDashBoardGetAllApplications');
const GET_ALL_APPLICATIONS_FINISHED = Symbol('SalesDashBoardGetAllApplicationsFinished');
const GET_ALL_APPLICATIONS_ERROR = Symbol('SalesDashBoardGetAllApplicationsError');

const GET_SEARCH_BY_START = Symbol('SalesDashBoardGetAllApplicationsStart');
const GET_SEARCH_BY_FINISHED = Symbol('SalesDashBoardGetAllApplicationsFinished');
const GET_SEARCH_BY_ERROR = Symbol('SalesDashBoardGetAllApplicationsError');
const USER_LIST=Symbol("UserList")
const CHANGE_VALUE = Symbol('ChangeValue');
const RESET_DATA = Symbol('ResetData');

const SET_SEARCH_BY_STATUS = Symbol('SetSearchByStatus');
export {
    GET_ALL_APPLICATIONS_START,
    GET_ALL_APPLICATIONS,
    GET_ALL_APPLICATIONS_FINISHED,
    GET_ALL_APPLICATIONS_ERROR,
    GET_SEARCH_BY_START,
    GET_SEARCH_BY_FINISHED,
    GET_SEARCH_BY_ERROR,
    CHANGE_VALUE,
    SET_SEARCH_BY_STATUS,
    USER_LIST,
    RESET_DATA
}
