import { get } from "lodash";
import * as ACTION_TYPE from "./dashboard.action.constant";
import { GET, POST } from "../../../utils/WebAPI.service";
import {
  // API_GET_ALL_APPLICATIONS,
  API_GET_SEARCHBY_FILTERS,
  // API_GET_USER_BY_ROLE_ID,
  API_DASHBOARD_FETCH_DATA
} from "../../../utils/APIUrls";
import { UPDATE_FILTER_OPTIONS } from "../../../components/CreditHeader/header.action.constant";
import { getDateString, getYMD } from "../../../utils/utility";
import { changeValue as headerChangeValue } from "../../../components/CreditHeader/header.action";



const getSearchByFilters = () => async dispatch => {
  dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_START });
  try {
    const response = await GET(API_GET_SEARCHBY_FILTERS);
    if (response.data.status === "error") {
      throw response.data;
    }
    const filters = get(response, "data.data.responseList", []).map(value => ({
      label: value,
      value
    }));
    dispatch({ type: UPDATE_FILTER_OPTIONS, data: filters });

  } catch (error) {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_ERROR, error: error.message });
    return error;
  } finally {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_FINISHED })
  }
};
export const fetchDashBoardData = data => async dispatch => {
  dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_START });
  try {
    await POST(API_DASHBOARD_FETCH_DATA, data).then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: data.data });
      } else {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: [] });
      }
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_ERROR, error: error.message });
    return error;
  } finally {
    dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_FINISHED });
  }
};
export const resetData = (data)=> dispatch => {
  dispatch({type:ACTION_TYPE.RESET_DATA , data:data})
}

const setSearchFilterStatus = name => ({
  type: ACTION_TYPE.SET_SEARCH_BY_STATUS,
  name
});

/**
 * @param name: field Name
 * @param val: field Value
 * @returns {{type: string, data: *, name: string}}
 */
const changeValue = (name, val) => ({
  type: ACTION_TYPE.CHANGE_VALUE,
  data: val,
  name: name
});

export {
  getSearchByFilters,
  changeValue,
  setSearchFilterStatus,
};
