import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { goTo } from "../../../utils/utility";
export default class LinkShared extends Component {
  state = { counter: 3 };
  componentDidMount = () => {
    setInterval(() => {
      this.setState({ counter: this.state.counter - 1 });
    }, 1000);
    setTimeout(() => {
      goTo(this.props.nextScreen)
    }, 3000);
  };
  render() {
    return (
      <div className="share-wrapper mt-3 ">
        <h2>Share Username link</h2>
        <p>Username link has been shared.</p>
        <Alert variant={"success"}>you will be redirected to next screen in {this.state.counter}</Alert>
      </div>
    );
  }
}
