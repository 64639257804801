import * as CreditBehaviourConstant from "./creditBehaviour.actions.constant";
import { GET, POST } from "../../../utils/WebAPI.service";
import { API_FETCH_ANALYSIS, API_GET_APPLICANTS_SUMMARY } from "../../../utils/APIUrls";

const getApplicantsSummary = appNo => dispatch => {
  return GET(API_GET_APPLICANTS_SUMMARY(appNo))
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({
          type: CreditBehaviourConstant.GET_APPLICANTS_SUMMARY,
          data: data.data
        });
        return data;
      } else {
        throw data;
      }
    })
    .catch(err => {
      throw err;
    });
};

const fetchAnalysis = data => dispatch => {
  return POST(API_FETCH_ANALYSIS, data)
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({
          type: CreditBehaviourConstant.FETCH_ANALYSIS,
          data: data.data
        });
        dispatch({
          type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
          status: true
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const setInitialResponse = status => ({
  type: CreditBehaviourConstant.SET_INITIAL_RESPONSE,
  status
});

export { getApplicantsSummary, fetchAnalysis, setInitialResponse };
