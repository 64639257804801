import React, { Component } from "react";
import AboutYourBusiness from "../ViewsComponents/AboutYourBusiness/aboutYourBusiness";
import { GET, POST } from "../../utils/WebAPI.service";
import { API_GET_VERIFY_LINK, API_POST_VALIDATE_GST_USERNAME_LINK } from "../../utils/APIUrls";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loaderModal } from "../../store/actions";
import WhiteLogo from "../../assets/Images/whiteLogo.png";
import { goTo } from "../../utils/utility";
import { getUsernameAndGst } from "./usernameAndOtp.actions";
class UserName extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthenticated: false };
  }
  componentDidMount = () => {
    this.validateUUID();
  };
  validateUUID = async () => {
    let { match } = this.props,
      { uuid } = match.params;
    loaderModal(true);
    await GET(API_GET_VERIFY_LINK(uuid))
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ isAuthenticated: true, gstin: data.data.gstin });
        } else {
          window.alert(data.message);
          this.setState({ isAuthenticated: true });
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  gstUsernameHandler = async () => {
    let { gstUsername, gstin } = this.state;
    let { match, actions } = this.props,
      { loaderModal, getUsernameAndGst } = actions,
      { uuid } = match.params;
    var dataToSend = {
      data: {
        gstUsername,
        gstin,
        isDataFetched: true
      }
    };
    loaderModal(true);
    await POST(API_POST_VALIDATE_GST_USERNAME_LINK(uuid), dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          getUsernameAndGst(dataToSend.data);
          goTo(`/validateotp-with-username/${uuid}`);
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  render() {
    let { isAuthenticated, gstUsername, gstin } = this.state,
      { loaderModalStatus } = this.props;
    return (
      <>
        <LoaderModal status={loaderModalStatus} />
        {isAuthenticated ? (
          <div className="validate-username__wrapper">
            <div className="gradient-background">
              <img src={WhiteLogo} alt="" />
            </div>
            <div className="card-wrapper">
              <h1>Get your BIZ360 Report</h1>
              <AboutYourBusiness {...this.props}/>
              <div className="label-heading">Enter GST Registered Username</div>
              <input
                type="text"
                className="input__generic"
                value={gstUsername}
                name="gstUsername"
                onChange={e => this.setState({ [e.target.name]: e.target.value })}
              />
              <button className="button__generic d-block" onClick={this.gstUsernameHandler}>
                Next
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModalStatus
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getUsernameAndGst, loaderModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserName);
