import React, { Component } from "react";
import { Icon } from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/chevronLeft";
import { goTo } from "../../../../utils/utility";
import InputMask from "react-input-mask";
import { connect } from "react-redux";

class CreditReport extends Component {
  state = {
    gstNumber: ""
  };
  gstInputHandler = e => {
    var name = e.target.name;
    var value = e.target.value ? e.target.value.replace(/[- ]+/g, "") : "";
    this.setState({ [name]: value });
  };
  render() {
    return (
      <div className="row creditReport__wrapper">
        <div className="col-md-8">
          <div className="card_wrapper">
            <div className="subHeadings" onClick={() => goTo("/dashboard/business/credit-report")}>
              Credit Report Details
            </div>
          </div>
          <div className="main-box__wrapper">
            <div className="sub-box__wrapper">
              <div>
                <div className="subHeadings">Get your Business health for Free!</div>
                <div className="content">
                  Generate your Business Report to calculate your business health and loan eligibility.
                </div>
                <InputMask
                  className="input__generic mr-4 w-100"
                  placeholder="Enter your GST number"
                  mask={"** - ********** - ***"}
                  value={this.state.gstNumber.toUpperCase()}
                  onChange={this.gstInputHandler}
                  maskChar={null}
                  name={"gstNumber"}
                />
              </div>
              <div>
                <div className="btn_div">
                  <button className="buttonGeneric_Blue" onClick={() => goTo("/dashboard/generate-gst")}>
                    Get your Biz360 Report
                  </button>
                </div>
              </div>
            </div>
            <div className="sub-box__wrapper">
              <div>
                <div className="subHeadings">Directly apply for loan</div>
                <div className="content">Directly apply for loan</div>
              </div>
              <div>
                <div className="btn_div newLoan">
                  <button className="buttonGeneric_Blue">Apply for new loan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreditReport);
