import React from "react";
import { get } from "lodash";
import { rupeeCommaFormatter } from "../../../utils/utility";
import OkaySign from "../../../assets/Images/okaySign.svg";
import CrossSign from "../../../assets/Images/crossIcon.svg";
const obligationsColumns = [
  {
    Header: "A/c Holder's Name",
    accessor: "accountHolderName",
    Cell: ({ row }) => {
      let {original}=row
      let { accountHolderName, ownershipType } = original;
      return (
        <span title={accountHolderName}>
          {accountHolderName}
          <br />
          <span title={ownershipType}>{ownershipType}</span>
        </span>
      );
    }
  },
  {
    Header: "Type",
    accessor: "type"
  },
  {
    Header: "Lender",
    accessor: "lender"
  },
  {
    Header: "Loan Amount",
    accessor: "loanAmount",
    Cell: ({row}) => {
      let {original}=row

      let { loanAmount } = original;
      return <span title={loanAmount}>{rupeeCommaFormatter(loanAmount)}</span>;
    }
  },
  {
    Header: "Current Balance",
    accessor: "currentBalance",
    Cell: ({row}) => {
      let {original}=row

      let { currentBalance } = original;
      return (
        <span title={currentBalance}>
          {rupeeCommaFormatter(currentBalance)}
        </span>
      );
    },
    width: 90
  },
  {
    Header: "EMI Amount",
    accessor: "emiAmount",
    Cell: ({row}) => {
      let {original}=row

      let { emiAmount } = original;
      return <span title={emiAmount}>{rupeeCommaFormatter(emiAmount)}</span>;
    }
  },
  {
    Header: "Opening Date",
    accessor: "openingDate"
  },
  {
    Header: "Closing Date",
    accessor: "closingDate"
  },
  {
    Header: "Reporting Date",
    accessor: "reportingDate"
  },
  {
    Header: "Month On Book",
    accessor: "monthOnBook"
  },
  {
    Header: "Comment",
    accessor: "comment"
  },
  {
    Header: "Remarks",
    accessor: "remarks"
  },
  {
    Header: "EMI Considered",
    accessor: "",
    Cell: ({row}) => {
      let {original}=row

      let obligatedEMI = get(original, "obligatedEMI", "");
      return (
        <span title={obligatedEMI}>{rupeeCommaFormatter(obligatedEMI)}</span>
      );
    }
  },
  {
    Header: "Bank Details",
    accessor: "",
    Cell: ({row}) => {
      let {original}=row

      let accountHolderName = get(original, "accountHolderName", "");
      let accountNumber = get(original, "accountNumber", "");
      return accountHolderName && accountNumber ? (
        <span>
          {accountHolderName} - {accountNumber}
        </span>
      ) : (
        "NA"
      );
    }
  },
  {
    Header: "Duplicate",
    accessor: "",
    Cell: ({row}) => {
      let {original}=row

      let isDuplicate = get(original, "isDuplicate", "");
      return isDuplicate ? <span>True</span> : <span>False</span>;
    }
  },
  {
    Header: "Closing in 6 Months",
    accessor: "isClosingInSixMonths",
    Cell: ({cell}) => {
      let {value}=cell
      if (value) {
        return <img src={OkaySign} alt={"true"} className="closingSign" />;
      } else {
        return <img src={CrossSign} alt="" className="closingSign" />;
      }
    },
    sortable: false,
    width: 80
  },
  {
    Header: "EMI Obligated",
    accessor: "isEmiObligated",
    Cell: ({cell}) => {
      if (cell.value) {
        return <img src={OkaySign} alt={"true"} className="closingSign" />;
      } else {
        return <img src={CrossSign} alt="" className="closingSign" />;
      }
    },
    sortable: false,
    width: 80
  }
];
const accountColumns = [
  {
    Header: "Account Holder",
    accessor: "accountHolderName",
    Cell: ({row}) => {
      let {original}=row
      let accountHolderName = get(original, "accountHolderName") || "";
      return <label title={accountHolderName}>{accountHolderName}</label>;
    }
  },
  {
    Header: "Lender",
    accessor: "lender",
    Cell: ({row}) => {
      let {original}=row

      let lender = get(original, "lender") || "";
      return <label title={lender}>{lender}</label>;
    }
  },
  {
    Header: "EMI Amount",
    accessor: "amount",
    Cell: ({row}) => {
      let {original}=row

      let amount = get(original, "amount") || "";
      return <label title={amount}>{amount}</label>;
    },
    width: 100
  },
  {
    Header: "Last Reporting Date",
    accessor: "lastReportingDate",
    Cell: ({row}) => {
      let {original}=row

      let date = get(original, "lastReportingDate") || "";
      return <label title={date}>{date}</label>;
    }
  }
];

export {obligationsColumns, accountColumns };
