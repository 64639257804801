const initialState = {
  usernameAndGstinData: []
};

export const UserNameAndGstReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case "USERNAME_GST_DATA":
      return {
        ...state,
        usernameAndGstinData: data
      };
    default:
      return state;
  }
};
