import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDocumentList } from "./DocumentUpload.actions";
import { getCookie, goTo } from "../../../utils/utility";
import Select from "react-select";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_BUSINESS_DOCUMENT, API_POST_PROFILE_DOCUMENT } from "../../../utils/APIUrls";
import { AiOutlineFilePdf } from "react-icons/ai";
import { loaderModal } from "../../../store/actions";
export class DocumentUpload extends Component {
  state = {};
  businessDocsUpload = async (e, businessId, docType, docName) => {
    let files = e.target.files;
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: getCookie("token"),
    };
    let formData = new FormData();
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }
    await POST(API_POST_BUSINESS_DOCUMENT(getCookie("applicationNumber"), businessId, docType, docName), formData, {
      headers,
    }).then(({ data }) => {
      if (data.status !== "success") {
        alert(data.message);
      } else {
        for (let file in files) {
          if (files.hasOwnProperty(file)) {
            this.setState({ [`entityFileName${docType}`]: [] }, () => {
              this.setState((prevState) => ({
                [`entityFileName${docType}`]: [...prevState[`entityFileName${docType}`], files[file].name],
              }));
            });
          }
        }
      }
    });
    loaderModal(false);
  };
  profileDocsUpload = async (e, profileId, docType, docName) => {
    let files = e.target.files;
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: getCookie("token"),
    };
    let formData = new FormData();
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }
    await POST(API_POST_PROFILE_DOCUMENT(getCookie("applicationNumber"), profileId, docType, docName), formData, {
      headers,
    }).then(({ data }) => {
      if (data.status !== "success") {
        alert(data.message);
      } else {
        for (let file in files) {
          if (files.hasOwnProperty(file)) {
            this.setState({ [`profileFileName${docType}${profileId}`]: [] }, () => {
              this.setState((prevState) => ({
                [`profileFileName${docType}${profileId}`]: [
                  ...prevState[`profileFileName${docType}${profileId}`],
                  files[file].name,
                ],
              }));
            });
          }
        }
      }
    });
    loaderModal(false);
  };
  render() {
    let { documentListData } = this.props;
    let { businessDocs = [], profileDocs = [] } = documentListData;
    return (
      <div className="document-upload__wrapper">
        <h2>Entity Documents - {businessDocs.businessName}</h2>
        <div className="row no-gutters">
          {businessDocs?.documents?.map((el, index) => {
            return (
              <div className="col-md-4 mt-2 pr-4">
                <div className="card__wrapper" key={`business-docs-${index}`}>
                  <h3>{el.docType}</h3>
                  <Select
                    onChange={(e) => this.setState({ [`docName${el.docType}`]: e.value })}
                    options={el.docName.map((el) => ({ label: el, value: el }))}
                    className="select-handler"
                    // value={bankList.find((option) => option.label === el.bankName)}
                  />
                  <div className="filenames__box">
                    {this.state[`entityFileName${el.docType}`]?.map((fileNames, fileNameIndex) => {
                      return (
                        <div className="file-name__wrapper">
                          <AiOutlineFilePdf size={20} />
                          {fileNames.slice(0, 10)}
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="button__generic mt-3 position-relative"
                    disabled={!this.state[`docName${el.docType}`]}
                  >
                    <input
                      type="file"
                      onChange={(e) =>
                        this.businessDocsUpload(
                          e,
                          businessDocs.businessId,
                          el.docType,
                          this.state[`docName${el.docType}`]
                        )
                      }
                      multiple
                    />
                    + Insert
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {profileDocs?.map((profileDoc, index) => {
          return (
            <div key={profileDoc.profileId}>
              <h2>Applicant Documents - {profileDoc.name}</h2>
              <div className="row no-gutters">
                {profileDoc?.documents?.map((el, profileDocId) => {
                  return (
                    <div className="col-md-4 mt-2 pr-4">
                      <div className="card__wrapper" key={`profile-docs-${profileDocId}`}>
                        <h3>{el.docType}</h3>
                        <Select
                          onChange={(e) => this.setState({ [`docNameProfile${el.docType}${profileDocId}`]: e.value })}
                          options={el.docName.map((el) => ({ label: el, value: el }))}
                          className="select-handler"
                          // value={bankList.find((option) => option.label === el.bankName)}
                        />
                        <div className="filenames__box">
                          {this.state[`profileFileName${el.docType}${profileDoc.profileId}`]?.map(
                            (fileNames, fileNameIndex) => {
                              return (
                                <div className="file-name__wrapper">
                                  <AiOutlineFilePdf size={20} />
                                  {fileNames.slice(0, 10)}
                                </div>
                              );
                            }
                          )}
                        </div>
                        <button
                          className="button__generic mt-3 position-relative"
                          disabled={!this.state[`docNameProfile${el.docType}${profileDocId}`]}
                        >
                          <input
                            type="file"
                            onChange={(e) =>
                              this.profileDocsUpload(
                                e,
                                profileDoc.profileId,
                                el.docType,
                                this.state[`docNameProfile${el.docType}${profileDocId}`]
                              )
                            }
                            multiple
                          />
                          + Insert
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="d-flex mt-5 justify-content-end">
          <button className="button__generic" onClick={() => goTo(this.props.nextScreen)}>
            Submit
          </button>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.props.actions.getDocumentList(getCookie("applicationNumber"));
  }
}

const mapStateToProps = ({ DocumentUploadReducer }) => ({
  documentListData: DocumentUploadReducer.documentListData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getDocumentList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
