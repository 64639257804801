const GET_GST_TREND_DETAILS = Symbol("getGstTrendDetails"),
  GET_CONCENTRATION_RISK_DETAILS = Symbol("getConcentrationRiskDetails"),
  GET_TURNOVER_AND_PURCHASES_DETAILS = Symbol("getTurnoverAndPurchaseDetails"),
  GET_CREDIT_HISTORY_DETAILS = Symbol("getCreditHistoryDetails"),
  GET_ALL_APPLICANT_SUMMARY = Symbol("getAllApplicantSummary"),
  GET_CURRENT_OBLIGATION = Symbol("getCurrentObligations"),
  GET_ENTITY_DETAILS = Symbol("getEntityDetails"),
  GET_FIND_PRODUCTS_FOR_BIZ_REPORT = Symbol("getFindProductsForBizReport"),
  GET_BUSINESS_COMMENTS_FOR_REPORT = Symbol("getBusinessCommentsForReport");

export {
  GET_GST_TREND_DETAILS,
  GET_CONCENTRATION_RISK_DETAILS,
  GET_TURNOVER_AND_PURCHASES_DETAILS,
  GET_CREDIT_HISTORY_DETAILS,
  GET_ALL_APPLICANT_SUMMARY,
  GET_CURRENT_OBLIGATION,
  GET_ENTITY_DETAILS,
  GET_FIND_PRODUCTS_FOR_BIZ_REPORT,
  GET_BUSINESS_COMMENTS_FOR_REPORT
};
