import React, { Component } from "react";
import { GET } from "../../../../utils/WebAPI.service";
import { API_GET_FETCH_ALL_APPLICATION } from "../../../../utils/APIUrls";
import { rupeeCommaFormatter, goTo } from "../../../../utils/utility";
export default class Home extends Component {
  state = {
    loanData: [],
  };
  render() {
    let { loanData } = this.state;
    return (
      <div className="my-loans-track-loans__wrapper">
        <div className="headings__wrapper">
          <div className="main-heading__wrapper">
            <div className="main-heading__styling">My Loans</div>
          </div>
          <div className="note-for-user">Keep a track of all your loans at one single place</div>
        </div>
        <div className="loans-body__wrapper">
          <div className="loan-boxes">
            <div className="main-box__wrapper my-2 col-md-10 col-sm-12">
              {loanData &&
                loanData.map((el) => {
                  return (
                    <div className="sub-box__wrapper" key={el.appId}>
                      <div className="col-md-12 mt-2">
                        <div className="loan-title">{el.entityName}</div>
                        <div className="loan-status">{el.status}</div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="loan-type">Application for {el.loanType}</div>
                        <div className="loan-id-stamp">
                          Date: 21/11/2019 <br />
                          ID: {el.appId}
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="loan-amount__wrapper">
                          <div className="loan-amount-title">Loan Amount</div>
                          <div className="loan-amount">₹{rupeeCommaFormatter(el.loanAmount)}</div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 loan-stats">
                        <div className="interest-rate__wrapper">
                          <div className="row">
                            <div className="yellow-circle mr-2" />
                            <div className="interest-rate">
                              <div className="interest-rate-heading">Interest Rate</div>
                              <div className="interest-rate-value">{el.interestRate}</div>
                            </div>
                          </div>
                        </div>
                        <div className="tenure__wrapper">
                          <div className="row">
                            <div className="yellow-circle mr-2" />
                            <div className="interest-rate">
                              <div className="interest-rate-heading">Tenure</div>
                              <div className="interest-rate-value">{`${Math.floor(el.tenure / 12)} Years ${
                                el.tenure % 12
                              } Months`}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="loan-action-btn__wrapper col-md-12 mt-1 mb-2">
                        <button className="loan-application-action-button cancel-application">
                          Cancel application
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <button className="button__generic" onClick={() => goTo("/dashboard/loans/loan-purpose")}>
          New Loan Application
        </button>
      </div>
    );
  }
  componentDidMount() {
    GET(API_GET_FETCH_ALL_APPLICATION).then(({ data }) => {
      data.data && this.setState({ loanData: data.data });
    });
    document.cookie = "applicationNumber=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    sessionStorage.clear()
  }
}
