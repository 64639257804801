import * as KYCConstant from "./KYC.actions.constants";

import { API_GET_RELATIONSHIP, API_GET_PROFILE_ADDRESS_TYPE, API_GET_GENDER, API_GET_PROFILE_DATA } from "../../../utils/APIUrls";
import { GET } from "../../../utils/WebAPI.service";
const getRelationship = (constitution) => {
  return (dispatch) => {
    return GET(API_GET_RELATIONSHIP(constitution))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: KYCConstant.GET_RELATIONSHIP, data: data.data.responseList });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getProfileAddressType = () => {
  return (dispatch) => {
    return GET(API_GET_PROFILE_ADDRESS_TYPE).then(({ data }) => {
      if (data.data) {
        return dispatch({ type: KYCConstant.GET_PROFILE_ADDRESS_TYPE, data: data.data.responseList });
      }
      return data;
    });
  };
};
const deleteKYCAddress = (id) => {
  return { type: "KYC_DELETE_ADDRESS", data: id };
};
const getGender = () => {
  return (dispatch) => {
    return GET(API_GET_GENDER)
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: KYCConstant.GET_GENDER, data: data.data.responseList });
        }
        return data.data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const fetchProfileData = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_GET_PROFILE_DATA(applicationNumber))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: KYCConstant.GET_PROFILE_DATA, data: data.data});
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};


export { getRelationship, getProfileAddressType, deleteKYCAddress, getGender , fetchProfileData };
