import React from "react";

const applicantColumns = [{
    Header: 'Identifier',
    accessor: '',
    // Cell: ({original}) => <label>{original.identifier}</label>,
}, {
    Header: 'Enquiry',
    accessor: 'enquiry',
}, {
    Header: 'Response',
    accessor: 'response',
}, {
    Header: 'Match',
    accessor: 'match',
}];

export {
    applicantColumns
}
