import React, { Component } from "react";
import AboutYourBusiness from "../AboutYourBusiness/aboutYourBusiness";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import Select from "react-select";
import {
  getSecondaryIndustry,
  getPrimaryIndustry,
  getConstitution,
  getBusinessType,
  setGstAddressInNewAddress,
  getBusinessAddressType,
} from "./GSTData.actions";
import { loaderModal } from "../../../store/actions";
import DatePicker from "react-date-picker";
import { getNewDateFormat, getDMY, goTo } from "../../../utils/utility";
import { changeGstData, getTaxPayerData, editUserNameHandler } from "../../HomePage/homepage.actions";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_ADD_BUSINESS } from "../../../utils/APIUrls";
import { FaPencilAlt } from "react-icons/fa";
import CommonModal from "../../../components/Modal/modal";
import AddressModal from "../UserAddress/addressModal";
class GSTData extends Component {
  state = {
    primaryIndustry: "",
    secondaryIndustry: "",
    businessType: "",
    constitution: "",
    dateOfIncorporation: new Date(),
    isCompleted: true,
    isUserNameEditable: false,
    allNewAddress: [],
  };
  componentDidMount = async () => {
    let { actions } = this.props,
      { getConstitution, getBusinessType, loaderModal, getBusinessAddressType } = actions;
    loaderModal(true);
    getConstitution();
    getBusinessAddressType();
    await getBusinessType();
    loaderModal(false);
  };
  selectHandler = async (e, name) => {
    let { getSecondaryIndustry, getPrimaryIndustry, changeGstData, loaderModal } = this.props.actions;
    changeGstData(name, e.value);
    this.setState({ [name]: e.value });
    loaderModal(true);
    if (name === "businessType") {
      await getPrimaryIndustry(e.value);
    }
    if (name === "primaryIndustry") {
      await getSecondaryIndustry(this.state.businessType, e.value);
    }
    loaderModal(false);
  };
  inputHandler = (e, objectName) => {
    let name = e.target.name,
      value = e.target.value;
    this.props.actions.changeGstData(name, value);
  };
  dateChangeHandler = (dateOfIncorporation) => {
    this.props.actions.changeGstData("dateOfIncorporation", getDMY(dateOfIncorporation));
  };

  submitGstDataHandler = (e) => {
    e.preventDefault();
    let { allNewAddress } = this.state;
    if (allNewAddress.length === 0) {
      alert("Please Add at least one address");
    } else {
      this.addBusinessHandler();
    }
  };
  addBusinessHandler = async () => {
    let { gstTaxPayerData, actions , applicationNumber } = this.props,
      { loaderModal } = actions;
    var dataToSend = {
      data: {
        address: null,
        businessName: gstTaxPayerData.businessName,
        businessPan: null,
        businessType: gstTaxPayerData.businessType,
        constitution: gstTaxPayerData.constitution,
        dateOfIncorporation: gstTaxPayerData.dateOfIncorporation,
        gstData: [
          {
            addresses: [...this.state.allNewAddress],
            dateOfGSTRegistration: gstTaxPayerData.gstData[0].dateOfGSTRegistration,
            email: null,
            gstStatus: gstTaxPayerData.gstData[0].gstStatus,
            gstUsername: gstTaxPayerData.gstData[0].gstStatus,
            gstin: gstTaxPayerData.gstin,
            isLinkActive: true,
            isOtpVerified: true,
            mobile: null,
            uuId: null,
            validationLink: null,
          },
        ],
        gstin: gstTaxPayerData.gstin,
        primaryIndustry: gstTaxPayerData.primaryIndustry,
        secondaryIndustry: gstTaxPayerData.secondaryIndustry,
        taxpayerType: gstTaxPayerData.taxpayerType,
        tradeName: gstTaxPayerData.tradeName,
      },
    };
    loaderModal(true);
    sessionStorage.setItem("constitution", gstTaxPayerData.constitution);
    await POST(API_POST_ADD_BUSINESS(applicationNumber), dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          goTo(this.props.nextScreen);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
    loaderModal(false);
  };
  toggleModal = (type, addressData, addressId) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalType: type, addressId, addressData });
  };
  deleteAddressHandler = () => {
    let { addressId, allNewAddress } = this.state;
    this.setState({
      allNewAddress: allNewAddress.filter((el, index) => {
        return index !== addressId;
      }),
    });
    this.toggleModal("");
  };
  addNewAddress = (data) => {
    this.setState({ allNewAddress: [...this.state.allNewAddress, data] });
  };
  editExistingAddress = (data) => {
    let { addressId } = this.state;
    let allNewAddress = this.state.allNewAddress.slice();
    allNewAddress[addressId] = data;
    this.setState({ allNewAddress });
  };
  render() {
    let {
        gstTaxPayerData,
        loaderModalStatus,
        getConstitutionData,
        getBusinessTypeData,
        getPrimaryIndustryData,
        getSecondaryIndustryData,
        getBusinessAddressTypeData,
      } = this.props,
      gstData = (gstTaxPayerData.gstData && gstTaxPayerData.gstData[0]) || [],
      { primaryIndustry, secondaryIndustry, businessType, allNewAddress } = this.state;
    let constitutionOptions = getConstitutionData.map((el) => {
      return { value: el, label: el };
    });
    let businessTypeOptions = getBusinessTypeData.map((el) => {
      return { value: el, label: el };
    });
    let primaryIndustryOptions = getPrimaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    let secondaryIndustryOptions = getSecondaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    return (
      <form onSubmit={this.submitGstDataHandler}>
        <div className="gst-data__wrapper">
          <LoaderModal status={loaderModalStatus} />
          <AboutYourBusiness {...this.props}/>
          <div className="row no-gutters mt-5">
            <div className="col-md-4 col-sm-8 col-12 ">
              <div className="heading">Date of Registration</div>
              <div className="subheading mt-1">{gstData.dateOfGSTRegistration?.split("-").join("/")}</div>
            </div>
            <div className="col-md-4 col-sm-8 col-12 ">
              <div className="heading">Date of Incorporation</div>
              <DatePicker
                onChange={this.dateChangeHandler}
                value={getNewDateFormat(gstTaxPayerData.dateOfIncorporation)}
                required
                format={"dd/MM/y"}
              />
            </div>
            <div className="col-md-4 col-sm-8 col-12 ">
              <div className="heading">Constitution of Business</div>
              <div className="subheading mt-1">
                {gstTaxPayerData.constitution ? (
                  gstTaxPayerData.constitution
                ) : (
                  <Select
                    options={constitutionOptions}
                    className="select__generic w-100 w-24rem"
                    name={"constitution"}
                    onChange={(e) => this.selectHandler(e, "constitution")}
                    value={constitutionOptions.find((option) => option.label === gstTaxPayerData.constitution)}
                  />
                )}
              </div>
              <input
                tabIndex={-1}
                style={{ opacity: 0, height: 0 }}
                required={true}
                value={gstTaxPayerData.constitution}
              />
            </div>
            <div className="col-md-4 col-sm-8 col-12 mt-4">
              <div className="heading">Business Type</div>
              <Select
                options={businessTypeOptions}
                className="select__generic w-100 w-24rem mt-2"
                onChange={(e) => this.selectHandler(e, "businessType")}
                name="businessType"
                defaultInputValue={businessType}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={businessType} />
            </div>
            <div className="col-md-4 col-sm-8 col-12 mt-4">
              <div className="heading">Primary Industry</div>
              <div className="subheading">
                <Select
                  options={primaryIndustryOptions}
                  className="select__generic w-100 w-24rem"
                  isDisabled={!businessType}
                  onChange={(e) => this.selectHandler(e, "primaryIndustry")}
                  name="primaryIndustry"
                  defaultInputValue={primaryIndustry}
                />
                <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={primaryIndustry} />
              </div>
            </div>
            <div className="col-md-4 col-sm-8 col-12 mt-4">
              <div className="heading">Secondary Industry</div>
              <div className="subheading">
                <Select
                  options={secondaryIndustryOptions}
                  className="select__generic w-100 w-24rem"
                  isDisabled={!primaryIndustry}
                  onChange={(e) => this.selectHandler(e, "secondaryIndustry")}
                  name="secondaryIndustry"
                  defaultInputValue={secondaryIndustry}
                  required
                />
                <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={secondaryIndustry} />
              </div>
            </div>
          </div>
          <span className="bold-text address-mobile-view__wrapper ">Address</span>
          <span className="optional-text__small ml-4">Please fill in Address Type of the primary address</span>
          <div className="address__wrapper">
            {allNewAddress &&
              allNewAddress.map((el, index) => {
                return (
                  <div className="available-wrapper ml-0" key={index}>
                    <div className="child__space-between">
                      <div> Address {index + 1}</div>
                      <FaPencilAlt onClick={() => this.toggleModal("editAddress", el, index)} className="cursor-pointer"/>
                    </div>
                    <div className="bold-text" style={{ fontSize: "12px" }}>
                      {el.addressType}({el.ownership})
                    </div>
                    <p className="mt-4">{`${el.line1}, ${el.line2}, ${el.district}, ${el.state}-${el.pinCode}`}</p>
                  </div>
                );
              })}
            <div className="add-wrapper ml-0" onClick={() => this.toggleModal("addAddress")}>
              +Add Address
            </div>
          </div>
          <button className="button__generic mb-3">Continue</button>
        </div>
        <CommonModal
          isOpen={this.state.modalIsOpen}
          props={this.props}
          type={this.state.modalType}
          addressData={this.state.addressData || []}
          title={"Address"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={AddressModal}
          addNewAddress={(data) => this.addNewAddress(data)}
          deleteAddressHandler={this.deleteAddressHandler}
          editExistingAddress={(data) => this.editExistingAddress(data)}
          addressTypeArray={getBusinessAddressTypeData}
          styles={{
            content: {
              width: "30%",
              minWidth: "400px",
            },
          }}
        />
      </form>
    );
  }
}
const mapStateToProps = ({ data, homePageReducers, GSTDataReducer }) => ({
  loaderModalStatus: data.loaderModalStatus,
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
  getConstitutionData: GSTDataReducer.getConstitutionData,
  getBusinessTypeData: GSTDataReducer.getBusinessTypeData,
  getPrimaryIndustryData: GSTDataReducer.getPrimaryIndustryData,
  getSecondaryIndustryData: GSTDataReducer.getSecondaryIndustryData,
  getBusinessAddressTypeData: GSTDataReducer.getBusinessAddressTypeData,
  allNewAddress: GSTDataReducer.allNewAddress,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSecondaryIndustry,
      getPrimaryIndustry,
      getConstitution,
      getBusinessType,
      loaderModal,
      setGstAddressInNewAddress,
      changeGstData,
      getTaxPayerData,
      editUserNameHandler,
      getBusinessAddressType,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(GSTData);
