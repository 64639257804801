import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderModal from "../../../../../components/LoaderModal/loaderModal";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { fetchLoanUse } from "../../../../ViewsComponents/LoanPurpose/LoanPurpose.actions";
import { getTaxPayerData } from "../../../../HomePage/homepage.actions";
import { getCookie } from "../../../../../utils/utility";
import { setGstAddressInNewAddress } from "../../../../ViewsComponents/GSTData/GSTData.actions";
import GSTUsername from "../../../../ViewsComponents/GSTUsername/GSTusername";
import GSTOTP from "../../../../ViewsComponents/GSTOTPPage/GSTOTPPage";
import GSTData from "../../../../ViewsComponents/GSTData/GSTData";
import Banking from "../../../../ViewsComponents/Banking/Banking";
import KYC from "../../../../ViewsComponents/MultipleApplicantKYC/MultipleApplicantKYC";
import GstNumber from "../../../../ViewsComponents/GSTNumber/GstNumber";
import LoanPreference from "../../../../ViewsComponents/LoanPreference/LoanPreference";
import LoanPurpose from "../../../../ViewsComponents/LoanPurpose/LoanPurpose";
import AmountAndTenure from "../../../../ViewsComponents/AmountAndTenure/AmountAndTenure";
import LoanApplicationPreview from "../../../../ViewsComponents/LoanApplicationPreview/LoanApplicationPreview";
import { FaChevronLeft } from "react-icons/fa";
import DocumentUpload from "../../../../ViewsComponents/DocumentUpload/DocumentUpload";
class LoanApplication extends Component {
  render() {
    let { loaderModalStatus, history } = this.props,
      applicationNumber = getCookie("applicationNumber");
    return (
      <>
        <div onClick={() => history.goBack()} className="back-button">
          <FaChevronLeft className="mr-3" size={20} />
          Back
        </div>
        <div className="loan-application__wrapper">
          <LoaderModal status={loaderModalStatus} />
          <Helmet>
            <title>User details</title>
            <meta name="description" content="Business Report for business" />
            <meta name="description" content="credit Report for business" />
            <meta name="keyword" content="how to apply for business loans,how to apply for msme loans" />
          </Helmet>
          <Switch>
            <Route
              path="/dashboard/loans/loan-purpose"
              exact
              render={(props) => <LoanPurpose {...props} nextScreen={"loan-preference"} />}
            />
            <Route
              path="/dashboard/loans/loan-preference"
              exact
              component={() => <LoanPreference nextScreen={"loan-amount-and-tenure"} />}
            />
            <Route
              path="/dashboard/loans/loan-amount-and-tenure"
              exact
              component={() => <AmountAndTenure nextScreen={"gst-number"} />}
            />
            <Route path="/dashboard/loans/gst-number" exact component={() => <GstNumber nextScreen={"gst-data"} />} />
            <Route
              path="/dashboard/loans/gst-data"
              exact
              render={(props) => (
                <GSTData {...props} nextScreen={"gst-username"} applicationNumber={applicationNumber} />
              )}
            />
            <Route
              path="/dashboard/loans/gst-username"
              exact
              render={(props) => <GSTUsername {...props} nextScreen={"gst-otp"} notSuccessNextScreen={"kyc"} />}
            />
            <Route path="/dashboard/loans/gst-otp" exact render={(props) => <GSTOTP {...props} nextScreen={"kyc"} />} />
            <Route path="/dashboard/loans/kyc" exact render={(props) => <KYC {...props} nextScreen={"banking"} />} />
            <Route
              path="/dashboard/loans/banking"
              exact
              render={(props) => <Banking {...props} nextScreen={"document-upload"} />}
            />
            <Route
              path="/dashboard/loans/document-upload"
              exact
              render={(props) => <DocumentUpload {...props} nextScreen={"preview"} />}
            />
            <Route
              path="/dashboard/loans/preview"
              exact
              render={(props) => <LoanApplicationPreview {...props} nextScreen={"/dashboard/loans/home"} />}
            />
          </Switch>
        </div>
      </>
    );
  }
  componentDidMount() {
    let { actions } = this.props,
      { setGstAddressInNewAddress, getTaxPayerData, fetchLoanUse } = actions;
    var gstNumber = getCookie("gstNumber");
    fetchLoanUse();
    getTaxPayerData(gstNumber).then((data) => {
      if (data.data) {
        setGstAddressInNewAddress(data.data.gstData[0].addresses || []);
      }
    });
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchLoanUse, getTaxPayerData, setGstAddressInNewAddress }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanApplication);
