import React, { Component } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import CommonModal from "../../../components/Modal/modal";
import AddressModal from "../UserAddress/addressModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRelationship, getProfileAddressType, deleteKYCAddress, getGender } from "./KYC.actions";
import { FaPencilAlt } from "react-icons/fa";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_ADD_PROFILE } from "../../../utils/APIUrls";
import { loaderModal } from "../../../store/actions";
import { getDMY, goTo } from "../../../utils/utility";

class WithoutAadhar extends Component {
  state = {
    date: new Date(),
    modalIsOpen: false,
    mobile: "",
    isChecked: false,
    KYCAllAddresses: [],
  };
  onChange = (date) => this.setState({ date });
  toggleModal = (type, addressData, addressId) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalType: type, addressId, addressData });
  };
  selectHandler = (e, name) => {
    this.setState({ [name]: e.value });
  };
  inputHandler = (e) => {
    var name = e.target.name,
    value = name === "pan" ? e.target.value.toUpperCase() : e.target.value;
    if (name === "mobile") {
      let regMobile = /^[0-9]*$/;
      if (regMobile.test(value)) {
        this.setState({ [name]: value });
      }
    } else if (name === "pan" && value.length === 10) {
      var regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      if (regPan.test(value)) {
        this.setState({ [name]: value });
      } else {
        alert("Please enter correct pan");
      }
    } else {
      this.setState({ [name]: value });
    }
  };
  dateHandler = (e) => {
    this.setState({ dateOfBirth: e });
  };
  addProfileHandler = async (e) => {
    e.preventDefault();
    let { dateOfBirth, email, name, pan, gender, mobile, relation, KYCAllAddresses } = this.state;
    let { actions , apiPayLoad } = this.props,
      { loaderModal } = actions;
    let dateOfBirthDMY = getDMY(dateOfBirth);
    var dataToSend = {
      data: {
        address: [...KYCAllAddresses],
        dateOfBirth: dateOfBirthDMY,
        disclaimerText: "Test",
        email,
        gender,
        mobile,
        name,
        pan,
        relation,
        profileType: "Applicant",
      },
    };
    loaderModal(true);
    if (this.state.KYCAllAddresses.length !== 0) {
      await POST(API_POST_ADD_PROFILE(apiPayLoad), dataToSend).then(({ data }) => {
        if (data.status === "success") {
         
          goTo(this.props.nextScreen);
        } else {
          alert(data.message);
        }
      });
    } else {
      window.alert("Please add at least one address ");
    }
    loaderModal(false);
  };
  deleteAddressHandler = () => {
    let { addressId, KYCAllAddresses } = this.state;
    this.setState({
      KYCAllAddresses: KYCAllAddresses.filter((el, index) => {
        return index !== addressId;
      }),
    });
    this.toggleModal("");
  };
  addNewAddress = (data) => {
    this.setState({ KYCAllAddresses: [...this.state.KYCAllAddresses, data] });
  };
  editExistingAddress = (data) => {
    let { addressId } = this.state;
    let KYCAllAddresses = this.state.KYCAllAddresses.slice();
    KYCAllAddresses[addressId] = data;
    this.setState({ KYCAllAddresses });
  };
  render() {
    let { dateOfBirth, email, name, pan, gender, mobile, relation, KYCAllAddresses } = this.state,
      { relationshipData, genderData, profileAddressTypeData } = this.props,
      relationshipList = relationshipData.map((el) => {
        return { value: el, label: el };
      }),
      genderList = genderData.map((el) => {
        return { value: el, label: el };
      });

    return (
      <form onSubmit={this.addProfileHandler}>
        <div className="withoutAadhar">
          <h1>Complete your KYC</h1>

          <div className="row no-gutters">
            <div className="col-md-6 col-sm-6 pr-3">
              <label htmlFor="" className="label-heading">
                Name
              </label>
              <input
                type="text"
                className="input__generic w-100"
                onChange={this.inputHandler}
                name="name"
                value={name}
                required
              />
            </div>
            <div className="col-md-3 col-sm-6  pr-3">
              <label className="label-heading mb-4">Gender</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                options={genderList}
                onChange={(e) => this.selectHandler(e, "gender")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={gender} />
            </div>
            <div className="col-md-3 col-sm-6">
              <label htmlFor="" className="label-heading">
                Date Of Birth
              </label>
              <DatePicker
                onChange={this.dateHandler}
                value={dateOfBirth}
                className="w-100"
                required
                format={"dd/MM/y"}
              />
            </div>
            <div className="col-md-4 col-sm-6  pr-3">
              <label htmlFor="" className="label-heading">
                Mobile Number
              </label>
              <input
                type="text"
                className="input__generic w-100"
                maxLength={10}
                onChange={this.inputHandler}
                name="mobile"
                value={mobile}
                required
              />
            </div>
            <div className="col-md-4 com-sm-6  pr-3">
              <label htmlFor="" className="label-heading">
                Email ID<span className="optional-text__small">(optional)</span>
              </label>
              <input
                type="text"
                className="input__generic w-100"
                onChange={this.inputHandler}
                name="email"
                value={email}
              />
            </div>
            <div className="col-md-4 col-sm-6">
              <label htmlFor="" className="label-heading">
                PAN ID
              </label>
              <input
                type="text"
                className="input__generic w-100"
                onChange={this.inputHandler}
                name="pan"
                value={pan}
                required
                maxLength={10}
              />
            </div>
            <div className="col-md-4 col-sm-6  pr-3">
              <label htmlFor="" className="label-heading mb-4">
                Relationship with the Entity
              </label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={relationshipList}
                onChange={(e) => this.selectHandler(e, "relation")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={relation} />
            </div>
            <div className=" ml-3">
              <label htmlFor="" className="label-heading ">
                Address
              </label>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                {KYCAllAddresses.map((el, index) => {
                  return (
                    <div className="available-wrapper" key={index}>
                      <div className="d-flex justify-content-between">
                        <div>Address {index + 1}</div>
                        <FaPencilAlt
                          onClick={() => this.toggleModal("editAddress", el, index)}
                          className="cursor-pointer"
                        />
                      </div>
                      <div className="bold-text" style={{ fontSize: "12px" }}>
                        {el.addressType}({el.ownership})
                      </div>
                      <p className="mt-4">{`${el.line1} ${el.line2} ${el.district} ${el.city}-${el.pinCode}`}</p>
                    </div>
                  );
                })}
                <div className="add-wrapper" onClick={() => this.toggleModal("addAddress")}>
                  +Add Address
                </div>
              </div>
            </div>
            <CommonModal
              isOpen={this.state.modalIsOpen}
              props={this.props}
              type={this.state.modalType}
              addressData={this.state.addressData || []}
              deleteAddressHandler={this.deleteAddressHandler}
              title={"Address"}
              closeAction={() => this.toggleModal("")}
              RenderingComponent={AddressModal}
              addNewAddress={(data) => this.addNewAddress(data)}
              editExistingAddress={(data) => this.editExistingAddress(data)}
              addressTypeArray={profileAddressTypeData}
              styles={{
                content: {
                  width: "30%",
                  minWidth: "400px",
                },
              }}
            />
          </div>
          <button className="button__generic">Continue</button>
        </div>
      </form>
    );
  }
  componentDidMount = () => {
    let { getRelationship, getProfileAddressType, getGender } = this.props.actions,
      constitution = sessionStorage.getItem("constitution");
    getRelationship(constitution);
    getProfileAddressType();
    getGender();
  };
}
const mapStateToProps = ({ KYCReducer, homePageReducers }) => ({
  relationshipData: KYCReducer.relationshipData,
  profileAddressTypeData: KYCReducer.profileAddressTypeData,
  KYCAllAddresses: KYCReducer.KYCAllAddresses,
  genderData: KYCReducer.genderData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getRelationship, getProfileAddressType, deleteKYCAddress, loaderModal, getGender },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithoutAadhar);
