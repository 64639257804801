import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Icon from "react-icons-kit";
import { calendar } from "react-icons-kit/fa/calendar";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { cross } from "react-icons-kit/metrize/cross";
import ReactTable from "../../../components/React-Table/ReactTable";
import Modal from "react-modal";
import { connect } from "react-redux";
import { changeValue, getSearchByFilters, setSearchFilterStatus, resetData } from "./dashboard.action";
import { updateFilterHandler } from "../../../components/CreditHeader/header.action";
import { getDateString, getYMD, getDMY, rupeeCommaFormatter } from "../../../utils/utility";
import { Helmet } from "react-helmet";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import Select from "react-select";
import { loaderModal, checkUserLogin } from "../../../store/actions";
import { fetchDashBoardData } from "./dashboard.action";
import { search } from "react-icons-kit/fa/search";

class Dashboard extends Component {
  changeUnderwriterStatus = i => {
    let list = this.state.underwritersList;
    list[i].selected = !list[i].selected;
    this.setState({ underwritersList: list });
  };
  underwritersModal = status => {
    this.setState({
      modalVisible: status
    });
  };

  handleEvent = (event, picker) => {
    const { actions } = this.props;
    let startdate = new Date(picker.startDate);
    let enddate = new Date(picker.endDate);
    startdate = getDateString(startdate);
    enddate = getDateString(enddate);
    actions.changeValue("startDate", getYMD(new Date(startdate).toLocaleDateString()));
    actions.changeValue("endDate", getYMD(new Date(enddate).toLocaleDateString()));
    actions.changeValue("activeTabApplicant", "All");
    this.setState({ isDateApplied: true });
  };

  applicantTabToggle = async item => {
    this.setState({ currentPage: 1, statusFilter: item });
    const { activeTabApplicant, actions, dashboardData } = this.props,
      { param } = dashboardData.request,
      { key, startDate, endDate } = param || [];
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: 1,
        param: {
          key,
          startDate,
          endDate
        },
        statusFilter: item === "" ? null : item,
        type: "ALL"
      }
    };
    if (activeTabApplicant !== item) {
      actions.changeValue("activeTabApplicant", item);
      await this.props.actions.fetchDashBoardData({ ...dataToSend });
    }
  };

  getPageData = type => {
    let { currentPage } = this.state;
    currentPage = type === "next" ? currentPage + 1 : currentPage - 1;
    this.setState({ currentPage });
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: currentPage,
        statusFilter: get(this, "props.activeTabApplicant", ""),
        type: "ALL"
      }
    };
    this.props.actions.fetchDashBoardData({ ...dataToSend });
  };

  getAllApplications = async type => {
    let { searchByFilters, actions } = this.props;
    let { setSearchFilterStatus } = actions;
    !searchByFilters[type] && (await setSearchFilterStatus(type));
  };

  rowClicked = rowValue => {
    document.cookie = `applicationNumber=${rowValue.caseId}; path=/Credit`;
    var win = window.open(`${rowValue.caseId}/overview`, "_blank");
    win.focus();
  };

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      currentPage: 1,
      maxDate: new Date(),
      statusFilter: "all"
    };
    props.actions.changeValue("activeTabApplicant", "");
  }

  componentWillMount() {
    window.scroll(0, 0);
  }

  async componentDidMount() {
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: 1,
        type: "ALL"
      }
    };
    this.props.actions.getSearchByFilters();
    await this.props.actions.fetchDashBoardData({ ...dataToSend });
    this.setState({ dashboardDataBackup: this.props.dashboardData });
  }
  searchByFiltersHandler = () => {
    let { searchFilter, searchInput, endDate, startDate, actions } = this.props;
    if (!this.state.isDateApplied) {
      endDate = startDate = null;
    }
    if (!searchInput) {
      searchInput = null;
    }
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: 1,
        param: {
          key: searchInput,
          startDate: startDate,
          endDate: endDate
        },
        statusFilter: this.state.statusFilter === "all" ? null : this.state.statusFilter || null,
        type: searchFilter.value || "ALL"
      }
    };
    actions.fetchDashBoardData({ ...dataToSend });
  };
  clearFilterHandler = () => {
    this.props.actions.resetData(this.state.dashboardDataBackup);
  };

  render() {
    let { modalVisible } = this.state;
    const { applicationLoader, endDate, startDate, dashboardData } = this.props,
      data = get(dashboardData, "searchResponse") || [],
      tabsData = get(dashboardData, "uw") || [],
      activeTabApplicant = get(this, "props.activeTabApplicant", "");
    const dataTableHeaders = [
      {
        Header: "Date",
        accessor: "",
        Cell: ({ row }) => {
          let { original } = row;
          return getDMY(new Date(original.date));
        }
      },
      {
        Header: "Application Id",
        accessor: "caseId"
      },
      {
        Header: "Application Name",
        accessor: "applicationName",
        width: 150
      },
      {
        Header: "Primary Industry",
        accessor: "primaryIndustry"
      },
      {
        Header: "PA (CIBIL Score)",
        accessor: "cibilScore"
      },
      {
        Header: "CC Score",
        accessor: "ccScore"
      },
      {
        Header: "Turnover",
        accessor: "turnOver",
        Cell: ({cell}) => {
          return rupeeCommaFormatter(cell.value);
        }
      },
      {
        Header: "Loan Amount",
        accessor: "loanAmount",
        Cell: ({cell}) => {
          return rupeeCommaFormatter(cell.value);
        }
      },
      {
        Header: "Status",
        accessor: "subStatus"
      },
      {
        Header: "Underwriter",
        accessor: "underWriter"
      }
    ];

    let userList = [];
    if (this.props.userList) {
      userList = this.props.userList.map((el, index) => {
        const name = el.firstName + " " + el.middleName + " " + el.lastName;
        return {
          value: el.userId,
          label: name
        };
      });
    }
    return (
      <div className={"dashboard-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard</title>
        </Helmet>
        <LoaderModal status={applicationLoader} />
        <Modal
          parentSelector={() => document.querySelector(".dashboard-wrapper")}
          isOpen={modalVisible}
          onRequestClose={null}
          className={"underwriters-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4>Please select the name of underwriter for case allocation. </h4>
            <span onClick={() => this.underwritersModal(false)} className={"cross-icon"}>
              <Icon icon={cross} size={22} />
            </span>
          </div>
          <div className={"underwriters-box"}>
            <h3>Underwriter</h3>
            <Select options={userList} onChange={e => this.setState({ selectedUser: e.value })} />
          </div>
          <div className={"text-center"}>
            <button type={"button"} onClick={this.assignUserHandler}>
              Done
            </button>
          </div>
        </Modal>
        <div className={"daterange-bar-wrapper"}>
          <div className={"container"}>
            <div className={"daterange-box"}>
              <span>Search by Date</span>
              <DateRangePicker onApply={this.handleEvent} showDropdowns maxDate={this.state.maxDate}>
                <button>
                  <span className={"icons"}>
                    <Icon icon={calendar} size={16} />
                  </span>
                  <span>
                    {getDateString(startDate)} - {getDateString(endDate)}
                  </span>
                  <span className={"icons"}>
                    <Icon icon={caretDown} size={16} />
                  </span>
                </button>
              </DateRangePicker>
              <span className={"search-icon"} onClick={this.searchByFiltersHandler}>
                <Icon icon={search} size={16} />
              </span>
              <div className="clear-filter" onClick={this.clearFilterHandler}>
                Clear All
              </div>
            </div>
          </div>
        </div>
        <div className={"container"}>
          <div className={"applicants-list-wrapper"}>
            <ul className={"list-unstyled"}>
              <li onClick={() => this.applicantTabToggle("")} className={activeTabApplicant === "" ? "active" : null}>
                <span>{get(tabsData, "all", 0)}</span>
                <span>All Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Approved")}
                className={activeTabApplicant === "Approved" ? "active" : null}
              >
                <span>{get(tabsData, "approved", 0)}</span>
                <span>Approved Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Pending")}
                className={activeTabApplicant === "Pending" ? "active" : null}
              >
                <span>{get(tabsData, "pending", 0)}</span>
                <span>Pending Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Overdue")}
                className={activeTabApplicant === "Overdue" ? "active" : null}
              >
                <span>{get(tabsData, "overdue", 0)}</span>
                <span>Overdue Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Rejected")}
                className={activeTabApplicant === "Rejected" ? "active" : null}
              >
                <span>{get(tabsData, "rejected", 0)}</span>
                <span>Rejected Applications</span>
              </li>
            </ul>
          </div>
          <div className={"datatable-wrapper container"}>
            <ReactTable
              className={!data.length ? "no-data no-pagination" : null}
              data={data}
              columns={dataTableHeaders}
              defaultPageSize={10}
              showPagination={false}
              resizable={false}
              minRows={0}
              getTrProps={this.rowClicked}
            />
            <ul className={"datatable-pagination"}>
              <li>
                <button
                  disabled={this.state.currentPage < 2}
                  className={"prev-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("prev")}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {this.state.currentPage} of {get(dashboardData, "totalPage", 1)}
                </span>
              </li>
              <li>
                <button
                  disabled={get(dashboardData, "totalPage", 1) === this.state.currentPage}
                  className={"next-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("next")}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditDashboard, CreditHeaderReducer }) => ({
  applicationLoader: creditDashboard.applicationLoader,
  applications: get(creditDashboard, "allApplications"),
  startDate: get(creditDashboard, "form.values.startDate", getYMD(new Date().toLocaleDateString())),
  endDate: get(creditDashboard, "form.values.endDate", getYMD(new Date().toLocaleDateString())),
  activeTabApplicant: get(creditDashboard, "form.values.activeTabApplicant", ""),
  searchByFilters: creditDashboard.searchByFilters,
  userList: creditDashboard.userList,
  assignResponse: creditDashboard.assignResponse,
  dashboardData: creditDashboard.dashboardData,
  searchInput: get(CreditHeaderReducer, "form.values.searchInput", ""),
  searchFilter: get(CreditHeaderReducer, "form.values.searchFilter", "")
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSearchByFilters,
      updateFilterHandler,
      changeValue,
      setSearchFilterStatus,
      loaderModal,
      checkUserLogin,
      fetchDashBoardData,
      resetData
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
