const FETCH_GST_ANNUAL_ANALYSIS = Symbol("FetchGstAnnualAnalysis");
const FETCH_GST_ANNUAL_ANALYSIS_START = Symbol("FetchGstAnnualAnalysisStart");
const FETCH_GST_ANNUAL_ANALYSIS_FINISHED = Symbol("FetchGstAnnualAnalysisFinished");
const FETCH_GST_ANNUAL_ANALYSIS_ERROR = Symbol("FetchGstAnnualAnalysisFinished"),
  GET_GST_ANALYSIS = Symbol("getGstAnalysis");

export {
  FETCH_GST_ANNUAL_ANALYSIS,
  FETCH_GST_ANNUAL_ANALYSIS_FINISHED,
  FETCH_GST_ANNUAL_ANALYSIS_START,
  FETCH_GST_ANNUAL_ANALYSIS_ERROR,
  GET_GST_ANALYSIS
};
