import React from "react";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect } from "react-router-dom";
import SME from "../../StaticPages/SME";
import MicroEnterprises from "../../StaticPages/MicroEnterprises";
import LAP from "../../StaticPages/LAP";
import BalanceTransfer from "../../StaticPages/BalanceTransfer";
import BankCcLimit from "../../StaticPages/BankCcLimit";
import BankOdLimit from "../../StaticPages/BankOdLimit";
import BankTermLoan from "../../StaticPages/BankTermLoan";
import BillInvoiceDiscounting from "../../StaticPages/BillInvoiceDiscounting";
import DealerPurchaseFinance from "../../StaticPages/DealerPurchaseFinance";
import { Component } from "react";
export const hist = createBrowserHistory();

export default class Tools extends Component {
  render() {
    return (
      <>
        <div className="user-details__wrapper">
          <Switch>
            <Route path="/msme/tools/sme" exact component={SME} />
            <Route path="/msme/tools/LAP" exact component={LAP} />
            <Route path="/msme/tools/Balance-Transfer" exact component={BalanceTransfer} />
            <Route path="/msme/tools/Bank-Cc-Limit" exact component={BankCcLimit} />
            <Route path="/msme/tools/Bank-Od-Limit" exact component={BankOdLimit} />
            <Route path="/msme/tools/Bank-Term-Loan" exact component={BankTermLoan} />
            <Route path="/msme/tools/micro-enterprises" exact component={MicroEnterprises} />
            <Route path="/msme/tools/Bill-Invoice-Discounting" exact component={BillInvoiceDiscounting} />
            <Route path="/msme/tools/Dealer-Purchase-Finance" exact component={DealerPurchaseFinance} />
          </Switch>
        </div>
      </>
    );
  }
}
