import React, { Component } from "react";
import { rupeeCommaFormatter } from "../../../utils/utility";

export default class WithSameTenure extends Component {
  render() {
    let {
      pendingEmiInstallments,
      totalFinancialImpact,
      incrementInEmi,
      oldEmiAmount,
      newEmiAmount
    } = this.props.calculatedData;
    return (
      <div className="with-same-tenure__wrapper">
        <div className="f-12">
          New EMI Amount and Net Financial Impact is estimated basis existing loan details and moratorium period
          selected
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div className="black-heading">Increase in EMI :</div>
            <div className="grey__text">Assuming tenure is kept constant</div>
          </div>
          <div className="right-section">
            <div className="blue_value">₹{rupeeCommaFormatter(incrementInEmi)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>Old EMI :</div>
          </div>
          <div className="right-section">
            <div>₹{rupeeCommaFormatter(oldEmiAmount)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>New EMI :</div>
          </div>
          <div className="right-section">
            <div>₹{rupeeCommaFormatter(newEmiAmount)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="left-section">
            <div className="black-heading">Total Financial Impact :</div>
          </div>
          <div className="right-section">
            <div className="blue_value">₹{rupeeCommaFormatter(totalFinancialImpact)}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div className="left-section">
            <div>Pending Installments :</div>
          </div>

          <div className="right-section">
            <div>{pendingEmiInstallments}</div>
          </div>
        </div>
      </div>
    );
  }
}
