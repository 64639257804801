import React from "react";
  import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import HomePage from "./Views/HomePage/homePage";
import BIZ3360 from "./Views/BIZ3360/WithGST/biz360";
import LoanAdvisory from "./Views/LoanAdvisory/loanAdvisory";
import UserName from "./Views/UsernameAndOtp/username";
import OTPShare from "./Views/UsernameAndOtp/otp";
import MainDashboard from "./Views/Dashboard/MainDashboard/mainDashboard";
import LoanApplication from "./Views/LoanApplication/loanApplication";
import CreditDashboard from "./Views/CreditDashboard/CreditDashboard";
import CreditLogin from "./Views/CreditDashboard/CreditLogin/CreditLogin";
import Biz360Report from "./Views/BIZ360Report/Biz360Report";
import ContactUs from './Views/ContactUs/ContactUs'
import Tools from "./Views/Msme/Tools/Tools";
import Dashboard from "./Views/CreditDashboard/Dashboard/Dashboard";
import ComingSoon from "./Views/StaticPages/ComingSoon";
import MoratoriumCalculator from "./Views/Tools/MoratoriumCalculator/MoratoriumCalculator";
import ReactGA from "react-ga";
import CookieConsent from "react-cookie-consent";
import biz360withoutGST from "./Views/BIZ3360/WithoutGST/biz360withoutGST";
import AdsPage from "./Views/AdsLandingPage/AdsPage";
import AdsEndingPage from "./Views/AdsLandingPage/AdsEndingPage";
import LoanApplicationPreview from './Views/ViewsComponents/LoanApplicationPreview/LoanApplicationPreview'
export const hist = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <Router history={hist}>
        <CookieConsent
          location="bottom"
          buttonText="Okay"
          cookieName="cookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#00cdbe", fontSize: "16px", background: "#fff" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/biz360/withGst/:page" exact component={BIZ3360} />
          <Route path="/biz360/withoutGst/:page" exact component={biz360withoutGST} />
          <Route path="/biz360/withGst/Biz360Report" exact component={Biz360Report} />
          <Route path="/biz360/withoutGst/CibilReport" exact component={Biz360Report} />
          <Route path="/loan-application/:page" exact component={LoanApplication} />
          <Route path="/loanProducts" exact component={LoanAdvisory} />
          <Route path="/validateUsername/:uuid" exact component={UserName} />
          <Route path="/validateotp/:uuid" exact component={OTPShare} />
          <Route path="/validateotp-with-username/:uuid" exact component={OTPShare} />
          <Route path="/credit/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/loans/:page" exact component={MainDashboard} />
          <Route path="/dashboard/documents/:page" exact component={MainDashboard} />
          <Route path="/dashboard/loan-advisory/:page" exact component={MainDashboard} />
          <Route path="/dashboard/business/:page" component={MainDashboard} />
          <Route path="/credit/:appNo/:page" exact component={CreditDashboard} />
          <Route path="/credit/login" exact component={CreditLogin} />
          {/* <Route path="/dashboard/:page" exact component={MainDashboard} /> */}
          <Route path="/msme/tools/:page" exact component={Tools} />
          <Route path="/calculator/moratorium" component={MoratoriumCalculator} />
          <Route path="calculator/moratorium?ads=true&time=8000" component={MoratoriumCalculator} />
          <Route path="/impact" exact component={ComingSoon} />
          <Route path="/approach" exact component={ComingSoon} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/products" exact component={ComingSoon} />
          <Route path="/get-loan-recommendation" exact component={AdsPage} />
          <Route path="/thank-you" exact component={AdsEndingPage} />
        </Switch>
      </Router>
    );
  }
  // componentDidMount = () => {
  //   ReactGA.initialize("UA-174923583-1");
  //   ReactGA.pageview(window.location.pathname);
  // };
}

export default App;
