import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Overview from "./Overview/Overview";
import CreditNavs from "../../components/CreditNavs/CreditNavs";
import { get } from "lodash";
import BusinessRisk from "./BusinessRisk/BusinessRisk";
import CashFlow from "./CashFlow/CashFlow";
import CreditBehaviour from "./CreditBehaviour/CreditBehaviour";
import Flag from "./Flag/Flag";
import CreditCapacity from "./CreditCapacity/CreditCapacity";
import FinalDecision from "./FinalDecision/FinalDecision";
import Header from "../../components/CreditHeader/Header";
class CreditDashboard extends Component {
  render() {
    const arrPath = get(this, "props.location.pathname", "").split("/");
    const activeTab = arrPath.length ? arrPath[arrPath.length - 1] : "overview";
    return (
      <>
        {/* <Header {...this.props} /> */}
        <CreditNavs active={activeTab} {...this.props} />
        <Switch>
          <Route path="/credit/:appNo/overview" exact component={Overview} />
          <Route path="/credit/:appNo/business_risk" exact component={BusinessRisk} />
          <Route path="/credit/:appNo/cash_flow" exact component={CashFlow} />
          <Route path="/credit/:appNo/credit_behaviour" exact component={CreditBehaviour} />
          <Route path="/credit/:appNo/flag" exact component={Flag} />
          <Route path="/credit/:appNo/credit_capacity" exact component={CreditCapacity} />
          <Route path="/credit/:appNo/final_decision" exact component={FinalDecision} />
          <Redirect from={"/credit/:appNo"} exact to={`/Sales/:appNo/overview`} />
        </Switch>
      </>
    );
  }
}
const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModalStatus,
  userDetails: data.userDetails
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreditDashboard);
