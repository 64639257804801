import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import GSTDataComponent from "../../../ViewsComponents/GSTData/GSTData";
import Header from "../../../ViewsComponents/Header/Header";
export default class GSTData extends Component {
  render() {
    return (
      <div className="gst-data__wrapper">
        <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "Accurate and complete business information will help us evaluate your case faster in one go!",
                "Remember to add all the addresses from where you operate your business",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9">
            <Header navBar_className={"navbar-light"} backButtonText={"Home"} backLink={"/"} {...this.props}/>
            <GSTDataComponent nextScreen={"gst-username"}/>
          </div>
        </div>
      </div>
    );
  }
}
