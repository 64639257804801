import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CheckBox from "../../../components/CheckBox/CheckBox";
import ReactTable from "../../../components/React-Table/ReactTable";
import Icon from "react-icons-kit";
import Slider from "react-rangeslider";
import {
  setHeaderFixed,
  setMaxDate,
  convertNumToStr,
  rupeeCommaFormatter,
  emiCalculator,
  postFoirCalculator,
  deleteCookie
} from "../../../utils/utility";
import { accountColumns as _accountColumns, obligationsColumns as _obligationsColumns } from "./creditCapacity.config";
import Modal from "react-modal";
import { POST } from "../../../utils/WebAPI.service";
import { API_UPDATE_CREDIT_CAPACITY_ANALYSIS } from "../../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  updateInputValue,
  updateModalInputValue,
  getEMIDetails,
  setActiveAccount,
  selectAccountEMI,
  getObligationsData,
  getMarginData,
  selectObligationData,
  setFilteredObligationsData,
  getTurnoverDetails,
  getCreditCapacityDetails,
  resetFormModal,
  updateBureauTradeLine,
  getLoanAmountAndTenure
} from "./creditCapacity.actions";
import { get } from "lodash";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";
import { rupee } from "react-icons-kit/fa/rupee";
import NumberFormat from "react-number-format";
import TradeLineModal from "./tradelineModal";
import { cross } from "react-icons-kit/metrize/cross";
import { fileExcel } from "react-icons-kit/icomoon/fileExcel";
import { checkUserLogin, setUserDetails, setUserLoginStatus, loaderModal } from "../../../store/actions";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
class CreditCapacity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        closedCases: false,
        creditCards: false,
        duplicateCases: false
      },
      foirData: {},
      modalVisible: false,
      maxDate: setMaxDate(),
      annualMargin: 0,
      monthlyMargin: 0,
      revisedPostFoir: 0,
      emi: 0,
      formModalData: []
    };
  }

  setEMIScenarios = async (value, name) => {
    let { actions, form } = this.props;
    let { updateInputValue } = actions;
    this.setState({
      "emi.loanAmount": get(form, "emi.loanAmount", 0),
      "emi.interestRate": get(form, "emi.interestRate", 0),
      "emi.tenure": get(form, "emi.tenure", 0)
    });
    let reg = /^[0-9+.]*$/;
    if (reg.test(value)) {
      await updateInputValue(name, value);
      this.setState({ [name]: value });
    }

    let emi = emiCalculator(this.state["emi.loanAmount"], this.state["emi.interestRate"], this.state["emi.tenure"]);
    this.setState({ emi });
  };
  setEMIScenariosForRate = async (value, name) => {
    let { actions, form } = this.props;
    let { updateInputValue } = actions;
    this.setState({
      "emi.loanAmount": get(form, "emi.loanAmount", 0),
      "emi.interestRate": get(form, "emi.interestRate", 0),
      "emi.tenure": get(form, "emi.tenure", 0)
    });
    let reg = /^[0-9+.]*$/;
    if (reg.test(value)) {
      await updateInputValue(name, value);
      this.setState({ [name]: value });
    }

    let emi = emiCalculator(this.state["emi.loanAmount"], this.state["emi.interestRate"], this.state["emi.tenure"]);
    this.setState({ emi });
  };
  inputHandler = e => {
    let { actions } = this.props;
    let { updateInputValue } = actions;
    let { name, value } = e.target;
    updateInputValue(name, value);
  };

  inputNumberHandler = e => {
    let { actions } = this.props;
    let { updateInputValue } = actions;
    let { value, name } = e.target;
    let reg = /^[0-9+.]*$/;
    if (reg.test(value)) {
      updateInputValue(name, value);
    }
  };
  closeModalHandler = () => {
    this.setState({ addModalVisible: false, editModalVisible: false, obligateModalVisible: false });
  };
  validateForm = data => {
    for (let item in data) {
      if (data.hasOwnProperty(item)) {
        if (typeof data[item] === "object") {
          for (let objItem in data[item]) {
            if (data[item].hasOwnProperty(objItem)) {
              if (data[item][objItem] === "") {
                return false;
              }
            }
          }
        } else {
          if (data[item] === "") {
            return false;
          }
        }
      }
    }
    return true;
  };
  getPostFoir = () => {
    let { foirData } = this.state;
    let { actions, form, match, obligationsData } = this.props;
    let { loaderModal, getCreditCapacityDetails } = actions;
    console.log(form);
    let applicationNumber = get(match, "params.appNo", "");
    const headers = { "Content-Type": "application/json" };
    let data = {
      emi: {
        loanAmount: get(form, "emi.loanAmount", ""),
        tenure: get(form, "emi.tenure", ""),
        interestRate: get(form, "emi.interestRate", "")
      },
      margin: get(form, "margin", ""),
      marginRemark: get(form, "marginRemark", ""),
      sales: get(form, "sales", ""),
      salesRemark: get(form, "salesRemark", ""),
      totalEmiObligated: get(obligationsData, "totalEmiObligated", 0)
    };
    if (this.validateForm(data)) {
      loaderModal(true);
      POST(API_UPDATE_CREDIT_CAPACITY_ANALYSIS(applicationNumber), { data }, { headers })
        .then(({ data }) => {
          window.alert(data.message);
          loaderModal(false);
          if (data.status === "success") {
            getCreditCapacityDetails(applicationNumber)
              .then(({ status, data }) => {
                if (status === "success") {
                  this.setState(prevState => ({
                    foirData: {
                      ...prevState.foirData,
                      preFoir: data.preFoir
                    },
                    annualMargin: data.annualMargin,
                    monthlyMargin: data.monthlyMargin,
                    revisedPostFoir: data.revisedPostFoir
                  }));
                }
              })
              .catch(err => {
                console.log(err);
                window.alert("Something went wrong please try again later.");
              });
          }
        })
        .catch(err => {
          console.log(err);
          window.alert("Something went wrong please try again later.");
        });
    } else {
      window.alert("Please Enter All Required List.");
    }
  };
  setFilters = name => {
    let { filters } = this.state;
    let { setFilteredObligationsData } = this.props.actions;
    let prevValue = filters[name];
    filters[name] = !prevValue;
    this.setState({ filters }, () => setFilteredObligationsData(this.state.filters));
  };

  clearFilters = () => {
    let filters = {};
    let { setFilteredObligationsData } = this.props.actions;
    for (let item in this.state.filters) {
      if (this.state.filters.hasOwnProperty(item)) {
        filters[item] = false;
      }
    }
    this.setState({ filters }, () => setFilteredObligationsData(this.state.filters));
  };

  setModalStatus = (status, name, data) => {
    this.setState({ formModalData: data });
    let { actions } = this.props;
    let { resetFormModal } = actions;
    !status && resetFormModal();
    this.setState({ [name]: status });
  };

  setModalInputValue = data => {
    let { actions } = this.props;
    let { updateModalInputValue } = actions;
    updateModalInputValue("lender", data.lender);
    updateModalInputValue("accountHolderName", data.accountHolderName);
    updateModalInputValue("emiAmount", data.amount);
    updateModalInputValue("reportingDate", data.lastReportingDate);
    this.setModalStatus(true);
  };
  setActiveAccount = item => {
    let { actions, activeAccount } = this.props;
    let { setActiveAccount } = actions;
    let num = Number(item.slice(7)) - 1;
    if (activeAccount !== num) {
      setActiveAccount(num);
    }
  };
  fetchDetails = async () => {
    let { actions, match } = this.props;
    let {
      loaderModal,
      getEMIDetails,
      getObligationsData,
      getMarginData,
      getTurnoverDetails,
      getCreditCapacityDetails,
      getLoanAmountAndTenure
    } = actions;
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      loaderModal(true);
      getEMIDetails(applicationNumber).catch(err => {
        console.log(err);
      });
      getObligationsData(applicationNumber).catch(err => {
        console.log(err);
      });
      getTurnoverDetails(applicationNumber).catch(err => {
        console.log(err);
      });
      getLoanAmountAndTenure(applicationNumber);
      getMarginData(applicationNumber).catch(err => {
        console.log(err);
      });
      await getCreditCapacityDetails(applicationNumber)
        .then(({ status, data }) => {
          if (status === "success") {
            this.setState(prevState => ({
              foirData: {
                ...prevState.foirData,
                preFoir: data.preFoir || 0
              },
              annualMargin: data.annualMargin || 0,
              monthlyMargin: data.monthlyMargin || 0,
              revisedPostFoir: data.revisedPostFoir || 0,
              eligibleLoanAmount: data.eligibleLoanAmount || 0
            }));
          }
        })
        .catch(err => {
          console.log(err);
        });
      loaderModal(false);
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { setUserDetails, setUserLoginStatus, checkUserLogin } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }

  componentDidMount() {
    this.fetchDetails();
    window.addEventListener("scroll", setHeaderFixed);
    let { form } = this.props;
    this.setState({
      emi: emiCalculator(get(form, "emi.loanAmount", 0), get(form, "emi.interestRate", 0), get(form, "emi.tenure", 0))
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }
  setInputRangeValue = (value, name) => {
    this.setState({ [name]: value });
  };
  render() {
    let { filters, editModalVisible, addModalVisible, obligateModalVisible, foirData } = this.state,
      { marginData, filteredObligationsData, form, turnoverDetails, obligationsData, match } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      emiDetails = get(this, "props.emiDetails"),
      totalEmiObligated = get(obligationsData, "totalEmiObligated", 0),
      preFoir = (get(foirData, "preFoir") || 0).toFixed(2),
      totalBankingTurnover = (get(turnoverDetails, "totalBankingTurnover") || 0).toFixed(0),
      imputedGSTTurnover = (get(turnoverDetails, "imputedGSTTurnover") || 0).toFixed(0),
      annualMargin = (get(this, "state.annualMargin") || 0).toFixed(0),
      monthlyMargin = (get(this, "state.monthlyMargin") || 0).toFixed(0),
      marginValue = (get(marginData, "marginValue") || 0).toFixed(2),
      weightedAverageMargin = (get(marginData, "weightedAverageMargin") || 0).toFixed(2);
    const obligationsColumns = [
      {
        Cell: ({ original, index }) => {
          return (
            <div>
              <label>{index + 1}</label>
              <span className="editButton" onClick={() => this.setModalStatus(true, "editModalVisible", original)}>
                Edit
              </span>
            </div>
          );
        },
        Header: "S.No.",
        accessor: ""
      },
      ..._obligationsColumns
    ];
    const accountsColumns = [
      {
        Header: "Select",
        accessor: "",
        Cell: ({ original, index }) => {
          return <div>{index + 1}</div>;
        },
        width: 60,
        sortable: false
      },
      ..._accountColumns,
      {
        Header: "Obligate",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <button onClick={() => this.setModalStatus(true, "obligateModalVisible", original)} type={"button"}>
              Obligate
            </button>
          );
        },
        width: 100,
        sortable: false
      }
    ];
    return (
      <div className={"credit-capacity-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Credit Capacity</title>
        </Helmet>
        <Modal
          isOpen={addModalVisible}
          onRequestClose={null}
          className={"credit-capacity-modal"}
          overlayClassName={"content-modal-overlay"}
          parentSelector={() => document.querySelector(".credit-capacity-wrapper")}
        >
          <div className={"box-header"}>
            <h3>Add New Trade Line</h3>
            <span onClick={() => this.setModalStatus(false, "addModalVisible")} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <TradeLineModal
            isEditable={{}}
            formModalData={[]}
            {...this.props}
            closeModalHandler={this.closeModalHandler}
          />
        </Modal>
        <Modal
          isOpen={editModalVisible}
          onRequestClose={null}
          className={"credit-capacity-modal"}
          overlayClassName={"content-modal-overlay"}
          parentSelector={() => document.querySelector(".credit-capacity-wrapper")}
        >
          <div className={"box-header"}>
            <h3>
              Edit Trade Line Details for {this.state.formModalData ? this.state.formModalData.accountHolderName : ""}
            </h3>
            <span onClick={() => this.setModalStatus(false, "editModalVisible")} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <TradeLineModal
            isEditable={{
              ownershipType: true,
              type: true,
              accountHolderName: true,
              loanAmount: true,
              currentBalance: true,
              emiAmount: true,
              openingDate: true,
              closingDate: true,
              reportingDate: true,
              monthOnBook: true,
              remark: true,
              duplicate: true,
              closingIn6Months: true
            }}
            formModalData={this.state.formModalData}
            isEdit={true}
            {...this.props}
            closeModalHandler={this.closeModalHandler}
            getCreditCapacityDetails={this.fetchDetails}
          />
        </Modal>
        <Modal
          isOpen={obligateModalVisible}
          onRequestClose={null}
          className={"credit-capacity-modal"}
          overlayClassName={"content-modal-overlay"}
          parentSelector={() => document.querySelector(".credit-capacity-wrapper")}
        >
          <div className={"box-header"}>
            <h3>Obligate for {this.state.formModalData ? this.state.formModalData.accountHolderName : ""}</h3>
            <span onClick={() => this.setModalStatus(false, "obligateModalVisible")} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <TradeLineModal
            isEditable={{
              ownershipType: true,
              type: true,
              accountHolderName: true,
              loanAmount: true,
              currentBalance: true,
              emiAmount: true,
              openingDate: true,
              closingDate: true,
              reportingDate: true,
              monthOnBook: true,
              remark: true,
              duplicate: true,
              closingIn6Months: true
            }}
            formModalData={this.state.formModalData}
            isObligate={true}
            {...this.props}
            closeModalHandler={this.closeModalHandler}
          />
        </Modal>
        <div className={"content-wrapper"}>
          <div className="box-wrapper">
            <div className="box-header">
              <h4>EMI Eligibility</h4>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Sales As Per GST</th>
                  <th>Sales As Per Banking</th>
                  <th>Sales Considered for Eligibility</th>
                  <th>Comments & Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Icon icon={rupee} size={20} />
                    {rupeeCommaFormatter(imputedGSTTurnover)}/-
                  </td>
                  <td>
                    <Icon icon={rupee} size={20} />
                    {rupeeCommaFormatter(totalBankingTurnover)}/-
                  </td>
                  <td>
                    <input
                      name={"sales"}
                      value={get(form, "sales", "")}
                      onChange={this.inputNumberHandler}
                      type={"text"}
                      className={"input-field"}
                      placeholder={"In Rupees"}
                    />
                  </td>
                  <td>
                    <input
                      name={"salesRemark"}
                      value={get(form, "salesRemark", "")}
                      onChange={this.inputHandler}
                      type={"text"}
                      className={"input-field"}
                      placeholder={"Write"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>Weighted Average Margin</th>
                  <th>Operating Margin</th>
                  <th>Margin Considered for Eligibility</th>
                  <th>Comments & Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{weightedAverageMargin ? `${weightedAverageMargin}` : "0"}%</td>
                  <td>{marginValue ? `${marginValue}` : "0"}%</td>
                  <td>
                    <input
                      name={"margin"}
                      value={get(form, "margin", "")}
                      onChange={this.inputNumberHandler}
                      type={"text"}
                      className={"input-field"}
                      placeholder={"In %"}
                    />
                  </td>
                  <td>
                    <input
                      name={"marginRemark"}
                      value={get(form, "marginRemark", "")}
                      onChange={this.inputHandler}
                      type={"text"}
                      className={"input-field"}
                      placeholder={"Write"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th>Annual Income</th>
                  <th>Monthly Income</th>
                  <th>Total Monthly Obligation</th>
                  <th>Pre FOIR</th>
                  <th>Eligible Loan / Limit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Icon icon={rupee} size={20} />
                    {rupeeCommaFormatter(annualMargin)}/-
                  </td>
                  <td>
                    <Icon icon={rupee} size={20} />
                    {rupeeCommaFormatter(monthlyMargin)}/-
                  </td>
                  <td>
                    <Icon icon={rupee} size={20} />
                    {rupeeCommaFormatter(totalEmiObligated ? totalEmiObligated.toFixed(0) : 0)}
                    /-
                  </td>
                  <td>{preFoir >= 0 ? preFoir + "%" : " NA"}</td>
                  <td>{rupeeCommaFormatter(this.state.eligibleLoanAmount)}</td>
                </tr>
              </tbody>
            </table>
            <div className={"btns-box"}>
              <button onClick={this.getPostFoir} type={"button"}>
                Submit
              </button>
            </div>
          </div>
          <div className={"row emi-scenario-box mt-3"}>
            <div className={"col-md-8 offset-md-2"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>EMI Scenarios</h4>
                </div>
                <ul>
                  <li>
                    <label>Loan Amount Considered</label>
                    {/* <Slider
                      min={500000}
                      max={50000000}
                      step={100000}
                      value={get(form, "emi.loanAmount")}
                      handleLabel={convertNumToStr(get(form, "emi.loanAmount"))}
                      onChange={val => this.setEMIScenarios(val, "emi.loanAmount")}
                      tooltip={false}
                    /> */}
                    <InputRange
                      maxValue={50000000}
                      minValue={500000}
                      value={get(form, "emi.loanAmount")}
                      onChange={val => this.setEMIScenarios(val, "emi.loanAmount")}
                      formatLabel={value => {
                        return `Rs.${rupeeCommaFormatter(value)}`;
                      }}
                    />
                    <NumberFormat
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      className="input-field  ml-2"
                      style={{ width: "inherit" }}
                      prefix={"₹"}
                      value={get(form, "emi.loanAmount")}
                      onValueChange={val => this.setEMIScenarios(Number(val.value), "emi.loanAmount")}
                    />
                  </li>
                  <li>
                    <label>Loan Tenure</label>

                    <InputRange
                      minValue={6}
                      maxValue={60}
                      value={get(form, "emi.tenure")}
                      onChange={val => this.setEMIScenarios(val, "emi.tenure")}
                      formatLabel={value => `${value} Months`}
                    />
                    <input
                      value={get(form, "emi.tenure")}
                      className="input-field ml-2"
                      style={{ width: "inherit" }}
                      onChange={val => this.setEMIScenarios(val.target.value, "emi.tenure")}
                    />
                  </li>
                  <li>
                    <label>Rate</label>

                    <InputRange
                      minValue={1}
                      maxValue={100}
                      value={get(form, "emi.interestRate")}
                      onChange={val => this.setEMIScenariosForRate(val, "emi.interestRate")}
                      formatLabel={value => `${value} %`}
                    />
                    <input
                      value={get(form, "emi.interestRate")}
                      className="input-field ml-2"
                      style={{ width: "inherit" }}
                      onChange={val => this.setEMIScenariosForRate(val.target.value, "emi.interestRate")}
                    />
                  </li>
                </ul>

                {
                  <div className="emiValueWrapper">
                    Calculated EMI is :{" "}
                    <span className="emiValueBox">
                      <Icon icon={rupee} size={20} />

                      {rupeeCommaFormatter(this.state.emi)}
                    </span>
                  </div>
                }
                <table>
                  <thead>
                    <tr>
                      <th>Pre Loan Fixed Obligation</th>
                      <th>Post Loan Fixed Obligation</th>
                      <th>Post FOIR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Icon icon={rupee} size={20} />
                        {rupeeCommaFormatter(totalEmiObligated)}/-
                      </td>
                      <td>
                        <Icon icon={rupee} size={20} />
                        {rupeeCommaFormatter(this.state.emi + totalEmiObligated)}
                        /-
                      </td>
                      <td>{postFoirCalculator(preFoir, this.state.emi, monthlyMargin)}%</td>
                    </tr>
                  </tbody>
                </table>
                <div className={"btns-box"}></div>
              </div>
            </div>
          </div>
          <div className={"box-wrapper"}>
            <div className={"row box-header m-0"}>
              <div className={"col-6"}>
                <h4>Current Obligations</h4>
              </div>
              <div className={"col-6 text-right"}>
                <button onClick={() => this.setModalStatus(true, "addModalVisible")} type={"button"}>
                  Add New Trade Line
                </button>
              </div>
            </div>
            <div className={"row filters-box"}>
              <div className={"col-md-10"}>
                <div className={"row"}>
                  <div className={"col-md-2"}>
                    <label>Select Filters</label>
                  </div>
                  <div className={"col-md-10"}>
                    <ul>
                      <li>
                        <CheckBox
                          labelColor={"#a4a1fb"}
                          checkboxColor={"#a4a1fb"}
                          htmlFor={"filter1"}
                          label={"Remove Closed Cases"}
                          checked={filters.closedCases}
                          onChange={() => this.setFilters("closedCases")}
                        />
                      </li>
                      <li>
                        <CheckBox
                          labelColor={"#3670cc"}
                          checkboxColor={"#3670cc"}
                          htmlFor={"filter2"}
                          label={"Remove Credit Card"}
                          checked={filters.creditCards}
                          onChange={() => this.setFilters("creditCards")}
                        />
                      </li>
                      <li>
                        <CheckBox
                          labelColor={"#36ddd1"}
                          checkboxColor={"#36ddd1"}
                          htmlFor={"filter3"}
                          label={"Remove Duplicate Cases"}
                          checked={filters.duplicateCases}
                          onChange={() => this.setFilters("duplicateCases")}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={"col-md-2 text-center"}>
                <button onClick={this.clearFilters} type={"button"}>
                  Clear All Filers
                </button>
                <span className="icon-download" title="Excel Download">
                  <a href={`/Backend/generateLoanDetailsExcel/${applicationNumber}`} download>
                    <Icon icon={fileExcel} size={22} />
                  </a>
                </span>
              </div>
            </div>
            <ReactTable
              className={!filteredObligationsData.length ? "no-data obligations-table" : "obligations-table"}
              data={filteredObligationsData}
              columns={obligationsColumns}
              resizable={false}
              minRows={0}
              pageSizeOptions={[10, 20, 25, 50, 100]}
              defaultPageSize={10}
            />
            <div className={"row obligations-data"}>
              <div className={"col-md-6 offset-md-6 text-right"}>
                <label>Total EMI Obligated</label>
                <span>
                  <Icon icon={rupee} size={20} />
                  {rupeeCommaFormatter(totalEmiObligated)}
                </span>
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-10 col-md-offset-1"}>
              <h4>Reconcile EMIs from Banking</h4>
              {emiDetails ? (
                <Tabs defaultActiveKey="account1" id="accounts-tabs" onSelect={this.setActiveAccount}>
                  {emiDetails.map((item, index) => {
                    return (
                      <Tab key={index} eventKey={`account${index + 1}`} title={`${item.accountNumber}`}>
                        <h4 style={{ color: "#00cdbe" }}>
                          {item.accountHolderName ? item.accountHolderName.trim() : null} -{" "}
                          {item.bankName ? item.bankName.split("_").join(" ") : null}
                        </h4>
                        <ReactTable
                          className={!emiDetails.length ? "no-data accounts-table" : "accounts-table"}
                          data={item.emiDetails}
                          columns={accountsColumns}
                          resizable={false}
                          showPagination={false}
                          minRows={0}
                        />
                      </Tab>
                    );
                  })}
                </Tabs>
              ) : (
                <div style={{ marginTop: "25px" }}>
                  <ContentLoader height={200} type={"facebook"} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditCapacity }) => ({
  form: creditCapacity.form,
  formModal: creditCapacity.formModal,
  emiDetails: creditCapacity.emiDetails,
  activeAccount: creditCapacity.activeAccount,
  obligationsData: creditCapacity.obligationsData,
  filteredObligationsData: creditCapacity.filteredObligationsData,
  mainObligationsData: creditCapacity.mainObligationsData,
  marginData: creditCapacity.marginData,
  turnoverDetails: creditCapacity.turnoverDetails,
  loanAmountTenureData: creditCapacity.loanAmountTenureData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      updateInputValue,
      updateModalInputValue,
      getEMIDetails,
      setActiveAccount,
      selectAccountEMI,
      getObligationsData,
      getMarginData,
      selectObligationData,
      setFilteredObligationsData,
      getTurnoverDetails,
      getCreditCapacityDetails,
      resetFormModal,
      updateBureauTradeLine,
      getLoanAmountAndTenure,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCapacity);
