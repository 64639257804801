import * as ACTION_CONSTANT from './header.action.constant';

/**
 * @param name: field Name
 * @param val: field Value
 * @returns {{type: string, data: *, name: string}}
 */
const changeValue = (name, val) => {
    return { type: ACTION_CONSTANT.CHANGE_VALUE, data: val, name: name };
};

/**
 * Attach Handler for Filter in Input and select boxes
 * @param callback
 * @returns {{type, callback}}
 */
const updateFilterHandler = callback => {
    return { type: ACTION_CONSTANT.ATTACH_FILTER_HANDLER, callback };
};

export {
    changeValue,
    updateFilterHandler
}