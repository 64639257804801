// import { get } from 'lodash';
import * as ACTION_TYPE from "./businessRisk.action.constant";
import { GET } from "../../../utils/WebAPI.service";
import { API_FETCH_GST_ANNUAL_ANALYSIS, API_GET_GST_ANALYSIS } from "../../../utils/APIUrls";
const getGstAnalysis = appNo => dispatch => {
  return GET(API_GET_GST_ANALYSIS(appNo))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ACTION_TYPE.GET_GST_ANALYSIS, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getGSTINAnnualAnalysis = (appNo, gstin) => dispatch => {
  return GET(API_FETCH_GST_ANNUAL_ANALYSIS(appNo, gstin))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ACTION_TYPE.FETCH_GST_ANNUAL_ANALYSIS, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
export { getGSTINAnnualAnalysis, getGstAnalysis };
