import React, { Component } from "react";
import ComparedOffers1 from "../../../assets/Images/HomePage/ComparedOffers1.png";
import CreditAdvisory1 from "../../../assets/Images/HomePage/CreditAdvisory1.png";
import DigitalExperience1 from "../../../assets/Images/HomePage/DigitalExperience1.png";
import FastProcess1 from "../../../assets/Images/HomePage/FastProcess1.png";
import NoSpamCalls1 from "../../../assets/Images/HomePage/NoSpamCalls1.png";
import Solutions1 from "../../../assets/Images/HomePage/Solutions1.png";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_ADD_WEBSITE_USER_DETAILS } from "../../../utils/APIUrls";
import Achievement1 from "../../../assets/Images/HomePage/Achievement1.png";
import Achievement2 from "../../../assets/Images/HomePage/Achievement2.png";
import Achievement3 from "../../../assets/Images/HomePage/Achievement3.png";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default class AimToHelpYou extends Component {
  state = {
    mobileNumber: "",
    msgStatus: false,
    backendMsg: false,
  };

  inputHandler = (e) => {
    var value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(value)) {
      this.setState({ mobileNumber: value });
    }
  };
  assistHandler = () => {
    var dataToSend = {
      mobileNumber: this.state.mobileNumber,
    };
    POST(API_POST_ADD_WEBSITE_USER_DETAILS, dataToSend).then(({ data }) => {
      this.setState({
        msgStatus: true,
        backendMsg: false,
        message: data.message,
      });
      if (data) {
        data.status === "success" && this.setState({ backendMsg: true });
      }
    });
  };
  render() {
    return (
      <>
        <div className="aim__wrapper">
          <h2>How we aim to help you</h2>
          <p className="montserrat">Curated, Hasslefree and Data Driven</p>
          <div className="skewed-box">
            <div className="content">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={DigitalExperience1} alt="" />
                    <div className="heading">Completely digital experience</div>
                    <p className="montserrat">
                      Hassle-free and paperless process as our technology does
                      the heavy lifting.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={Solutions1} alt="" />
                    <div className="heading">
                      Cash flow based credit solutions
                    </div>
                    <p className="montserrat">
                      Data driven recommendations for loans and working capital
                      management solutions
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={NoSpamCalls1} alt="" />
                    <div className="heading">No generic and spam calls</div>
                    <p className="montserrat">Business data based credit recommendations.</p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={CreditAdvisory1} alt="" />
                    <div className="heading">Free digital credit advisory</div>
                    <p className="montserrat">
                      Independent suggestions to optimize interest rate and
                      terms
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={ComparedOffers1} alt="" />
                    <div className="heading">Digitally compare offers</div>
                    <p className="montserrat">
                      Compare offers from all leading lenders and apply to
                      multiple lenders in one go
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="card-aim__wrapper">
                    <img src={FastProcess1} alt="" />
                    <div className="heading">Transparent and Fast process</div>
                    <p className="montserrat">
                      Complete visibility of loan application process to reduce
                      Turnaround time
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="assist__wrapper">
          <div className="col-md-5 col-sm-12">
            <div className="heading">Always available to assist you</div>
            <p className="montserrat">
              Our team is here to guide you through the process of loan
              application anytime, anywhere.
            </p>
            <input
              type="text"
              className="input-field__assist"
              placeholder="Enter your number to get personal assistance"
              onChange={this.inputHandler}
              value={this.state.mobileNumber}
              maxLength={10}
            />
            <button className="button__assist" onClick={this.assistHandler}>
              Confirm
            </button>
            {this.state.msgStatus ? (
              this.state.backendMsg ? (
                <p>Your response has been recorded</p>
              ) : (
                <p>{this.state.message}</p>
              )
            ) : null}
          </div>
          <div className="col-md-7 col-sm-12 p-0">
            <div className="row achievement heading">Selected At :</div>
            <div className="row achievement">
            <div className="achievement-box">
                <img className="shields" src={Achievement1} alt="Achievements" />
              </div><div className="achievement-box">
                <img className="shields" src={Achievement2} alt="Achievements" />
              </div><div className="achievement-box">
                <img className="shields" src={Achievement3} alt="Achievements" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
