import * as BusinessConstants from "./Business.actions.constants";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_ALL_BUSINESS } from "../../../utils/APIUrls";

const fetchAllBusiness = () => dispatch => {
  return GET(API_GET_ALL_BUSINESS).then(({ data }) => {
    if (data.data) {
      dispatch({ type: BusinessConstants.FETCH_ALL_BUSINESS, data: data.data });
    }
    return data;
  });
};
export { fetchAllBusiness };
