import { API_GET_PROFILE } from "../../../utils/APIUrls";
import { GET } from "../../../utils/WebAPI.service";
import * as ReportDownloadConstants from "./ReportDownload.actions.constants";
const getProfile = () => {
  return (dispatch) => {
    return GET(API_GET_PROFILE).then(({ data }) => {
      if (data.data) {
        dispatch({ type: ReportDownloadConstants.GET_PROFILE, data: data.data });
      }
      return data;
    });
  };
};
export { getProfile };

