import * as Biz360ReportConstants from "./Biz360Report.actions.constants";

const initialState = {
  gstTrendAnalysisData: [],
  concentrationRiskDetailsData: [],
  turnoverAndPurchaseDetailsData: [],
  creditHistoryDetailsData: [],
  allApplicantSummaryData: [],
  currentObligationsData: [],
  productsForBizReportData: [],
  entityDetailsData: [],
  businessCommentsForReportData: []
};

export const Biz360ReportReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case Biz360ReportConstants.GET_GST_TREND_DETAILS:
      return {
        ...state,
        gstTrendAnalysisData: data
      };
    case Biz360ReportConstants.GET_CONCENTRATION_RISK_DETAILS:
      return {
        ...state,
        concentrationRiskDetailsData: data
      };
    case Biz360ReportConstants.GET_TURNOVER_AND_PURCHASES_DETAILS:
      return {
        ...state,
        turnoverAndPurchaseDetailsData: data
      };
    case Biz360ReportConstants.GET_CREDIT_HISTORY_DETAILS:
      return {
        ...state,
        creditHistoryDetailsData: data
      };
    case Biz360ReportConstants.GET_ALL_APPLICANT_SUMMARY:
      return {
        ...state,
        allApplicantSummaryData: data
      };
    case Biz360ReportConstants.GET_CURRENT_OBLIGATION:
      return {
        ...state,
        currentObligationsData: data
      };
    case Biz360ReportConstants.GET_ENTITY_DETAILS:
      return {
        ...state,
        entityDetailsData: data
      };
    case Biz360ReportConstants.GET_FIND_PRODUCTS_FOR_BIZ_REPORT:
      return {
        ...state,
        productsForBizReportData: data
      };
    case Biz360ReportConstants.GET_BUSINESS_COMMENTS_FOR_REPORT:
      return {
        ...state,
        businessCommentsForReportData: data
      };
    default:
      return state;
  }
};
