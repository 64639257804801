import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import WhiteLogo1 from "../../../assets/Images/WhiteLogo1.png";
import { FaChevronLeft } from "react-icons/fa";
import { goTo } from "../../../utils/utility";
export default class Header extends Component {
  sigInHandler = () => {};
  render() {
    let { isLogoPresent, navBar_className, backButtonText } = this.props;
    return (
      <div className="header__container">
        <Navbar expand="lg" className={navBar_className}>
          {isLogoPresent ? (
            <Navbar.Brand href="#home">
              <img src={WhiteLogo1} width="85%" alt="Credochain Logo" />
            </Navbar.Brand>
          ) : null}
          {backButtonText ? (
            <div className="back-button__container" onClick={()=>this.props.history.goBack()}>
              <FaChevronLeft className="mr-3" size={20} />
              {backButtonText}
            </div>
          ) : null}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link href="http://platform.credochain.com" target="_blank">
                Platform
              </Nav.Link>
              <Nav.Link className="menubar-item nav-link" href="#ourApproach">
                Approach
              </Nav.Link>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  MSME Loans
                </DropdownToggle>
                <DropdownMenu className="MSMELoan_dropdown" right>
                  <div className="d-flex">
                    <div className="horizontal-line">
                      <DropdownItem href="../../msme/tools/LAP" className="MSMELoan_dropdownItems">
                        Loan Against Property (LAP)
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/SME" className="MSMELoan_dropdownItems">
                        Unsecured Business Loan - SME
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/micro-enterprises" className="MSMELoan_dropdownItems">
                        Unsecured Business Loan - Micro
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Balance-Transfer" className="MSMELoan_dropdownItems">
                        Balance Transfer(LAP)
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bank-Od-Limit" className="MSMELoan_dropdownItems">
                        Bank OD Limit{" "}
                      </DropdownItem>
                    </div>
                    <div>
                      <DropdownItem href="../../msme/tools/Bank-Term-Loan" className="MSMELoan_dropdownItems">
                        Bank/NBFC Term Loan
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bank-Cc-Limit" className="MSMELoan_dropdownItems">
                        Bank CC Limit{" "}
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Bill-Invoice-Discounting" className="MSMELoan_dropdownItems">
                        Bill/Invoice Discounting
                      </DropdownItem>
                      <DropdownItem href="../../msme/tools/Dealer-Purchase-Finance" className="MSMELoan_dropdownItems">
                        Dealer/purchase Finance
                      </DropdownItem>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Nav.Link as={Link} to="/impact">
                Impact
              </Nav.Link>
              <Nav.Link onClick={this.sigInHandler} className="signin-btn">
                Sign in
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
