import React, { Component } from "react";
import { API_ADD_REFERENCES } from "../../../utils/APIUrls";
import get from "lodash/get";
import  InputLabelAnimation  from "../../../components/InputLabelAnimation/InputLabelAnimation";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Select from "react-select";
import { POST, GET } from "../../../utils/WebAPI.service";
import { changeInputValue } from "./finalDecision.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loaderModal } from "../../../store/actions";

class EditRatioForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chequeBounces: 0,
      totalChequesIssued: 0,
      status: "Inward",
      bounceRatio: 0,
      pinCodes: [],
      pinCodeLoader: false,
      index: null
    };
    this.searchPinCode = null;
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  bounceRatioFun = br => {
    this.setState({
      bounceRatio: br
    });
    this.props.sendData(br);
  };

  submit = event => {
    event.preventDefault();
    this.props.closeAction();
  };

  setPinCode = e => {
    let { actions } = this.props,
      { index } = this.state,
      { changeInputValue } = actions;
    changeInputValue(e.value, "address.pinCode", index);
    changeInputValue(e.district, "address.district", index);
    changeInputValue(e.city, "address.city", index);
    changeInputValue(e.state, "address.state", index);
  };

  setInputPinCode = e => {
    clearTimeout(this.searchPinCode);
    this.searchPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            pinCodeLoader: true
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  pinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    pinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      state: list[i].state,
                      area: list[i].area,
                      district: list[i].district
                    });
                  }
                }

                this.setState({
                  pinCodeLoader: false,
                  pinCodes
                });
              } else {
                this.setState({ pinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };

  inputHandler = e => {
    let { actions } = this.props,
      { index } = this.state,
      { changeInputValue } = actions,
      value = e.target.value,
      name = e.target.name;
    changeInputValue(value, name, index);
  };

  inputNumberHandler = e => {
    let { actions } = this.props,
      { index } = this.state,
      { changeInputValue } = actions,
      value = e.target.value,
      name = e.target.name,
      reg = /^[0-9]*$/;
    if (reg.test(value)) {
      changeInputValue(value, name, index);
    }
  };

  selectHandler = (i, name) => {
    let { actions, referenceRelations } = this.props,
      { index } = this.state,
      { changeInputValue } = actions,
      value = "";
    switch (name) {
      // case 'address.ownership':
      //     value = residenceStatus[i - 1];
      //     break;
      case "relation":
        value = referenceRelations[i - 1];
        break;
      default:
        value = "";
    }
    changeInputValue(value, name, index);
  };

  componentWillMount() {
    const { referencesList, index } = this.props,
      pinCode = get(referencesList, `[${index}].address.pinCode`);
    pinCode && this.setInputPinCode(pinCode);
  }
  componentDidMount() {
    const { referencesList, index1 } = this.props;
    if (index1 < referencesList.length) {
      this.setState({
        index: index1
      });
    } else {
      this.setState({
        index: referencesList.length
      });
    }
  }

  submitForm = e => {
    e.preventDefault();
    let { referencesList, props } = this.props,
      { loaderModal } = props.actions,
      applicationNumber = get(props.match, "params.appNo", "");
    const headers = { "Content-Type": "application/json" };
    let data = {
      applicationNumber,
      referenceList: referencesList.map(item => ({
        address: {
          addressType: get(item, "address.addressType", ""),
          city: get(item, "address.city", ""),
          district: get(item, "address.district", ""),
          line1: get(item, "address.line1", ""),
          line2: get(item, "address.line2", ""),
          ownership: get(item, "address.ownership", ""),
          pinCode: get(item, "address.pinCode", ""),
          state: get(item, "address.state", "")
        },
        email: get(item, "email", ""),
        id: get(item, "id", ""),
        isVerified: false,
        mobile: get(item, "mobile", ""),
        name: get(item, "name", ""),
        relation: get(item, "relation", "Select Relationship"),
        relationsFrom: "APPLICANT"
      }))
    };
    loaderModal(true);
    POST(API_ADD_REFERENCES, { data }, { headers })
      .then(({ data }) => {
        window.alert(data.message);
        loaderModal(false);
        if (data.status === "success") {
          this.props.closeAction();
        }
      })
      .catch(err => {
        window.alert("Something went wrong please try again later.");
        loaderModal(false);
        console.log(err);
      });
  };

  render() {
    const { pinCodes, pinCodeLoader, index } = this.state,
      { referencesList, referenceRelations } = this.props,
      pinCode = get(referencesList, `[${index}].address.pinCode`);

    return (
      <form onSubmit={this.submitForm} className="edit-ratio-form1">
        <div className="box-wrapper">
          {/* <div className="box-header"> */}
          <div className={"row"}>
            <div className={"col-md-6 mt-3"}>
              <InputLabelAnimation
                labelText={"Reference Name"}
                name={"name"}
                required={true}
                onChange={this.inputHandler}
                tabIndex={index * 8 + 1}
                inputValue={get(referencesList, `[${index}].name`, "")}
              />
            </div>
            <div className={"col-md-6 mt-3"}>
              <InputLabelAnimation
                labelText={"Mobile Number"}
                name={"mobile"}
                required={true}
                onChange={this.inputNumberHandler}
                tabIndex={index * 8 + 2}
                maxLength={10}
                inputValue={get(referencesList, `[${index}].mobile`, "")}
              />
            </div>
            <div className={"col-md-6 mt-3"}>
              <InputLabelAnimation
                labelText={"Email"}
                name={"email"}
                required={true}
                type={"email"}
                onChange={this.inputHandler}
                tabIndex={index * 8 + 3}
                inputValue={get(referencesList, `[${index}].email`, "")}
              />
            </div>
            <div className={"col-md-6 mt-3"} >
              <DropdownButton
                tabIndex={index * 8 + 4}
                onSelect={i => this.selectHandler(i, "relation")}
                title={get(
                  referencesList,
                  `[${index}].relation`,
                  "Select Relationship"
                )}
                id={"relation-select"}
                className={"select-box"}
              >
                {referenceRelations.map((item, index) => (
                  <Dropdown.Item title={item} key={index + 1} eventKey={index + 1}>
                    {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <div className={"address-box"}>
            <label
              className={"input-label mt-3 ml-3"}
            >
              Address
            </label>
            <div className={"row"}>
              <div className={"col-md-6 mt-3"}>
                <InputLabelAnimation
                  labelText={"Enter Floor/Building Number"}
                  tabIndex={index * 8 + 5}
                  required={true}
                  name={"address.line1"}
                  onChange={this.inputHandler}
                  inputValue={
                    get(referencesList, `[${index}].address.line1`) || ""
                  }
                />
              </div>
              <div className={"col-md-6 mt-3"}>
                <InputLabelAnimation
                  labelText={"Enter Street/Landmark"}
                  name={"address.line2"}
                  onChange={this.inputHandler}
                  tabIndex={index * 8 + 6}
                  required={true}
                  inputValue={
                    get(referencesList, `[${index}].address.line2`) || ""
                  }
                />
              </div>
              <div className={"col-md-3 mt-3"}>
                <Select
                  tabIndex={index * 8 + 8}
                  value={pinCodes.find(option => option.value === pinCode)}
                  title={"Pin Code"}
                  placeholder={"Pin Code"}
                  isSearchable={true}
                  name="pin_code"
                  options={pinCodes}
                  className="pin-code-select-search"
                  classNamePrefix="select"
                  onInputChange={this.setInputPinCode}
                  onChange={this.setPinCode}
                  isLoading={pinCodeLoader}
                />
              </div>
              <div className={"col-md-3 mt-3"} >
                <InputLabelAnimation
                  labelText={"District"}
                  name={"address.district"}
                  inputValue={get(
                    referencesList,
                    `[${index}].address.district`,
                    ""
                  )}
                  disabled={true}
                  required={true}
                />
              </div>
              <div className={"col-md-3 mt-3"} >
                <InputLabelAnimation
                  labelText={"City"}
                  name={"address.city"}
                  inputValue={
                    get(referencesList, `[${index}].address.city`) || ""
                  }
                  disabled={true}
                  required={true}
                />
              </div>
              <div className={"col-md-3 mt-3"}>
                <InputLabelAnimation
                  labelText={"State"}
                  name={"address.state"}
                  inputValue={
                    get(referencesList, `[${index}].address.state`) || ""
                  }
                  disabled={true}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            className="mt-3 blueButton"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ referencesReducers, listData }) => ({
  referencesList: referencesReducers.referencesList,
  // residenceStatus: listData.residenceStatusList,
  referenceRelations: listData.referenceRelations
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      changeInputValue
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRatioForm);
