import { GET } from "../utils/WebAPI.service";
import { API_GET_USER_DETAILS, API_GET_PINCODES } from "../utils/APIUrls";
export function loaderModal(val) {
  return {
    type: "SET_LOADER_MODAL_STATUS",
    val,
  };
}
export const updateErrorMessage = (error) => ({
  type: "SET_ERROR_MESSAGE",
  error,
});
export function setUserDetails(val) {
  return {
    type: "SET_USER_DETAILS",
    val,
  };
}

export function setAuthenticationStatus(val) {
  return {
    type: "SET_AUTHENTICATION_STATUS",
    val,
  };
}

export function setUserLoginStatus(val) {
  return {
    type: "SET_USER_LOGIN_STATUS",
    val,
  };
}
export function checkUserLogin() {
  return (dispatch) => {
    return GET(API_GET_USER_DETAILS)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch(setUserDetails(data.data));
          dispatch(setUserLoginStatus(true));
        } else {
          dispatch(setUserLoginStatus(false));
        }
        dispatch(setAuthenticationStatus(true));
        return data;
      })
      .catch((error) => {
        return error;
      });
  };
}

export const getPincode = (pincode) => {
  return (dispatch) => {
    return GET(API_GET_PINCODES(pincode))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: "GET_PINCODES", data: data.data });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
