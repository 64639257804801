import * as LoanApplicationPreviewConstant from "./LoanApplicationPreview.actions.constants";
const initialState = {
  previewApplicationData: [],
};

export const LoanApplicationPreviewReducer = (state = initialState, action) => {
  let { type, data, name, value, profileIndex, objectName, addressIndex } = action;

  switch (type) {
    case LoanApplicationPreviewConstant.APPLICATION_PREVIEW:
      return {
        ...state,
        previewApplicationData: data,
      };
    case "UPDATE_PREVIEW_OBJECT":
      console.log(name.split(".")[0])
      if ( name.split(".")[0] === "profileData" && (profileIndex !== null || profileIndex !== undefined)) {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            profileData: state.previewApplicationData.profileData.map((el, index) =>
              index === profileIndex ? { ...el, [name.split(".")[1]]: value } : el
            ),
          },
        };
      } else {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            [name.split(".")[0]]: { ...state.previewApplicationData[name.split(".")[0]], [name.split(".")[1]]: value },
          },
        };
      }
    case "UPDATE_PREVIEW_OBJECT_ADDRESS":
      if (objectName === "profileData") {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            profileData: state.previewApplicationData.profileData.map((el, index) => {
              return index === profileIndex
                ? {
                    ...el,
                    address: el.address.map((el, originalAddressIndex) => {
                      return originalAddressIndex === profileIndex ? value : el;
                    }),
                  }
                : el;
            }),
          },
        };
      } else {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            businessData: {
              ...state.previewApplicationData.businessData,
              gstData: state.previewApplicationData.businessData.gstData.map((el, index) => {
                return index === profileIndex
                  ? {
                      ...el,
                      addresses: el.addresses.map((el, originalAddressIndex) => {
                        return originalAddressIndex === profileIndex ? value : el;
                      }),
                    }
                  : el;
              }),
            },
          },
        };
      }
    case "DELETE_PREVIEW_OBJECT_ADDRESS":
      if (objectName === "profileData") {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            profileData: state.previewApplicationData.profileData.map((el, index) => {
              return index === profileIndex
                ? { ...el, address: el.address.filter((_, index) => index !== addressIndex) }
                : el;
            }),
          },
        };
      } else {
        return {
          ...state,
          previewApplicationData: {
            ...state.previewApplicationData,
            businessData: {
              ...state.previewApplicationData.businessData,
              gstData: state.previewApplicationData.businessData.gstData.map((el, index) => {
                return index === profileIndex
                  ? { ...el, addresses: el.addresses.filter((_, index) => index !== addressIndex) }
                  : el;
              }),
            },
          },
        };
      }
    default:
      return state;
  }
};
