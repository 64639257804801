const CHANGE_FORM_INPUT_VALUE = Symbol('ChangeFormInputValue');
const CHANGE_FORM_MODAL_INPUT_VALUE = Symbol('ChangeFormModalInputValue');
const SET_EMI_DETAILS = Symbol('SetEMIDetails');
const SET_ACTIVE_ACCOUNT = Symbol('SetActiveAccount');
const SELECT_ACCOUNT_EMI = Symbol('SelectAccountEMI');
const SET_OBLIGATIONS_DATA = Symbol('SetObligationsData');
const GET_CURRENT_OBLIGATIONS = Symbol('GetCurrentObligations');
const SET_FILTERED_OBLIGATIONS = Symbol('SetFilteredObligations');
const GET_MARGIN_DATA = Symbol('GetMarginData');
const SELECT_OBLIGATIONS_DATA = Symbol('SelectObligationsData');
const SET_TURNOVER_DETAILS = Symbol('SetTurnoverDetails');
const SET_CREDIT_CAPACITY_DETAILS = Symbol('SetCreditCapacityDetails');
const RESET_FORM_MODAL = Symbol('ResetFormModal');
const GET_LOAN_TENURE_INTEREST=Symbol("LoanTenureInterest")

export {
    CHANGE_FORM_INPUT_VALUE,
    SET_EMI_DETAILS,
    CHANGE_FORM_MODAL_INPUT_VALUE,
    SET_ACTIVE_ACCOUNT,
    SELECT_ACCOUNT_EMI,
    SET_OBLIGATIONS_DATA,
    GET_CURRENT_OBLIGATIONS,
    SET_FILTERED_OBLIGATIONS,
    GET_MARGIN_DATA,
    SELECT_OBLIGATIONS_DATA,
    SET_TURNOVER_DETAILS,
    SET_CREDIT_CAPACITY_DETAILS,
    RESET_FORM_MODAL,
    GET_LOAN_TENURE_INTEREST
}
