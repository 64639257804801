import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { goTo } from "../../utils/utility";

class CreditNavs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: "overview"
    };
  }

  clickHandle = (e, name) => {
    let { match } = this.props;
    const { appNo } = match.params;
    this.setState({ activeLink: name });
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === "overview") {
        window.open(`/Credit/${appNo}/overview`);
      } else if ((e.ctrlKey || e.metaKey) && name === "business_risk") {
        window.open(`/Credit/${appNo}/business_risk`);
      } else if ((e.ctrlKey || e.metaKey) && name === "cash_flow") {
        window.open(`/Credit/${appNo}/cash_flow`);
      } else if ((e.ctrlKey || e.metaKey) && name === "credit_behaviour") {
        window.open(`/Credit/${appNo}/credit_behaviour`);
      } else if ((e.ctrlKey || e.metaKey) && name === "flag") {
        window.open(`/Credit/${appNo}/flag`);
      } else if ((e.ctrlKey || e.metaKey) && name === "credit_capacity") {
        window.open(`/Credit/${appNo}/credit_capacity`);
      } else if ((e.ctrlKey || e.metaKey) && name === "final_decision") {
        window.open(`/Credit/${appNo}/final_decision`);
      }
    } else if (e.button === 0) {
      if (name === "overview") {
        goTo(`/Credit/${appNo}/overview`);
      } else if (name === "business_risk") {
        goTo(`/Credit/${appNo}/business_risk`);
      } else if (name === "cash_flow") {
        goTo(`/Credit/${appNo}/cash_flow`);
      } else if (name === "credit_behaviour") {
        goTo(`/Credit/${appNo}/credit_behaviour`);
      } else if (name === "flag") {
        goTo(`/Credit/${appNo}/flag`);
      } else if (name === "credit_capacity") {
        goTo(`/Credit/${appNo}/credit_capacity`);
      } else if (name === "final_decision") {
        goTo(`/Credit/${appNo}/final_decision`);
      }
    }
  };

  render() {
    let { active } = this.props,
      { activeLink } = this.state;
    return (
      <section className={"credit-navs-wrapper"}>
        <Navbar>
          <Navbar.Collapse>
            <Nav className="credit-navs" activeKey={active}>
              <NavItem
                eventKey="overview"
                className={activeLink === "overview" ? "active" : null}
                onClick={e => this.clickHandle(e, "overview")}
              >
                Overview
              </NavItem>
              <NavItem
                eventKey="business_risk"
                className={activeLink === "business_risk" ? "active" : null}
                onClick={e => this.clickHandle(e, "business_risk")}
              >
                Business Risk
              </NavItem>
              <NavItem
                eventKey="cash_flow"
                className={activeLink === "cash_flow" ? "active" : null}
                onClick={e => this.clickHandle(e, "cash_flow")}
              >
                Cash Flow
              </NavItem>
              <NavItem
                eventKey="credit_behaviour"
                className={activeLink === "credit_behaviour" ? "active" : null}
                onClick={e => this.clickHandle(e, "credit_behaviour")}
              >
                Credit Behaviour
              </NavItem>
              <NavItem
                eventKey="flag"
                className={activeLink === "flag" ? "active" : null}
                onClick={e => this.clickHandle(e, "flag")}
              >
                Flag
              </NavItem>
              <NavItem
                eventKey="credit_capacity"
                className={activeLink === "credit_capacity" ? "active" : null}
                onClick={e => this.clickHandle(e, "credit_capacity")}
              >
                Credit Capacity
              </NavItem>
              <NavItem
                eventKey="final_decision"
                className={activeLink === "final_decision" ? "active" : null}
                onClick={e => this.clickHandle(e, "final_decision")}
              >
                Final Decision
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
    );
  }
  componentDidMount = () => {
    let { url } = this.props.match,
      activeLink = url.split("/")[3];
    this.setState({ activeLink });
  };
}

CreditNavs.defaultProps = {
  active: ""
};

export default CreditNavs;
