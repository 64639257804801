import React, { Component } from "react";
import BlueLogo from "../../../assets/Images/BlueLogo1x.png";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import NumberFormat from "react-number-format";
import WithSameTenure from "./WithSameTenure";
import NotAvailed from "./NotAvailed";
import WithSameEMI from "./WithSameEMI";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import whiteLogo from "../../../assets/Images/whiteLogo.png";
import { Link } from "react-router-dom";
import Axios from "axios";
import { goTo } from "../../../utils/utility";
import { Helmet } from "react-helmet";
import Select from "react-select";
import qs from "qs";

export default class MoratoriumCalculator extends Component {
  state = {
    shouldCalculate: "Loan Amount",
    period: 2,
    loanDetailsTab: "SAME_TENURE",
    minLoan: 50000,
    maxLoan: 50000000,
    loanAmount: 2500000,
    minInterest: 7,
    maxInterest: 30,
    interestRate: 12,
    minTenure: 12,
    maxTenure: 800,
    tenure: 240,
    minPaidDate: 0,
    maxPaidDate: 360,
    paidDate: 25,
    loaderStatus: false,
    ads: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ads,
    time: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).time 

  };
  inputHandler = (value, name) => {
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    if(this.state.ads){
      this.calculateHandler()
      await setTimeout(() => this.props.history.push("/get-loan-recommendation"), this.state.time || 5000)
    } else {
      this.calculateHandler()
    }
  }
 
  calculateHandler = async () => {
    let { loanAmount, interestRate, tenure, period, paidDate, shouldCalculate, loanDetailsTab } = this.state,
      dataToSend = {
        data: {
          loanAmount: shouldCalculate === "Loan Amount" ? loanAmount : 0,
          emi: shouldCalculate === "EMI" ? loanAmount : 0,
          interestRate,
          loanTenure: tenure,
          emiPaidMonthsTillDate: paidDate,
          moratoriumMonths: period,
          moratoriumOption: loanDetailsTab,
        },
      };
    this.setState({ loaderStatus: true });
    await Axios.post("https://credochain-1.appspot.com/v1/emi/calculate/changes", dataToSend)
      .then(({ data }) => {
        data.status === "success" && this.setState({ calculatedData: data.data });
      })
      .catch((err) => {
        this.setState({ loaderStatus: false });
        throw err;
      });
    this.setState({ loaderStatus: false });
    window.scroll(0, document.body.offsetHeight);
  };
  homePageRedirection = () => {
    goTo("/");
  };
  render() {
    let {
        period,
        minLoan,
        maxLoan,
        loanAmount,
        minInterest,
        maxInterest,
        interestRate,
        minTenure,
        maxTenure,
        tenure,
        minPaidDate,
        maxPaidDate,
        paidDate,
        loanDetailsTab,
        calculatedData,
        loaderStatus,
      } = this.state,
      monthsOptions = [1, 2, 3, 4, 5, 6].map((el) => ({ label: el, value: el }));
    return (
      <div className="moratorium-calculator__wrapper">
        <LoaderModal status={loaderStatus} />
        <Helmet>
          <title>Moratorium Calculator</title>
          <meta name="description" content="Moratorium Calculator" />
          <meta
            name="keyword"
            content="Moratorium Calculator,moratorium calculator online,moratorium calculator india,moratorium calculator malaysia,mortgage moratorium calculator,10 year moratorium calculator,moratorium emi calculator,moratorium loan calculator,moratorium period emi calculator,emi calculator with moratorium,moratorium interest calculator,moratorium period calculator "
          />
        </Helmet>
        <header className="header">
          <div className="heading">Moratorium Calculator</div>
          <div className="reference">
            by
            <Link to="/" as={Link}>
              <img src={BlueLogo} alt="Logo" />
            </Link>
            Credochain
          </div>
        </header>
        <main className="main__section">
          <div className="card__wrapper">
            <div className="heading">Enter your Loan Details</div>
            <label htmlFor="" className="label-heading">
              Loan Amount(Rupees)
            </label>
            <div>
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                prefix={"₹"}
                className="input-generic__dotted tooltip-range__input"
                onValueChange={(e) => this.inputHandler(e && e.floatValue, "loanAmount")}
                value={loanAmount}
                maxLength={11}
                style={{ left: (loanAmount * 100) / (maxLoan - minLoan - 2) + "%" }}
              />
              <div className="range__wrapper translate">
                <InputRange
                  maxValue={maxLoan}
                  minValue={minLoan}
                  value={loanAmount}
                  onChange={(value) => this.inputHandler(value, "loanAmount")}
                />
              </div>
            </div>
            <div>
              <label htmlFor="" className="label-heading">
                Interest Rate (p.a)
              </label>
              <br />
              <NumberFormat
                suffix={"%"}
                className="input-generic__dotted tooltip-range__input"
                onValueChange={(e) => this.inputHandler(e && e.floatValue, "interestRate")}
                maxLength={6}
                value={interestRate}
                style={{ left: (interestRate * 100) / (maxInterest - minInterest - 2) + "%" }}
              />
              <div className="range__wrapper">
                <InputRange
                  minValue={minInterest}
                  maxValue={maxInterest}
                  value={interestRate}
                  onChange={(value) => this.inputHandler(value, "interestRate")}
                />
              </div>
            </div>
            <div>
              <label htmlFor="" className="label-heading">
                Loan Tenure(months)
              </label>
              <br />
              <NumberFormat
                className="input-generic__dotted tooltip-range__input"
                onValueChange={(e) => this.inputHandler(e && e.floatValue, "tenure")}
                value={tenure}
                suffix={" months"}
                name="tenure"
                maxLength={10}
                style={{ left: (tenure * 100) / (maxTenure - minTenure - 2) + "%" }}
              />
              <div className="range__wrapper">
                <InputRange
                  maxValue={maxTenure}
                  minValue={minTenure}
                  value={tenure}
                  onChange={(value) => this.inputHandler(value, "tenure")}
                />
              </div>
            </div>
            <div>
              <label htmlFor="" className="label-heading">
                Installments (EMI's) paid till date
              </label>
              <br />
              <NumberFormat
                className="input-generic__dotted tooltip-range__input"
                onValueChange={(e) => this.inputHandler(e && e.floatValue, "paidDate")}
                value={paidDate}
                maxLength={3}
                style={{ left: (paidDate * 100) / (maxPaidDate - minPaidDate - 2) }}
              />
              <div className="range__wrapper">
                <InputRange
                  maxValue={maxPaidDate}
                  minValue={minPaidDate}
                  value={paidDate}
                  onChange={(value) => this.inputHandler(value, "paidDate")}
                />
              </div>
            </div>
            <label htmlFor="" className="label-heading">
              Moratorium Period availed
            </label>
            <Select
              isSearchable={true}
              options={[{ label: "Not Availed", value: 0 }, ...monthsOptions]}
              onChange={(e) => this.setState({ period: e.value })}
            />
            <button className="button__generic" onClick={this.handleSubmit}>
              Calculate Financial Impact
            </button>
          </div>
          {calculatedData ? (
            <div className="loan-details__wrapper">
              <div className="card__wrapper">
                <div className="heading">Updated loan details</div>
                <div className="button__container d-flex">
                  <div
                    className={loanDetailsTab === "NONE" ? "button__generic m-0 f-14" : "grey-button f-14"}
                    onClick={() => this.setState({ loanDetailsTab: "NONE" }, () => this.calculateHandler())}
                  >
                    Not Availed
                  </div>
                  <div
                    className={loanDetailsTab === "SAME_TENURE" ? "button__generic m-0 f-14" : "grey-button f-14"}
                    onClick={() => this.setState({ loanDetailsTab: "SAME_TENURE" }, () => this.calculateHandler())}
                  >
                    With same tenure
                  </div>
                  <div
                    className={loanDetailsTab === "SAME_EMI" ? "button__generic m-0 f-14" : "grey-button f-14"}
                    onClick={() => this.setState({ loanDetailsTab: "SAME_EMI" }, () => this.calculateHandler())}
                  >
                    With same EMI
                  </div>
                </div>
                {loanDetailsTab === "NONE" || loanDetailsTab === "SAME_TENURE" ? (
                  loanDetailsTab === "SAME_TENURE" ? (
                    <WithSameTenure {...this.state} />
                  ) : (
                    <NotAvailed {...this.state} />
                  )
                ) : (
                  <WithSameEMI {...this.state} />
                )}
              </div>
              <div className="card__wrapper" style={{ color: "#00cdbe", fontSize: 18, textAlign: "center" }}>
                If you want tentative revised repayment schedule.
                <br />
                Please mail us at{" "}
                <a href="mailto:info@credochain.com?Subject=Revised%20Repayment%20Schedule" target="_top">
                  info@credochain.com
                </a>{" "}
                info@credochain.com . We will send you the same in next 4 hours.
              </div>
            </div>
          ) : null}
        </main>
        <footer className="footer__wrapper">
          <>
            <img src={whiteLogo} alt="Logo" onClick={this.homePageRedirection} className="cursor-pointer" />
            Powered by Credochain
          </>
        </footer>
      </div>
    );
  }
}
