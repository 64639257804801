import { useTable } from "react-table";
import React from "react";

function Table({ columns, data, getTrProps }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });
  return (
    <table {...getTableProps()} className="react-table">
      <thead className="react-table-heading">
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="react-table-heading-row"
          >
            {headerGroup.headers.map((column, key) => (
              <th
                {...column.getHeaderProps()}
                className="react-table-heading-cell system-decision-weight"
                key={key}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="react-table-body">
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="react-table-body-row"
              onClick={() => getTrProps(row.values)}
            >
              {row.cells.map((cell, key) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="react-table-body-cell custom-padding"
                    key={key}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;
