import * as GstNumberConstants from "./GstNumber.actions.constants";

const initialState = {
  businessDetailsData: [],
  gstData:[]
};

export const GstNumberReducer = (state = initialState, actions) => {
  let { data, type } = actions;

  switch (type) {
    case GstNumberConstants.FETCH_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetailsData: data,
      };
    case GstNumberConstants.GET_GST_DATA:
      return {
        ...state,
        gstData: data,
      };
    default:
      return state;
  }
};
