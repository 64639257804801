import React from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import LimitUtilizationEditForm from "./LimitUtilizationEditForm";
import  CredoModal  from "../../../components/Modal/modal";
import { rupeeCommaFormatter } from "../../../utils/utility";
class BankAccountDetails extends React.PureComponent {
  state = {
    modalOpen: false,
    modalType: ""
  };
  toggleModal = (modalType, props) => {
    const modalState = !this.state.modalOpen;
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalType: modalState ? modalType : ""
    });
  };

  render() {
    let { data } = this.props;
    let className;
    let dataTosendModal = {
      accountNumber: data.accountNumber,
      applicationNumber: this.props.applicationNumber,
      sanctionLimit: data.sanctionLimit
    };
    let { modalOpen, modalType } = this.state;
    return (
      <div className={className ? className : "col-md-6"}>
        <div className={"box-content"}>
          <div className={"box-header"}>
            <h3 className={"box-heading"}>Bank Account {this.props.index}</h3>
            <div className={"actions"}></div>
          </div>
          <ul className={"bank-details-list"}>
            <li>
              <label>Account Number</label>
              <span>{data.accountNumber}</span>
            </li>
            <li>
              <label>Type of Account</label>
              <span>{data.accountType}</span>
            </li>
            <li>
              <label>Bank Name</label>
              <span>{data.bankName}</span>
            </li>
            <li>
              <label>Account Holder's Name</label>
              <span>
                {data.accountHolderName ? data.accountHolderName.trim() : null}
              </span>
            </li>
            {data.accountType === "CC/OD Account" ? (
              <li>
                <label>Sanction Limit</label>
                <span>
                  {rupeeCommaFormatter(data.sanctionLimit)}
                  <span
                    onClick={() => this.toggleModal("limitUtilization")}
                    className="sanctionLimitEdit"
                  >
                    <Icon icon={ic_edit} size={18} />
                  </span>
                </span>
              </li>
            ) : null}

            <li>
              <label>Months Available</label>
              <ul className={"months-list"}>
                {typeof data.monthAvailable !== "undefined" &&
                data.monthAvailable != null ? (
                  data.monthAvailable.map((month, index) => (
                    <li key={index}>
                      {month.split("-")[0]}
                      <br />
                      {month.split("-")[1]}
                    </li>
                  ))
                ) : (
                  <li>NA</li>
                )}
              </ul>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={modalOpen}
          className={"edit-ratio-modal"}
          props={dataTosendModal}
          sendData={this.getData}
          sendData1={this.getData1}
          styles={{
            content: {
              width: "300px",
              borderRadius: "25px",
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff"
            }
          }}
          title={
            modalType === "editRatio"
              ? "Add Revised Ratio"
              : "editRatio1"
              ? "Add Revised Ratio"
              : "Add Amount"
          }
          closeAction={() => this.toggleModal("")}
          RenderingComponent={
            modalType === "limitUtilization" ? LimitUtilizationEditForm : ""
          }
        />
      </div>
    );
  }
}

export default BankAccountDetails;
