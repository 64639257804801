import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import DummyImage from "../../../assets/Images/dummyImage1.jpg";
export default class ImpactMSME extends Component {
  render() {
    return (
      <div className="impact__wrapper">
        <h2>Creating impact for MSMEs</h2>
        <p>Hear what others have to say about us</p>
        <div className="carousel__wrapper">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={DummyImage} alt="First slide" />
              <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={DummyImage} alt="Third slide" />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={DummyImage} alt="Third slide" />

              <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}
