import React, { Component } from "react";
import BusinessHealth from "./MainViews/businessHealth";
import LoanRecommendation from "./MainViews/loanRecommendation";
import HowCredochainWorks from "./MainViews/howCredochainWorks";
import AimToHelpYou from "./MainViews/aimToHelpYou";
import ImpactMSME from "./MainViews/impactMSME";
import Footer from "../../components/Footer/footer";
import LendingPartners from "./MainViews/lendingPartners";
import OurApproach from "./MainViews/OurApproach";
import { Helmet } from "react-helmet";

export default class HomePage extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       headingText:"",
       subheading:"",
       step1:"",
       step2:"",
       step3:""
    }
  }
  
  componentWillMount = () => {
    window.scroll(0, 0);
  };
  render() {

    return (
      <div className="homepage__wrapper">
        <Helmet>
          <title>Home</title>
          <meta name="description" content="Moratorium Calculator" />
          <meta
            name="keyword"
            content="how to apply for business loans,how to apply for unsecured business loans,how to apply for msme loans,best business loans in india,business loan india,cheap business loans,collateral business loans"
          />
        </Helmet>
        <BusinessHealth />
        <LoanRecommendation />
        <HowCredochainWorks
          headingText="How Credochain Works"
          subHeadingText="Fast, Easy and Secure"
          step1="Let us know your fund requirememnts and share all business and cashflow information online - All in under 7 minutes and yes no paper documents please!"
          step2="Select from the best suited loan offers for your business by comparing eligibility – Realtime in-principle approval if you qualify"
          step3="Get funds in your bank account within 3 days from document submission."
        
        />
        {console.log(this.state.headingText)}
        <OurApproach />
        <AimToHelpYou />
        {/* <ImpactMSME /> */}
        {/* <LendingPartners /> */}
        <Footer />
      </div>
    );
  }
}
