import * as BusinessConstants from "./Business.actions.constants";
const initialState = {
  allBusinessData: []
};

export const MyBusinessReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case BusinessConstants.FETCH_ALL_BUSINESS:
      return {
        ...state,
        allBusinessData: data
      };
    default:
      return state;
  }
};
