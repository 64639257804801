import React, { Component } from "react";
import WhiteLogo from "../../assets/Images/whiteLogo.png";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaVimeo,
  FaMedium
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";


import MapImage from "../../assets/Images/map.png";
export default class Footer extends Component {
  
  
  render() {
    return (
      <div
        className="footer__wrapper"
        style={{ paddingTop: this.props.paddingTop }}
      >
        <div className="row border__right">
          <div className="col-sm-4 col-xs-12">
            <div className="row">
              <div className="col-sm-3">
                <img src={WhiteLogo} alt="credochain logo" />
              </div>
              <div className="col-sm-9">
                <span className="footer__logo">
                  Credochain <br />
                  Technologies
                </span>
              </div>
            </div>
            <p>
              Credochain is a data driven and analytical platform that caters to
              these complex requirements.
            </p>
            <div className="meet__wrapper">
              <div className="bold-text">Meet Us At</div>
              <div className="icon__wrapper mt-2">
                <a href="https://www.facebook.com/credochain" target="_blank">
                  <FaFacebookSquare size={20} />
                </a>
                <a
                  href="https://www.linkedin.com/company/credochain-technologies-pvt-ltd"
                  target="_blank"
                >
                  <FaLinkedin size={20} />
                </a>
                <a href="https://vimeo.com/user103215760" target="_blank">
                  <FaVimeo size={24} />
                </a>
                <a href="https://medium.com/credochain/about" target="_blank">
                  <FaMedium size={24} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xs-12 no-padding">
            <div className="second-column">
              <div className="row">
                <div className="col-md-7 col-sm-6">
                  <div className="text-grey">Our Solutions</div>
                  <a href="#biz360Report">Biz360 Report</a>
                  <a href="#loanApplication">Loan Application</a>
                  <a href="#loanRecommendation">Loan Recommendation</a>
                  <a href="#msmeLoans" className="msme-footer-btn">
                    MSME Loans
                  </a>
                </div>
                <div className="col-md-5 col-sm-6 pl-4">
                  <div className="text-grey">Company</div>
                  <a href="https://medium.com/credochain" target="_blank">Blog</a>
                  <a href="http://platform.credochain.com/about-us" target="_blank">About Us</a>
                  {/* <Link to="/biz360">Career</Link> */}
                  <Link to="/contact-us">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4 col-xs-12 mobile-footer">
            <div className="bold-text text-grey">Contact Us</div>
            <div className="mt-4">
              <a href="mailto:support@credochain.com" className="no-decoration">
              <MdEmail className="mr-3" />
                support@credochain.com
              </a>
            </div>
            <div className="mt-3 mb-4">
              <a href={this.props.phone ? `tel:${this.props.phone}` : "tel:011-40042927"} className="no-decoration">
              <MdPhone className="mr-3" />
                {this.props.phone ? this.props.phone : "011-40042927" }
              </a>
            </div>
            <div className="row">
              <div className="mr-5">
                <div className="bold-text text-grey">Office Address</div>
                <span className="mt-2 fs-14">
                  Plot No. 27A, 3rd Floor
                  <br /> Hauz Khas Village <br />
                  New Delhi-110016
                </span>
              </div>
              <div className="mt-4">
                <img src={MapImage} width="100rem" />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright__container">
          By accessing and using this page you agree to the Terms of Use and
          Privacy Policy Copyright © 2019 Credochain Technologies Pvt. Ltd. All
          rights reserved
        </div>
      </div>
    );
  }
}