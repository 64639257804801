import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import { loaderModal, setUserDetails, setUserLoginStatus, checkUserLogin } from "../../../store/actions";
import { API_GET_GENERATE_TOKEN, API_POST_LOGIN } from "../../../utils/APIUrls";
import { validateEmail, goTo } from "../../../utils/utility";
class CreditLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isFlipped: false,
      error: ""
    };
    this.props.actions.checkUserLogin().then(data => {
      if (data.status === "success") {
        goTo("/credit/dashboard");
      }
    });
  }

  setEmail = e => {
    this.setState({ email: e.target.value });
  };

  setPassword = e => {
    this.setState({ password: e.target.value });
  };
  loginSubmitForm = async e => {
    e.preventDefault();
    let { history, actions } = this.props,
      { loaderModal, setUserDetails, setUserLoginStatus } = actions,
      { email, password } = this.state;
    if (validateEmail(email)) {
      loaderModal(true);
      await axios
        .get(API_GET_GENERATE_TOKEN)
        .then(({ data }) => {
          document.cookie = `token=${data.data.tokenId}; path=/`;
          const headers = {
            "Content-Type": "application/json",
            tokenId: data.data.tokenId
          };
          axios
            .post(
              API_POST_LOGIN,
              {
                data: {
                  email: email,
                  password: password,
                  tokenId: data.data.tokenId
                }
              },
              { headers }
            )
            .then(async ({ data }) => {
              if (data.status === "success") {
                setUserDetails(data.data);
                setUserLoginStatus(true);
                if (data.data.roleName === "B2C_UW") {
                  history.push("/Credit/dashboard");
                } else if (data.data.roleName === "CPO_USER") {
                  history.push("/CPODashboard/User");
                } else if (data.data.roleName === "ADMIN") {
                  history.push("/Admin");
                } else {
                  history.push("/");
                }
              } else {
                this.setState({ error: data.message });
              }
            })
            .catch(err => {
              console.log(err);
              this.setState({
                error: "Something went wrong please try again later."
              });
            });
          loaderModal(false);
        })
        .catch(err => {
          loaderModal(false);
          console.log(err);
          this.setState({
            error: "Something went wrong please try again later."
          });
        });
    } else {
      this.setState({
        error: "Please Enter Correct Email Id or Mobile Number"
      });
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { isLogin, history } = this.props;
    isLogin && history.push("/Sales/dashboard");
  }

  render() {
    let { email, password } = this.state,
      { loaderModalStatus } = this.props;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <div className={"content-wrapper login-wrapper"}>
          <div className={"content-box"}>
            <h2 className={"heading"}>Login to your Credochain Account</h2>
            <div className={"row"}>
              <div className={"col-md-6 offset-md-6"}>
                <form onSubmit={this.loginSubmitForm} className={"form-box"}>
                  <InputLabelAnimation
                    labelText={"Enter Login Id or Mobile Number"}
                    onChange={this.setEmail}
                    name={"email"}
                    tabIndex={1}
                    inputValue={email}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={"Password"}
                    name={"password"}
                    tabIndex={2}
                    onChange={this.setPassword}
                    type={"password"}
                    inputValue={password}
                    required={true}
                  />
                  <div className={"actions-box"}>
                    <button tabIndex={3} type={"submit"}>
                      Login
                    </button>
                    <Link to={"/forgot_password"} className={"primary-link"}>
                      Forgot Password?
                    </Link>
                    <Link to={"/signup"} className={"primary-link"}>
                      Don’t have an account? Sign Up here.
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
  loaderModalStatus: data.loaderModalStatus
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      setUserDetails,
      setUserLoginStatus,
      checkUserLogin
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditLogin);
