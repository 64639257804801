import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getApplicationPreview,
  updatePreviewObject,
  updatePreviewObjectAddress,
  deletePreviewObjectAddress,
} from "./LoanApplicationPreview.actions";
import {
  getCookie,
  rupeeCommaFormatter,
  getDateString,
  monthsToYearConverter,
  goTo,
  getNewDateFormat,
  getDMY,
} from "../../../utils/utility";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { fetchLoanUse } from "../LoanPurpose/LoanPurpose.actions";
import { loanPreferenceOptions } from "./LoanApplicationPreview.config";
import { getPrimaryIndustry, getSecondaryIndustry, getBusinessType, getConstitution } from "../GSTData/GSTData.actions";
import CommonModal from "../../../components/Modal/modal";
import addressModal from "../UserAddress/addressModal";
import { getProfileAddressType, getRelationship, getGender } from "../KYC/KYC.actions";
import DatePicker from "react-date-picker";
import { FiEdit2 } from "react-icons/fi";
import { POST, GET } from "../../../utils/WebAPI.service";
import {
  API_POST_CREATE_APPLICATION,
  API_POST_ADD_BUSINESS,
  API_POST_UPDATE_PROFILE,
  API_GET_SUBMIT_APPLICATION,
} from "../../../utils/APIUrls";
import { eligibilityData } from "../Banking/Banking.actions";
import { loaderModal } from "../../../store/actions";

export class LoanApplicationPreview extends Component {
  state = {
    isLoanAppEditable: false,
  };
  toggleModal = (addressData, objectName, profileIndex, addressIndex) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, addressData, profileIndex, addressIndex, objectName });
  };
  deleteAddressHandler = () => {
    let { objectName, profileIndex, addressIndex } = this.state;
    this.props.actions.deletePreviewObjectAddress(objectName, profileIndex, addressIndex);
    this.toggleModal();
  };
  editExistingAddress = (data) => {
    let { objectName, profileIndex, addressIndex } = this.state;
    this.props.actions.updatePreviewObjectAddress(objectName, profileIndex, addressIndex, data);
    this.toggleModal();
  };
  tenureHandler = (months, years) => {
    // console.log(months, years);
    this.props.actions.updatePreviewObject("applicationData.tenure", Number(months) + Number(years * 12) || 0);
  };
  dataUpload(api, data) {
    POST(api, { data: data })
      .then(({ data }) => {
        if (data.status !== "success") {
          alert(data.message);
        }
      })
      .catch((err) => {
        alert("Something Went wrong");
        throw err;
      });
  }
  submitApplication = async () => {
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    await GET(API_GET_SUBMIT_APPLICATION(getCookie("applicationNumber"))).then(({ data }) => {
      if (data.status === "success") {
        this.props.actions.eligibilityData(data.data);
        goTo("/dashboard/loans/eligibility");
      } else {
        alert(data.message);
      }
    });
    loaderModal(false);
  };
  render() {
    let {
        previewApplicationData,
        actions,
        LoanUseData,
        getConstitutionData,
        getBusinessTypeData,
        getPrimaryIndustryData,
        getSecondaryIndustryData,
        profileAddressTypeData,
        relationshipData,
        genderData,
      } = this.props,
      { applicationData, bankDetails, businessData, profileData } = previewApplicationData,
      { isLoanAppEditable, isBusinessEditable, modalIsOpen, addressData } = this.state;
    let loanUseOptions = LoanUseData?.responseList
      ? LoanUseData.responseList.map((el) => {
          return { label: el, value: el };
        })
      : [];
    let constitutionOptions = getConstitutionData.map((el) => {
      return { value: el, label: el };
    });
    let businessTypeOptions = getBusinessTypeData.map((el) => {
      return { value: el, label: el };
    });
    let primaryIndustryOptions = getPrimaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    let secondaryIndustryOptions = getSecondaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    let relationshipList = relationshipData.map((el) => {
      return { value: el, label: el };
    });
    let genderList = genderData.map((el) => {
      return { value: el, label: el };
    });
    return (
      <div className="preview__wrapper">
        <div className="d-flex justify-content-end mb-4">
          <div className="button__generic cursor-pointer " onClick={this.submitApplication}>
            Submit
          </div>
        </div>
        <div className="card__wrapper ">
          <div className="header">
            <h3 className="heading">Loan Application details</h3>
            {isLoanAppEditable ? (
              <div
                className="edit__button"
                onClick={() => {
                  this.dataUpload(API_POST_CREATE_APPLICATION, applicationData);
                  this.setState({ isLoanAppEditable: false });
                }}
              >
                Submit
              </div>
            ) : (
              <div
                className="edit__button"
                onClick={() => {
                  actions.fetchLoanUse();
                  this.setState({ isLoanAppEditable: true });
                }}
              >
                Edit
              </div>
            )}
          </div>
          <div className="row no-gutters">
            <div className="col-md-2">
              <label className="label">Created on</label>
              <div className="value">{getDateString(applicationData?.createdDate)}</div>
            </div>
            {profileData?.map((el, index) => {
              return (
                <div className="col-md-2" key={`loan-application-applicant-key-${index}`}>
                  <label className="label">
                    {el.profileType === "Applicant" ? "Primary Applicant" : "Co-applicant"}
                  </label>
                  <div className="value">{el?.name}</div>
                </div>
              );
            })}
          </div>
          <div className="row no-gutters">
            <div className="col-md-2">
              <label className="label">Applied Amount</label>
              {isLoanAppEditable ? (
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  className="input__generic"
                  onValueChange={(e) => actions.updatePreviewObject("applicationData.loanAmount", e && e.floatValue)}
                  value={applicationData?.loanAmount}
                  name="loanAmount"
                  maxLength={11}
                />
              ) : (
                <div className="value">{rupeeCommaFormatter(applicationData?.loanAmount)}</div>
              )}
            </div>
            <div className="col-md-2">
              <label className="label">Requested Tenure</label>
              {isLoanAppEditable ? (
                <div className="loan-tenure__wrapper">
                  <input
                    type="text"
                    className="input__generic mr-2 py-3"
                    value={Math.floor(applicationData?.tenure / 12)}
                    onChange={(e) => this.tenureHandler(e.target.value * 12, applicationData?.tenure % 12)}
                  />
                  years
                  <input
                    type="text"
                    className="input__generic mx-2 py-3"
                    value={applicationData?.tenure % 12}
                    onChange={(e) => this.tenureHandler(e.target.value, Math.floor(applicationData?.tenure / 12))}
                  />
                  months
                </div>
              ) : (
                <div className="value">{monthsToYearConverter(applicationData?.tenure)}</div>
              )}
            </div>
            <div className="col-md-3">
              <label className="label">Preferred Loan Type</label>
              {isLoanAppEditable ? (
                <Select
                  className="basic-single w-70 mt-1"
                  classNamePrefix="select"
                  options={loanPreferenceOptions}
                  name={"loanType"}
                  onChange={(e) => actions.updatePreviewObject("applicationData.loanType", e.value)}
                  value={loanPreferenceOptions.find((option) => option.label === applicationData?.loanType)}
                />
              ) : (
                <div className="value">{applicationData?.loanType}</div>
              )}
            </div>
            <div className="col-md-3">
              <label className="label">Loan Purpose</label>
              {isLoanAppEditable ? (
                <Select
                  className="basic-single w-70 mt-1"
                  classNamePrefix="select"
                  options={loanUseOptions}
                  name={"loanType"}
                  onChange={(e) => actions.updatePreviewObject("applicationData.loanPurpose", e.value)}
                  value={loanUseOptions.find((option) => option.label === applicationData?.loanPurpose)}
                />
              ) : (
                <div className="value">{applicationData?.loanPurpose}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row no-gutters mt-5">
          <div className="col-md-9 col-sm-12 pr-4">
            <div className="card__wrapper">
              <div className="header">
                <h3 className="heading">{businessData?.tradeName}</h3>
                {isBusinessEditable ? (
                  <div
                    className="edit__button"
                    onClick={() => {
                      this.dataUpload(API_POST_ADD_BUSINESS(getCookie("applicationNumber")), businessData);
                      this.setState({ isBusinessEditable: false });
                    }}
                  >
                    Submit
                  </div>
                ) : (
                  <div
                    className="edit__button"
                    onClick={() => {
                      actions.getPrimaryIndustry(businessData?.businessType);
                      actions.getSecondaryIndustry(businessData?.businessType, businessData?.primaryIndustry);
                      actions.getBusinessType();
                      actions.getConstitution();
                      this.setState({ isBusinessEditable: true });
                    }}
                  >
                    Edit
                  </div>
                )}
              </div>
              <div className="row no-gutters">
                <div className="col-md-4">
                  <label className="label">Business Type</label>
                  {isBusinessEditable ? (
                    <Select
                      className="basic-single w-70 mt-1"
                      classNamePrefix="select"
                      options={businessTypeOptions}
                      name={"loanType"}
                      onChange={(e) => actions.updatePreviewObject("businessData.businessType", e.value)}
                      value={businessTypeOptions.find((option) => option.label === businessData?.businessType)}
                    />
                  ) : (
                    <div className="value">{businessData?.businessType}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="label">Primary Industry</label>
                  {isBusinessEditable ? (
                    <Select
                      className="basic-single w-70 mt-1"
                      classNamePrefix="select"
                      options={primaryIndustryOptions}
                      name={"loanType"}
                      onChange={(e) => actions.updatePreviewObject("businessData.primaryIndustry", e.value)}
                      value={primaryIndustryOptions.find((option) => option.label === businessData?.primaryIndustry)}
                    />
                  ) : (
                    <div className="value">{businessData?.primaryIndustry}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="label">Secondary Industry</label>
                  {isBusinessEditable ? (
                    <Select
                      className="basic-single w-70 mt-1"
                      classNamePrefix="select"
                      options={secondaryIndustryOptions}
                      name={"loanType"}
                      onChange={(e) => actions.updatePreviewObject("businessData.secondaryIndustry", e.value)}
                      value={secondaryIndustryOptions.find(
                        (option) => option.label === businessData?.secondaryIndustry
                      )}
                    />
                  ) : (
                    <div className="value">{businessData?.secondaryIndustry}</div>
                  )}
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-4">
                  <label className="label">Business Vintage</label>
                  {/* {isBusinessEditable} */}
                  <div className="value">{businessData?.secondaryIndustry}</div>
                </div>
                <div className="col-md-4">
                  <label className="label">Business Constitution</label>
                  {isBusinessEditable ? (
                    <Select
                      className="basic-single w-70 mt-1"
                      classNamePrefix="select"
                      options={constitutionOptions}
                      name={"loanType"}
                      onChange={(e) => actions.updatePreviewObject("businessData.constitution", e.value)}
                      value={constitutionOptions.find((option) => option.label === businessData?.constitution)}
                    />
                  ) : (
                    <div className="value">{businessData?.constitution}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="label">PAN Number</label>
                  {isBusinessEditable ? (
                    <div>
                      <input
                        type="text"
                        className="input__generic"
                        value={businessData?.businessPan}
                        onChange={(e) => actions.updatePreviewObject("businessData.businessPan", e.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="value">{businessData?.businessPan}</div>
                  )}
                </div>
              </div>
              {businessData?.gstData?.map((el, gstIndex) => (
                <div className="row no-gutters border-bottom__row">
                  <div className="col-md-4">
                    <label className="label">GSTIN Number {gstIndex + 1}</label>
                    <div className="value">{el.gstin}</div>
                  </div>
                  <div className="col-md-4">
                    <label className="label">Date of Registration</label>
                    <div className="value">{el.dateOfGSTRegistration}</div>
                  </div>
                  <div className="col-md-4">
                    <label className="label">GST Verification</label>
                    <div className="value">{Boolean(el.isOtpVerified)}</div>
                  </div>
                  {el.addresses?.map((addressData, addressIndex) => (
                    <div className="col-md-3 col-sm-6 mt-2" key={`applicant-key-${el}-${addressIndex}`}>
                      <label className="label">
                        Address
                        {isBusinessEditable ? (
                          <span
                            className="edit__address-icon"
                            onClick={() => this.toggleModal(addressData, "businessData", gstIndex, addressIndex)}
                          >
                            <FiEdit2 />
                          </span>
                        ) : null}{" "}
                      </label>

                      <div className="value">
                        <div className="address__wrapper-gst">
                          <div className="green-small-text">{addressData.ownership}</div>
                          <div>{addressData.line1}</div>
                          <div>{addressData.line2}</div>
                          <div>{addressData.city}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="card__wrapper">
              <div className="header">
                <h3 className="heading">Anika Fashions</h3>
                <div className="edit__button">Edit</div>
              </div>
            </div>
          </div>
        </div>
        {profileData?.map((el, profileIndex) => {
          return (
            <div className="row no-gutters mt-5" key={el?.id}>
              <div className="col-md-9 col-sm-12 pr-3">
                <div className="card__wrapper">
                  <div className="header">
                    <div className="heading">
                      {el.profileType === "Applicant" ? "Primary Applicant" : "Co-applicant"}
                    </div>
                    {this.state[`isAppEditable${profileIndex}`] ? (
                      <div
                        className="edit__button"
                        onClick={() => {
                          this.dataUpload(API_POST_UPDATE_PROFILE(getCookie("applicationNumber")), el);
                          this.setState({ [`isAppEditable${profileIndex}`]: false });
                        }}
                      >
                        Submit
                      </div>
                    ) : (
                      <div
                        className="edit__button"
                        onClick={() => {
                          actions.getRelationship(businessData?.constitution);
                          actions.getGender();
                          this.setState({ [`isAppEditable${profileIndex}`]: true });
                        }}
                      >
                        Edit
                      </div>
                    )}
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Name</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <input
                            type="text"
                            className="input__generic"
                            value={el.name}
                            onChange={(e) =>
                              actions.updatePreviewObject("profileData.name", e.target.value, profileIndex)
                            }
                          />
                        </div>
                      ) : (
                        <div className="value">{el.name}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Date of Birth</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <DatePicker
                            onChange={(e) =>
                              actions.updatePreviewObject("profileData.dateOfBirth", getDMY(e), profileIndex)
                            }
                            value={getNewDateFormat(el.dateOfBirth)}
                            required
                            format={"dd/MM/y"}
                          />
                        </div>
                      ) : (
                        <div className="value">{el.dateOfBirth}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Gender</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <Select
                            className="basic-single w-70 mt-1"
                            classNamePrefix="select"
                            options={genderList}
                            name={"relation"}
                            onChange={(e) => actions.updatePreviewObject("profileData.gender", e.value, profileIndex)}
                            value={genderList.find((option) => option.label === el.gender)}
                          />
                        </div>
                      ) : (
                        <div className="value">{el.gender}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Email ID</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <input
                            type="text"
                            className="input__generic"
                            value={el.email}
                            onChange={(e) =>
                              actions.updatePreviewObject("profileData.email", e.target.value, profileIndex)
                            }
                          />
                        </div>
                      ) : (
                        <div className="value">{el.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-md-3 col-sm-6">
                      <label className="label">PAN ID</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <input
                            type="text"
                            className="input__generic"
                            value={el.pan}
                            onChange={(e) =>
                              actions.updatePreviewObject("profileData.pan", e.target.value, profileIndex)
                            }
                          />
                        </div>
                      ) : (
                        <div className="value">{el.pan}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Mobile Number</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <div>
                          <input
                            type="text"
                            className="input__generic"
                            value={el.mobile}
                            onChange={(e) =>
                              actions.updatePreviewObject("profileData.mobile", e.target.value, profileIndex)
                            }
                          />
                        </div>
                      ) : (
                        <div className="value">{el.mobile}</div>
                      )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label className="label">Relationship Entity</label>
                      {this.state[`isAppEditable${profileIndex}`] ? (
                        <Select
                          className="basic-single w-70 mt-1"
                          classNamePrefix="select"
                          options={relationshipList}
                          name={"relation"}
                          onChange={(e) => actions.updatePreviewObject("profileData.relation", e.value, profileIndex)}
                          value={relationshipList.find((option) => option.label === el.relation)}
                        />
                      ) : (
                        <div className="value">{el.relation}</div>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters">
                    {el.address?.map((addressData, addressIndex) => (
                      <div className="col-md-3 col-sm-6" key={`applicant-key-${el}-${addressIndex}`}>
                        <label className="label">
                          Address
                          {this.state[`isAppEditable${profileIndex}`] ? (
                            <span
                              className="edit__address-icon"
                              onClick={() => this.toggleModal(addressData, "profileData", profileIndex, addressIndex)}
                            >
                              <FiEdit2 />
                            </span>
                          ) : null}{" "}
                        </label>

                        <div className="value">
                          <div className="address__wrapper-gst">
                            <div className="green-small-text">{addressData.ownership}</div>
                            <div>{addressData.line1}</div>
                            <div>{addressData.line2}</div>
                            <div>{addressData.city}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card__wrapper">
                  <div className="header">
                    <div className="heading">Primary Applicant Documents</div>
                    <div className="edit__button">Edit</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="row no-gutters mt-4">
          <div className="col-md-9 col-sm-12 pr-3">
            <div className="card__wrapper">
              <div className="header">
                <h3 className="heading">Banking Details</h3>
                <div className="edit__button" onClick={() => goTo("banking")}>
                  Edit
                </div>
              </div>
              {bankDetails?.map((el, index) => {
                return (
                  <div className="row no-gutters" key={`bank-details-key-${index}`}>
                    <div className="col-md-4">
                      <label className="label">Account Number 1</label>
                      <div className="value">{el.accountNumber}</div>
                    </div>
                    <div className="col-md-4">
                      <label className="label">Bank Name</label>
                      <div className="value">{el.bankName}</div>
                    </div>
                    <div className="col-md-4">
                      <label className="label">Account Holder's Name</label>
                      <div className="value">{el.accountHolderName}</div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="label">Account Type</label>
                      <div className="value">{el.accountType}</div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="label">Sanction Limit</label>
                      <div className="value">{el.sanctionLimit}</div>
                    </div>
                    <div className="col-md-4 mt-3">
                      <label className="label">Months Available</label>
                      <div className="value">{el.monthAvailable}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
        <CommonModal
          isOpen={modalIsOpen}
          props={this.props}
          addressData={addressData || []}
          styles={{
            content: {
              width: "30%",
              minWidth: "400px",
            },
          }}
          addressTypeArray={profileAddressTypeData}
          title={"Address"}
          type={"editAddress"}
          closeAction={this.toggleModal}
          RenderingComponent={addressModal}
          editExistingAddress={this.editExistingAddress}
          deleteAddressHandler={this.deleteAddressHandler}
        />
      </div>
    );
  }
  componentDidMount() {
    let { actions } = this.props;
    actions.getApplicationPreview(getCookie("applicationNumber"));
    actions.getProfileAddressType();
  }
}
const mapStateToProps = ({ LoanApplicationPreviewReducer, LoanPurposeReducer, GSTDataReducer, KYCReducer }) => ({
  previewApplicationData: LoanApplicationPreviewReducer.previewApplicationData,
  LoanUseData: LoanPurposeReducer.LoanUseData,
  getConstitutionData: GSTDataReducer.getConstitutionData,
  getBusinessTypeData: GSTDataReducer.getBusinessTypeData,
  getPrimaryIndustryData: GSTDataReducer.getPrimaryIndustryData,
  getSecondaryIndustryData: GSTDataReducer.getSecondaryIndustryData,
  profileAddressTypeData: KYCReducer.profileAddressTypeData,
  relationshipData: KYCReducer.relationshipData,
  genderData: KYCReducer.genderData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getApplicationPreview,
      fetchLoanUse,
      getPrimaryIndustry,
      getSecondaryIndustry,
      getBusinessType,
      getConstitution,
      updatePreviewObject,
      getProfileAddressType,
      updatePreviewObjectAddress,
      deletePreviewObjectAddress,
      getRelationship,
      getGender,
      eligibilityData,
      loaderModal
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoanApplicationPreview);
