import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import NumberFormat from "react-number-format";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_CREATE_APPLICATION } from "../../../utils/APIUrls";
import { goTo, getCookie } from "../../../utils/utility";
import { fetchApplication } from "./AmountAndTenure.actions";
import { bindActionCreators } from "redux";
class AmountAndTenure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: 1500000,
      years: "",
      months: "",
      maxLoan: 20000000,
      minLoan: 100000,
    };
    this.monthsRef = React.createRef();
  }
  inputHandler = (value, name) => {
    value < this.state.maxLoan && this.setState({ [name]: value });
  };
  inputTenureHandler = (e) => {
    var name = e.target.name,
      value = e.target.value;
    let reg = /^[0-9]*$/;
    if (reg.test(value)) {
      if (name === "years") {
        value.length === 1 && this.monthsRef.current.focus();
        this.setState({ [name]: value });
      }
      if (name === "months") {
        this.setState({ monthsError: false });
        value < 12 ? this.setState({ [name]: value }) : this.setState({ monthsError: true });
      }
    }
  };
  submitLoanApplicationHandler = () => {
    let { loanAmount, loanPurpose, loanType, years, months, applicationNumber } = this.state,
      dataToSend = {
        data: {
          applicationNumber,
          businessId: null,
          loanAmount,
          loanPurpose: loanPurpose,
          loanType: loanType,
          tenure: Number(years * 12) + Number(months),
        },
      };

    POST(API_POST_CREATE_APPLICATION, dataToSend).then(({ data }) => {
      if (data.status === "success") {
        document.cookie = `applicationNumber=${data.data.applicationNumber}; path=/`;
        goTo(this.props.nextScreen);
      } else {
        alert(data.message);
      }
    });
  };
  selectHandler = (value, name) => {
    this.setState({ [name]: value });
  };
  render() {
    let { loanAmount, years, months, monthsError, minLoan, maxLoan, loanPurpose, loanType } = this.state,
      { loanUseData } = this.props,
      loanUseOptions = loanUseData.responseList
        ? loanUseData.responseList.map((el) => {
            return { label: el, value: el };
          })
        : [],
      loanPreferenceOptions = [
        "BUSINESS LOAN",
        "MUDRA LOAN",
        "LOAN AGAINST PROPERTY",
        "WORKING CAPITAL",
        "MERCHANT CASH ADVANCE",
        "TERM LOAN",
        "SUPPLY CHAIN ADVANCE",
        "let us choose for your business",
      ].map((el) => {
        return { label: el, value: el };
      });
    return (
      <div className="amount-tenure__wrapper">
        <div className="previous-values__row ">
          <div className="left-section mr-4">
            <div className="bold-text">Loan purpose</div>
            <Select
              className="basic-single mt-4 select-width__generic"
              classNamePrefix="select"
              options={loanUseOptions}
              name={"loanPurpose"}
              onChange={(e) => this.selectHandler(e.value, "loanPurpose")}
              value={loanUseOptions.find((option) => option.label === loanPurpose)}
            />
          </div>
          <div className="right-section">
            <div className="bold-text">Loan type preference</div>
            <Select
              className="basic-single mt-4 select-width__generic"
              classNamePrefix="select"
              options={loanPreferenceOptions}
              name={"loanType"}
              onChange={(e) => this.selectHandler(e.value, "loanType")}
              value={loanPreferenceOptions.find((option) => option.label === loanType)}
            />
          </div>
        </div>
        <div className="bold-text">How much loan do you wish for your business</div>
        <div>Use the slider to select your loan amount or enter an amount in the text field.</div>
        <div className="number-range__wrapper">
          <NumberFormat
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            prefix={"₹"}
            className="input-generic__dotted tooltip-range__input"
            onValueChange={(e) => this.inputHandler(e && e.floatValue, "loanAmount")}
            value={loanAmount}
            name="loanAmount"
            maxLength={11}
            style={{ left: (loanAmount * 100) / (maxLoan - minLoan - 2) + "%" }}
          />
          <div className="range__wrapper">
            <InputRange
              maxValue={maxLoan}
              minValue={minLoan}
              value={loanAmount}
              onChange={(value) => this.inputHandler(value, "loanAmount")}
            />
          </div>
        </div>

        <div className="bold-text">Loan Tenure</div>
        <div className="loan-tenure__wrapper">
          <input
            type="text"
            className="input__generic mr-4"
            maxLength={2}
            name="years"
            onChange={this.inputTenureHandler}
            value={years}
          />
          years
          <input
            type="text"
            className="input__generic mx-4"
            maxLength={2}
            name="months"
            onChange={this.inputTenureHandler}
            ref={this.monthsRef}
            value={months}
          />
          months
        </div>
        <div className="error-message">{monthsError ? "Please enter months between 1 to 11" : null}</div>
        <button className="button__generic" onClick={this.submitLoanApplicationHandler}>
          Submit
        </button>
      </div>
    );
  }
  async componentDidMount() {
    let { actions } = this.props,
      loanPurposeSessionStorage = sessionStorage.getItem("loanPurpose"),
      loanTypeSessionStorage = sessionStorage.getItem("loanType"),
      applicationNumber = getCookie("applicationNumber");
    if (applicationNumber) {
      actions.fetchApplication(applicationNumber).then((data) => {
        let { fetchApplicationData } = this.props,
          { applicationNumber, interestRate, loanAmount, loanPurpose, loanType, tenure } = fetchApplicationData,
          months = tenure % 12,
          years = Math.floor(tenure / 12);
        this.setState({
          applicationNumber,
          interestRate,
          loanAmount,
          loanPurpose,
          loanType,
          years,
          months,
        });
      });
    } else {
      this.setState({
        loanPurpose: loanPurposeSessionStorage,
        loanType: loanTypeSessionStorage,
      });
    }
  }
}

const mapStateToProps = ({ LoanPurposeReducer, AmountAndTenureReducer }) => ({
  loanUseData: LoanPurposeReducer.LoanUseData,
  fetchApplicationData: AmountAndTenureReducer.fetchApplicationData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchApplication,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AmountAndTenure);
