import React, { Component } from "react";
import Select from "react-select";
import { goTo } from "../../../utils/utility";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import NumberFormat from "react-number-format";
import { loanAdvisoryData, getLoanUse } from "../homepage.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios from "axios";
import { API_GET_GENERATE_TOKEN } from "../../../utils/APIUrls";

class LoanRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: "12-36 Months",
      interestRate: { min: 2, max: 10 },
      loanAmount: 50000,
    };
  }
  loanTenureHandler = (section) => {
    this.setState({ activeSection: section });
  };
  componentDidMount = async () => {
    this.props.actions.getLoanUse();
  };
  bestLoanHandler = () => {
    let { loanAdvisoryData } = this.props.actions,
      { loanAmount, interestRate, activeSection, loanPurpose } = this.state,
      data = {
        loanAmount,
        interestRate,
        loanTenure: activeSection,
        loanPurpose,
      };
    loanAdvisoryData(data);
    axios.get(API_GET_GENERATE_TOKEN).then(({ data }) => {
      if (data.status === "success") {
        document.cookie = `token=${data.data.tokenId}; path=/`;
        goTo("/loanProducts");
      }
    });
  };
  setInputRangeValue = (value, name) => {
    this.setState({ [name]: value });
  };
  inputHandler = (e, name) => {
    var value = e.floatValue;
    this.setState({ [name]: value });
    var nameSplitted = name.split(".");
    if (nameSplitted[0] === "interestRate") {
      var interestName = nameSplitted[1];
      if (value < 100 || value === undefined) {
        this.setState({ interestRate: { ...this.state.interestRate, [interestName]: value } });
      }
    }
  };
  selectHandler = (e, name) => {
    this.setState({ [name]: e.value });
  };
   price_in_words = (price) => {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function(dgt, prevDgt) {
        return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function(dgt, nxtDgt, denom) {
        return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };
  
    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (price += "", isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
      }
      str = words.reverse().join("")
    } else str = "";
    return str
  
  }
  render() {
    let { activeSection, loanAmount, interestRate } = this.state,
      { loanPurposeData } = this.props,
      loanPurposeList = loanPurposeData.map((el) => {
        return { value: el, label: el };
      });
    return (
      <div className="recommendation__wrapper" id="loanRecommendation">
        <div className="patch-wrapper">
          <h2>Get free loan recommendations from our digital advisors</h2>
          <p className="montserrat">
            Credochain compares all the possible loans and working capital management solutions available based on your business and
            cashflow requirements to recommend suitable products for your business.
          </p>
          <div className="user-input__wrapper">
            <div className="row">
              <div className="col-md-3 col-sm-6 loan-amount">
                <div className="heading">Desired Loan Amount</div>
                <NumberFormat
                  thousandSeparator={true}
                  thousandsGroupStyle="lakh"
                  prefix={"₹"}
                  style={{width: "42%"}}
                  className="input-generic__dotted mb-4 tc"
                  onValueChange={(e) => this.inputHandler(e, "loanAmount")}
                  value={loanAmount}
                  name="loanAmount"
                  maxLength={12}
                />
                 <div>{this.price_in_words(loanAmount)}</div>
                <InputRange
                  maxValue={50000000}
                  minValue={50000}
                  value={loanAmount}
                  step={10000}
                  onChange={(value) => this.setInputRangeValue(value, "loanAmount")}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="heading">Loan Tenure</div>
                <div className="loan-tenure__wrapper">
                  <div
                    className={activeSection === "< 12 Months" ? "section active-section" : "section no-right-borders"}
                    onClick={() => this.loanTenureHandler("< 12 Months")}
                  >
                    Less than 12 months
                  </div>
                  <div
                    className={activeSection === "12-36 Months" ? "section active-section" : "section top-bottom-borders"}
                    onClick={() => this.loanTenureHandler("12-36 Months")}
                  >
                    12 to 36 months
                  </div>
                  <div
                    className={activeSection === ">36 Months" ? "section active-section" : "section no-left-borders"}
                    onClick={() => this.loanTenureHandler(">36 Months")}
                  >
                    More than 36 months
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="heading">Preferred Interest Rate</div>
                <div className="interest-rate-input-value">
                  <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    suffix={"%"}
                    className="input-generic__dotted mb-4"
                    onValueChange={(e) => this.inputHandler(e, "interestRate.min")}
                    value={interestRate.min}
                    name="interestRate"
                  />
                  <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    suffix={"%"}
                    className="input-generic__dotted mb-4"
                    onValueChange={(e) => this.inputHandler(e, "interestRate.max")}
                    value={interestRate.max}
                    name="interestRate"
                  />
                </div>

                <InputRange
                  maxValue={30}
                  minValue={2}
                  value={interestRate}
                  onChange={(value) => this.setInputRangeValue(value, "interestRate")}
                />
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="heading"> Loan Purpose</div>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  options={loanPurposeList}
                  onChange={(e) => this.selectHandler(e, "loanPurpose")}
                />
              </div>
            </div>
          </div>
          <button className="secondary-button__custom" onClick={this.bestLoanHandler}>
            View best loan products
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ homePageReducers }) => ({
  loanPurposeData: homePageReducers.loanPurposeData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loanAdvisoryData,
      getLoanUse,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanRecommendation);
