import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import BusinessDetails from "./../../assets/Images/businessDetails.png";
import ToggleButton from "../../components/ToogleButton/ToggleButton";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import NumberFormat from "react-number-format";
import { bindActionCreators } from "redux";
import { findProducts, getPrimaryCollateral } from "./loanAdvisory.actions";
import { getLoanUse, getTaxPayerData } from "../HomePage/homepage.actions";
import LoaderModal from "../../components/LoaderModal/loaderModal";
import { loaderModal } from "../../store/actions";
import { MdEdit } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import InputMask from "react-input-mask";
import { goTo } from "../../utils/utility";
import Axios from "axios";
import { API_GET_GENERATE_TOKEN } from "../../utils/APIUrls";
import { Navbar, Nav } from "react-bootstrap";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import WhiteLogo from "../../../src/assets/Images/whiteLogo.png";

class LoanAdvisory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: "12-36 Months",
      businessRegistered: false,
      interestRate: { min: 2, max: 10 },
      loanAmount: 20000,
      businessVintage: 2,
      gstNumber: "",
    };
  }
  loanTenureHandler = (section) => {
    this.setState({ activeSection: section });
  };
  toggleHandler = (name) => {
    this.setState({ [name]: !this.state[name] });
  };
  componentWillMount = () => {
    window.scroll(0, 0);
  };
  setInputRangeValue = (value, name) => {
    this.setState({ [name]: value });
  };
  inputHandler = (e, name) => {
    var value = e.floatValue;
    this.setState({ [name]: value });
    var nameSplitted = name.split(".");
    if (nameSplitted[0] === "interestRate") {
      var interestName = nameSplitted[1];
      if (value < 100 || value === undefined) {
        this.setState({ interestRate: { ...this.state.interestRate, [interestName]: value } });
      }
    }
  };
  selectHandler = (e, name) => {
    this.setState({ [name]: e.value });
  };
  findBestProduct = async () => {
    let { actions } = this.props,
      { findProducts, loaderModal } = actions,
      {
        loanAmount,
        interestRate,
        isDigitalPaymentEnabled,
        businessRegistered,
        womenEntrepreneurs,
        loanTenure,
        businessVintage,
        collateral,
        collateralValue,
        loanPurpose,
      } = this.state;
    var dataToSend = {
      businessVintage,
      collateral,
      collateralValue,
      loanAmount,
      loanTenure,
      loanUse: loanPurpose,
      maxInterest: interestRate.max,
      minInterest: interestRate.min,
      msmeRegistration: businessRegistered,
      womenEntrepreneurs,
      isDigitalPaymentEnabled,
    };
    loaderModal(true);
    await findProducts(dataToSend);
    this.setState({ isCollapsible: true });
    window.scroll(0, 100);
    loaderModal(false);
  };
  gstInputHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value ? e.target.value.replace(/[- ]+/g, "") : "";
    this.setState({ [name]: value });
  };
  addGstHandler = async () => {
    let { loaderModal, getTaxPayerData } = this.props.actions;
    loaderModal(true);
    await Axios.get(API_GET_GENERATE_TOKEN).then(({ data }) => {
      document.cookie = `token=${data.data.tokenId}; path=/`;
    });
    await getTaxPayerData(this.state.gstNumber).then(({ data }) => {
      if (data.gstin) {
        document.cookie = `gstNumber=${this.state.gstNumber.toUpperCase()}; path=/`;
        goTo("/Biz360/withGst");
      } else {
        goTo("/biz360/withoutGst");
        this.setState({ isGstWrong: true });
      }
    });
    loaderModal(false);
  };
  render() {
    let {
        activeSection,
        loanAmount,
        interestRate,
        collateral,
        businessVintage,
        collateralValue,
        loanPurpose,
        isCollapsible,
        gstNumber,
      } = this.state,
      { loanPurposeData, primaryCollateralData, findProductsData, loaderModalStatus } = this.props,
      loanPurposeList = loanPurposeData.map((el) => {
        return { value: el, label: el };
      }),
      primaryCollateralList = primaryCollateralData.map((el) => {
        return { value: el, label: el };
      });
    return (
      <div className="loan-advisory__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <div className="skewed-box__blue">
          <div className="content">
            {/* <Header /> */}
            <Navbar expand="lg" className="header_loanadvisory">
              <Navbar.Brand href="#home">
                <img src={WhiteLogo} alt="Credochain Logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/">
                    Home
                  </Nav.Link>
                  <Nav.Link as={Link} to="/">
                    Our Solutions
                  </Nav.Link>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      MSME Loans
                    </DropdownToggle>
                    <DropdownMenu className="MSMELoan_dropdown" right>
                      <div className="d-flex">
                        <div className="horizontal-line">
                          <DropdownItem href="../../msme/tools/SME" className="MSMELoan_dropdownItems">
                            Unsecured Business Loan - SME
                          </DropdownItem>
                          <DropdownItem href="../../msme/tools/micro-enterprises" className="MSMELoan_dropdownItems">
                            Unsecured Business Loan - Micro
                          </DropdownItem>
                          <DropdownItem href="../../msme/tools/LAP" className="MSMELoan_dropdownItems">
                            Loan Against Property (LAP)
                          </DropdownItem>
                          <DropdownItem href="../../msme/tools/Balance-Transfer" className="MSMELoan_dropdownItems">
                            Balance Transfer(LAP)
                          </DropdownItem>
                          <DropdownItem href="../../msme/tools/Bank-Od-Limit" className="MSMELoan_dropdownItems">
                            Bank Overdraft Limit (OD Limit){" "}
                          </DropdownItem>
                        </div>
                        <div>
                          <DropdownItem href="../../msme/tools/Bank-Cc-Limit" className="MSMELoan_dropdownItems">
                            Bank CC Limit{" "}
                          </DropdownItem>
                          <DropdownItem href="../../msme/tools/Bank-Term-Loan" className="MSMELoan_dropdownItems">
                            Bank/NBFC Term Loan
                          </DropdownItem>
                          <DropdownItem
                            href="../../msme/tools/Bill-Invoice-Discounting"
                            className="MSMELoan_dropdownItems"
                          >
                            Bill/Invoice Discounting
                          </DropdownItem>
                          <DropdownItem
                            href="../../msme/tools/Dealer-Purchase-Finance"
                            className="MSMELoan_dropdownItems"
                          >
                            Dealer/Purchase Finance
                          </DropdownItem>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Nav.Link as={Link} to="/">
                    Blog
                  </Nav.Link>
                  <Nav.Link as={Link} to="/">
                    Contact Us
                  </Nav.Link>
                  <Nav.Link onClick={this.siginHandler}> Sign in</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <div className="recommendation__wrapper">
              <h2 style={{ fontSize: "3rem" }}>Get free loan recommendations from our digital advisors</h2>
              <p style={{ fontSize: "14px" }}>
                Credochain compares all the possible loans and working capital management solutions available based on
                your business and cashflow requirements to recommend suitable products for your business.
              </p>
              <div className="user-input__wrapper m-0">
                {isCollapsible ? (
                  <div className="edit-button__wrapper">
                    <MdEdit className="edit-button" size={18} onClick={() => this.setState({ isCollapsible: false })} />
                    Modify search
                  </div>
                ) : null}

                <div className="row">
                  <div className="col-md-3 col-sm-6 user-input-sub__wrapper">
                    <div className="heading">Desired Loan Amount</div>
                    {!isCollapsible ? (
                      <>
                        <NumberFormat
                          thousandSeparator={true}
                          thousandsGroupStyle="lakh"
                          prefix={"₹"}
                          className="input-generic__dotted mb-4"
                          onValueChange={(e) => this.inputHandler(e, "loanAmount")}
                          value={loanAmount}
                          name="loanAmount"
                          maxLength={10}
                        />
                        <InputRange
                          maxValue={200000}
                          minValue={0}
                          value={loanAmount}
                          onChange={(value) => this.setInputRangeValue(value, "loanAmount")}
                        />
                      </>
                    ) : (
                      <div className={"align-left"}>₹ {loanAmount}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-6 user-input-sub__wrapper">
                    <div className="heading">Loan Tenure</div>
                    <div className="loan-tenure__wrapper">
                      {!isCollapsible ? (
                        <>
                          <div
                            className={activeSection === "< 12 Months" ? "section active-section" : "section"}
                            onClick={() => this.loanTenureHandler("< 12 Months")}
                          >
                            Less than 12 months
                          </div>
                          <div
                            className={activeSection === "12-36 Months" ? "section active-section" : "section"}
                            onClick={() => this.loanTenureHandler("12-36 Months")}
                          >
                            12 to 36 months
                          </div>
                          <div
                            className={activeSection === ">36 Months" ? "section active-section" : "section"}
                            onClick={() => this.loanTenureHandler(">36 Months")}
                          >
                            More than 36 months
                          </div>
                        </>
                      ) : (
                        <div className={"align-left"}>{activeSection}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6 user-input-sub__wrapper">
                    <div className="heading">Preferred Interest Rate</div>
                    {!isCollapsible ? (
                      <>
                        <div className="interest-rate-input-value">
                          <NumberFormat
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            suffix={"%"}
                            className="input-generic__dotted mb-4"
                            onValueChange={(e) => this.inputHandler(e, "interestRate.min")}
                            value={interestRate.min}
                            name="interestRate"
                          />
                          <NumberFormat
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                            suffix={"%"}
                            className="input-generic__dotted mb-4"
                            onValueChange={(e) => this.inputHandler(e, "interestRate.max")}
                            value={interestRate.max}
                            name="interestRate"
                          />
                        </div>
                        <InputRange
                          maxValue={100}
                          minValue={0}
                          value={interestRate}
                          onChange={(value) => this.setInputRangeValue(value, "interestRate")}
                        />
                      </>
                    ) : (
                      <div className={"align-left"}>{`${interestRate.min} to ${interestRate.max}`}</div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-6 user-input-sub__wrapper">
                    <div className="heading"> Loan Purpose</div>
                    {!isCollapsible ? (
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={loanPurposeList}
                        onChange={(e) => this.selectHandler(e, "loanPurpose")}
                        value={loanPurposeList.find((option) => option.label === loanPurpose)}
                      />
                    ) : (
                      <div className={"align-left"}>{loanPurpose}</div>
                    )}
                  </div>
                </div>
                <div className="business-details__wrapper">
                  <div className="business-details-heading__wrapper">
                    <img src={BusinessDetails} alt="Business Details" />
                    <span className="business-details__heading">Business Details</span>
                    {!isCollapsible ? null : (
                      <span className="details-saved__wrapper">
                        Saved <TiTick className={"details-saved__icon"} />
                      </span>
                    )}
                  </div>
                  {!isCollapsible ? (
                    <>
                      <div className="row no-gutters">
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p>Business Operating Vintage</p>
                            <NumberFormat
                              thousandSeparator={true}
                              thousandsGroupStyle="lakh"
                              className="input-generic__dotted mb-4"
                              onValueChange={(e) => this.inputHandler(e, "businessVintage")}
                              value={businessVintage}
                              name="businessVintage"
                              suffix=" years"
                              maxLength={10}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p>Collateral Available for Hypothecation</p>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              options={primaryCollateralList}
                              onChange={(e) => this.selectHandler(e, "collateral")}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p>Market Value of Primary Collateral</p>
                            <NumberFormat
                              thousandSeparator={true}
                              thousandsGroupStyle="lakh"
                              prefix={"₹"}
                              className="input-generic__dotted mb-4"
                              onValueChange={(e) => this.inputHandler(e, "collateralValue")}
                              value={collateralValue}
                              name="collateralValue"
                              maxLength={10}
                              disabled={!collateral}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p> Women Entrepreneurs</p>
                            <ToggleButton
                              toggleHandler={(name) => this.toggleHandler(name)}
                              name="womenEntrepreneurs"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p>Business registered as MSME under MSME Act</p>
                            <ToggleButton
                              toggleHandler={(name) => this.toggleHandler(name)}
                              name="businessRegistered"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 business-details-sub__wrapper">
                          <div className="input-value__wrapper">
                            <p>Do you use Card/Digital payments</p>
                            <ToggleButton
                              toggleHandler={(name) => this.toggleHandler(name)}
                              name="isDigitalPaymentEnabled"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-center__wrapper">
                        <button
                          className="button__generic"
                          style={{ background: "#3670CC" }}
                          onClick={this.findBestProduct}
                        >
                          View best loan products
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="products__row">
              {findProductsData.map((el) => {
                return (
                  <div className="card__wrapper">
                    <h3>{el.productName}</h3>
                    <div className="grey__text">Why this</div>
                    {el.positives &&
                      el.positives.map((el, index) => {
                        return (
                          <div className={"mt-1"}>
                            <span className={"bullet-green"}>&#9679;</span>
                            {el}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            <div className="submit-gst__wrapper">
              <div className="left-section">
                Submit your Business GST Number to get the best possible loans and apply to them online
              </div>
              <div className="right-section">
                <div className="input-gst__wrapper">
                  <InputMask
                    className="input__generic"
                    placeholder="Enter your GST number"
                    mask={"** - ********** - ***"}
                    value={gstNumber.toUpperCase()}
                    onChange={this.gstInputHandler}
                    maskChar={null}
                    name={"gstNumber"}
                  />
                  <div className="no-gst__link" onClick={() => goTo("/biz360/withoutgst")}>
                    Don’t have a GSTIN? Click here
                  </div>
                </div>

                <button
                  className="button__generic submit-gst__button"
                  onClick={this.addGstHandler}
                  disabled={gstNumber.length < 15}
                >
                  Submit
                </button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
  componentDidMount = () => {
    let { loanAdvisoryData, actions } = this.props,
      { loanAmount, loanPurpose, loanTenure, interestRate } = loanAdvisoryData,
      { findProducts, getLoanUse, getPrimaryCollateral } = actions;
    this.setState(
      {
        loanAmount: loanAmount || 20000,
        loanPurpose,
        activeSection: loanTenure || "12-36 Months",
        interestRate: interestRate || { min: 2, max: 10 },
      },
      () => {
        getLoanUse();
        let { loanAmount, loanTenure, loanPurpose, interestRate } = this.state;
        var dataToSend = {
          loanAmount: loanAmount,
          loanTenure: loanTenure,
          loanUse: loanPurpose,
          maxInterest: interestRate.max,
          minInterest: interestRate.min,
        };
        findProducts(dataToSend);
        getPrimaryCollateral();
      }
    );
  };
}

const mapStateToProps = ({ homePageReducers, loanAdvisoryReducers, data }) => ({
  loanAdvisoryData: homePageReducers.loanAdvisoryData,
  loanPurposeData: homePageReducers.loanPurposeData,
  primaryCollateralData: loanAdvisoryReducers.primaryCollateralData,
  findProductsData: loanAdvisoryReducers.findProductsData,
  loaderModalStatus: data.loaderModalStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      findProducts,
      getLoanUse,
      getPrimaryCollateral,
      loaderModal,
      getTaxPayerData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanAdvisory);
