import React, { Component } from "react";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_OTP_GENERATE } from "../../../utils/APIUrls";
import { tokenGenerator } from "../../../utils/utility";

export default class MobileInputModal extends Component {
  state = {
    mobile: ""
  };
  mobileNumberHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(value)) {
      this.setState({ [name]: value });
    }
  };
  generateOTP = () => {
    let { loaderModal } = this.props.actions,
      { mobile } = this.state,
      dataToSend = {
        data: {
          mobile
        }
      };
    loaderModal(true);
    tokenGenerator().then(async data => {
      await POST(API_POST_OTP_GENERATE, dataToSend).then(({ data }) => {
        if (data.status === "success") {
          this.props.otpSuccessHandler(mobile);
        } else {
          alert(data.message);
        }
      });
    });
    loaderModal(false);
  };
  render() {
    let { mobile } = this.state;
    return (
      <div>
        <input
          type="text"
          className="input__generic padding fs-14 mt-4"
          placeholder="Enter your mobile number to continue"
          name="mobile"
          value={mobile}
          onChange={this.mobileNumberHandler}
          maxLength={10}
        />
        <button className="secondary-button__custom padding mt-2" onClick={this.generateOTP} disabled={mobile.length < 10}>
          Sign In 
        </button>
      </div>
    );
  }
}
