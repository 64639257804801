import React, { Component } from "react";
import InputRange from "react-input-range";
import NumberFormat from "react-number-format";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FinancialDataRequired from "../../assets/Images/StaticPages/FinancialDatarequired.png";
import BusinessVintage from "../../assets/Images/StaticPages/BusinessVintage.png";
import OptimumTurnover from "../../assets/Images/StaticPages/OptimumTurnover.png";
import Highcharts from "highcharts";

export default class calculateEMI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loanAmount: 50000,
      interestRate: 11,
      loanTenure: 12,
      pmt:0,
    };
  }

  setInputRangeValue = (value, name) => {
    this.setState({ [name]: value });
  };
  inputHandler = (e, name) => {
    var value = e.floatValue;
    this.setState({ [name]: value });
  };

  PMT = (ir, nperiod, pv) => {
    let rate = ir / 1200,
      fv = 0,
      type = 0;
    if (!fv) fv = 0;
    if (!type) type = 0;
    if (rate == 0) return -(pv + fv) / nperiod;
    var pvif = Math.pow(1 + rate, nperiod);
    var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);
    if (type == 1) {
      pmt /= 1 + rate;
    }
    return pmt;
  };

  TotalInterest = (pmt, nperiod, pv) => {
    if(pmt){
      if (nperiod){
        if(pv){
          var interestAmount = ((pmt*nperiod)-pv);
        }
      }
    }
    return interestAmount;
  }
  render() {
    const value = 0.66;
    const { businessVintage, optimumTurnover, financialDataRequired } = this.props;
    let { loanAmount, interestRate, loanTenure } = this.state;
    
                    
    return (
      <div className="calculateEMI_wrapper">
        <div className="topBlueContainer" style={{ background: "#3670CC" }}>
          <div className="heading_EMI">Calculate your EMI</div>
          <div className="subHeadings-EMI_wrapper">
            {businessVintage ? (
              <div className="EMI_subHeading_container">
                <img src={BusinessVintage} className="EMI_icon_image" />
                <div>
                  <div className="subHeading_EMI">Business Vintage</div>
                  <div className="subHeadings-data">{businessVintage}</div>
                </div>
              </div>
            ) : null}
            {optimumTurnover ? (
              <div className="EMI_subHeading_container">
                <img src={OptimumTurnover} className="EMI_icon_image" />
                <div>
                  <div className="subHeading_EMI">Optimum Turnover</div>
                  <div className="subHeadings-data">{optimumTurnover}</div>
                </div>
              </div>
            ) : null}
            {financialDataRequired ? (
              <div className="EMI_subHeading_container">
                <img src={FinancialDataRequired} className="EMI_icon_image" />
                <div>
                  <div className="subHeading_EMI">Financial Data required</div>
                  <div className="subHeadings-data">{financialDataRequired}</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="bottomContainer">
          <div className="leftSideContainer col-md-6 col-sm-12">
            <div className="heading-parameters">Set requirement parameters</div>
            <div className="label-parameters">Loan Amount</div>
            <div className="selectDrag_wrapper loan-amount">
              <InputRange
                maxValue={50000000}
                minValue={50000}
                value={loanAmount}
                step={10000}
                onChange={(value) => this.setInputRangeValue(value, "loanAmount")}
              />
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                style={{ width: "20%" }}
                prefix={"₹"}
                className="input-generic__dotted mb-5 tc"
                onValueChange={(e) => this.inputHandler(e, "loanAmount")}
                value={loanAmount}
                name="loanAmount"
                maxLength={12}
              />
            </div>
            <div className="label-parameters">Interest Rate (p.a)</div>
            <div className="selectDrag_wrapper">
              <InputRange
                maxValue={25}
                minValue={11}
                formatLabel={(value) => `${value}%`}
                value={interestRate}
                onChange={(value) => this.setInputRangeValue(value, "interestRate")}
              />
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                suffix={"%"}
                style={{ width: "10%" }}
                className="input-generic__dotted mb-5 tc"
                onValueChange={(e) => this.inputHandler(e, "interestRate")}
                value={interestRate}
                name="interestRate"
              />
            </div>
            <div className="label-parameters">Loan Tenure</div>
            <div className="selectDrag_wrapper loan-amount">
              <InputRange
                maxValue={60}
                minValue={12}
                value={loanTenure}
                formatLabel={(value) => `${value} months`}
                onChange={(value) => this.setInputRangeValue(value, "loanTenure")}
              />
              <NumberFormat
                thousandSeparator={true}
                thousandsGroupStyle="lakh"
                suffix={" months"}
                style={{ width: "18%" }}
                className="input-generic__dotted mb-5 tc"
                onValueChange={(e) => this.inputHandler(e, "loanTenure")}
                value={loanTenure}
                name="loanTenure"
              />
            </div>
          </div>
          <div className="rightSideContainer col-md-6 col-sm-12 tc">
            <div className="pmt-graph">
              <div className="amount-line principal"></div>
              <div className="principal-amount__wrapper">
                <div>Principal Amount</div>
                <div className="principal-amount"><NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    displayType={'text'}
                    prefix={"₹"}
                    value={loanAmount}
                    maxLength={12}
                  /></div>
              </div>
              <div className="amount-line interest"></div>
              <div className="interest-amount__wrapper">
                <div>Interest Amount</div>
                <div className="interest-amount"><NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    displayType={'text'}
                    prefix={"₹"}
                    value={this.TotalInterest(this.PMT(interestRate, loanTenure, -loanAmount), loanTenure, loanAmount).toFixed(2)}
                    maxLength={12}
                  /></div> 
                  {/* {console.log(((this.TotalInterest(this.PMT(interestRate, loanTenure, -loanAmount), loanTenure, loanAmount))/(loanTenure*(this.PMT(interestRate, loanTenure, -loanAmount)))).toFixed(2))} */}


              </div>
              <CircularProgressbar
                strokeWidth={12}
                maxValue={1}
                value={((this.TotalInterest(this.PMT(interestRate, loanTenure, -loanAmount), loanTenure, loanAmount))/(loanTenure*(this.PMT(interestRate, loanTenure, -loanAmount)))).toFixed(2)}
                styles={buildStyles({
                  pathColor: "#001061",
                  connectorColor: '#FFFFFF',
                  trailColor: "#3670cc",
                  strokeLinecap: "square",
                 
                })}
              />
              <div className="monthlyInstallment">
                <div className="emi-amount">
                  <NumberFormat
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    displayType={'text'}
                    prefix={"₹"}
                    style={{fontSize: "2.4rem"}}
                    value={this.PMT(interestRate, loanTenure, -loanAmount).toFixed(2)}
                    maxLength={12}
                  />
                </div>
                <div className="emi-label">Your Monthly Installment</div>
              </div>
            </div>

            <button className="buttonGeneric_Blue">Apply loan with your selected preferences</button>
          </div>
        </div>
      </div>
    );
  }
}
