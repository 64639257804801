import { GET } from "../../utils/WebAPI.service";
import { API_GET_TAX_PAYER_DATA, API_GET_LOAN_USE } from "../../utils/APIUrls";
import * as HomePageConstants from "./homepage.actions.constants";
const getTaxPayerData = gstin => {
  return dispatch => {
    return GET(API_GET_TAX_PAYER_DATA(gstin))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: HomePageConstants.GET_TAX_PAYER_DATA, data: data.data });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const changeGstData = (name, value) => {
  return { type: "CHANGE_GST_DATA", name, value };
};
const loanAdvisoryData = data => {
  return { type: "LOAN_RECOMMENDATION_DATA", data };
};
const editUserNameHandler = (name, value) => {
  return { type: "EDIT_GST_USERNAME", name, value };
};
const getLoanUse = () => {
  return dispatch => {
    return GET(API_GET_LOAN_USE).then(({ data }) => {
      if (data.data) {
        dispatch({ type: HomePageConstants.GET_LOAN_USE, data: data.data.responseList });
      }
      return data;
    });
  };
};
export { getTaxPayerData, changeGstData, loanAdvisoryData, getLoanUse , editUserNameHandler };
