import set from "lodash/set";
import get from "lodash/get";

/**
 *
 * @param state: Redux State
 * @param name: name of Field to be fetch
 * @returns {*}
 * @private
 */
const _getValue = (state, name) => {
    return get(state, [name]);
};

/**
 * @param state: state Values
 * @param name: name of field to be updated
 * @param value: new value
 * @returns {{form: *}}
 * @private
 */

const _updateFormValues = (state, name, value='') => {
    const _preValue = _getValue(state, name);
    if (_preValue !== value) {
        return { ...state, form: get(set(state, `form.values.${name}`, value), 'form') };
    }
    return state;
};

export {
    _updateFormValues
}