import * as COMMON from '../../../store/constant';

const BANK_DEAILS = "CASH_FLOW_BANK_DETAILS";
const BANK_ANALYSIS = "CASH_FLOW_BANK_ANALYSIS";
const EDIT_RATIO="EDIT_RATIO";
const EDIT_RATIO_ACC="EDIT_RATIO_ACC";
const CHANGE_VALUE = Symbol('ChangeValue');
const SET_ENTITY_DETAILS="SET_ENTITY_DETAILS"

export { BANK_DEAILS, BANK_ANALYSIS,EDIT_RATIO,EDIT_RATIO_ACC, COMMON, CHANGE_VALUE,SET_ENTITY_DETAILS };
