import React, { Component } from "react";
export default class OurApproach extends Component {
  state = {
    isHoveredCard1: false,
    isHoveredCard2: false,
    isHoveredCard3: false
  };

  onMouseover = (text, cardNumber) => {
    this.setState({ [cardNumber]: text });
  };
  render() {
    let { isHoveredCard1, isHoveredCard2, isHoveredCard3 } = this.state;
    return (
      <div className="our-approach__wrapper" id="ourApproach">
        <h2 className="blue-heading">Our Approach</h2>
        <p className="montserrat">
          Credochain's technology enabled platform does the heavy lifting so you don't have to run-around and be
          exploited by loan agents. You focus on your business and we support you in getting the best credit.
        </p>
        <div className="row no-gutters">
          <div className="col-md-4 col-sm-12 mb-3">
            <div
              className="approach-card__wrapper card1"
              onMouseEnter={() => this.setState({ isHoveredCard1: true })}
              onMouseLeave={() => this.setState({ isHoveredCard1: false })}
            >
              {isHoveredCard1 ? (
                <div className={ "mt-3 animation" }>
                  <h3 className="">Cashflow based Lending</h3>
                  <div className="row mt-5 ">
                    <div className="col-sm-12">
                      <h3 className="our-approach-heading">Avail cash flow based loans</h3>
                      <p className="our-approach-text">
                        Based on your business’s ability to generate cashflows to service loans, so no collateral
                        required.
                      </p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h3 className="our-approach-heading">Empower yourself to negotiate better with lenders</h3>
                      <p className="our-approach-text">Exercise power of your business data with complete control and privacy.</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h3 className="our-approach-heading">Data driven Credit Advisory</h3>
                      <p className="our-approach-text">
                        Understand how lenders look at your business. How can you avail better rates and credit suitable
                        to your business requirements.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <h2>Cashflow based Lending</h2>
              )}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div
              className="approach-card__wrapper card2"
              onMouseEnter={() => this.setState({ isHoveredCard2: true })}
              onMouseLeave={() => this.setState({ isHoveredCard2: false })}
            >
              {isHoveredCard2 ? (
                <div className= "mt-3 animation" >
                  <h3 >Cluster based</h3>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Digital Record</h4>
                      <p className="our-approach-text">Create digital record for your business by onboarding onto Credochain.</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Better Affordability</h4>
                      <p className="our-approach-text">Improve affordability of rates based on the competitiveness and viability of your cluster.</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Inclusive Growth</h4>
                      <p className="our-approach-text">Share benefits of growth of your cluster.</p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Cluster specific Support</h4>
                      <p className="our-approach-text">Avail other cluster specific support including raw material supply and forward linkages</p>
                    </div>
                  </div>
                </div>
              ) : (
                <h2>Cluster based</h2>
              )}
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div
              className="approach-card__wrapper card3"
              onMouseEnter={() => this.setState({ isHoveredCard3: true })}
              onMouseLeave={() => this.setState({ isHoveredCard3: false })}
            >
              {isHoveredCard3 ? (
                <div className="mt-3 animation">
                  <h3>Digitalized & Automated</h3>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Hassle-Free</h4>
                      <p className="our-approach-text">
                        Get in-principle loan sanction in less than 10 mins. No paper, no lengthy loan application
                        forms; data access is completely digital.
                      </p>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-sm-12">
                      <h4 className="our-approach-heading">Biz360 Report</h4>
                      <p className="our-approach-text"> Create 360 degree view of your business - Completely automated, paperless and Realtime.</p>
                    </div>
                  </div>
                </div>
              ) : (
                <h2>Digitalized & Automated</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
