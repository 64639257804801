import * as ACTION_CONSTANT from './header.action.constant';
import * as Header_Selector from './header.selector';


const initialState = {
    form: {
        values: {},
        errors: {},
    },
    filterOptions: [],
    filterHandler: () => {},
};


const CreditHeaderReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case ACTION_CONSTANT.CHANGE_VALUE:
            return Header_Selector._updateFormValues(state, action.name, action.data);
        
        case ACTION_CONSTANT.UPDATE_FILTER_OPTIONS:
            return { ...state, filterOptions: action.data };
    
        case ACTION_CONSTANT.ATTACH_FILTER_HANDLER:
            return { ...state, filterHandler: action.callback };
            
        default:
            return state;
    }
};

export {
    CreditHeaderReducer
};