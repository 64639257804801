import React, { Fragment } from "react";

export default function OtherLoanProducts() {
  return (
    <Fragment>
      <div className="SME_bottom_container container">
        <div className="label_avoidRisk">
          To avoid these risks try other loan products
        </div>
        <div className="SME_bottom_card_wrapper">
          <div className="SME_bottom_subcard_wrapper">
            <div className="label_loanProductTopics">
              Unsecured Business Loan
            </div>
            <div className="loanProducts_details">
              Unsecured Business Loan for Micro Enterprises is a short term loan
              without collateral for very small or micro enterprises.
            </div>
            <button className="button_Blue">Know more</button>
          </div>
          <div className="SME_bottom_subcard_wrapper">
            <div className="label_loanProductTopics">Bank CC Limit</div>
            <div className="loanProducts_details">
              Cash Credit Limit (CC Limit) is line of credit facility offered
              generally by banks in India which can be used for the business.
            </div>
            <button className="button_Blue">Know more</button>
          </div>
          <div className="SME_bottom_subcard_wrapper">
            <div className="label_loanProductTopics">Bank Overdraft Limit</div>
            <div className="loanProducts_details">
              OD limit account is the account from which you can withdraw an
              amount even if limit has been crossed.
            </div>
            <button className="button_Blue">Know more</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
