import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";
import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class BankCcLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
      businessVintage:"",
      optimumTurnover:"",
      financialDataRequired:""
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title> Bank CC Limit</title>
          <meta
            name="description"
            content="Cash Credit Limit is line of credit facility  by keeping commercial or residential property as collateral.
                  It is termed as secured as in case of default by borrower,
                  bank can recover the debt by selling off the property."
          />
          <meta
            name="tag"
            content="bank cash credit interest calculator,bank cash credit interest rate,bank cash credit account,bank cash credit renewal letter format,bank cash credit in balance sheet,bank cash credit limit,bank cash credit account renewal letter format,bank cash advance credit card,td bank cash credit card,td bank cash credit card login,td bank cash credit card rewards,td bank cash credit card review,td bank cash credit card approval odds,bank cash deposit credit,bank cash credit limit renewal letter format,bank cash credit letter"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center"> Bank CC Limit</div>
              <br />
              <div className="about-details center">
                <p className="fs-16">
                  Cash Credit Limit {"(CC Limit) "}
                  <span className="highlight-blue">is line of credit facility </span>
                  by keeping commercial or residential property as collateral. It is termed as secured as in case of
                  default by borrower, bank can recover the debt by selling off the property.
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="₹50,000+"
              interestRate="9% - 12%"
              loanTenure="10-20 Years"
              decisionTime="Case Dependent"
              processingFee="0.5% - 1%"
              buttonClick=""
              buttonText="Find the Best Offers"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Bank CC Limit </div>
              <div className="sub-heading_card_wrapper">When to opt for Bank CC Limit </div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">Money Buffer</div>
                  Best suited for regular trading and wholesale business where borrower can park in money into CC
                  account and optimise the interest.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Wide Business Range</div>
                  Suitable for wide range of business use like investment in parts, inventory purchase, sale on credit,
                  geographic and product expansion.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Flexible Repayment</div>
                  Pay on what you use and not on what is sanctioned to you.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Business Supportive</div>
                  Improve working capital cycle of the business
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Collateral</div>
                  Current assets Stock and debtors offered
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="horizontal-space" />

          <div className="center negative-margin">
            <CalculateEMI 
              businessVintage="20-30 days"
              optimumTurnover="5 times of limit amount"
              financialDataRequired="Provisional & projected financials"
            />
          </div>
          <div className="horizontal-space" />

          <br />
          <br />
          <div className="why-loan__wrapper extra-padding center">
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Bank CC Limit</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">Approval is time consuming</div>
                <div className="SME_subCard_wrapper  ">
                  Annual renewal is necessary and debtors statements are required every quarter.
                </div>
                <div className="SME_subCard_wrapper  ">Not suitable if one time capital expenditure is planned.</div>
              </div>
            </div>

            <OtherLoanProducts />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
