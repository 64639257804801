import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './reducer';

export default function configureStore(initialState) {
    const middlewares = [thunk, logger];
    let enhancer;
    if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancer = compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__(),
        );
    } else {
        enhancer = compose(applyMiddleware(middlewares[0]));
    }
    return createStore(
        reducers,
        initialState,
        enhancer,
    );
}