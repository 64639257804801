import React, { Component } from "react";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSecondaryIndustry,
  getPrimaryIndustry,
  getBusinessType,
  getConstitution,
  getBusinessAddressType,
} from "../GSTData/GSTData.actions";
import { loaderModal } from "../../../store/actions";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_ADD_BUSINESS } from "../../../utils/APIUrls";
import CommonModal from "../../../components/Modal/modal";
import { FaPencilAlt } from "react-icons/fa";
import addressModal from "../UserAddress/addressModal";
import { goTo, getNewDateFormat, getDMY } from "../../../utils/utility";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
class GstDataInput extends Component {
  state = {
    date: new Date(),
    allNewAddress: [],
    businessPan: "",
  };
  componentDidMount = async () => {
    let { getBusinessType, getConstitution, loaderModal, getBusinessAddressType } = this.props.actions;
    loaderModal(true);
    getBusinessType();
    getBusinessAddressType();
    await getConstitution();
    loaderModal(false);
  };
  selectHandler = async (e, name) => {
    this.setState({ [name]: e.value });
    let { getSecondaryIndustry, getPrimaryIndustry, loaderModal } = this.props.actions;
    loaderModal(true);
    if (name === "businessType") {
      await getPrimaryIndustry(e.value);
    }
    if (name === "primaryIndustry") {
      await getSecondaryIndustry(this.state.businessType, e.value);
    }
    loaderModal(false);
  };

  inputHandler = (e) => {
    var name = e.target.name,
      value = name === "businessPan" ? e.target.value.toUpperCase() : e.target.value;
    if (name === "businessPan" && value.length === 10) {
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      if (regpan.test(value)) {
        this.setState({ [name]: value });
      } else {
        alert("Please enter correct pan");
      }
    } else {
      this.setState({ [name]: value });
    }
  };
  dateHandler = (e, name) => {
    this.setState({ [name]: e });
  };
  submitGSTDataHandler = (e) => {
    e.preventDefault();
    this.addBusinessHandler();
  };
  addBusinessHandler = async () => {
    let {
        allNewAddress,
        businessPan,
        businessName,
        constitution,
        businessType,
        primaryIndustry,
        secondaryIndustry,
        dateOfIncorporation,
        taxpayerType,
        tradeName,
      } = this.state,
      { loaderModal } = this.props.actions;
    var dataToSend = {
      data: {
        address: [...allNewAddress],
        businessName: businessName,
        businessPan: businessPan,
        businessType: businessType,
        constitution: constitution,
        dateOfIncorporation: getDMY(dateOfIncorporation),
        gstData: null,
        gstin: null,
        primaryIndustry: primaryIndustry,
        secondaryIndustry: secondaryIndustry,
        taxpayerType: taxpayerType,
        tradeName: tradeName,
      },
    };
    loaderModal(true);
    sessionStorage.setItem("constitution", constitution);

    await POST(API_POST_ADD_BUSINESS(), dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          sessionStorage.setItem("businessPan", businessPan);
          goTo(this.props.nextScreen);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
    loaderModal(false);
  };
  toggleModal = (type, addressData, addressId) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalType: type, addressId, addressData });
  };
  deleteAddressHandler = () => {
    let { addressId, allNewAddress } = this.state;
    this.setState({
      allNewAddress: allNewAddress.filter((el, index) => {
        return index !== addressId;
      }),
    });
    this.toggleModal("");
  };
  addNewAddress = (data) => {
    this.setState({ allNewAddress: [...this.state.allNewAddress, data] });
  };
  editExistingAddress = (data) => {
    let { addressId } = this.state;
    let allNewAddress = this.state.allNewAddress.slice();
    allNewAddress[addressId] = data;
    this.setState({ allNewAddress });
  };
  render() {
    let {
        businessPan,
        businessName,
        constitution,
        businessType,
        primaryIndustry,
        secondaryIndustry,
        allNewAddress,
        dateOfIncorporation,
        dateOfGSTRegistration,
      } = this.state,
      {
        getConstitutionData,
        getBusinessTypeData,
        getPrimaryIndustryData,
        getSecondaryIndustryData,
        getBusinessAddressTypeData,
      } = this.props;
    let constitutionOptions = getConstitutionData.map((el) => {
      return { value: el, label: el };
    });
    let businessTypeOptions = getBusinessTypeData.map((el) => {
      return { value: el, label: el };
    });
    let primaryIndustryOptions = getPrimaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    let secondaryIndustryOptions = getSecondaryIndustryData.map((el) => {
      return { value: el, label: el };
    });
    return (
      <form onSubmit={this.submitGSTDataHandler}>
        <div className="gstData-input__wrapper">
          <h1>About Your Business</h1>
          <div className="row mt-4">
            <div className="col-md-3 col-sm-6 pl-0">
              <div className="heading">Business PAN</div>
              <input
                type="text"
                className="input__generic w-100"
                name="businessPan"
                value={businessPan}
                onChange={this.inputHandler}
                maxLength={10}
                required
              />
            </div>
            <div className="col-md-9 col-sm-6 pl-0">
              <div className="heading">
                Name of Entity
                <OverlayTrigger
                  key={"top"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`} >
                      Name of the firm for proprietorships and <br />
                      legal name of the entity for other business types
                    </Tooltip>
                  }
                >
                  <FaInfoCircle className="ml-3 cursor-pointer"/>
                </OverlayTrigger>{" "}
              </div>
              <input
                type="text"
                className="input__generic w-100"
                name="businessName"
                value={businessName}
                onChange={this.inputHandler}
                required
              />
            </div>

            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Constitution of Business</div>
              <Select
                className="basic-single mt-4"
                classNamePrefix="select"
                options={constitutionOptions}
                name={"constitution"}
                onChange={(e) => this.selectHandler(e, "constitution")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={constitution} />
            </div>
            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Date of Incorporation</div>
              <DatePicker
                onChange={(e) => this.dateHandler(e, "dateOfIncorporation")}
                value={dateOfIncorporation}
                className="w-100"
                required
                format={"dd/MM/y"}
                showLeadingZeros={true}
              />
            </div>
            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Date of Registration</div>
              <DatePicker
                onChange={(e) => this.dateHandler(e, "dateOfGSTRegistration")}
                className="w-100"
                name="dateOfGSTRegistration"
                value={dateOfGSTRegistration}
                required
                format={"dd/MM/y"}
              />
            </div>
            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Business Type</div>
              <Select
                className="basic-single mt-4"
                classNamePrefix="select"
                name="businessType"
                options={businessTypeOptions}
                onChange={(e) => this.selectHandler(e, "businessType")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={businessType} />
            </div>
            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Primary Industry</div>
              <Select
                className="basic-single mt-4"
                classNamePrefix="select"
                name="primaryIndustry"
                options={primaryIndustryOptions}
                isDisabled={!businessType}
                onChange={(e) => this.selectHandler(e, "primaryIndustry")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={primaryIndustry} />
            </div>
            <div className="col-md-4 col-sm-6 pl-0">
              <div className="heading">Secondary Industry</div>
              <Select
                className="basic-single mt-4"
                classNamePrefix="select"
                isClearable={true}
                name="secondaryIndustry"
                options={secondaryIndustryOptions}
                isDisabled={!primaryIndustry}
                onChange={(e) => this.selectHandler(e, "secondaryIndustry")}
              />
              <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={true} value={secondaryIndustry} />
            </div>
          </div>
          <span className="bold-text">Address</span>
          <span className="optional-text__small ml-2">Please fill in Address Type of the primary address</span>
          <div className="address__wrapper">
            {allNewAddress &&
              allNewAddress.map((el, index) => {
                return (
                  <div className="available-wrapper ml-0" key={index}>
                    <div className="child__space-between">
                      <div> Address {index + 1}</div>
                      <FaPencilAlt onClick={() => this.toggleModal("editAddress", el, index)} />
                    </div>
                    <div className="bold-text" style={{ fontSize: "12px" }}>
                      {el.addressType}({el.ownership})
                    </div>

                    <p className="mt-4">{`${el.line1}, ${el.line2}, ${el.district}, ${el.state}-${el.pinCode}`}</p>
                  </div>
                );
              })}
            <div className="add-wrapper ml-0" onClick={() => this.toggleModal("addAddress")}>
              +Add Address
            </div>
          </div>
          <CommonModal
            isOpen={this.state.modalIsOpen}
            props={this.props}
            type={this.state.modalType}
            addressData={this.state.addressData || []}
            title={"Address"}
            closeAction={() => this.toggleModal("")}
            RenderingComponent={addressModal}
            addNewAddress={(data) => this.addNewAddress(data)}
            deleteAddressHandler={this.deleteAddressHandler}
            editExistingAddress={(data) => this.editExistingAddress(data)}
            addressTypeArray={getBusinessAddressTypeData}
            styles={{
              content: {
                width: "30%",
                minWidth: "400px",
              },
            }}
          />
          <button className="button__generic">Continue</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ GSTDataReducer }) => ({
  getConstitutionData: GSTDataReducer.getConstitutionData,
  getBusinessTypeData: GSTDataReducer.getBusinessTypeData,
  getPrimaryIndustryData: GSTDataReducer.getPrimaryIndustryData,
  getSecondaryIndustryData: GSTDataReducer.getSecondaryIndustryData,
  getTaxPayerTypeData: GSTDataReducer.getTaxPayerTypeData,
  getBusinessAddressTypeData: GSTDataReducer.getBusinessAddressTypeData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSecondaryIndustry,
      getPrimaryIndustry,
      getBusinessType,
      getConstitution,
      loaderModal,
      getBusinessAddressType,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GstDataInput);
