import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import GstDataInput from "./GSTDataInput/GSTDataInput";
import KYC from "./KYC/KYC";
import ReportDownload from "./ReportDownload/ReportDownload";
import { Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

class Biz360withoutOTP extends Component {
  render() {
    let { loaderModalStatus } = this.props;
    return (
      <div className="withoutGst__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <Helmet>
          <title>User details</title>
          <meta name="description" content="Business Report for business" />
          <meta name="description" content="credit Report for business" />
          <meta name="keyword" content="how to apply for business loans,how to apply for msme loans" />
        </Helmet>
        <Switch>
          <Route path="/biz360/withoutGst/gst-data-input" exact component={GstDataInput} />
          <Route path="/biz360/withoutGst/kyc" exact component={KYC} />
          <Route path="/biz360/withoutGst/report-download" exact component={ReportDownload} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModalStatus,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Biz360withoutOTP);
