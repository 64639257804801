import React, { Component } from "react";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_OTP_GENERATE } from "../../../utils/APIUrls";

export default class SignIn extends Component {
  state = {
    mobile: ""
  };
  registerUserHandler = async() => {
    let { mobile } = this.state,
    dataToSend = {
      data: {
        mobile
      }
    };
    await POST(API_POST_OTP_GENERATE, dataToSend).then(({ data }) => {
      if (data.status === "success") {
        this.props.changeModalHandler("OtpScreen" , this.state.mobile);
      } else {
        alert(data.message);
      }
    });
  };
  inputHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (regMobile.test(value)) {
      this.setState({ [name]: value });
    }
  };
  render() {
    let { mobile } = this.state;
    return (
      <div className="signin__wrapper">
        <label className="mt-4">Mobile Number</label>
        <br />
        <input
          type="text"
          className="input__generic w-100"
          onChange={this.inputHandler}
          value={mobile}
          name="mobile"
          maxLength={10}
        />
        <button className="button__generic" onClick={this.registerUserHandler}>
          Confirm
        </button>
        <div className="already-account__wrapper">
          <p>Don’t have an account?</p>
          <p onClick={() => this.props.changeModalHandler("SignUpUserInput")}>
            Click here to create your free Credochain account
          </p>
        </div>
      </div>
    );
  }
}
