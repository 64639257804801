import React, { Component } from 'react'
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import LoanPurposeComponent from "../../ViewsComponents/LoanPurpose/LoanPurpose";
import Header from "../../ViewsComponents/Header/Header";
export default class LoanPurpose extends Component {
    render() {
        return (
            <div className="row no-gutters">
            <div className="col-md-3">
              <SidePanel
                advisories={[
                  "Select the loan purpose to help us recommend the right loan product for you! ",
                  "If you are not sure, just click SKIP to move ahead",
                ]}
              ></SidePanel>
            </div>
            <div className="col-md-9">
              <Header navBar_className={"navbar-light"} backButtonText={"Home"} backLink={"/"} {...this.props}/>
              <LoanPurposeComponent nextScreen={"loan-preference"}/>
            </div>
          </div>
        )
    }
}
