import { GET } from "../../../utils/WebAPI.service";
import { API_GET_BANK_NAMES, API_BANK_DETAILS } from "../../../utils/APIUrls";
import * as BankingConstant from "./Banking.constants";

const getBankNames = () => (dispatch) => {
  return GET(API_GET_BANK_NAMES)
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: BankingConstant.BANK_NAMES, data: data.data });
      }
    })
    .catch((err) => {
      throw err;
    });
};
const getBankDetails = (applicationNumber) => (dispatch) => {
  return GET(API_BANK_DETAILS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: BankingConstant.BANK_DETAILS, data: data.data });
      }
      return data
    })
    .catch((err) => {
      throw err;
    });
};
const eligibilityData = (data) => {
  return { type: BankingConstant.ADD_ELIGIBILITY_DATA, data: data };
};

export { getBankNames, eligibilityData, getBankDetails };
