import React from "react";
import { Fragment } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";
import { Helmet } from "react-helmet";
export default function BalanceTransfer(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Balance Transfer (LAP)</title>
        <meta
          name="description"
          content="Balance transfer (LAP) property is a secured term loan by keeping commercial or residential
                  property as collateral.It is termed as secured as in case of default by borrower, bank
                can recover the debt by selling off the property"
        />
        <meta
          name="tag"
          content="balance transfer loan,balance transfer credit card,balance transfer on emi,a balance transfer check,a balance transfer definition,a balance transfer fee is,is a balance transfer worth it,how a balance transfer works,balance transfer business loan,balance transfer discover,balance transfer emi,balance transfer emi calculator"
        />
      </Helmet>
      <div className="SME__wrapper">
        <div className="header">
          <Header />
        </div>
        <div className="container center">
          <div className="about__wrapper">
            <br />
            <br />
            <div className="about-title center"> Balance Transfer (LAP)</div>
            <br />
            <div className="about-details center">
              <p className="fs-16">
                Balance transfer (LAP) property is a{" "}
                <span className="highlight-blue">
                  secured term loan by keeping commercial or residential property as collateral.
                </span>{" "}
                It is termed as secured as in case of default by borrower, bank can recover the debt by selling off the
                property. Balance transfer (LAP) has the same features as LAP, the only key difference between LAP and
                Balance Transfer (LAP) is that LAP is a fresh funding where amount would be given directly to the
                borrower and in case of balance transfer new lender will close down the loan already running from
                existing lender by paying off directly to existing lender and remaining amount from the sanctioned loan
                would be transferred to borrower account
              </p>
            </div>
          </div>
          <br />
        </div>
        <div className="container">
          <LoanInfoBox
            loanAmount="₹10 Lakhs - ₹5 Crores "
            interestRate="9%"
            loanTenure="10-20 Years"
            decisionTime="6-8 day"
            processingFee="1.5% - 2.5%"
            buttonClick=""
            buttonText="Find the Lowest Rates"
          />
        </div>

        <div className="horizontal-space" />

        <div className="why-loan__wrapper center">
          <br />
          <div className="SME_card_container container">
            <div className="heading_card_wrapper">Why Balance Transfer </div>
            <div className="sub-heading_card_wrapper">When to opt for Balance Transfer</div>

            <div className="SME_card_wrapper">
              <div className="SME_subCard_wrapper">
                <div className="title">Higher loan amount</div>
                Chances of getting higher loan amount on same property
              </div>
              <div className="SME_subCard_wrapper">
                <div className="title">Wide Business Range</div>
                Suitable for wide range of business use like big large expenditure, new product expansion, new geography
                expansion, new office setup.
              </div>
              <div className="SME_subCard_wrapper">
                <div className="title">Business requirement</div>
                Best suited for debt restructuring and interest optimization
              </div>
              <div className="SME_subCard_wrapper">
                <div className="title">Faster Approval</div>
                In comparison to CC/OD Limit
              </div>
            </div>
          </div>
          <br />
        </div>
        <div className="horizontal-space" />

        <div className="center negative-margin">
        <CalculateEMI 
              businessVintage="3 years +"
              financialDataRequired="For higher loan amount"
            />
        </div>

        <br />
        <div className="why-loan__wrapper extra-padding center">
          <div className="SME_card_container container">
            <div className="heading_card_wrapper">Challenges with Unsecured Business Loan- Micro Enterprises</div>
            <div className="SME_card_wrapper">
              <div className="SME_subCard_wrapper  ">Sometime rate become the issue</div>
              <div className="SME_subCard_wrapper  ">Prepayment charges need to be paid to existing lender</div>
              <div className="SME_subCard_wrapper  ">
                Risk of loss of property for borrower in case of default in payments
              </div>
            </div>
          </div>

          <OtherLoanProducts />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
