//homepage
const API_GET_TAX_PAYER_DATA = (gstin) => `/v1/gst/taxpayerData/fetch/${gstin}`,
  API_GET_GENERATE_TOKEN = `/GlobalBackend/v1/ums/byPass/token/generate`,
  API_POST_ADD_WEBSITE_USER_DETAILS = "/v1/ums/byPass/addWebsiteUserDetails";

// Biz360

// with gst
const API_GET_BUSINESS_TYPE = `/v1/master/businessType/fetch`,
  API_GET_BUSINESS_ADDRESS_TYPE = `/v1/master/addressType/fetch`,
  API_GET_CONSTITUTION = `/v1/master/constitution/fetch`,
  API_GET_PRIMARY_INDUSTRY = (businessType) =>
    `/v1/master/primaryIndustry/fetch/${businessType}`,
  API_GET_SECONDARY_INDUSTRY = (primaryIndustry, businessType) =>
    `/v1/master/secondaryIndustry/fetch/${primaryIndustry}/${businessType}`,
  API_POST_GST_OTP_REQUEST = `/v1/gst/otp/initiate`,
  API_POST_VALIDATE_GST_OTP = `/v1/gst/otp/validate`,
  API_GET_RELATIONSHIP = (constitution) =>
    `/v1/master/relation/fetch/${constitution}`,
  API_GET_PROFILE_ADDRESS_TYPE = "/v1/master/profileAddressType/fetch",
  API_POST_ADD_PROFILE = (businessPan) =>
    businessPan
      ? `/v1/profile/create/profile/${businessPan}`
      : `/v1/profile/create`,
  API_POST_UPDATE_PROFILE = (applicationNumber) =>
    `/v1/profile/update/profile/${applicationNumber}`,
  API_POST_ADD_TEMP_USER = "/v1/ums/tempUser/create",
  API_POST_SIGN_UP = "/v1/ums/signUp",
  API_GET_TAX_PAYER_TYPE = "/v1/master/taxPayer/fetch",
  API_POST_GENERATE_USERNAME_LINK = "/v1/gst/userNameLink/initiate",
  API_POST_GST_OTP_LINK = "/v1/gst/otpLink/initiate",
  API_POST_VALIDATE_GST_USERNAME_LINK = (link) =>
    `/v1/gst/byPass/userNameLink/validate/${link}`,
  API_POST_VALIDATE_GST_OTP_LINK = (link, otp) =>
    `/v1/gst/byPass/otpLink/validate/${link}/${otp}`;
//Common API'S

const API_GET_PINCODES = (pincode) => `/v1/master/pincodes/fetch/${pincode}`,
  API_POST_ADD_BUSINESS = (applicationNumber) => {
    return applicationNumber
      ? `/v1/business/create/business/${applicationNumber}`
      : `/v1/business/create`;
  },
  API_GET_GENDER = "/v1/master/gender/fetch",
  API_GET_PROFILE = "/v1/profile/fetch",
  API_GET_VERIFY_LINK = (uuid) => `/v1/gst/byPass/otpLink/verify/${uuid}`;

// Loan Advisory

const API_GET_LOAN_USE = "/v1/master/byPass/loanUse/fetch",
  API_POST_FIND_PRODUCTS = "/v1/advisory/products/fetch",
  API_GET_PRIMARY_COLLATERAL = "/v1/master/primaryCollateral/fetch";

//Biz360 Report

const API_GET_GST_TREND_DETAILS = (pan) => {
    return pan ? `/v1/gst/trend/fetch/${pan}` : "/v1/gst/trend/fetch";
  },
  API_GET_CONCENTRATION_RISK_DETAILS = (pan) => {
    return pan
      ? `/v1/gst/concentrationRisk/fetch/${pan}`
      : "/v1/gst/concentrationRisk/fetch";
  },
  API_GET_TURNOVER_AND_PURCHASES_DETAILS = (pan) => {
    return pan
      ? `/v1/gst/turnoverPurchaseDetails/fetch/${pan}`
      : "/v1/gst/turnoverPurchaseDetails/fetch";
  },
  API_GET_CREDIT_HISTORY_DETAILS = (pan) => {
    return pan
      ? `/v1/behaviour/creditHistory/fetch/${pan}`
      : "/v1/behaviour/creditHistory/fetch";
  },
  API_GET_ALL_APPLICANT_SUMMARY = (pan) => {
    return pan
      ? `/v1/behaviour/applicantsSummary/fetch/${pan}`
      : "/v1/behaviour/applicantsSummary/fetch";
  },
  API_GET_CURRENT_OBLIGATION = (pan) => {
    return pan
      ? `/v1/capacity/obligations/fetch/${pan}`
      : "/v1/capacity/obligations/fetch";
  },
  API_GET_ENTITY_DETAILS = (pan) => {
    return pan
      ? `/v1/business/entity/fetch/${pan}`
      : "/v1/business/entity/fetch";
  },
  API_GET_FIND_PRODUCTS_FOR_BIZ_REPORT = (pan) => {
    return pan
      ? `/v1/advisory/biz360products/fetch/${pan}`
      : "/v1/advisory/biz360products/fetch";
  },
  API_GET_BUSINESS_COMMENTS_FOR_REPORT = (pan) => {
    return pan
      ? `/v1/business/reportComments/fetch/${pan}`
      : "/v1/business/reportComments/fetch";
  };

//Loan Application

const API_POST_B2COTP_VALIDATE = "/v1/ums/b2cOtp/validate",
  API_POST_CREATE_APPLICATION = "/v1/application/create",
  API_POST_UPLOAD_FILES = (applicationNumber, bank, type, sanctionedLimit) =>
    `/v1/bank/upload/banking/${applicationNumber}/${bank}/${type}/${sanctionedLimit}`,
  API_GET_FETCH_ALL_APPLICATION = "/v1/application/all/fetch",
  API_GET_SUBMIT_APPLICATION = (applicationNumber) =>
    `/v1/application/submit/data/${applicationNumber}`,
  API_GET_APPLICATION_DATA = (applicationNumber) =>
    `/v1/application/applicationData/fetch/${applicationNumber}`,
  API_GET_ALL_GST = (applicationNumber) =>
    `/v1/gst/all/fetch/${applicationNumber}`,
  API_GET_GST = `/v1/gst/fetch`,
  API_GET_PROFILE_DATA = (applicationNumber) =>
    `/v1/profile/data/fetch/${applicationNumber}`,
  API_GET_PROFILE_DELETE = (applicationNUmber, profileId) =>
    `/v1/profile/data/delete/${applicationNUmber}/${profileId}`,
  API_GET_ACCOUNT_DELETE = (applicationNumber, accountNumber) =>
    `/v1/bank/remove/account/${applicationNumber}/${accountNumber}`,
  API_GET_APPLICATION_PREVIEW = (applicationNumber) =>
    `/v1/application/preview/fetch/${applicationNumber}`;

//Dashboard

const API_GET_ALL_BUSINESS = "/v1/business/all/fetch";

//master

const API_GET_BANK_NAMES = "/v1/bank/master",
  API_GET_STATES = "/v1/master/states/fetch",
  API_GET_LOAN_AMOUNT = "/v1/master/loanAmount/fetch",
  API_GET_SALES = "/v1/master/sales/fetch";

//ums

const API_GET_USER_DETAILS = "/v1/ums/userDetails/fetch",
  API_POST_LOGIN = "/GlobalBackend/v1/ums/byPass/login",
  API_POST_OTP_GENERATE = "/v1/ums/otp/generate",
  API_USER_LOGOUT = "GET /v1/ums/logout";
 

// Overview

const API_FETCH_ID_VERIFICATION_REPORT = (panNumber, applicationNumber) =>
    `/v1/overview/idverification/report/fetch/${panNumber}/${applicationNumber}`,
  API_FETCH_TURNOVER_DETAILS = (applicationNumber) =>
    `/v1/overview/turnover/fetch/${applicationNumber}`,
  API_FETCH_FORM_FILLED_BY = (applicationNumber) =>
    `/v1/overview/agent/fetch/${applicationNumber}`,
  API_GET_PROFILE_DETAIL = (applicationNumber) =>
    `/v1/overview/profile/fetch/${applicationNumber}`,
  API_GET_BUSINESS_DETAILS = (applicationNumber) =>
    `/v1/business/details/fetch/${applicationNumber}`;

// Business Risk

const API_FETCH_GST_ANNUAL_ANALYSIS = (applicationNum, gstin) =>
    `/v1/businessRisk/annualAnalysis/fetch/${applicationNum}/${gstin}`,
  API_GET_GST_ANALYSIS = (applicationNumber) =>
    `/v1/businessRisk/mergeAnalysis/fetch/${applicationNumber}`;

// CashFlow

const API_BANK_ANALYSIS = (applicationNumber) =>
    `/v1/bank/get/analysis/${applicationNumber}`,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER = (applicationNumber, accountNumber) =>
    `/v1/bank/get/analysis/${applicationNumber}/${accountNumber}`,
  API_BANK_DETAILS = (applicationNumber) =>
    `/v1/bank/bankDetails/fetch/${applicationNumber}`,
  API_EDIT_BOUNCE_RATIO = (applicationNumber) => ``,
  API_EDIT_BOUNCE_RATIO_ACC = (applicationNumber) => ``,
  API_GET_ENTITY_NAME = (applicationNumber) => ``,
  API_SUBMIT_BOUNCE_RATIO_ACC = (applicationNumber) => ``,
  API_SUBMIT_BOUNCE_RATIO = (applicationNumber) => ``,
  API_UPDATE_SANCTIONED_LIMIT = `/v1/bank/updateSanctionedLimit`;

// credit behaviour

const API_POST_UPDATE_BUREAU_TRADE_LINE = (applicationNumber) =>
    `/v1/capacity/tradeLine/update/${applicationNumber}`,
  API_FETCH_ANALYSIS = `/v1/behaviour/analysis/fetch`,
  API_GET_APPLICANTS_SUMMARY = (applicationNumber) =>
    `/v1/behaviour/applicantSummary/fetch/${applicationNumber}`;

// Flags

const API_GET_CAPACITY_FLAGS = (applicationNumber) =>
    `/v1/flag/capacity/fetch/${applicationNumber}`,
  API_GET_BUSINESS_PROFILE_FLAGS = (applicationNumber) =>
    `/v1/flag/businessProfile/fetch/${applicationNumber}`,
  API_GET_BUSINESS_RISK_FLAGS = (applicationNumber) =>
    `/v1/flag/businessRisk/fetch/${applicationNumber}`,
  API_GET_CASH_FLOW_RISK_FLAGS = (applicationNumber) =>
    `/v1/flag/cashFlow/fetch/${applicationNumber}`,
  API_GET_CREDIT_BEHAVIOUR_FLAGS = (applicationNumber) =>
    `/v1/flag/creditBehaviour/fetch/${applicationNumber}`;

// Credit Capacity

const API_GET_EMI_DETAILS = (applicationNumber) =>
    `/v1/capacity/emiDetails/fetch/${applicationNumber}`,
  API_GET_CURRENT_OBLIGATIONS = (applicationNumber) =>
    `/v1/capacity/obligationsByAppNum/fetch/${applicationNumber}`,
  API_GET_MARGIN_DATA = (applicationNumber) =>
    `/v1/master/marginData/fetch/${applicationNumber}`,
  API_GET_TURNOVER_DETAILS = (applicationNumber) =>
    `/v1/overview/turnover/fetch/${applicationNumber}`,
  API_GET_CREDIT_CAPACITY_DETAILS = (applicationNumber) =>
    `/v1/capacity/creditCapacity/fetch/${applicationNumber}`,
  API_GET_LOAN_AMOUNT_TENURE_INTEREST = (applicationNumber) =>
    `/v1/capacity/loanAmountAndTenure/fetch/${applicationNumber}`,
  API_UPDATE_CREDIT_CAPACITY_ANALYSIS = (applicationNumber) =>
    `/v1/capacity/creditCapacity/update/${applicationNumber}`,
  API_ADD_TRADE_LINES = (applicationNumber) =>
    `POST /v1/capacity/tradeLine/update/${applicationNumber}`;

// Final Decision
const API_ADD_REFERENCES = "/v1/references/add",
  API_GET_FINAL_DECISION = (appNum) =>
    `/v1/finalDecision/get/decision/${appNum}`,
  API_GET_DECISION = (appNum) => `/v1/finalDecision/get/decision/${appNum}`,
  API_GET_DEVIATION_MASTER = (applicationNumber) =>
    `/v1/finalDecision/get/decisionSummary/${applicationNumber}`,
  API_GET_ALL_DDS_CODES = "/v1/finalDecision/get/allDdsCodes",
  API_GET_RCU_DOCUMENT_TYPE = "",
  API_GET_RCU_AGENCY_MASTER = (pinCode) => `/get/rcuAgencyMaster/${pinCode}`,
  API_GET_DEVIATION_REASON_MASTER =
    "/v1/finalDecision/get/deviationsReasonMaster",
  API_GET_DEVIATIONS = (applicationNumber) =>
    `/v1/flag/deviations/fetch/${applicationNumber}`,
  API_GET_SPOC_DETAILS = (appNum) => `/v1/spoc/get/details/${appNum}`,
  API_GET_BANKING_DETAILS_FOR_FINAL_DECISION = (applicationNumber) =>
    `/v1/finalDecision/get/bankingDetailsForFinalDecision/${applicationNumber}`,
  API_GET_CASE_HIGHLIGHTS = (applicationNumber) =>
    `/v1/finalDecision/get/caseHighlights/${applicationNumber}`,
  API_GET_LIMIT_UTILIZATION_ANALYSIS = (applicationNumber) =>
    `/v1/bank/get/limitAnalysis/${applicationNumber}`,
  API_INITITATE_FIPD_REQUEST = "/v1/fipd/initiate/fipdRequest",
  API_VERIFY_REFERENCE = (referenceId) =>
    `/v1/references/verify/data/${referenceId}`,
  API_GET_ALL_APPLICANT_SUMMARY_FINAL_DECISION = (applicationNumber) =>
    `/v1/finalDecision/get/businessAndProfileSummary/${applicationNumber}`,
  API_GET_DECISION1 = "",
  API_ADD_DEVIATION = "/v1/finalDecision/add/decisionSummary",
  API_INITIATE_RCU_REQUEST = ` /v1/rcu/initiate/rcuRequest`,
  API_GET_REPAYMENT_TYPE = "/v1/master/get/repaymentType",
  API_ADD_DECISION = "/v1/finalDecision/add/decision",
  API_ADD_MANUAL_DEVIATION = (applicationNumber) =>
    `/v1/finalDecision/add/manualDeviations/${applicationNumber}`,
  API_GET_FIPD_DETAILS = "/v1/fipd/get/fipdDetails",
  API_GET_REFERENCE_RELATION = "/v1/master/get/referenceRelation",
  API_SPOC_DETAILS = "/v1/spoc/add/details",
  API_UPDATE_CMR = "",
  API_EDIT_MITIGATION = (applicationNumber) =>
    `/v1/finalDecision/edit/mitigation/${applicationNumber}`;

//contact us
const API_POST_CONTACT_US = "/v1/ums/byPass/contactUs";

// dashboard

const API_DASHBOARD_FETCH_DATA = "/v1/application/filter",
  API_GET_SEARCHBY_FILTERS = "/v1/master/searchBy/fetch";

//tools
const API_POST_MORATORIUM_CALCULATE = "/v1/moratorium/byPass/calculate/changes";

// document upload

const API_GET_DOCUMENT_MASTER = (applicationNumber) =>
    `/v1/document/master/fetch/${applicationNumber}`,
  API_POST_BUSINESS_DOCUMENT = (
    applicationNumber,
    businessId,
    docType,
    docName
  ) =>
    `/v1/document/business/upload/${applicationNumber}/${businessId}/${docType}/${docName}`,
  API_POST_PROFILE_DOCUMENT = (
    applicationNumber,
    profileId,
    docType,
    docName
  ) =>
    `/v1/document/profile/upload/${applicationNumber}/${profileId}/${docType}/${docName}`;

//Landing Page

const API_GET_BUSINESS_TYPE_FETCH = "/v1/master/businessType/fetch",
  API_GET_lOAN_AMOUNT_FETCH = "/v1/master/loanAmount/fetch",
  API_GET_SALES_FETCH = "/v1/master/sales/fetch",
  API_GET_STATES_FETCH = "/v1/master/states/fetch",
  API_POST_LOAN_ADVISORY_DETAILS = "/v1/ums/byPass/loanAdvisory";

export {
  API_GET_TAX_PAYER_DATA,
  API_GET_GENERATE_TOKEN,
  API_GET_BUSINESS_TYPE,
  API_GET_BUSINESS_ADDRESS_TYPE,
  API_GET_CONSTITUTION,
  API_GET_PRIMARY_INDUSTRY,
  API_GET_SECONDARY_INDUSTRY,
  API_GET_PINCODES,
  API_POST_ADD_BUSINESS,
  API_GET_TAX_PAYER_TYPE,
  API_POST_GST_OTP_REQUEST,
  API_POST_VALIDATE_GST_OTP,
  API_GET_RELATIONSHIP,
  API_GET_PROFILE_ADDRESS_TYPE,
  API_POST_ADD_PROFILE,
  API_GET_GENDER,
  API_GET_PROFILE,
  API_POST_ADD_TEMP_USER,
  API_POST_SIGN_UP,
  API_GET_LOAN_USE,
  API_GET_GST_TREND_DETAILS,
  API_GET_CONCENTRATION_RISK_DETAILS,
  API_GET_TURNOVER_AND_PURCHASES_DETAILS,
  API_GET_CREDIT_HISTORY_DETAILS,
  API_GET_ALL_APPLICANT_SUMMARY,
  API_GET_CURRENT_OBLIGATION,
  API_GET_ENTITY_DETAILS,
  API_GET_FIND_PRODUCTS_FOR_BIZ_REPORT,
  API_POST_GENERATE_USERNAME_LINK,
  API_POST_GST_OTP_LINK,
  API_GET_VERIFY_LINK,
  API_POST_VALIDATE_GST_USERNAME_LINK,
  API_POST_VALIDATE_GST_OTP_LINK,
  API_POST_FIND_PRODUCTS,
  API_GET_PRIMARY_COLLATERAL,
  API_GET_BUSINESS_COMMENTS_FOR_REPORT,
  API_POST_B2COTP_VALIDATE,
  API_POST_CREATE_APPLICATION,
  API_GET_ALL_BUSINESS,
  API_POST_UPDATE_PROFILE,
  API_GET_BANK_NAMES,
  API_POST_UPLOAD_FILES,
  API_GET_FETCH_ALL_APPLICATION,
  API_GET_SUBMIT_APPLICATION,
  API_GET_USER_DETAILS,
  API_POST_LOGIN,
  API_FETCH_ID_VERIFICATION_REPORT,
  API_GET_BUSINESS_DETAILS,
  API_GET_PROFILE_DETAIL,
  API_FETCH_FORM_FILLED_BY,
  API_FETCH_TURNOVER_DETAILS,
  API_FETCH_GST_ANNUAL_ANALYSIS,
  API_GET_GST_ANALYSIS,
  API_BANK_ANALYSIS,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER,
  API_BANK_DETAILS,
  API_EDIT_BOUNCE_RATIO,
  API_EDIT_BOUNCE_RATIO_ACC,
  API_GET_ENTITY_NAME,
  API_SUBMIT_BOUNCE_RATIO_ACC,
  API_SUBMIT_BOUNCE_RATIO,
  API_UPDATE_SANCTIONED_LIMIT,
  API_POST_UPDATE_BUREAU_TRADE_LINE,
  API_FETCH_ANALYSIS,
  API_GET_APPLICANTS_SUMMARY,
  API_GET_CAPACITY_FLAGS,
  API_GET_BUSINESS_PROFILE_FLAGS,
  API_GET_BUSINESS_RISK_FLAGS,
  API_GET_CASH_FLOW_RISK_FLAGS,
  API_GET_CREDIT_BEHAVIOUR_FLAGS,
  API_GET_EMI_DETAILS,
  API_GET_CURRENT_OBLIGATIONS,
  API_GET_MARGIN_DATA,
  API_GET_TURNOVER_DETAILS,
  API_GET_CREDIT_CAPACITY_DETAILS,
  API_GET_LOAN_AMOUNT_TENURE_INTEREST,
  API_ADD_TRADE_LINES,
  API_UPDATE_CREDIT_CAPACITY_ANALYSIS,
  API_ADD_REFERENCES,
  API_GET_FINAL_DECISION,
  API_GET_DECISION,
  API_GET_DEVIATION_MASTER,
  API_GET_ALL_DDS_CODES,
  API_GET_RCU_DOCUMENT_TYPE,
  API_GET_RCU_AGENCY_MASTER,
  API_GET_DEVIATION_REASON_MASTER,
  API_GET_DEVIATIONS,
  API_GET_SPOC_DETAILS,
  API_GET_BANKING_DETAILS_FOR_FINAL_DECISION,
  API_GET_CASE_HIGHLIGHTS,
  API_GET_LIMIT_UTILIZATION_ANALYSIS,
  API_INITITATE_FIPD_REQUEST,
  API_VERIFY_REFERENCE,
  API_GET_ALL_APPLICANT_SUMMARY_FINAL_DECISION,
  API_GET_DECISION1,
  API_ADD_DEVIATION,
  API_INITIATE_RCU_REQUEST,
  API_GET_REPAYMENT_TYPE,
  API_ADD_DECISION,
  API_ADD_MANUAL_DEVIATION,
  API_GET_FIPD_DETAILS,
  API_GET_REFERENCE_RELATION,
  API_SPOC_DETAILS,
  API_UPDATE_CMR,
  API_EDIT_MITIGATION,
  API_POST_OTP_GENERATE,
  API_DASHBOARD_FETCH_DATA,
  API_GET_SEARCHBY_FILTERS,
  API_USER_LOGOUT,
  API_POST_MORATORIUM_CALCULATE,
  API_POST_ADD_WEBSITE_USER_DETAILS,
  API_GET_APPLICATION_DATA,
  API_GET_ALL_GST,
  API_GET_PROFILE_DATA,
  API_GET_GST,
  API_GET_PROFILE_DELETE,
  API_GET_ACCOUNT_DELETE,
  API_GET_APPLICATION_PREVIEW,
  API_GET_DOCUMENT_MASTER,
  API_POST_BUSINESS_DOCUMENT,
  API_POST_PROFILE_DOCUMENT,
  API_POST_CONTACT_US,
  API_GET_LOAN_AMOUNT,
  API_GET_STATES,
  API_GET_SALES,
  API_GET_BUSINESS_TYPE_FETCH,
  API_GET_lOAN_AMOUNT_FETCH,
  API_GET_SALES_FETCH,
  API_GET_STATES_FETCH,
  API_POST_LOAN_ADVISORY_DETAILS,
};
