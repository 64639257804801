import React from "react";
import AdvisoryClip from "../../../assets/Images/AdvisoryClip.png";
import WhiteLogo1 from "../../../assets/Images/WhiteLogo2x.png";
export default function SidePanel(props) {
  let { advisories } = props;
  return (
    <div className="advisory__wrapper">
      <div className="logo">
        <img src={WhiteLogo1} alt="White Logo" className="logo-image" />
      </div>
      <div className="main__content">
        <h1>Get your BIZ360 Report</h1>
        {advisories &&
          advisories.map((el, index) => {
            return (
              <h3 className="advisory" key={index}>
                <img src={AdvisoryClip} alt="Advisory Clip" className="advisory-clip" />
                {el}
              </h3>
            );
          })}
      </div>
      <div className="video">{props.children}</div>
    </div>
  );
}
