import React, { Component } from "react";
import DummyImage from "../../../assets/Images/HomePage/ComparedOffers.png";
export default class LendingPartners extends Component {
  render() {
    return (
      <div className="lending-partners__wrapper">
        <div className="row">
          <div className="col-sm-5">
            <h2>Our Lending Partners</h2>
            <p>We partner with lenders that you love to get you unmatched experiences.</p>
            <q>There’s a perfect lender for every borrower</q>
          </div>
          <div className="col-sm-7 images-wrapper">
            <div className="img__row">
              <img src={DummyImage} alt="" />
              <img src={DummyImage} alt="" />
              <img src={DummyImage} alt="" />
            </div>
            <div className="img__row">
              <img src={DummyImage} alt="" />
              <img src={DummyImage} alt="" />
              <img src={DummyImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
