import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchAllBusiness } from "../Business.actions";
import { goTo } from "../../../../utils/utility";
import InputMask from "react-input-mask";
import { getTaxPayerData } from "../../../HomePage/homepage.actions";
import { loaderModal } from "../../../../store/actions";
class myBusinesses extends Component {
  s;
  state = {
    gstNumber: "",
  };
  businessHandler = (el) => {
    sessionStorage.setItem("businessPan", el.businessPan);
    el.isGstPresent ? goTo("/dashboard/business/biz360-details") : goTo("/dashboard/business/credit-details");
  };
  gstInputHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value ? e.target.value.replace(/[- ]+/g, "") : "";
    this.setState({ [name]: value });
  };
  generateBizHandler = async () => {
    let { loaderModal, getTaxPayerData } = this.props.actions;
    loaderModal(true);
    await getTaxPayerData(this.state.gstNumber).then(({ data }) => {
      if (data && data.gstin) {
        document.cookie = `gstNumber=${this.state.gstNumber.toUpperCase()}; path=/`;
        goTo("/dashboard/business/add-business-gst-data/gst-data");
      } else {
        alert("We are unable to fetch your data. Please try again later");
      }
      loaderModal(false);
    });
  };
  render() {
    let { allBusinessData } = this.props,
      { gstNumber } = this.state;
    return (
      <Fragment>
        <div className=" row my-business__wrapper">
          <div className="col-md-12 header_div">
            <div className="header_MyBusiness">My Business</div>
            <div className="business_profile">
              <div className="profile_image"></div>
              <div className="profile_name">Bhiku Mhatre</div>
            </div>
          </div>
          <div className="col-md-8">
            <div className=" main-box__wrapper">
              {allBusinessData.map((el) => {
                return (
                  <div className=" sub-box__wrapper" key={el.businessId} onClick={() => this.businessHandler(el)}>
                    <div className="heading_content">{el.entityName}</div>
                    <div className="Biz360Generated content">{el.lastReportGenerationDate}</div>
                  </div>
                );
              })}
            </div>
            <div className="main-box__wrapper mt-2">
              <div className="sub-box__wrapper GreyColor">
                <div className="heading_black">Add a new Business</div>
                <div className="Biz360Generated addnewBusiness">
                  Get Biz360 report and apply for loan for a new business
                </div>
                <InputMask
                  className="input-field mr-4 w-100"
                  placeholder="Enter your GST number"
                  mask={"** - ********** - ***"}
                  value={gstNumber.toUpperCase()}
                  onChange={this.gstInputHandler}
                  maskChar={null}
                  name={"gstNumber"}
                />
                <div className="btn_div">
                  <button
                    className="buttonGeneric_Blue"
                    disabled={gstNumber.length < 15}
                    onClick={this.generateBizHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Commented for notifications(as we don't have) */}
          {/* <div className="col-md-4">
            <div className="card_wrapper" style={{ height: "130vh" }}>
              <div className="notification_div">
                <div className="heading_notify">Notification</div>
                <div className="heading_showAll">Show all</div>
              </div>
              <div className="notifications_sub-div">
                <div className="circle"></div>
              </div>
              <div className="notifications_sub-div">
                <div className="circle"></div>
              </div>
              <div className="notifications_sub-div">
                <div className="circle"></div>
              </div>
            </div>
          </div> */}
        </div>
      </Fragment>
    );
  }
  componentDidMount = () => {
    let { fetchAllBusiness } = this.props.actions;
    fetchAllBusiness();
    sessionStorage.clear();
    document.cookie = "gstNumber=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = "businessPan=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
}

const mapStateToProps = ({ MyBusinessReducer }) => ({
  allBusinessData: MyBusinessReducer.allBusinessData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAllBusiness,
      getTaxPayerData,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(myBusinesses);
