import * as GstNumberConstants from "./GstNumber.actions.constants";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_ALL_GST, API_GET_GST } from "../../../utils/APIUrls";

const fetchBusinessDetails = (applicationNumber) => (dispatch) => {
  return GET(API_GET_ALL_GST(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GstNumberConstants.FETCH_BUSINESS_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getGSTData = () => (dispatch) => {
  return GET(API_GET_GST)
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GstNumberConstants.GET_GST_DATA, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export { fetchBusinessDetails, getGSTData };
