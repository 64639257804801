import React, { Component } from "react";
import { API_INITITATE_FIPD_REQUEST } from "../../../utils/APIUrls";
import { POST, GET } from "../../../utils/WebAPI.service";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRCUAgencyMaster} from "./finalDecision.action";
import Select from "react-select";
import Edit from "../../../assets/Images/Vector.svg";

class Fiypd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chequeBounces: 0,
      totalChequesIssued: 0,
      status: "Inward",
      bounceRatio: 0,
      pinCodes: [],
      pinCodeLoader: false,
      state: "",
      city: "",
      district: "",
      area: "",
      pin: "",
      addressButton: -1,
      rcuInitiateBtn: -1,
      agencyEdit:-1,
      agencyList:[]
    };
    this.searchPinCode = null;
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  bounceRatioFun = br => {
    this.setState({
      bounceRatio: br
    });
    this.props.sendData(br);
  };

  submit = event => {
    event.preventDefault();
    this.props.closeAction();
  };

  rcuInitiate = (event, index) => {
    event.preventDefault();
    let { fiypd, props } = this.props;
    let applicationNumber = get(props.match, "params.appNo", "");
    var comment = document.querySelector(
      `textarea[name='rcu-comment1${index}back']`
    ).value;
    if(this.state.agencyEdit!==-1){
      var agency = document.querySelector(
        `select[name='rcu-agency1${index}back']`
      ).value;

    }
    else{
      var agency=fiypd.verificationDetails[index].agency;

    }
    var verificationStatus = null;
    var line1 = "";
    var line2 = "";
    var pinCode = "";
    var city = "",
      state = "",
      district = "";

    if (this.state.addressButton !== index) {
      line1 = fiypd.verificationDetails[index].address.line1;
      line2 = fiypd.verificationDetails[index].address.line2;
      pinCode = this.state.pin;
      state = this.state.state;
      city = this.state.city;
      district = this.state.district;
    } else {
      line1 = document.querySelector(
        `input[name='rcu-addressLine1${index}back']`
      ).value;
      line2 = document.querySelector(
        `input[name='rcu-addressLine2${index}back']`
      ).value;
      pinCode = this.state.pin;
      state = this.state.state;
      city = this.state.city;
      district = this.state.district;
    }

    var address = {
      addressType: fiypd.verificationDetails[index].address.addressType,
      city,
      district,
      durationOfStay: fiypd.verificationDetails[index].address.durationOfStay,
      line1,
      line2,
      ownership: fiypd.verificationDetails[index].address.ownership,
      pinCode,
      state
    };
    var verificationId = fiypd.verificationDetails[index].verificationId;
    var entity = fiypd.verificationDetails[index].entity;
    var isAddressEdited = this.state.addressButton;

    var data = {
      address,
      agency,
      applicationNumber,
      // category,
      comment,
      entity,
      isAddressEdited,
      verificationId,
      verificationStatus
    };
    let { loaderModal } = props.actions;
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    POST(API_INITITATE_FIPD_REQUEST, { data }, { headers })
      .then(async ({ data, status }) => {
        if (data.status === "success") {
          alert(data.message);
          loaderModal(false);
          await this.setState({
            addressButton: -1,
            rcuInitiateBtn: index
          });
          this.props.closeAction();
        } else {
          alert(data.message);
          loaderModal(false);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
      });
  };

  rcuUpdate = (event, index) => {
    event.preventDefault();
    let { fiypd, props } = this.props;
    let applicationNumber = get(props.match, "params.appNo", "");
    var comment = document.querySelector(
      `textarea[name='rcu-comment1${index}back']`
    ).value;
    if(this.state.agencyEdit!==-1){
      var agency = document.querySelector(
        `select[name='rcu-agency1${index}back']`
      ).value;

    }
    else{
      var agency=fiypd.verificationDetails[index].agency;

    }
    var verificationStatus = document.querySelector(
      `select[name='rcu-status1${index}back']`
    ).value;
    var line1 = "";
    var line2 = "";
    var pinCode = "";
    var city = "",
      state = "",
      district = "";
    if (this.state.addressButton !== index) {
      line1 = fiypd.verificationDetails[index].address.line1;
      line2 = fiypd.verificationDetails[index].address.line2;
      pinCode = this.state.pin;
      state = this.state.state;
      city = this.state.city;
      district = this.state.district;
    } else {
      line1 = document.querySelector(
        `input[name='rcu-addressLine1${index}back']`
      ).value;
      line2 = document.querySelector(
        `input[name='rcu-addressLine2${index}back']`
      ).value;
      pinCode = this.state.pin;
      state = this.state.state;
      city = this.state.city;
      district = this.state.district;
    }
    var address = {
      addressType: fiypd.verificationDetails[index].address.addressType,
      city,
      district,
      durationOfStay: fiypd.verificationDetails[index].address.durationOfStay,
      line1,
      line2,
      ownership: fiypd.verificationDetails[index].address.ownership,
      pinCode,
      state
    };
    var verificationId = fiypd.verificationDetails[index].verificationId;
    var entity = fiypd.verificationDetails[index].entity;
    var isAddressEdited = this.state.addressButton;
    var data = {
      address,
      agency,
      applicationNumber,
      comment,
      entity,
      isAddressEdited,
      verificationId,
      verificationStatus
    };
    let { loaderModal } = props.actions;
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    POST(API_INITITATE_FIPD_REQUEST, { data }, { headers })
      .then(async ({ data, status }) => {
        if (data.status === "success") {
          alert(data.message);
          loaderModal(false);
          await this.setState({
            addressButton: -1,
            rcuInitiateBtn: index
          });
          this.props.finalDataReferesh()
          this.props.closeAction();
        } else {
          alert(data.message);
          loaderModal(false);
        }
      })
      .catch(err => {
        console.log(err);
        loaderModal(false);
      });
  };

  setPinCode = e => {
    if(typeof e!=undefined && e.length>-1){
      this.setState(
        {
          city: e[0].city,
          state: e[0].state,
          area: e[0].area,
          district: e[0].district,
          pin: e[0].value
        }
      );   
    }
    else{
      this.setState(
        {
          city: e.city,
          state: e.state,
          area: e.area,
          district: e.district,
          pin: e.value
        }
      );

    }
   
  };

  setInputPinCode = (e) => {
    clearTimeout(this.searchPinCode);
    this.searchPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            pinCodeLoader: true
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  pinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    pinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      state: list[i].state,
                      area: list[i].area,
                      district: list[i].district
                    });
                  }
                }

                this.setState({
                  pinCodeLoader: false,
                  pinCodes
                });
                this.setPinCode(pinCodes)
              } else {
                this.setState({ pinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };

  setButton =  index => {
    this.setState({
      addressButton: index
    });
    const { fiypd } = this.props;
    let pinCode=  fiypd.verificationDetails[index].address
         .pinCode
    
    pinCode && this.setInputPinCode(pinCode);
  
  };
  
  setAgency = async (e,index) => {
    e.preventDefault();
    this.setState({
      agencyEdit: index
    });
    const { fiypd,actions } = this.props;
        await actions.getRCUAgencyMaster(this.state.pin) 
  };

  inputText = e => {
    this.setState({
      line1: e.target.value
    });
  };
  inputText2 = e => {
    this.setState({
      line2: e.target.value
    });
  };

  componentDidMount() {
    let { fiypd } = this.props;
    let pinCode=  fiypd.verificationDetails[0].address
         .pinCode
    pinCode && this.setInputPinCode(pinCode);
    fiypd.verificationDetails.map((el, index) => {
      if(this.state.agencyEdit!==-1){
        (document.querySelector(
          `select[name='rcu-agency1${index}back']`
        ).value = fiypd.verificationDetails[index].agency)

      }
      return (
        (document.querySelector(
          `select[name='rcu-status1${index}back']`
        ).value = fiypd.verificationDetails[index].verificationStatus)
      );
    }); 
  }
  render() {
    const { pinCodeLoader, pinCodes } = this.state,
      { fiypd ,count,
        businessDetails,
        businessFormIndex,agencyMaster} = this.props,
        
      pinCode = get(
        businessDetails,
        `[${businessFormIndex}].addressDetails[${count}].address.pinCode`
      );
    return (
      <form onSubmit={this.submit} className="edit-ratio-form1">
        <div className="box-wrapper">
          <div className="box-header">
            <h4>{fiypd.verificationDetails[0].entity}</h4>
          </div>
          <div className={"table-box1"}>
            <table className={"primary-table"}>
              <thead>
                <tr>
                  <th style={{ width: "200px" }}>Address</th>
                  <th>Comment</th>
                  <th>Agency</th>
                  <th>Action</th>
                  <th>Status</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {fiypd.verificationDetails
                  ? fiypd.verificationDetails.map((el, index) => {
                      return (
                        <tr key={index} style={{ marginBottom: "5px" }}>
                          {this.state.addressButton === index ? (
                            <td>
                              <input
                                name={`rcu-addressLine1${index}back`}
                                className="input-field"
                                defaultValue={el.address.line1}
                                onChange={this.inputText}
                              />
                              <input
                                name={`rcu-addressLine2${index}back`}
                                className="input-field mt-1"
                                defaultValue={el.address.line2}
                                onChange={this.inputText2}
                              />
                              {/* <td className={"select"}> */}
                              <Select
                                value={pinCodes.find(
                                  option =>
                                    option.value ===
                                    fiypd.verificationDetails[index].address
                                      .pinCode
                                )}
                                placeholder={"Pin Code"}
                                isSearchable={true}
                                name={"pinCode"}
                                options={pinCodes}
                                className="pin-code-select-search"
                                classNamePrefix="select"
                                onInputChange={this.setInputPinCode}
                                onChange={this.setPinCode}
                                isLoading={pinCodeLoader}
                              />
                              {/* </td> */}
                            </td>
                          ) : (
                            <td>
                              {el.address.line1},{el.address.line2},
                              {el.address.pincode}
                              {el.address.city},
                              {
                                <button
                                  onClick={() => this.setButton(index)}
                                  className="btnIcon"
                                >
                                  <img
                                    src={Edit}
                                    alt="Edit Icon"
                                    className="editIcon"
                                  />
                                </button>
                              }
                            </td>
                          )}
                          <td>
                            <textarea
                              name={`rcu-comment1${index}back`}
                              className="textArea-input-field"
                              defaultValue={el.comment}
                            />
                          </td>

                          {this.state.agencyEdit===index ? (
                            <td>
                             <select
                              className={"select-box"}
                              name={`rcu-agency1${index}back`}
                              // value={props.options.filter(option => option.label === 'Some label')}
                            >
                              <option value="" defaultValue>Select Agency</option>
                             {agencyMaster.map((item =>
                               <option key={item.name} value={item.name} >{item.name}</option>

                             ))}
                              
                              
                            </select>
                             
                            </td>
                          ) : (
                            <td>
                              {el.agency}
                              {
                                <button
                                  onClick={(e) => this.setAgency(e,index)}
                                  className="btnIcon"
                                >
                                  <img
                                    src={Edit}
                                    alt="Edit Icon"
                                    className="editIcon"
                                  />
                                </button>
                              }
                            </td>
                          )}
                          <td>
                            {el.isInitiated ? (
                              <button
                                className="btn"
                                onClick={event =>
                                  this.rcuInitiate(event, index)
                                }
                              >
                                Reinitiate
                              </button>
                            ) : this.state.rcuInitiateBtn === index ? (
                              <button
                                className="btn"
                                onClick={event =>
                                  this.rcuInitiate(event, index)
                                }
                              >
                                Reinitiate
                              </button>
                            ) : (
                              <button
                                className="btn"
                                onClick={event =>
                                  this.rcuInitiate(event, index)
                                }
                              >
                                Initiate
                              </button>
                            )}
                          </td>
                          <td>
                            <select
                              className={"select-box"}
                              name={`rcu-status1${index}back`}
                            >
                              <option value={""} defaultValue>Select status</option>
                              <option value={"positive"}>Positive</option>
                              <option value={"negative"}>Negative</option>
                              <option value={"referToCredit"}>
                                Refer To Credit
                              </option>
                              <option value={"waived"}>Waived</option>
                            </select>
                          </td>
                          <td>
                            <button
                              className="btn"
                              onClick={event =>
                                this.rcuUpdate(
                                  event,
                                  index
                                  // el.verificationId,
                                  // "Update"
                                )
                              }
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btns-box"
                style={{ marginRight: "20px" }}
                onClick={() =>
                  this.setState({ addRCU: [...this.state.addRCU, 1] })
                }
              >
                + Add Row
              </button>
            </div> */}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({  listData,gstnAddressReducers,finalDecision }) => ({
  //responseList:finalDecision.responseList
  statesList: listData.statesList,
  businessDetails: gstnAddressReducers.businessDetails,
  agencyMaster:finalDecision.agencyMaster
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {

      getRCUAgencyMaster
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fiypd);
