import React, { Component } from "react";
import { POST } from "../../../utils/WebAPI.service";
import get from "lodash/get";
import { API_UPDATE_SANCTIONED_LIMIT } from "../../../utils/APIUrls";
// import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBankDetails, getBankingAnalysis } from "./cashflow.action";
import { loaderModal } from "../../../store/actions";
class LimitUtilizationEditForm extends Component {
  state = { sectionLimitAmount: 0, error: "" };

  handleChange = event => {
    this.setState({ sectionLimitAmount: Math.round(event.target.value) });
  };
  componentDidMount() {
    this.setState({ sectionLimitAmount: this.props.props.sanctionLimit });
  }

  submit = async event => {
    event.preventDefault();
    let { accountNumber, applicationNumber } = this.props.props,
      { getBankDetails,getBankingAnalysis } = this.props.actions,
      { sectionLimitAmount } = this.state;
    if (accountNumber && accountNumber && sectionLimitAmount) {
      let data = {
        accountNumber,
        applicationNumber,
        sanctionedLimit: sectionLimitAmount
      };
      const headers = { "Content-Type": "application/json" };
      await POST(API_UPDATE_SANCTIONED_LIMIT, data, { headers })
        .then(({ data }) => {
          let { status, message } = data;
          if (status === "success") {
            this.props.closeAction();
            getBankDetails(applicationNumber);
            getBankingAnalysis(applicationNumber)
          } else {
            this.setState({ error: message });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            error: "Something went wrong please try again later."
          });
        });
    } else {
      this.setState({
        error: "Please enter Sanctioned Limit."
      });
    }
  };

  render() {
    const { sectionLimitAmount } = this.state;
    return (
      <form onSubmit={this.submit} className="edit-ratio-form">
        <div className="input-container">
          <label htmlFor="sectionLimitAmount">
            Enter Sanction Limit Amount
          </label>
          <input
            className="input credit-input"
            name="sectionLimitAmount"
            id="sectionLimitAmount"
            placeholder="Enter Sanction Limit Amount"
            type="number"
            value={Number(sectionLimitAmount)}
            onChange={this.handleChange}
          />
        </div>
        {/* {this.state.error ? (
          <ErrorMessage>{this.state.error}</ErrorMessage>
        ) : null} */}
        <div className="row">
          <button className="submit" type="submit" onClick={this.submit}>
            Submit
          </button>
        </div>
      </form>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getBankDetails,
      loaderModal,
      getBankingAnalysis
    },
    dispatch
  )
});
export default connect(
  null,
  mapDispatchToProps
)(LimitUtilizationEditForm);
