import * as LoanPurposeConstant from "./LoanPurpose.actions.constant";
import { GET } from "../../../utils/WebAPI.service";
import { API_GET_LOAN_USE } from "../../../utils/APIUrls";
const fetchLoanUse = () => (dispatch) => {
  return GET(API_GET_LOAN_USE).then(({ data }) => {
    if (data.data) {
      dispatch({ type: LoanPurposeConstant.FETCH_LOAN_USE, data: data.data });
    }
  });
};

export { fetchLoanUse };
