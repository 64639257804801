import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { getBankNames, eligibilityData, getBankDetails } from "./Banking.actions";
import { POST, GET } from "../../../utils/WebAPI.service";
import { API_POST_UPLOAD_FILES, API_GET_SUBMIT_APPLICATION , API_GET_ACCOUNT_DELETE } from "../../../utils/APIUrls";
import { getCookie, goTo } from "../../../utils/utility";
import { loaderModal } from "../../../store/actions";
import LoaderModal from "../../../components/LoaderModal/loaderModal";
import { MdDeleteForever , MdPictureAsPdf } from "react-icons/md";

class Banking extends Component {
  state = {
    accountType: "",
    pdfFileNames: [],
    bankAccounts: [
      {
        bankName: "",
        accountType: "savings",
        files: [{ documentId: "", fileName: null }],
        sanctionLimit: 0.0,
        filesFormData: "",
      },
    ],
  };
  addAccountHandler = () => {
    this.setState({
      bankAccounts: [
        ...this.state.bankAccounts,
        {
          bankName: "",
          accountType: "savings",
          files: [{ documentId: "", fileName: null }],
          sanctionLimit: null,
          filesFormData: "",
        },
      ],
    });
  };
  inputHandler = (e) => {
    var name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  selectHandler = (e, index) => {
    const bankAccounts = this.state.bankAccounts.slice();
    bankAccounts[index].bankName = e.value;
    this.setState({ bankAccounts });
  };
  accountTypeHandler = (type, index) => {
    const bankAccounts = this.state.bankAccounts.slice();
    bankAccounts[index].accountType = type;
    this.setState({ bankAccounts });
  };
  bankInputHandler = (e, index) => {
    const bankAccounts = this.state.bankAccounts.slice();
    bankAccounts[index].sanctionLimit = e.target.value;
    this.setState({ bankAccounts });
  };
  fileHandler = (e, index) => {
    let formData = new FormData();
    var files = e.target.files;
    const bankAccounts = this.state.bankAccounts.slice();
    bankAccounts[index].filesFormData = files;
    this.setState({ bankAccounts });
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
        bankAccounts[index].files = [...bankAccounts[index].files, { documentId: null, fileName: files[file].name }];
        this.setState({ bankAccounts });
      } else {
        break;
      }
    }
  };
  submitBankingHandler = async () => {
    let { bankAccounts } = this.state;
    let { loaderModal } = this.props.actions;
    const headers = { "Content-Type": "multipart/form-data" };
    let applicationNumber = getCookie("applicationNumber");
    let formData = new FormData();
    bankAccounts.map(async (el, index) => {
      for (let file in el.filesFormData) {
        if (el.filesFormData.hasOwnProperty(file)) {
          formData.append("files", el.filesFormData[file]);
        } else {
          break;
        }
      }
      // if(!bankName)
      loaderModal(true);
      return await POST(
        API_POST_UPLOAD_FILES(applicationNumber, el.bankName, el.accountType, el.sanctionLimit || "0"),
        formData,
        {
          headers,
        }
      ).then(async ({ data }) => {
        if (data.status === "success") {
          goTo(this.props.nextScreen)
          loaderModal(false);
        } else {
          alert(data.message);
          loaderModal(false);
        }
        formData = "";
      }).catch(err=>{
        throw err
      });
    });
  };
  removeBanking = (el, Id) => {
    let { bankAccounts } = this.state;
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    if (el.accountNumber) {
      GET(API_GET_ACCOUNT_DELETE(getCookie("applicationNumber"), el.accountNumber)).then(({ data }) => {
        if (data.status === "success") {
          this.setState({ bankAccounts: bankAccounts.filter((el, index) => index !== Id) });
        }
        loaderModal(false);
        alert(data.message);
      }).catch(err=>{
        loaderModal(false)
        throw err
      });
    } else {
      this.setState({ bankAccounts: bankAccounts.filter((el, index) => index !== Id) });
      loaderModal(false);

    }
  };
  render() {
    let { bankAccounts } = this.state,
      { bankNameData, loaderModalStatus } = this.props;
    let bankList = bankNameData.map((el, index) => {
      return { value: el.code, label: el.bankName };
    });
    return (
      <div className="banking__wrapper">
        <LoaderModal status={loaderModalStatus} />
        <h3 className="banking-heading">Bank Statements for last 12 months</h3>
        {bankAccounts.map((el, index) => {
          return (
            <div className="banking__section card__wrapper">
              <label htmlFor="" className="label-heading">
                Bank Name
              </label>
              <div className="delete-profile__icon">
                {index === 0 ? null : <MdDeleteForever size={30} onClick={() => this.removeBanking(el, index)} />}
              </div>
              <Select
                onChange={(e) => this.selectHandler(e, index)}
                options={bankList}
                className="select-handler"
                value={bankList.find((option) => option.label === el.bankName)}
              />
              <label htmlFor="" className="label-heading">
                Account Type
              </label>
              <div className="d-flex">
                <div
                  className={
                    el.accountType === "SAVING" || el.accountType === "Savings Account"
                      ? "button__generic mt-0"
                      : "button__grey"
                  }
                  onClick={() => this.accountTypeHandler("SAVING", index)}
                >
                  Saving A/c
                </div>
                <div
                  className={el.accountType === "CURRENT" ? "button__generic mt-0" : "button__grey"}
                  onClick={() => this.accountTypeHandler("CURRENT", index)}
                >
                  Current A/c
                </div>
                <div
                  className={el.accountType === "CREDIT_CARD" ? "button__generic mt-0" : "button__grey"}
                  onClick={() => this.accountTypeHandler("CREDIT_CARD", index)}
                >
                  CC/OD A/c
                </div>
                {el.accountType === "CREDIT_CARD" ? (
                  <input
                    type="text"
                    className="input__generic m-0 p-2"
                    onChange={(e) => this.bankInputHandler(e, index)}
                    value={el.sanctionLimit}
                    style={{ width: "auto" }}
                    placeholder="Enter sanction limit"
                  />
                ) : null}
              </div>
              <label htmlFor="" className="label-heading">
                Bank Statement
              </label>
              <div className="position-relative" style={{ maxWidth: "max-content" }}>
                <button className="grey-dashed__button">+ Upload Button</button>
                <input type="file" multiple onChange={(e) => this.fileHandler(e, index)} />
              </div>
              <div className="file__wrapper">
                {el?.files.map((el, index) => {
                  return el?.fileName ? <div className="file__container"><MdPictureAsPdf />{el?.fileName?.slice(0, 15)}</div> : null;
                })}
              </div>
            </div>
          );
        })}
        <button className="button__generic" onClick={this.submitBankingHandler}>
          Submit
        </button>
        <button onClick={this.addAccountHandler} className="m-5 ghost-grey__button">
          Add another Bank
        </button>
      </div>
    );
  }
  componentDidMount = () => {
    let { getBankNames, getBankDetails } = this.props.actions,
      applicationNumber = getCookie("applicationNumber");
    getBankNames();
    getBankDetails(applicationNumber).then((data) => {
      data.data && this.setState({ bankAccounts: this.props.bankDetailsData });
    });
  };
}

const mapStateToProps = ({ BankingReducer, data }) => ({
  bankNameData: BankingReducer.bankNamesData,
  loaderModalStatus: data.loaderModalStatus,
  bankDetailsData: BankingReducer.bankDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBankNames,
      loaderModal,
      eligibilityData,
      getBankDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banking);
