import React, { Component } from "react";
import AboutYourBusiness from "../AboutYourBusiness/aboutYourBusiness";
import { Transition, animated } from "react-spring/renderprops";
import ShareUsernameLink from "./shareUsernameLink";
import UserInput from "./userInput";
import LinkShared from "./linkShared";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_GST_OTP_REQUEST } from "../../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loaderModal } from "../../../store/actions";
import Countdown from "react-countdown-now";
import { goTo } from "../../../utils/utility";
class GSTUsername extends Component {
  state = { index: 0 };
  nextPageHandlerChild = (e) =>
    this.setState((state) => ({
      index: state.index === 2 ? 0 : state.index + 1,
    }));
  pages = [
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }} >
        <ShareUsernameLink nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }} >
        <UserInput nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }}>
        <LinkShared nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
  ];
  sendUserName = async (e) => {
    e.preventDefault();
    let { gstTaxPayerData, actions } = this.props,
      { loaderModal } = actions,
      dataToSend = {
        data: {
          gstUsername: this.state.gstUsername,
          gstin: gstTaxPayerData.gstin,
        },
      };
    loaderModal(true);
    await POST(API_POST_GST_OTP_REQUEST, dataToSend).then(({ data }) => {
      if (data.status === "success") {
        goTo(this.props.nextScreen);
        sessionStorage.setItem("gstUsername" , this.state.gstUsername)
      } else {
        alert(data.message);
        if (
          data.message ===
          "OTP has been triggered already by using same credential within last 10 minutes. Please try after some time."
        ) {
          this.setState({ isTimerVisible: true });
        } else if (
          data.message === "OTP is already validated in last few hours for this data. Please proceed without OTP."
        ) {
          goTo(this.props.notSuccessNextScreen);
        }
      }
    });
    loaderModal(false);
  };
  gstUsernameHandler = (e) => {
    var name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  render() {
    let { isTimerVisible } = this.state;
    return (
      <div className="gst-username__wrapper">
        <AboutYourBusiness {...this.props} />
        <h2 className="mt-5">Enter GST Registered Username</h2>
        <input
          className="input__generic"
          value={this.state.gstUsername}
          onChange={this.gstUsernameHandler}
          name="gstUsername"
        />
        <button
          className="button__generic ml-4 mb-4"
          type="submit"
          onClick={this.sendUserName}
          disabled={!this.state.gstUsername || isTimerVisible}
        >
          Continue
        </button>
        {isTimerVisible ? (
          <div className="timer__wrapper">
            You may continue in&nbsp;&nbsp;
            <Countdown
              date={Date.now() + 600000}
              renderer={(props) => (
                <div>
                  {props.minutes} : {props.seconds}
                </div>
              )}
              onComplete={() => {
                this.setState({ isTimerVisible: false });
              }}
            />
          </div>
        ) : null}

        <p className="my-5">
          Need assistance? <span className="button__click-here">Click here</span>
        </p>
        <div className="slider-panel share-username__wrapper">
          <Transition
            items={this.state.index}
            from={{ opacity: 0, transform: "translate3d(100%,0,0)" }}
            enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
            leave={{ opacity: 0, transform: "translate3d(0%,0,0)" }}
            ref={(divElement) => {
              this.divElement = divElement;
            }}
            trail={2}
          >
            {(index) => this.pages[index]}
          </Transition>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ homePageReducers }) => ({
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ loaderModal }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTUsername);
