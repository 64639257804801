import * as Biz360Constants from "./KYC.actions.constants";
const initialState = {
  relationshipData: [],
  profileAddressTypeData: [],
  KYCAllAddresses: [],
  genderData: [],
  profileData:[]
};
export const KYCReducer = (state = initialState, actions) => {
  let { data } = actions;
  switch (actions.type) {
    case "KYC_DELETE_ADDRESS":
      return {
        ...state,
        KYCAllAddresses: state.KYCAllAddresses.filter((el, index) => {
          return index !== data;
        }),
      };

    case Biz360Constants.GET_RELATIONSHIP:
      if (data) {
        return {
          ...state,
          relationshipData: data,
        };
      }
      break;
    case Biz360Constants.GET_PROFILE_ADDRESS_TYPE:
      if (data) {
        return {
          ...state,
          profileAddressTypeData: data,
        };
      }
      break;
    case Biz360Constants.GET_GENDER:
      if (data) {
        return {
          ...state,
          genderData: data,
        };
      }
      break;
    case Biz360Constants.GET_PROFILE_DATA:
      if (data) {
        return {
          ...state,
          profileData: data,
        };
      }
      break;
    default:
      return state;
  }
};
