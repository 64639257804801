import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import GSTData from "../../../../ViewsComponents/GSTData/GSTData";
import GSTusername from "../../../../ViewsComponents/GSTUsername/GSTusername";
import GSTOTPPage from "../../../../ViewsComponents/GSTOTPPage/GSTOTPPage";
import KYC from "../../../../ViewsComponents/KYC/KYC";
import { getTaxPayerData } from "../../../../HomePage/homepage.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCookie } from "../../../../../utils/utility";
import { setGstAddressInNewAddress } from "../../../../ViewsComponents/GSTData/GSTData.actions";
import { FaChevronLeft } from "react-icons/fa";
class Biz360Flow extends Component {
  render() {
    return (
      <>
        <div onClick={() => this.props.history.goBack()} className="back-button">
          <FaChevronLeft className="mr-3" size={20} />
          Back
        </div>
        <div className="dashboard-page__wrapper">
          <Switch>
            <Route
              path="/dashboard/business/add-business-gst-data/gst-data"
              exact
              render={(props) => <GSTData {...this.props} nextScreen={"gst-username"} />}
            />
            <Route
              path="/dashboard/business/add-business-gst-data/gst-username"
              exact
              render={(props) => (
                <GSTusername {...this.props} nextScreen={"gst-otp"} notSuccessNextScreen={"gst-kyc"} />
              )}
            />
            <Route
              path="/dashboard/business/add-business-gst-data/gst-otp"
              exact
              render={(props) => <GSTOTPPage {...this.props} nextScreen={"gst-kyc"} />}
            />
            <Route
              path="/dashboard/business/add-business-gst-data/gst-kyc"
              exact
              render={(props) => (
                <KYC
                  {...this.props}
                  nextScreen={"/dashboard/business/home"}
                  apiPayLoad={getCookie("gstNumber") && getCookie("gstNumber").slice(2, 12)}
                />
              )}
            />
          </Switch>
        </div>
      </>
    );
  }
  componentDidMount = () => {
    let { actions } = this.props,
      { setGstAddressInNewAddress, getTaxPayerData } = actions;
    var gstNumber = getCookie("gstNumber");
    getTaxPayerData(gstNumber).then((data) => {
      if (data.data) {
        setGstAddressInNewAddress(data.data.gstData[0].addresses || []);
      }
    });
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getTaxPayerData, setGstAddressInNewAddress }, dispatch),
});
export default connect(null, mapDispatchToProps)(Biz360Flow);
