import React, { Component } from "react";
import { goTo, rupeeCommaFormatter } from "../../../../utils/utility";
import { IoMdMail } from "react-icons/io";
import { MdPhoneInTalk } from "react-icons/md";
import { connect } from "react-redux";
class Eligibility extends Component {
  render() {
    let  eligibilityData  = this.props.eligibilityData || [];
    return (
      <div className="eligibility__wrapper">
        <div className="optional-text__small">
          Based on your business report and your loan requirements, we have calculated the best possible loan
          eligibility.
        </div>
        <div className="card__wrapper">
          <div className="heading">Business Loan Eligibility</div>
          <div className="d-flex justify-content-between">
            <div className="sections">
              <div className="subheading">Range of loan Amount</div>
              <div className="value">{eligibilityData.loanAmountRange}</div>
            </div>
            <div className="sections">
              <div className="subheading">Range of EMI</div>
              <div className="value">
                {rupeeCommaFormatter(eligibilityData.minEmi)} - {rupeeCommaFormatter(eligibilityData.maxEmi)}
              </div>
            </div>
            <div className="sections mr-5">
              <div className="subheading">Tenure</div>
              <div className="value">{eligibilityData.tenure}</div>
            </div>
          </div>
        </div>
        <div className="card__wrapper">
          <div className="heading">Always available to assist you</div>
          <p>In case of any queries contact us at:</p>
          <div className="d-flex">
            <div className="section-contact mr-5">
              <IoMdMail className="mr-2"/>
              +1800-345-356
            </div>
            <div className="section-contact ml-5">
              <MdPhoneInTalk className="mr-2"/>
              support@credochain.com
            </div>
          </div>
        </div>
        <div className="card__wrapper">
          <div className="grey__text bold ">Terms subject to:</div>
          <div className="optional-text__small">
            a.If you do not wish to apply for loan now, you can generate your Biz360 Report and continue with your
            application later through your dashboard.
            <br />
            b.Rate of interest would be communicated by relationship manager and given on sanction letter. <br />
            c. Range given is only an approximation figure on the basis of data which is derived by the system. Actual
            loan amount may vary on the basis of risk assessment. <br />
            d. Loan is subject to completion of all the documents required by financial institution. <br />
            e. Loan acknowledgement kit will be signed by all the parties on the loan.
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="view-application" onClick={() => goTo("/dashboard/loans/home")}>
            View all applications
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ BankingReducer }) => ({
    eligibilityData: BankingReducer.eligibilityData
  });
  
  const mapDispatchToProps = {};
  
  export default connect(mapStateToProps, mapDispatchToProps)(Eligibility);