import React, { Fragment, Component } from "react";
import LoanAmount from "../../assets/Images/StaticPages/LoanAmount.png";
import InterestRate from "../../assets/Images/StaticPages/InterestRate.png";
import LoanTenure from "../../assets/Images/StaticPages/LoanTenure.png";
import DecisionTime from "../../assets/Images/StaticPages/DecisionTime.png";
import ProcessingFee from "../../assets/Images/StaticPages/ProcessingFee.png";

export default class LoanInfoBox extends Component {
  render() {
    const { loanAmount, loanTenure, processingFee, interestRate, decisionTime, buttonClick, buttonText } = this.props;

    return (
      <Fragment>
        <div className="loan-info-box__wrapper">
          <div className="card__wrapper no-gutter loan-info-box__shadow">
            <div className="loan-info__wrapper">
              <div className="loan-info__items center">
                <img src={LoanAmount} className="loan-info-icon" />
                <div className="loan-info-title">Loan Amount</div>
                <div className="loan-info-details">{loanAmount}</div>
              </div>
              <div className="loan-info__items center">
                <img src={InterestRate} className="loan-info-icon" />
                <div className="loan-info-title">Interest Rate</div>
                <div className="loan-info-details">{interestRate}</div>
              </div>
              <div className="loan-info__items center">
                <img src={LoanTenure} className="loan-info-icon" />
                <div className="loan-info-title">Loan Tenure</div>
                <div className="loan-info-details">{loanTenure}</div>
              </div>
              <div className="loan-info__items center">
                <img src={DecisionTime} className="loan-info-icon" />
                <div className="loan-info-title">Decision Time</div>
                <div className="loan-info-details">{decisionTime}</div>
              </div>
              <div className="loan-info__items center">
                <img src={ProcessingFee} className="loan-info-icon" />
                <div className="loan-info-title">Processing Fee</div>
                <div className="loan-info-details">{processingFee} </div>
              </div>
            </div>
            <div className="center grey-background curved-borders">
              <button
                className="secondary-button__custom"
                //   onClick={this.generateBizHandler}
                //   disabled={this.state.gstNumber.length < 15}
                onClick={buttonClick}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
