import * as GSTDataConstant from "./GSTData.constants";
import set from "lodash/set";
import get from "lodash/get";
const initialState = {
  getConstitutionData: [],
  getBusinessTypeData: [],
  getPrimaryIndustryData: [],
  getBusinessAddressTypeData: [],
  getSecondaryIndustryData: [],
  allNewAddress: [],
  withoutGSTObject: [],
};

export const GSTDataReducer = (state = initialState, actions) => {
  let { data, object, name, value } = actions;
  switch (actions.type) {
    case GSTDataConstant.GET_CONSTITUTION:
      return {
        ...state,
        getConstitutionData: data,
      };
    case GSTDataConstant.GET_BUSINESS_TYPE:
      return {
        ...state,
        getBusinessTypeData: data,
      };
    case GSTDataConstant.GET_PRIMARY_INDUSTRY:
      return {
        ...state,
        getPrimaryIndustryData: data,
      };
    case GSTDataConstant.GET_BUSINESS_ADDRESS_TYPE:
      return {
        ...state,
        getBusinessAddressTypeData: data,
      };
    case GSTDataConstant.GET_SECONDARY_INDUSTRY:
      return {
        ...state,
        getSecondaryIndustryData: data,
      };

    case GSTDataConstant.SET_ALL_ADDRESS_NEW_ADDRESS:
      return {
        ...state,
        allNewAddress: data,
      };
    case GSTDataConstant.DELETE_ADDRESS:
      return {
        ...state,
        allNewAddress: state.allNewAddress.filter((el, index) => {
          return index !== data;
        }),
      };
    case GSTDataConstant.SET_CHANGE_INPUT_VALUE:
      return {
        ...state,
        object: get(set(object, `${name}`, value), "object"),
      };
    case "CHANGE_INPUT_VALUE":
      return {
        ...state,
        withoutGSTObject: { ...state.withoutGSTObject, [name]: value },
      };
    default:
      return state;
  }
};
