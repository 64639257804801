import * as LoanPurposeConstant from "./LoanPurpose.actions.constant";
const initialState = {
  LoanUseData: [],
};

export const LoanPurposeReducer = (state = initialState, action) => {
  let { data, type } = action;
  switch (type) {
    case LoanPurposeConstant.FETCH_LOAN_USE:
      return {
        ...state,
        LoanUseData: data,
      };
    default:
      return state;
  }
};
