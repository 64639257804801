
const _changeSearchByFiltersStataus = (state, name) => {
    let _changedState = JSON.parse(JSON.stringify(state)),
        {searchByFilters} = _changedState;
    for (let item in searchByFilters) {
        if (searchByFilters.hasOwnProperty(item)) {
            item === name
                ? searchByFilters[item] = true
                : searchByFilters[item] = false
        } else {
            break;
        }
    }
    return {..._changedState, searchByFilters};
};

export {
    _changeSearchByFiltersStataus
}
