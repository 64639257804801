import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavItem, NavDropdown, Dropdown } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_notifications } from "react-icons-kit/md/ic_notifications";
import { ic_person } from "react-icons-kit/md/ic_person";
import { phone } from "react-icons-kit/fa/phone";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import { goTo , deleteCookie } from "../../utils/utility";
import { setUserDetails, setUserLoginStatus, loaderModal } from "../../store/actions";
import { get } from "lodash";
import { GET } from "../../utils/WebAPI.service";
import { bindActionCreators } from "redux";
import { changeValue } from "./header.action";
import { API_USER_LOGOUT } from "../../utils/APIUrls";
class Header extends Component {
  state = {
    inputField: false
  };
  userLogout = () => {
    let { setUserDetails, setUserLoginStatus, loaderModal } = this.props.actions;
    let { history } = this.props;
    loaderModal(true);
    GET(API_USER_LOGOUT)
      .then(({ data }) => {
        loaderModal(false);
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      })
      .catch(err => console.log(err));
  };

  handleChange = e => {
    let { actions } = this.props;
    let { changeValue } = actions;
    const value = e.target.value;
    const name = e.target.name;
    changeValue(name, value);
  };

  searchBy = (value, action) => {
    this.setState({ inputField: true });
    let { actions } = this.props;
    let { changeValue } = actions;
    changeValue(action.name, value);
  };

  getHomeUrl = arrPath => {
    let routeUrl = `/${arrPath[1]}/dashboard`;
    const { userDetails } = this.props;
    if (arrPath[1] === "Admin") {
      routeUrl = "/Credit/dashboard";
    } else if (arrPath[1] === "faq" || arrPath[1] === "help") {
      if (userDetails.roleName === "UW" || userDetails.roleName === "UW_ADMIN") {
        routeUrl = "/Credit/dashboard";
      } else if (userDetails.roleName === "CPO_USER") {
        routeUrl = "/cpoPanel";
      } else if (userDetails.roleName === "CPO_ADMIN") {
        routeUrl = "/cpopanelAdmin";
      } else {
        routeUrl = "/Sales/dashboard";
      }
    } else if (userDetails.roleName === "CPO_USER") {
      routeUrl = "/cpoPanel";
    } else if (userDetails.roleName === "CPO_ADMIN") {
      routeUrl = "/cpopanelAdmin";
    }
    return routeUrl;
  };
  render() {
    let { isLogin, userDetails, filterOptions, searchInput, searchFilter } = this.props,
      [firstName, lastName] = "";
    if (isLogin) {
      firstName = userDetails.firstName.charAt(0).toUpperCase() + userDetails.firstName.slice(1) || "";
      lastName = userDetails.lastName.charAt(0).toUpperCase() + userDetails.lastName.slice(1) || "";
    }
    let arrPath = get(this, "props.location.pathname", "").split("/"),
      activeUrl = arrPath[2],
      routeUrl = this.getHomeUrl(arrPath);
    return (
      <header className={"header-wrapper"}>
        <Navbar>
          <Navbar.Header>
            <Navbar.Brand>
              {isLogin ? (
                <Link to={routeUrl} className="logo" onClick={this.forceUpdate}>
                  <img className={"img-responsive"} src={require("../../assets/Images/BlueLogo1x.png")} alt="logo" />
                  <span>CredoChain</span>
                </Link>
              ) : (
                <span className="logo">
                  <img className={"img-responsive"} src={require("../../assets/Images/BlueLogo1x.png")} alt="logo" />
                  <span>CredoChain</span>
                </span>
              )}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight navbar>
              {isLogin && activeUrl !== "dashboard" ? (
                <NavItem title={"Home"} onClick={() => goTo(routeUrl)}>
                  Home
                </NavItem>
              ) : null}
              {isLogin && activeUrl === "dashboard" ? (
                <div className="searchWrapper">
                  <input
                    className={"header-type-search"}
                    name="searchInput"
                    onChange={this.handleChange}
                    value={searchInput}
                    placeholder={"Type Search"}
                    disabled={!this.state.inputField}
                  />
                  <Select
                    placeholder={"Search By"}
                    isSearchable={true}
                    name="searchFilter"
                    options={filterOptions}
                    className="header-select-search"
                    classNamePrefix="select"
                    value={searchFilter}
                    onChange={this.searchBy}
                  />
                </div>
              ) : (
                <NavItem title={"FAQ's"} onClick={() => goTo("/faq")}>
                  FAQ's
                </NavItem>
              )}
              {!isLogin && activeUrl !== "dashboard" ? (
                <NavItem title={"Help Line Number"}>
                  <Icon icon={phone} size={22} />
                  1800 200 100
                </NavItem>
              ) : null}
              {activeUrl !== "dashboard" ? (
                <NavItem title={"Home"} onClick={() => goTo("/help")}>
                  Help
                </NavItem>
              ) : null}
              {!isLogin ? (
                <NavItem title={"Login"} onClick={() => goTo("/login")}>
                  Login
                </NavItem>
              ) : null}
              {isLogin ? (
                <Fragment>
                  <NavItem title={"Notifications"}>
                    <span>
                      <Icon icon={ic_notifications} size={26} />
                    </span>
                  </NavItem>
                  <NavDropdown noCaret title={<Icon icon={ic_person} size={28} />} id="user-dropdown">
                    <Dropdown.Item title={`${firstName} ${lastName}`}>
                      {firstName} {lastName}
                    </Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item onClick={this.userLogout}>Logout</Dropdown.Item>
                  </NavDropdown>
                </Fragment>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </header>
    );
  }
}

Header.defaultProps = {
  filterHandler: () => {}
};

const mapStateToProps = ({ data, CreditHeaderReducer }) => ({
  userDetails: data.userDetails,
  isLogin: data.isLogin,
  searchInput: get(CreditHeaderReducer, "form.values.searchInput", ""),
  searchFilter: get(CreditHeaderReducer, "form.values.searchFilter", ""),
  filterOptions: CreditHeaderReducer.filterOptions,
  filterHandler: CreditHeaderReducer.filterHandler
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setUserLoginStatus,
      setUserDetails,
      loaderModal,
      changeValue
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
