import * as COMMON from "../../../store/constant";

const FLAG_CC_SCORE = "FLAG_CC_SCORE";
const CREDIT_FLAG = "Credit_Flag",
  BUSINESS_PROFILE_FLAGS = Symbol("businessProfileFlags"),
  BUSINESS_RISK_FLAGS = Symbol("businessRiskFlags"),
  CASH_FLOW_RISK_FLAGS = Symbol("cashFlowRiskFlags"),
  CREDIT_BEHAVIOUR_FLAGS = Symbol("creditBehaviourFlags"),
  CREDIT_CAPACITY_FLAGS = Symbol("creditCapacityFlags");

export {
  COMMON,
  FLAG_CC_SCORE,
  CREDIT_FLAG,
  BUSINESS_PROFILE_FLAGS,
  BUSINESS_RISK_FLAGS,
  CASH_FLOW_RISK_FLAGS,
  CREDIT_BEHAVIOUR_FLAGS,
  CREDIT_CAPACITY_FLAGS
};
