import React, { Component } from "react";
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import BankingComponent from "../../ViewsComponents/Banking/Banking";
import Header from "../../ViewsComponents/Header/Header";
export default class Banking extends Component {
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-md-3">
          <SidePanel
            advisories={[
              "Upload 12 months pdf bank statement of all your business accounts. ",
              "For ex. If you use Current Account of HDFC bank and CC Limit Account of SBI, upload statements for both these accounts",
            ]}
          ></SidePanel>
        </div>
        <div className="col-md-9">
          <Header navBar_className={"navbar-light"} />
          <BankingComponent nextScreen={"kyc"} />
        </div>
      </div>
    );
  }
}
