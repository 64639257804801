import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";

import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class DealerPurchaseFinance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Dealer/purchase Finance</title>
          <meta
            name="description"
            content="It is a kind of debt facility offered to different
                  distributors/dealers/stockist who regularly procure goods from manufacturer/super stockist and
                then sell to retailers or directly to consumers. It is very
                popular among consumer goods like automobiles, FMCG items,
                petroleum products."
          />
          <meta
            name="tag"
            content=" what is a dealer purchase loan ,dealer loaner car,dealer loan vs bank loan,dealer loaner vehicle,dealer loan services,dealer loan means,dealer loan and finance,dealer loan agency,dealer auto loan rates,dealer auto loan,dealer auto loan calculator,dealership auto loan rates,a loaner dealership,crashed a dealership loaner vehicle,dealer plan loan bank of nova scotia,dealer car loan bad credit,car dealer business loan,dealer vs bank loan,loandepot dealer,dealer loan finance,dealer loan financial,dealership loan fees "
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center">Dealer/purchase Finance</div>
              <br />
              <div className="about-details center">
                <p className="fs-16 ">
                  It is a kind of{" "}
                  <span className="highlight-blue">
                    debt facility offered to different distributors/dealers/stockist{" "}
                  </span>
                  who regularly procure goods from manufacturer/super stockist and then sell to retailers or directly to
                  consumers. It is very popular among consumer goods like automobiles, FMCG items, petroleum products.
                  It is offered where credit facility is not offered from manufacturer..
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="More than 50k"
              interestRate="0.8% - 1 % per month"
              loanTenure="90 -180 days"
              decisionTime="10-15 Days"
              processingFee="0.5% - 1%"
              buttonClick=""
              buttonText="Find the Best Offers"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Dealer/purchase Finance </div>
              <div className="sub-heading_card_wrapper">When to opt for Dealer/purchase Finance</div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">Improves working capital cycle</div>
                  Improves working capital cycle for both manufacturer and dealer.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Improve operational efficiency</div>
                  Manage complete chain which in anyway improve operational efficiency.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Variety of stock</div>
                  Sales can be improved by having different variety of stock.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Inventory level</div>
                  If there is need to manage inventory level.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Turnover ratio</div>
                  Improves stock turnover ratio
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Interest rate</div>
                  Easy to get and lower interest rate
                </div>
              </div>
            </div>
            <br />
            <div className="horizontal-space" />
          </div>
          <div className="center negative-margin">
          <CalculateEMI 
              businessVintage="2 years"
              optimumTurnover="Varies from lender policy"
              financialDataRequired="For first time on boarding of seller"
            />
            <div className="horizontal-space" />
          </div>
          <br />
          <br />
          <div className="why-loan__wrapper extra-padding center">
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Dealer/purchase Finance</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">Sometime bank may ask for additional security.</div>
                <div className="SME_subCard_wrapper  ">Available to dealers deal with large anchors only.</div>
              </div>
            </div>
            <br />
            <br />
            <OtherLoanProducts />
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }
}
