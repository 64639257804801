import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";

import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class BillInvoiceDiscounting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Bill/Invoice Discounting</title>
          <meta
            name="description"
            content="Bill discounting is the source of working capital from bill receivables. It is collateral free working capital
                facility. Banker generally get the invoice cross verified from
                customer of seller. In bill discounting, the bank buys the bill
                before the payment due date and pays the amount of the bill
                after a discount fee to the seller. This bill is then presented
                to seller's customer and full amount is collected."
          />
          <meta
            name="tag"
            content="bill discounting system,bill discounting meaning,bill discounting facility,bill discounting process,bill discounting vs factoring,bill discounting nbfc,bill discounting example,bill discounting in india,bill discounting accounting,bill discounting advantages,bill discounting and purchasing,bill discounting accounting entries,bill discounting and rediscounting,what is a bill discounting facility,example of a bill discounting,discounting a bill in banks,what is a bill discounting,bill discounting bank,bill discounting blockchain,bill discounting benefits,bill discounting companies,bill discounting charges,bill discounting charges in india,bill discounting calculation,bill discounting concept,bill discounting documents,bill discounting disclosure,bill discounting drawer,bill discounting definition investopedia,bill discounting explained,bill discounting format,bill discounting in financial services ,invoice discounting platform india,invoice discounting india,invoice discounting meaning,invoice discounting companies in india,invoice discounting vs factoring,invoice discounting market size in india,invoice discounting rbi,invoice discounting marketplace,invoice discounting facility,invoice discounting agreement,invoice discounting agreement template,invoice discounting advantages and disadvantages,invoice discounting accounting,invoice discounting accounting treatment,invoice discounting arrangement,meaning of invoice discounting,invoice discounting business model,invoice discounting by banks,invoice discounting companies,invoice discounting definition,invoice discounting disadvantages,invoice discounting export,invoice discounting entry,invoice discounting fintech,invoice discounting facility meaning,invoice discounting for msme,invoice discounting for small businesses,invoice discounting finance,is invoice discounting a good idea"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center">Bill/Invoice Discounting</div>
              <br />
              <div className="about-details center">
                <p className="fs-16 ">
                  Bill discounting is the <span className="highlight-blue">source of working capital </span>
                  from bill receivables. It is collateral free working capital facility. Banker generally get the
                  invoice cross verified from customer of seller. In bill discounting, the bank buys the bill before the
                  payment due date and pays the amount of the bill after a discount fee to the seller. This bill is then
                  presented to seller's customer and full amount is collected.
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="Depends upon the value of Invoice"
              interestRate="0.75% - 1.5% per month"
              loanTenure="90 Days"
              decisionTime="10-15 Days"
              processingFee="0.5% - 2%"
              buttonClick=""
              buttonText="Find the Lowest Rates"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Bill/Invoice Discounting </div>
              <div className="sub-heading_card_wrapper">When to opt for Bill/Invoice Discounting</div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">Improves working capital cycle</div>
                  In a way debtor days are lowered which ultimately improve company valuation.{" "}
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Improve cash flow</div>
                  Sales can be improved by increasing credit to customers than discounting them to improve cash flow.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Invoice amount</div>
                  Borrower gets up to 90% of the invoice amount.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">One shot disbursement</div>
                  To meet upfront requirement
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Hassle free facility</div>
                  Improves stock cycle
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Flexible payment</div>
                  Options with Lenders
                </div>
              </div>
            </div>
            <br />
            <div className="horizontal-space" />
          </div>
          <div className="center negative-margin">
            <CalculateEMI 
              businessVintage="2 years"
              optimumTurnover="Varies from lender policy"
              financialDataRequired="For first time on boarding of seller"
            />
            <div className="horizontal-space" />

            <div className="why-loan__wrapper extra-padding center"></div>
            <br />
            <br />
            <div className="why-loan__wrapper center">
              <div className="SME_card_container container">
                <div className="heading_card_wrapper">Challenges with Bill/Invoice Discounting</div>
                <div className="SME_card_wrapper">
                  <div className="SME_subCard_wrapper  ">
                    Banker doesn’t consider bills for which they are not able to verify customers.
                  </div>
                  <div className="SME_subCard_wrapper  ">Not suitable to meet day to day operational expense</div>
                </div>
              </div>

              <OtherLoanProducts />
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
