import React, { Component } from "react";
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import AmountAndTenureComponent from "../../ViewsComponents/AmountAndTenure/AmountAndTenure";
import Header from "../../ViewsComponents/Header/Header";
export default class AmountAndTenure extends Component {
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-md-3">
          <SidePanel
            advisories={[
              "Approval entirely depends on your cash flows and business profile",
              "Your loan application is active till Sep 2022",
              "Estimated EMI Range ₹ 25,0000- 45,000 per month",
            ]}
          ></SidePanel>
        </div>
        <div className="col-md-9">
          <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"loan-preference"} {...this.props}/>
          <AmountAndTenureComponent nextScreen={"gst-number"} />
        </div>
      </div>
    );
  }
}
