import React, { Component } from "react";
import SidePanel from "../../ViewsComponents/SidePanel/SidePanel";
import MultipleApplicantKYCComponent from "../../ViewsComponents/MultipleApplicantKYC/MultipleApplicantKYC";
import Header from "../../ViewsComponents/Header/Header";
export default class KYC extends Component {
  render() {
    return (
      <div className="row no-gutters">
        <div className="col-md-3">
          <SidePanel
            advisories={[
              "Provide accurate information of the Applicant(s) in order to successfully generate your report. ",
            ]}
            isLoan={true}
          ></SidePanel>
        </div>
        <div className="col-md-9">
          <Header navBar_className={"navbar-light"} />
          <MultipleApplicantKYCComponent nextScreen={"banking"} />
        </div>
      </div>
    );
  }
}
