import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCookie } from "../../../utils/utility";
import { loaderModal } from "../../../store/actions";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_GENERATE_USERNAME_LINK } from "../../../utils/APIUrls";

class UserInput extends Component {
  state = {
    mobileNumber: "",
  };

  generateGstUserName = (data) => {
    return POST(API_POST_GENERATE_USERNAME_LINK, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
  sendUserName = async () => {
    let gstin = getCookie("gstNumber"),
      { mobileNumber, email } = this.state,
      dataToSend = {
        data: {
          email,
          gstin,
          isDataFetched: true,
          mobileNumber,
        },
      };
    loaderModal(true);
    this.generateGstUserName(dataToSend).then((data) => {
      if (data.status === "success") {
        this.props.nextPageHandlerChild();
      } else {
        window.alert(data.message);
      }
    });
    loaderModal(false);
  };
  inputHandler = (e) => {
    var name = e.target.name,
      value = e.target.value;
    let regMobile = /^[0-9]*$/;
    if (name === "mobileNumber") {
      if (regMobile.test(value)) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };
  render() {
    let { mobileNumber, email } = this.state;
    return (
      <div className="share-wrapper mt-3">
        <h2>Share OTP link</h2>
        <div className="row mt-2">
          <div className="col-md-6 col-lg-6 col-xs-12 p-0">
            <label htmlFor="mobile">Mobile No.</label>
            <br />
            <input
              type="text"
              className="input__generic mt-0"
              name="mobileNumber"
              onChange={this.inputHandler}
              value={mobileNumber}
              maxLength={10}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xs-12 p-0">
            <label htmlFor="email">
              Email ID <span className="optional-text__small">(optional)</span>
            </label>
            <br />
            <input
              type="text"
              className="input__generic mt-0"
              name="email"
              onChange={this.inputHandler}
              value={email}
            />
          </div>
        </div>
        <button
          className="button__generic small-padding mt-1"
          onClick={this.sendUserName}
          disabled={!(mobileNumber || email)}
        >
          Send
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({  loaderModal }, dispatch),
});

export default connect(null, mapDispatchToProps)(UserInput);
