const GET_BUSINESS_TYPE = Symbol("getBusinessType"),
  GET_CONSTITUTION = Symbol("getConstitution"),
  GET_PRIMARY_INDUSTRY = Symbol("getPrimaryIndustry"),
  GET_SECONDARY_INDUSTRY = Symbol("getSecondaryIndustry"),
  SET_CHANGE_INPUT_VALUE = Symbol("changeInputValue"),
  SET_ALL_ADDRESS_NEW_ADDRESS = Symbol("setAllAddressNewAddress"),
  GET_BUSINESS_ADDRESS_TYPE = Symbol("getBusinessAddressType"),
  DELETE_ADDRESS = Symbol("deleteAddress");
export {
  GET_BUSINESS_TYPE,
  SET_ALL_ADDRESS_NEW_ADDRESS,
  GET_CONSTITUTION,
  GET_PRIMARY_INDUSTRY,
  GET_SECONDARY_INDUSTRY,
  SET_CHANGE_INPUT_VALUE,
  GET_BUSINESS_ADDRESS_TYPE,
  DELETE_ADDRESS,
};
