import React, { Component } from "react";
import Approval from "../../../assets/Images/HomePage/step2.png";
import Apply from "../../../assets/Images/HomePage/step1.png";
import Disbursement from "../../../assets/Images/HomePage/step3.png";

export default class HowCredochainWorks extends Component {
  state = {
    dimensions: {
      width: -1,
      height: -1,
      heading:""
    },
  };
  cardTogglerHandler = () => {};
  render() {

    const {headingText, subHeadingText, step1, step2, step3} = this.props
    return (
      <div className="credochain-works__wrapper" id="credochainworks">
        <h2>{headingText}</h2>
        <p className="montserrat">{subHeadingText}</p>
        <div className="card-credochain-works__wrapper">
          <div className="card-wrapper row">
          <div className="step-number__mobile">
            1
          </div>
            <div>
              <img className="mobile-steps" src={Apply} alt="" />
            </div>
            <div className="container">
              <h2>Apply</h2>
              <p className="montserrat">
                {step1}
              </p>
            </div>
          </div>
          <div className="card-wrapper row">
          <div className="step-number__mobile">
            2
          </div>
            <div>
              <img className="mobile-steps" src={Approval} alt="" />
            </div>
            <div className="container">
              <h2>Approval</h2>
              <p className="montserrat">
                {step2}
              </p>
            </div>
          </div>
          <div className="card-wrapper row">
          <div className="step-number__mobile">
            3
          </div>
            <div>
              <img className="mobile-steps" src={Disbursement} alt="" />
            </div>
            <div className="container">
              <h2>Disbursement</h2>
              <p className="montserrat">
                {step3}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
