import {
  API_GET_FINAL_DECISION,
  API_GET_DECISION,
  API_GET_DEVIATION_MASTER,
  API_GET_ALL_DDS_CODES,
  API_GET_RCU_DOCUMENT_TYPE,
  API_GET_RCU_AGENCY_MASTER,
  API_GET_DEVIATION_REASON_MASTER,
  API_GET_DEVIATIONS,
  API_GET_SPOC_DETAILS,
  API_GET_CREDIT_CAPACITY_DETAILS,
  API_GET_ALL_APPLICANT_SUMMARY_FINAL_DECISION,
  API_GET_BANKING_DETAILS_FOR_FINAL_DECISION,
  API_GET_CASE_HIGHLIGHTS,
  API_GET_LIMIT_UTILIZATION_ANALYSIS
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/WebAPI.service";
import * as ACTION_CONSTANTS from "./finalDecision.action.constant";

const getFinalDetails = applicationNumber => dispatch => {
  return GET(API_GET_FINAL_DECISION(applicationNumber))
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({
          type: `${ACTION_CONSTANTS.FINAL_DETAILS}`,
          data: data.data
        });
      }
    })
    .catch(err => {
      throw err;
    });
};

const getCreditCapacityDetails = applicationNumber => {
  return dispatch => {
    return GET(API_GET_CREDIT_CAPACITY_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_CAPACITY_DETAILS,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getLimitUtilzationAnalysis = applicationNumber => {
  return dispatch => {
    return GET(API_GET_LIMIT_UTILIZATION_ANALYSIS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_LIMIT_UTILIZATION_ANALYSIS,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getSummaryForAllApplicant = applicationNumber => {
  return dispatch => {
    return GET(API_GET_ALL_APPLICANT_SUMMARY_FINAL_DECISION(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_SUMMARY_APPLICANT,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getBankingDetailsForFinalDecision = applicationNumber => {
  return dispatch => {
    return GET(API_GET_BANKING_DETAILS_FOR_FINAL_DECISION(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_BANKING_DETAILS_FOR_FINAL_DECISION,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getCaseHighlights = applicationNumber => {
  return dispatch => {
    return GET(API_GET_CASE_HIGHLIGHTS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_CASE_HIGHLIGHTS,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const changeInputValue = (val, name) => ({
  type: ACTION_CONSTANTS.CHANGE_VALUE,
  data: val,
  name: name
});

const getDecisionSummary = applicationNumber => {
  return dispatch => {
    return GET(API_GET_DEVIATION_MASTER(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.SET_DECISION_DETAILS,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};
const getDecisionCode = applicationNumber => {
  return dispatch => {
    return GET(API_GET_ALL_DDS_CODES)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_DECISION_CODE,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getRCUDocumentType = () => {
  return dispatch => {
    return GET(API_GET_RCU_DOCUMENT_TYPE)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_RCU_DOCUMENT_TYPE,
            data: data.data.responseList
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getRCUAgencyMaster = pinCode => {
  return dispatch => {
    return GET(API_GET_RCU_AGENCY_MASTER(pinCode))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_RCU_AGENCY_MASTER,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getDeviationsReasonMaster = () => {
  return dispatch => {
    return GET(API_GET_DEVIATION_REASON_MASTER)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.GET_DEVIATION_REASON_MASTER,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getDeviations = applicationNumber => {
  return dispatch => {
    return GET(API_GET_DEVIATIONS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({ type: ACTION_CONSTANTS.GET_DEVIATIONS, data: data.data });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getSpoc = applicationNumber => {
  return dispatch => {
    return GET(API_GET_SPOC_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({ type: ACTION_CONSTANTS.GET_SPOC, data: data.data });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const changeAddressBoxAddressInputValue = (val, name, formIndex, index) => ({
  type: ACTION_CONSTANTS.CHANGE_ADDRESS_BOX_INPUT_VALUE,
  val,
  name,
  formIndex,
  index
});

const getDecision = applicationNumber => {
  return dispatch => {
    return GET(API_GET_DECISION(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({ type: ACTION_CONSTANTS.GET_DECISION, data: data.data });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getReferences = applicationNumber => {
  // return (dispatch) => {
  //     return GET(API_FETCH_REFERENCES(applicationNumber))
  //         .then(({data}) => {
  //             if (data.status === 'success') {
  //                 dispatch({type: ReferencesConstants.SET_REFERENCES, data: data.data.referenceList})
  //             } else {
  //                 dispatch({type: ReferencesConstants.SET_REFERENCES, data: [{}, {}]})
  //             }
  //             dispatch({type: ReferencesConstants.SET_INITIAL_RESPONSE, status: true});
  //             return data;
  //         })
  //         .catch(err => {
  //             throw err
  //         })
  // };
  return [];
};

export {
  getFinalDetails,
  changeInputValue,
  getDecisionSummary,
  getDecisionCode,
  getRCUDocumentType,
  getRCUAgencyMaster,
  getDeviationsReasonMaster,
  getDeviations,
  changeAddressBoxAddressInputValue,
  getDecision,
  getSpoc,
  getCreditCapacityDetails,
  getSummaryForAllApplicant,
  getCaseHighlights,
  getBankingDetailsForFinalDecision,
  getLimitUtilzationAnalysis,
  getReferences
};
