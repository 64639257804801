import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import KYCComponent from "../../../ViewsComponents/KYC/KYC";
import Header from "../../../ViewsComponents/Header/Header";
export default class KYC extends Component {
  render() {
    return (
      <div>
        <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "Enter correct GST Username to get the OTP",
                "Provide accurate business information of the Applicant(s) in order to successfully generate your BIZ360 report.",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9 pr-5">
            <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"gst-otp"} {...this.props}/>
            <KYCComponent nextScreen={"report-download"}/>
          </div>
        </div>
      </div>
    );
  }
}
