import React, { Component } from "react";
import AboutYourBusiness from "../AboutYourBusiness/aboutYourBusiness";
import { Transition, animated } from "react-spring/renderprops";
import ShareOTPLink from "./shareOTPLink";
import UserInput from "./userInput";
import LinkShared from "./linkShared";
import { POST } from "../../../utils/WebAPI.service";
import { API_POST_VALIDATE_GST_OTP } from "../../../utils/APIUrls";
import { loaderModal } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { goTo } from "../../../utils/utility";
class GSTOTPPage extends Component {
  pages = [
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }}>
        <ShareOTPLink nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }}>
        <UserInput nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
    (style) => (
      <animated.div style={{ ...style, position: "absolute", width: "100%" }}>
        <LinkShared nextPageHandlerChild={this.nextPageHandlerChild} {...this.props} />
      </animated.div>
    ),
  ];
  state = { index: 0 };
  nextPageHandlerChild = (e) =>
    this.setState((state) => ({
      index: state.index === 2 ? 0 : state.index + 1,
    }));
  otpHandler = async (e) => {
    var name = e.target.name,
      value = e.target.value;
    await this.setState({ [name]: value, isWrongOtp: false }, () => {});
    if (value.length === 6) {
      let { loaderModal } = this.props.actions;
      var dataToSend = {
        data: {
          gstin: this.props.gstTaxPayerData.gstin,
          otp: value,
        },
      };
      loaderModal(true);
      await POST(API_POST_VALIDATE_GST_OTP, dataToSend).then(({ data }) => {
        if (data.status === "success") {
          goTo(this.props.nextScreen);
        } else {
          this.setState({ isWrongOtp: true, otp: "" });
          goTo(this.props.nextScreen);
        }
      });
      loaderModal(false);
    }
  };
  render() {
    let { isWrongOtp } = this.state;
    return (
      <div className="gst-otp__wrapper">
        <AboutYourBusiness {...this.props} />
        <input
          type="text"
          className={isWrongOtp ? "shake-animation__input otp" : "otp"}
          placeholder="Enter OTP"
          value={this.state.otp}
          onChange={this.otpHandler}
          maxLength={6}
          name={"otp"}
        />
        {isWrongOtp ? <div className="wrong-otp__text">Please Enter correct OTP</div> : null}
        <p className="mt-4 mb-5">
          Didn’t get your OTP? Wait for &nbsp;<span className="button__click-here">10 minutes</span>
        </p>
        <div className="slider-panel">
          <Transition
            items={this.state.index}
            from={{ opacity: 0, transform: "translate3d(100%,0,0)" }}
            enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
            leave={{ opacity: 0, transform: "translate3d(0%,0,0)" }}
            ref={(divElement) => {
              this.divElement = divElement;
            }}
            trail={2}
          >
            {(index) => this.pages[index]}
          </Transition>
        </div>
        <p>
          Need assistance regarding the OTP? <span className="button__click-here">Click here</span>
        </p>
      </div>
    );
  }
}
const mapStateToProps = ({ homePageReducers }) => ({
  gstTaxPayerData: homePageReducers.gstTaxPayerData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ loaderModal }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTOTPPage);
