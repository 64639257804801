import React, { Component } from "react";
import SidePanel from "../../../ViewsComponents/SidePanel/SidePanel";
import GSTOTPPageComponent from "../../../ViewsComponents/GSTOTPPage/GSTOTPPage";
import Header from "../../../ViewsComponents/Header/Header";
export default class GSTOTP extends Component {
  render() {
    return (
      <div>
        <div className="row no-gutters">
          <div className="col-md-3">
            <SidePanel
              advisories={[
                "OTP is valid for only 10 minutes",
                "NOT received OTP - Your CA or Accountant might have received it. Please check with them once before re-triggering",
                "You can also send OTP link to your CA’s or Accountant’s mobile number or Email",
              ]}
            ></SidePanel>
          </div>
          <div className="col-md-9">
            <Header navBar_className={"navbar-light"} backButtonText={"Back"} backLink={"gst-username"} {...this.props}/>
            <GSTOTPPageComponent nextScreen={"Kyc"}/>
          </div>
        </div>
      </div>
    );
  }
}
