import React, { Component } from "react";

class AdsEndingPage extends Component {
  componentDidMount() {
    window.gtag("event", "conversion", {
      send_to: "AW-606313349/Y30hCPPuttoBEIW3jqEC",
    });
  }
  render() {
    return (
      <>
        <div className="ads-wrapper ads-thankyou__wrapper">
          <div className="background-image">
            <div className="content-wrapper white">
              <h1 className="content tc ">
                {" "}
                THANK
                <div className="sub-content tc">YOU!</div>
              </h1>
              <div className="tc callback">
                We Will Get Back To You in next 48 hours!
              </div>
              <div className="button__generic cursor-pointer mt-2 moratorium">
                <div
                  className="link"
                  onClick={() =>
                    this.props.history.push("/calculator/moratorium")
                  }
                >
                  Moratorium Calculator
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdsEndingPage;
