import React from "react";
import { Fragment, Component } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import OtherLoanProducts from "../../components/OtherLoanProducts/OtherLoanProducts";

import CalculateEMI from "../../components/CalculateEMI/calculateEMI";
import LoanInfoBox from "../../components/LoanInfoBox/LoanInfoBox";
import { Helmet } from "react-helmet";
export default class MicroEnterprises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loanAmount: "",
      loanTenure: "",
      processingFee: "",
      interestRate: "",
      decisionTime: "",
      buttonClick: "",
      buttonText: "",
    };
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Unsecured Business Loan - Micro Enterprises</title>
          <meta
            name="description"
            content="Unsecured Business Loan for Micro Enterprises is a short term loan without collateral for very small or micro
                  enterprises who may or may not have proper records of their financial
                transactions and operating the business with limited number of
                employees."
          />
          <meta
            name="tag"
            content="micro and small enterprises loan,micro small medium enterprises loan,micro small and medium enterprises loan,loan for micro enterprises,loan for micro and small enterprises,loan for small enterprise,loan for micro business,small medium enterprises loan,small and medium enterprises loan,micro enterprises loans"
          />
        </Helmet>
        <div className="SME__wrapper">
          <div className="header">
            <Header />
          </div>
          <div className="container center">
            <div className="about__wrapper">
              <br />
              <br />
              <div className="about-title center"> Unsecured Business Loan - Micro Enterprises</div>
              <br />
              <div className="about-details center">
                <p className="fs-16">
                  Unsecured Business Loan for Micro Enterprises is a{" "}
                  <span className="highlight-blue">
                    short term loan without collateral for very small or micro enterprises
                  </span>{" "}
                  who may or may not have proper records of their financial transactions and operating the business with
                  limited number of employees. Micro enterprises are generally small self-employed businesses. This loan
                  will be given to the entity in its promoter’s saving account or current account if available.
                </p>

                <p className="fs-16 width-80">
                  This loan can be availed from multiple financial institutions at same/different time. Generally,
                  proprietorship firms fall in this category.
                </p>
              </div>
            </div>
            <br />
          </div>
          <div className="container">
            <LoanInfoBox
              loanAmount="₹50,000 - ₹5 Lac"
              interestRate="14% - 22%"
              loanTenure="6 months - 2 years"
              decisionTime="Min 3 day"
              processingFee="1.5% - 2.5%"
              buttonClick=""
              buttonText="Find the Lowest Rates"
            />
          </div>

          <div className="horizontal-space" />

          <div className="why-loan__wrapper center">
            <br />
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Why Loan Against Property </div>
              <div className="sub-heading_card_wrapper">
                When to opt for Unsecured Business Loan - Micro Enterprises
              </div>

              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper">
                  <div className="title">Limited Presence</div>
                  This loan is preferred when small business with low volume having limited presence in area
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Wide Business Range</div>
                  Suitable for wide range of business use like small investment, inventory purchase.
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">No collateral involved</div>
                  When funds required urgently and no collateral in hand to be offered
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Individual Entrepreneurs</div>
                  Immediate boost to small business
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Invoice Facility</div>
                  For inventory and machine loan
                </div>
                <div className="SME_subCard_wrapper">
                  <div className="title">Shorter Tenure</div>
                  In comparison to mortgage loan
                </div>
              </div>
            </div>
            <br />
            <div className="horizontal-space" />
          </div>
          <div className="center negative-margin">
          <CalculateEMI 
              businessVintage="Min 1 Year"
              financialDataRequired="Not Registered"
            />
            <div className="horizontal-space" />
          </div>
          <br />
          <br />
          <div className="why-loan__wrapper extra-padding center">
            <div className="SME_card_container container">
              <div className="heading_card_wrapper">Challenges with Unsecured Business Loan- Micro Enterprises</div>
              <div className="SME_card_wrapper">
                <div className="SME_subCard_wrapper  ">Collateral get freezes with one lender</div>
                <div className="SME_subCard_wrapper  ">
                  No flexibility of Part payment so no benefit of buffer cash in bank
                </div>
                <div className="SME_subCard_wrapper  ">
                  Risk of loss of property for borrower in case of default in payments
                </div>
              </div>
            </div>

            <OtherLoanProducts />
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
